import { styled } from 'baseui';

export const Main = styled('main', () => ({
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  display: 'flex',
  flex: '1 1 auto',
  position: 'absolute',
  overflow: 'hidden',
  flexDirection: 'column',
}));

export const ContentContainer = styled('div', () => ({
  display: 'flex',
  flex: '1 1 auto',
  minHeight: 0,
  flexDirection: 'column',
}));

export const ContentFrameWrap = styled('div', ({ $theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  minHeight: 0,
  position: 'relative',
  flexDirection: 'row-reverse',
  backgroundColor: $theme.colors.white,
}));

export const ContentFrame = styled('div', ({ $theme }) => ({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
  minHeight: 0,
  marginLeft: '-100vw',
  overflowX: 'auto',
  position: 'relative',
  backgroundColor: $theme.colors.mono100,
  [$theme.mediaQuery.large]: {
    marginLeft: '0',
    overflowX: 'visible',
  },
}));

export const ContentInner = styled('div', ({ $theme }) => ({
  maxWidth: '100vw',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 0,
  height: '100%',
  overflowY: 'auto',
  [$theme.mediaQuery.large]: {
    maxWidth: 'unset',
  },
}));

