import React from 'react';
import { Tabs, Tab, onChange } from 'baseui/tabs-motion';
import TooltipTab from 'components/tab/tooltip-tab';
import { useMatch, useNavigate } from 'react-router-dom';
import { useTeamContext } from './context/team-context';
import { tabOverrides2, tabsOverrides2 } from '../tabs/baseui-overrides';
import useGetTeamsForUser from './hooks/useGetTeamsForUser';
import tooltipContent from './tool-tip-content';

const Tabnavigation = () => {
  const team  = useTeamContext();

  const { data } = useGetTeamsForUser();

  const navigate = useNavigate();

  const teamId = team.id;

  const match = useMatch({
    path: ':teamType/:id/:tab/*',
  });

  const tabState = match ? match.params.tab : teamId;

  const handleRoute: onChange = ({ activeKey }) => {
    navigate(`${activeKey}`);
  };

  if (team?.teamType === 'BROKER_SINGLE_PROP') {
    return (
      <Tabs
        disabled={!data || !data?.length}
        activeKey={tabState}
        renderAll
        onChange={handleRoute}
        {...tabsOverrides2}
      >
        <Tab key="open-houses" overrides={tabOverrides2} title="Open Houses" />
        <Tab key="members" overrides={tabOverrides2} title="Team Members" />
      </Tabs>
    );
  }

  if (team?.teamType === 'OWNER') {
    return (
      <Tabs
        disabled={!data || !data?.length}
        activeKey={tabState}
        renderAll
        onChange={handleRoute}
        {...tabsOverrides2}
      >
        <Tab key="units" overrides={tabOverrides2} title="Units" />
        <Tab key="members" overrides={tabOverrides2} title="Team Members" />
        <Tab key="campaigns" overrides={tabOverrides2} title="Campaigns" />
        <Tab key="activity" overrides={tabOverrides2} title="Channel Activity" />
      </Tabs>
    );
  }

  if (team?.teamType === 'BROKER') {
    return (
      <Tabs
        disabled={!data || !data?.length}
        activeKey={tabState}
        renderAll
        onChange={handleRoute}
        {...tabsOverrides2}
      >
        <Tab
          key="properties"
          overrides={{
            ...tabOverrides2,
            Tab: {
              ...tabOverrides2.Tab,
              component: React.forwardRef((props, ref) => (
                <TooltipTab {...props} myref={ref} content={tooltipContent.properties} />
              )),
            },
          }}
          title="Properties"
        />
        <Tab
          key="members"
          overrides={{
            ...tabOverrides2,
            Tab: {
              ...tabOverrides2.Tab,
              component: React.forwardRef((props, ref) => (
                <TooltipTab {...props} myref={ref} content={tooltipContent.teamMembers} />
              )),
            },
          }}
          title="Team Members"
        />
        <Tab
          key="campaigns"
          overrides={{
            ...tabOverrides2,
            Tab: {
              ...tabOverrides2.Tab,
              component: React.forwardRef((props, ref) => (
                <TooltipTab {...props} myref={ref} content={tooltipContent.campaigns} />
              )),
            },
          }}
          title="Campaigns"
        />
        <Tab
          key="activity"
          overrides={{
            ...tabOverrides2,
            Tab: {
              ...tabOverrides2.Tab,
              component: React.forwardRef((props, ref) => (
                <TooltipTab {...props} myref={ref} content={tooltipContent.channelActivity} />
              )),
            },
          }}
          title="Channel Activity"
        />
      </Tabs>
    );
  }

  return null;
};

export default Tabnavigation;
