import { IconX } from '@tabler/icons-react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { useCurrentUserContext } from 'context/current-user-context';
import { useEffect, useState } from 'react';

function UserIdentityMessage() {
  const currentUser = useCurrentUserContext();

  const [css, theme] = useStyletron();

  const savedVisibility = sessionStorage.getItem('uim_vis') !== 'false';

  const [visibility, setVisibility] = useState<boolean | null>(savedVisibility);

  const { representingUser, name } = currentUser;

  const handleVisibility = () => {
    setVisibility(false);
    sessionStorage.setItem('uim_vis', 'false');
  };

  useEffect(() => {
    const checkVisibility = sessionStorage.getItem('uim_vis') !== 'false';
    if (representingUser) {
      setVisibility(checkVisibility);
    }
  }, [representingUser]);

  if (representingUser && visibility) {
    return (
      <div
        className={css({
          ...theme.typography.ParagraphXSmall,
          backgroundColor: theme.colors.accent100,
          color: theme.colors.mono800,
          position: 'relative',
          textAlign: 'center',
          paddingTop: theme.sizing.scale0,
          paddingBottom: theme.sizing.scale0,
        })}
      >
        You are working as {name}
        <Button
          size="mini"
          kind="tertiary"
          shape="square"
          overrides={{
            BaseButton: {
              style: () => ({
                color: theme.colors.mono800,
                top: theme.sizing.scale0,
                width: theme.sizing.scale1000,
                height: theme.sizing.scale700,
                position: 'absolute',
                right: theme.sizing.scale600,
                backgroundColor: 'transparent',
                transitionProperty: 'border, background',
                borderTopWidth: '1px',
                borderBottomWidth: '1px',
                borderLeftWidth: '1px',
                borderRightWidth: '1px',
                borderColor: theme.colors.accent100,
                ':hover': {
                  borderColor: theme.colors.mono800,
                  borderTopStyle: 'solid',
                  borderRightStyle: 'solid',
                  borderBottomStyle: 'solid',
                  borderLeftStyle: 'solid',
                  backgroundColor: 'transparent',
                },
              }),
            },
          }}
          onClick={handleVisibility}
        >
          <IconX size={16} stroke={2} />
        </Button>
      </div>
    );
  }
  return null;
}

export default UserIdentityMessage;
