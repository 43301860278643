import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { MenuAdapterProps } from 'baseui/list';
import { StatefulMenu, StyledMenuDivider } from 'baseui/menu';
import { Popover } from 'baseui/popover';
import { Spinner } from 'baseui/spinner';
import { StatefulTooltip } from 'baseui/tooltip';
import { useCurrentUserContext } from 'context/current-user-context';
import { useClient } from 'context/client-context';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import MenuListItem from '../../lead/MenuListItem';
import useMenuHandlers from './hooks/useMenuHandlers';
import { useTeamContext } from '../context/team-context';
import useTeamPermissions from '../hooks/useTeamPermissions';
import useAddTeamWatch from '../watchWarnings/useAddTeamWatch';
import useRemoveTeamWatch from '../watchWarnings/useRemoveTeamWatch';

const TeamMenu = () => {
  const [css, theme] = useStyletron();

  const { id, userManageTeams, role } = useCurrentUserContext();

  const canAddTeam = role === 'SUPERUSER' || userManageTeams;

  const data = useTeamContext();

  const { canManageConfiguration } = useTeamPermissions();

  const { addBrokerTeam, deleteTeam } = useMenuHandlers();

  const client = useClient();

  // const location = useLocation();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false); // popover menu

  // Watch permission
  const teamLead = data?.teamMembers.filter((member) => member.role === 'TEAM_LEAD');

  const isTeamLead = id === teamLead?.[0].userRef;

  const canWatch = !isTeamLead && (role === 'SUPERUSER' || role === 'READ_ONLY_USER');

  const isWatching = Boolean(data?.watchWarnings?.find((uid) => uid === id));

  const watch = useAddTeamWatch();

  const unwatch = useRemoveTeamWatch();

  const handleWatchTeam = () => {
    if (isWatching) {
      unwatch.mutate(data?.id);
      return;
    }
    watch.mutate(data?.id);
  };

  const menu: any[] = [];

  if (canManageConfiguration && !!data?.id) {
    menu.push({
      label: 'Settings',
      id: 'settings',
      icon: () => <FontAwesomeIcon fixedWidth icon={solid('gear')} />,
      handler: () => navigate('settings'),
    });
  }

  if (!!client.state?.isBroker && canAddTeam) {
    menu.push({
      label: `Add ${client.state?.displayValue}`,
      id: 'add',
      icon: () => <FontAwesomeIcon fixedWidth icon={solid('plus')} />,
      handler: addBrokerTeam,
    });
  }

  if ((client.state?.isBroker || client.state?.isOwner) && canManageConfiguration && data?.id) {
    menu.push({
      label: `Delete ${client.state?.displayValue}`,
      id: 'delete',
      icon: () => <FontAwesomeIcon fixedWidth icon={regular('trash-can')} />,
      handler: deleteTeam,
    });
  }

  if (canWatch) {
    if (menu.length > 0) {
      menu.push({ id: 'divider' });
    }

    menu.push({
      label: (
        <>
          {isWatching ? 'Mute Notifications' : 'Receive Notifications'}{' '}
          {(watch.isLoading || unwatch.isLoading) && <Spinner $size="14px" $borderWidth="2px" />}
        </>
      ),
      id: 'watch',
      disabled: watch.isLoading || unwatch.isLoading,
      icon: () =>
        isWatching ? (
          <FontAwesomeIcon fixedWidth icon={regular('eye')} />
        ) : (
          <FontAwesomeIcon fixedWidth icon={solid('eye')} />
        ),
      handler: handleWatchTeam,
    });
  }

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // const closeModal = () => {
  //   setIsModalOpen(false);
  //   setComponent(undefined);
  // };

  const onSelectHandler = (item: { label: string; id: string; handler: any }) => {
    switch (item.id) {
      // settings
      // delete
      // add
      // watch
      case 'watch':
        item?.handler();
        break;
      default:
        setIsOpen(false);
        item?.handler();
    }
  };

  // useEffect(() => {
  //   if (state && state.openTeamSetting) {
  //     navigate('.', { replace: true });
  //     addBrokerTeam();
  //   }
  // }, [addBrokerTeam, state, navigate]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClickOutside={handleClickOutside}
        onClick={toggle}
        // placement="topRight"

        overrides={{
          Inner: { style: { backgroundColor: 'white' } },
        }}
        content={() => (
          <StatefulMenu
            onItemSelect={({ item }) => onSelectHandler(item)}
            items={menu}
            overrides={{
              List: {
                style: () => ({
                  minWidth: '256px',
                  minHeight: '0px',
                  maxHeight: '250px',
                  boxShadow: 'none',
                }),
              },
              Option: {
                props: {
                  overrides: {
                    ListItem: {
                      component: React.forwardRef<HTMLLIElement, MenuAdapterProps>(
                        (props: React.PropsWithChildren<MenuAdapterProps>, ref) => {
                          if (props.item?.id === 'divider') {
                            return <StyledMenuDivider />;
                          }
                          return (
                            <MenuListItem
                              {...props}
                              ref={ref}
                              artwork={props.item.icon}
                              endEnhancer={props.item.secondaryIcon || null}
                              artworkSize="SMALL"
                            >
                              {props.children}
                            </MenuListItem>
                          );
                        },
                      ),
                    },
                  },
                },
              },
            }}
          />
        )}
      >
        <div>
          <StatefulTooltip
            placement="top"
            popoverMargin={6}
            content="Actions"
            overrides={tooltipOverrides}
          >
            <Button size="compact" type="button" kind="tertiary" isSelected={isOpen}>
              <div className={css({ fontSize: theme.sizing.scale700 })}>
                <FontAwesomeIcon icon={solid('ellipsis')} />
              </div>
            </Button>
          </StatefulTooltip>
        </div>
      </Popover>
    </>
  );
};

export default TeamMenu;
