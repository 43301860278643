import { IconAlertCircle } from '@tabler/icons-react';
import { useStyletron } from 'baseui';

export default function FailedMessageIcon() {
  const [css, theme] = useStyletron();

  return (
    <IconAlertCircle
      className={css({ flexGrow: 0, flexShrink: 0, color: theme.colors.negative500 })}
      stroke={2}
      size={18}
    />
  );
}
