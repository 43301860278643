import React from 'react';
import { useCurrentUserContext } from 'context/current-user-context';
import useGetLead from './useGetLead';
import useGetTeam from '../../team/hooks/useGetTeam';
import useGetCampaign from '../../team/hooks/useGetCampaign';
import { TeamPermission } from '../../../backend/user';

const usePermissions = (leadId: string | undefined) => {
  const { id: userRef, teamPermissions } = useCurrentUserContext();

  const { data: lead, isLoading: isLeadLoading } = useGetLead(leadId);

  const { data: team, isLoading: isLoadingTeam } = useGetTeam(lead?.teamRef);

  const { data: campaign, isLoading: isCampaignLoading } = useGetCampaign(lead?.campaignRef);

  const isLoading = isLeadLoading || isLoadingTeam || isCampaignLoading;

  const teamSettings = team?.settings;

  const getTeamPermission = React.useCallback(
    (teamRef: string | undefined, key: keyof TeamPermission) => {
      if (!teamRef) {
        return false;
      }
      return teamPermissions?.[teamRef]?.[key];
    },
    [teamPermissions],
  );

  const permissions = React.useMemo(() => {
    const { assignedAgentRef, teamRef, leadStatus } = { ...lead };

    const { assignmentMode, collaborationMode } = { ...teamSettings };

    const isMyLead = assignedAgentRef === userRef;

    const fullAccess = getTeamPermission(teamRef, 'fullAccess');

    const readonly = getTeamPermission(teamRef, 'readonly');

    const assignLeads = getTeamPermission(teamRef, 'assignLeads');

    const agent = getTeamPermission(teamRef, 'agent');

    const isCampaignTeamMember =
      campaign?.scope === 'FEED_ADDRESS_OWNER' ||
      campaign?.scope === 'WHOLE_TEAM' ||
      (campaign?.category === 'default' && campaign?.status === 'ACTIVE') ||
      (!campaign && isMyLead) ||
      (!campaign && collaborationMode === 'FULL') ||
      (!!userRef && campaign?.teamMembers?.includes(userRef));

    // const isTeamLockMode = lockMode === 'TIMED' || lockMode === 'UNLIMITED';

    /**
     * In all conditions, user must be a team member with agent rights
     * on the team to initiate communication under one of the following:
     * 1. Lead unassigned and team is CLAIM and user is agent and
     *    campaign team member
     * 2. Current user owns the lead
     * 3. Lead assigned and user has fullacces (via super or team lead)
     * 4. Lead is assigned but not owned by current user and team is
     *    full collaboration
     * @type {Boolean} -
     */
    const canCommunicate =
      (!assignedAgentRef && agent && assignmentMode === 'CLAIM' && isCampaignTeamMember) ||
      isMyLead ||
      (!!assignedAgentRef && fullAccess && agent && isCampaignTeamMember) ||
      (!!assignedAgentRef &&
        agent &&
        !isMyLead &&
        isCampaignTeamMember &&
        collaborationMode === 'FULL');

    /**
     * Permission to assign leads must meet one of the following conditions:
     * 1. fullAccess via superuser or team lead
     * 2. assignLeads team permission
     * 3. current user owns lead
     * 4. Assigned but not to current user, user has agent rights and is
     *    on the campaign, and team is full collaboration
     * @type {Boolean}
     */
    const canAssign =
      fullAccess ||
      assignLeads ||
      isMyLead ||
      (!!assignedAgentRef &&
        agent &&
        !isMyLead &&
        isCampaignTeamMember &&
        collaborationMode === 'FULL') ||
      campaign?.scope === 'FEED_ADDRESS_OWNER';

    /**
     * Permission and rules to shared leads extends canAssign plus:
     * 1. lead must an OPPORTUNITY, PROSPECT or WAITING_LIST
     * @type {Boolean}
     */
    const canShare =
      canAssign &&
      (leadStatus === 'OPPORTUNITY' || leadStatus === 'PROSPECT' || leadStatus === 'WAITING_LIST');

    /**
     * Permission to edit lead details such as type, contacts, search
     * criteria and used to show/hide the make
     * opportunity/prospect/waitlist buttons. Must meet one of the following
     * conditions:
     * 1. fullAccess via superuser or team lead
     * 2. Current user owns the lead
     * 3. Lead is assigned but not to current user, user has agent
     *    rights and is on the campaign, and team is in full collaboration
     * @type {Boolean}
     */
    const canEdit =
      fullAccess ||
      isMyLead ||
      (!assignedAgentRef && agent && assignmentMode === 'CLAIM' && isCampaignTeamMember) ||
      (!!assignedAgentRef &&
        agent &&
        !isMyLead &&
        isCampaignTeamMember &&
        collaborationMode === 'FULL');
    // (!!assignedAgentRef && agent && !isMyLead && collaborationMode === 'FULL') ||
    // (!assignedAgentRef && !isMyLead && agent);

    /**
     * Permission and rules to set tours, extends canEdit plus:
     * 1. lead must be a NEW_LEAD or OPPORTUNITY
     * @type {Boolean}
     */
    const canSetTour = canEdit && (leadStatus === 'NEW_LEAD' || leadStatus === 'OPPORTUNITY');

    /**
     * Permission/rules to set property INTERESTED or NOT_INTERESTED:
     * 1. lead must be an NEW_LEAD, OPPORTUNITY or CLOSED
     * @type {Boolean}
     */
    const canSetPropertyStatus =
      canEdit &&
      (leadStatus === 'NEW_LEAD' || leadStatus === 'OPPORTUNITY' || leadStatus === 'CLOSED');

    /**
     * Permission to add a comment to lead
     * @type {Boolean}
     */
    const canComment = canEdit || canAssign || canCommunicate;

    /**
     * Permission to open lead detail page
     * @type {Boolean}
     */
    const canOpen =
      canEdit ||
      readonly ||
      canAssign ||
      canCommunicate || // canCommunicate handles unassigned in CLAIM needs to be a member of the campaign
      (!!assignedAgentRef &&
        agent &&
        !isMyLead &&
        isCampaignTeamMember &&
        (collaborationMode === 'VISIBILITY' || collaborationMode === 'FULL'));

    // in CLAIM, regardless of collaboration mode, user needs to be on campaign.
    return {
      canOpen,
      canEdit,
      canComment,
      canCommunicate,
      canAssign,
      canShare,
      canSetTour,
      canSetPropertyStatus,
      isLoading,
      isCampaignTeamMember,
      readOnlyState:
        readonly ||
        (!!assignedAgentRef && agent && !isMyLead && collaborationMode === 'VISIBILITY'),
    };
  }, [campaign, getTeamPermission, isLoading, lead, teamSettings, userRef]);

  return { ...permissions };
};

export default usePermissions;
