import React from 'react';
import { Input } from 'baseui/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useStyletron } from 'baseui';
import { useNavigationState, useNavigationUpdater } from './context/navigation-context';

const NavigationFilter = () => {
  const [css, theme] = useStyletron();

  const {
    state: { filters },
  } = useNavigationState();

  const { dispatch } = useNavigationUpdater();

  const updateFilter = (e: React.ChangeEvent<any>) => {
    dispatch({ type: 'SET_TEAM_FILTER', payload: e.target.value });
  };

  return (
    <div
      className={css({
        paddingBottom: 0,
        paddingLeft: theme.sizing.scale400,
        paddingRight: theme.sizing.scale500,
        paddingTop: 0,
        marginBottom: theme.sizing.scale200,
        marginTop: theme.sizing.scale200,
      })}
    >
      <Input
        clearable
        onChange={updateFilter}
        overrides={{
          Root: {
            style: () => ({
              backgroundColor: theme.colors.mono200,
              borderBottomStyle: 'none',
              borderLeftStyle: 'none',
              borderRightStyle: 'none',
              borderTopStyle: 'none',
            }),
          },
          InputContainer: {
            style: {
              backgroundColor: theme.colors.mono200,
            },
          },
          StartEnhancer: {
            style: {
              backgroundColor: theme.colors.mono200,
            },
          },
        }}
        placeholder="Search"
        size="compact"
        startEnhancer={
          <FontAwesomeIcon
            color={theme.colors.mono500}
            size="sm"
            icon={solid('magnifying-glass')}
          />
        }
        type="text"
        value={filters.team}
      />
    </div>
  );
};

export default NavigationFilter;
