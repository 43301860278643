import { useQuery } from 'react-query';
import useAxios from '../../../hooks/useAxios';
import { Team } from '../../../backend/team';

function useTeams() {
  const axios = useAxios();
  return {
    async getTeams() {
      const { data } = await axios.get<Team[]>('/api/teams');
      return data;
    },
  };
}

const useGetTeamsForUser = (options?: {}) => {
  const { getTeams } = useTeams();

  return useQuery({
    queryFn: getTeams,
    queryKey: ['getTeamsForUser'],
    ...options,
  });
};

export default useGetTeamsForUser;
