/* eslint-disable @typescript-eslint/no-unused-vars */
import { styled } from 'baseui';

type StyledPopoutProps = {
  $isExpanded?: boolean;
};

export const StyledPopoutStructure = styled<'div', StyledPopoutProps>(
  'div',
  ({ $theme, $isExpanded }) => ({
    // display: 'flex',
    width: '100%',
    minWidth: '354px',
    flexDirection: 'column',
    [$theme.mediaQuery.medium]: {
      // top: 'auto',
      // right: 0,
      // display: 'block',
      width: $isExpanded ? '500px' : '354px',
    },
  }),
);

export const StyledPopoutHeader = styled<'div', StyledPopoutProps >('div', ({ $theme }) => ({}));

export const StyledPopoutContent = styled<'div', StyledPopoutProps>(
  'div',
  ({ $theme, $isExpanded }) => ({
    height: $isExpanded ? 'auto' : '0px',
    overflow: $isExpanded ? 'auto' : 'hidden',
    // display: 'flex',
    flexGrow: 1,
    // overflowX: 'hidden',
    // flexDirection: 'column',
    ...$theme.typography.ParagraphSmall,
    // [$theme.mediaQuery.medium]: {
    //   height: $isExpanded ? 'auto' : '0px',
    // },
  }),
);

export const StyledPopoutBoundary = styled<'div', StyledPopoutProps>('div', ({ $theme }) => ({
  height: '70vh',
  maxHeight: '400px',
  // overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',
  minHeight: '1px',
  [$theme.mediaQuery.medium]: {
    height: '70vh',
    maxHeight: '400px',
  },
}));

export const StyledPopoutIconWrapper = styled('div', ({ $theme }) => ({
  width: '28px',
  height: '28px',
  display: 'flex',
  flex: '0 0 auto',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: $theme.sizing.scale550,
  color: $theme.colors.mono300,
  marginRight: $theme.sizing.scale200,
  ':last-child': {
    marginRight: $theme.sizing.scale600,
  },
  cursor: 'pointer',
  borderRadius: '6px',
  ':hover': {
    color: $theme.colors.white,
    backgroundColor: $theme.colors.mono900,
  },
}));
