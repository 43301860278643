import React, { useEffect } from 'react';
import { Button } from 'baseui/button';
import { Select, SIZE } from 'baseui/select';
import { LabelSmall } from 'baseui/typography';
import { FormControl } from 'baseui/form-control';
import { StatefulTooltip } from 'baseui/tooltip';
import { ButtonGroup } from 'baseui/button-group';
import { useClient } from 'context/client-context';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { groupedButtonOverrides } from 'components/lead/searchCriteria/styled-components';
import useGetUsers from 'hooks/useGetUsers';
import useGetProperties from 'components/properties/hooks/useGetProperties';
import RequiredFieldMarker from 'components/shared/RequiredFieldMarker';
import ControlledCheckbox from 'components/team/components/ControlledCheckbox';
import AppLoadingScreen from 'components/screen/AppLoadingScreen';
import * as S from './styled-components';
import { FormStepPropsT } from './types';

const TeamLeadForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit, workingPropertyRef } = props;

  const client = useClient();

  const isOwner = client.state?.isOwner;

  const teamTypes = client.state?.teamTypes;

  const propertyTypes = client.state?.propertyTypes;

  const { data, isLoading } = useGetProperties({ enabled: isOwner });

  const [selectedPropertyTypes, setSelectedPropertyTypes] = React.useState<number[]>([]);

  const userQuery = useGetUsers({ enabled: true });

  const users = userQuery.data;

  const filteredUsers = users
    ? users.filter((user) => user.status === 'ACTIVE' && user.role !== 'READ_ONLY_USER')
    : [];

  const {
    control,
    clearErrors,
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();

  const propertyTypesEnum: { [k: string]: number } | undefined = React.useMemo(
    () =>
      propertyTypes?.reduce(
        (obj, value, i) => ({
          ...obj,
          [value]: i,
        }),
        {},
      ),
    [propertyTypes],
  );

  useEffect(() => {
    // Register owner client fields
    if (isOwner) {
      register('propertyRef', {
        setValueAs: (val) => [{ id: val, label: val }],
      });
      register('teamName');
    }
  }, [isOwner, register]);

  useEffect(() => {
    if (isOwner) {
      // Set propertyRef to working property in progress
      setValue('propertyRef', workingPropertyRef);
    }
  }, [isOwner, setValue, workingPropertyRef]);

  useEffect(() => {
    if (isOwner) {
      // Set teamName to match property name
      const result = data?.filter((record) => record?.id === workingPropertyRef)[0];
      if (result) {
        setValue('teamName', result.name);
      }
    }
  }, [isOwner, register, setValue, data, workingPropertyRef]);

  useEffect(() => {
    register('teamType', { value: teamTypes?.[0] });
  }, [register, teamTypes]);

  useEffect(() => {
    const v = getValues('propertyTypes');
    // Sync setSelectedPropertyTypes to propertyTypes value on mount
    const currentValues = v?.map((item: any) => propertyTypesEnum?.[item]);
    if (currentValues) {
      setSelectedPropertyTypes(currentValues);
    }
  }, [getValues, propertyTypesEnum]);

  useEffect(() => {
    if (propertyTypes && propertyTypes?.length === 1) {
      // Client only supports 1 propertyType, set value on the hidden propertyTypes field
      setValue('propertyTypes', propertyTypes);
      return;
    }
    // Set propertyTypes value to selectedPropertyTypes state change
    const selected = selectedPropertyTypes.map((num) => propertyTypes?.[num]);
    setValue('propertyTypes', selected, { shouldDirty: !!selected.length });
  }, [selectedPropertyTypes, setValue, propertyTypes]);

  if (isLoading) {
    return <AppLoadingScreen height="150px" />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
      <Controller
        name="teamLead"
        control={control}
        render={({ field: { value, ref, onChange } }) => (
          <FormControl
            label={
              <>
                <RequiredFieldMarker>Select a Team Leader</RequiredFieldMarker>
                <StatefulTooltip
                  content={() => (
                    <S.MoreInfoToolTip>
                      The team leader has full control over team settings. You can change the team
                      leader at any time.
                    </S.MoreInfoToolTip>
                  )}
                >
                  <S.MoreInfoTip>
                    <FontAwesomeIcon icon={solid('circle-info')} />
                  </S.MoreInfoTip>
                </StatefulTooltip>
              </>
            }
            error={errors?.teamLead?.message as string | undefined}
          >
            <Select
              size={SIZE.compact}
              isLoading={userQuery.isLoading}
              error={!!errors.teamLead}
              controlRef={ref as any}
              value={value}
              clearable={false}
              placeholder="Select"
              maxDropdownHeight="25vh"
              onChange={(params) => {
                onChange(params.value);
              }}
              options={filteredUsers.map((u: any) => ({ label: u.name, id: u.id }))}
            />
          </FormControl>
        )}
      />
      <FormControl label="">
        <ControlledCheckbox
          control={control}
          label={
            <>
              <LabelSmall display="inline-block">Include team leader in phone transfers</LabelSmall>
              <StatefulTooltip
                content={() => (
                  <S.MoreInfoToolTip>
                    Check this option to include the team leader in the phone call routing.
                  </S.MoreInfoToolTip>
                )}
              >
                <S.MoreInfoTip>
                  <FontAwesomeIcon icon={solid('circle-info')} />
                </S.MoreInfoTip>
              </StatefulTooltip>
            </>
          }
          name="acceptPhoneTransfers"
        />
      </FormControl>

      {propertyTypes && propertyTypes?.length === 1 ? (
        <input type="hidden" {...register('propertyTypes', { value: propertyTypes })} />
      ) : (
        <>
          <input
            type="hidden"
            {...register('propertyTypes', {
              setValueAs: (v) =>
                Array.isArray(v) ? v : v.split(',').filter((value: string) => value),
            })}
          />

          <FormControl
            label={<RequiredFieldMarker>Supported Property Types</RequiredFieldMarker>}
            error={errors?.propertyTypes?.message as string | undefined}
          >
            <>
              <ButtonGroup
                mode="checkbox"
                kind="tertiary"
                size="compact"
                selected={selectedPropertyTypes}
                onClick={(_, index) => {
                  if (!selectedPropertyTypes.includes(index)) {
                    setSelectedPropertyTypes([...selectedPropertyTypes, index]);
                  } else {
                    setSelectedPropertyTypes(
                      selectedPropertyTypes.filter((value) => value !== index),
                    );
                  }
                  clearErrors('propertyTypes');
                }}
              >
                <Button type="button" {...groupedButtonOverrides}>
                  Commercial
                </Button>
                <Button type="button" {...groupedButtonOverrides}>
                  Residential
                </Button>
              </ButtonGroup>
            </>
          </FormControl>
        </>
      )}
    </form>
  );
};

export default TeamLeadForm;
