/* eslint-disable @typescript-eslint/no-unused-vars */
import { useStyletron } from 'baseui';
import { FormControl } from 'baseui/form-control';
import { useFormContext } from 'react-hook-form';
import ControlledInput from '~/components/team/components/ControlledInput';

type Props = {
  formRef?: string;
  onSubmit: (d: any) => any;
  showOptionalMessage?: boolean;
};
function WebChannelRequestForm(props: Props) {
  const formMethods = useFormContext();

  const { formRef, onSubmit, showOptionalMessage } = props;

  const [css, theme] = useStyletron();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  return (
    <>
      <div className={css({ ...theme.typography.ParagraphSmall })}>
        Track page visits and leads from a website with a shareable QR code tracking link.
      </div>
      {showOptionalMessage && (
        <div className={css({ ...theme.typography.ParagraphSmall })}>
          This step is optional and you can always change or edit it later. Skip this step.
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
        <FormControl label="Page Name" error={errors?.pageName?.message as string | undefined}>
          <ControlledInput
            baseWebProps={{ size: 'compact', clearable: true }}
            name="pageName"
            control={control}
          />
        </FormControl>
        <FormControl label="URL" error={errors?.url?.message as string | undefined}>
          <ControlledInput
            baseWebProps={{ size: 'compact', clearable: true }}
            name="url"
            control={control}
          />
        </FormControl>
      </form>
    </>
  );
}

export default WebChannelRequestForm;
