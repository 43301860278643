import { useWebSocketObservable } from 'context/web-socket-context';
import { useCallback } from 'react';
import { MessageEvent } from '../../backend/messages';

function useWebSocketMessage() {
  const messages$ = useWebSocketObservable();

  const createSubscription = useCallback(
    (messagrHandler: (m: MessageEvent<unknown>) => any) => {
      const subscription = messages$.subscribe({
        next: (messageEvent: MessageEvent<unknown>) => {
          messagrHandler(messageEvent);
        },
        error: (e) => console.error(e),
        complete: () => console.info('[useWebSocketMessage] complete'),
      });
      return subscription;
    },
    [messages$],
  );

  return { createSubscription };
}

export default useWebSocketMessage;
