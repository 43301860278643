import { useQuery } from 'react-query';
import { Property } from '../../../backend/property';
import useAxios from '../../../hooks/useAxios';

function useProperty() {
  const axios = useAxios();
  return {
    async getProperty({ queryKey }: any) {
      const [, id] = queryKey;
      if (typeof id === 'undefined') return Promise.reject();
      const { data } = await axios.get<Property>(`api/property/${id}`);
      return data;
    },
  };
}

function useGetProperty(id: string | undefined, options?: {}) {
  const { getProperty } = useProperty();
  return useQuery({
    queryFn: getProperty,
    queryKey: ['getProperty', id, ...(options ? [options] : [])],
    enabled: Boolean(id),
    retry: false,
    ...options,
  });
}

export default useGetProperty;
