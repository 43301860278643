import React from 'react';
import { ModalOverrides } from 'baseui/modal';
import { useModal } from 'components/modal/ModalProvider';
import CreateBrokerTeam from 'modules/team/admin/CreateBrokerTeam';
import DeleteTeam from 'modules/team/admin/DeleteTeam';
import useTeamLocation from './useTeamLocation';

const dialogOverrides: ModalOverrides = {
  Dialog: {
    style: {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      marginBottom: 'auto',
      marginTop: '32px',
      width: '600px',
    },
  },
};

function useMenuHandlers() {
  const { setSelectedTeam } = useTeamLocation();

  const { closeModal, isOpen, modalHandler } = useModal();

  const addBrokerTeam = () => {
    modalHandler(
      <CreateBrokerTeam isOpen={isOpen} setIsOpen={closeModal} setSelectedTeam={setSelectedTeam} />,
      {
        closeable: false,
        overrides: {
          ...dialogOverrides,
        },
      },
    );
  };

  const deleteTeam = () => {
    modalHandler(<DeleteTeam />, {
      size: 'full',
      overrides: {
        Dialog: {
          style: {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            marginBottom: 'auto',
            marginTop: '32px',
            maxWidth: '770px',
          },
        },
      },
    });
  };

  return { addBrokerTeam, deleteTeam };
}

export default useMenuHandlers;
