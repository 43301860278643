import { BaseUiOption, Weekday } from './types';
import { PhoneTypes } from '../backend/user';

export const phoneNumberTypeOptions: BaseUiOption<PhoneTypes>[] = [
  { label: 'Home', id: 'HOME' },
  { label: 'Office', id: 'OFFICE' },
  { label: 'Mobile', id: 'MOBILE' },
  { label: 'Other', id: 'OTHER' },
];

export enum VerificationState {
  UNKNOWN = 'unknown', // No Phone State
  APPROVED = 'approved', // MOBILE && APPROVED
  NOT_APPROVED = 'not_approved', // MOBILE && NOT APPROVED
  NOT_REQUIRED = 'not_required',
}

export const WEEKDAYS: Weekday[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const DAY = {
  SUNDAY: 'Sunday',
  MONDAY: 'Monday',
  TUESDAY: 'Tuesday',
  WEDNESDAY: 'Wednesday',
  THURSDAY: 'Thursday',
  FRIDAY: 'Friday',
  SATURDAY: 'Saturday',
};

export const STATES = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const DATE_RANGE: any = {
  ALL: 'All Dates',
  TODAY: 'Today',
  YESTERDAY: 'Yesterday',
  PAST_WEEK: 'Past Week',
  PAST_MONTH: 'Past Month',
  CUSTOM: 'Custom',
};

export const MAX_TEXT_AREA_LENGTH = 512;
