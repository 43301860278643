import { Alert } from 'backend/messages';
import useGetAlerts from 'components/notifications/useGetAlerts';

function useAlerts(orderDesc: boolean, showUnavailable: boolean) {
  const { data } = useGetAlerts();

  const sortByDate = (a: Alert & { createdDate: Date }, b: Alert & { createdDate: Date }) => {
    const prev = a.createdDate.getTime();
    const curr = b.createdDate.getTime();

    if (orderDesc) {
      return curr - prev;
    }
    return prev - curr;
  };

  const refineData = data?.filter((alert) => {
    if (alert.alertType === 'INCOMING_COMMUNICATION') {
      return !alert.lead.assignedAgentRef;
    }
    return alert;
  });

  const count = refineData?.length;

  const alerts = refineData
    ?.filter((alert) => {
      if (showUnavailable) return alert;
      return alert.invalidated === false;
    })
    .map((alert) => ({ ...alert, createdDate: new Date(alert.createdTs) }))
    .sort(sortByDate);

  return { alerts, count };
}

export default useAlerts;
