import { useQuery } from 'react-query';
import useAxios from './useAxios';
import { Client } from '../backend/client';

function useClient() {
  const axios = useAxios();
  return {
    async getClient({ queryKey }: any) {
      const [, id] = queryKey;
      const { data } = await axios.get<Client>(`api/client/${id}`);
      return data;
    },
  };
}

function useGetClient(id: string | undefined, options?: {}) {
  const { getClient } = useClient();

  return useQuery({
    queryFn: getClient,
    queryKey: ['getClient', id],
    enabled: !!id,
    refetchOnWindowFocus: false,
    ...options,
  });
}
export default useGetClient;
