import { useQuery } from 'react-query';
import useAxios from './useAxios';
import { User } from '../backend/user';

function useUsers() {
  const axios = useAxios();
  return {
    async getUsers() {
      const { data } = await axios.get<User[]>('api/users');
      return data;
    },
  };
}

function useGetUsers(options?: {}) {
  const { getUsers } = useUsers();
  return useQuery({
    queryFn: getUsers,
    queryKey: ['getUsers'],
    enabled: false,
    ...options,
  });
}

export default useGetUsers;
