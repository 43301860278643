import { useQuery } from 'react-query';
import { TwilioBusinessProfile } from 'backend/twilio';
import useAxios from './useAxios';

function useGetBusinessProfiles() {
  const axios = useAxios();
  return {
    async businessProfiles() {
      const { data } = await axios.get<TwilioBusinessProfile[]>('api/business-profiles');
      return data;
    },
  };
}

function useBusinessProfiles(options?: {}) {
  const { businessProfiles } = useGetBusinessProfiles();
  return useQuery({
    queryFn: businessProfiles,
    queryKey: ['businessProfiles'],
    retry: false,
    staleTime: 300000,
    ...options,
  });
}

export default useBusinessProfiles;
