import { useContext } from 'react';
import { AxiosContext } from '../context/AxiosContext';

const useAxios = () => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('Missing Axios');
  }
  return context.instance;
};

export const useAxiosIdentity = () => {
  const context = useContext(AxiosContext);
  if (!context) {
    throw new Error('No provider for axios identity.');
  }

  return { identity: context.identity, setIdentity: context.setIdentity };
};

export default useAxios;
