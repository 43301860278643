import { ColumnFiltersState, SortingState } from '@tanstack/react-table';
import { createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { addDateRange } from '~/helpers/util';

export enum List {
  All = 'list.0',
  NotContacted = 'list.1',
  Unassigned = 'list.2',
  Opportunity = 'list.3',
  Prospect = 'list.4',
  WaitingList = 'list.5',
  SummaryReport = 'list.6',
  Unresponded = 'list.7',
  NewDevCustomReport = 'list.8',
}

interface TableState {
  filters: ColumnFiltersState;
  sort: SortingState;
}

export type UserPageState = {
  [key in List]: TableState;
};
export interface UserInitProp {
  id: string;
}
export interface UserProps extends UserInitProp {
  page: UserPageState;
}
export interface UserState {
  users: UserProps[];
}

export type UserStore = ReturnType<typeof createUserStore>;

const initialFilters = [
  { id: 'inquiry', value: true },
  {
    id: 'timePeriod',
    value: [
      {
        id: 'MTD',
        label: 'Month to Date',
      },
    ],
  },
];

const newDevCustomDefault = addDateRange('MTD')?.map((d) => d.toISOString());

export const defaultPageState = {
  'list.0': {
    filters: initialFilters,
    sort: [],
  },
  'list.1': {
    filters: initialFilters,
    sort: [],
  },
  'list.2': {
    filters: initialFilters,
    sort: [],
  },
  'list.3': {
    filters: initialFilters,
    sort: [],
  },
  'list.4': {
    filters: initialFilters,
    sort: [],
  },
  'list.5': {
    filters: initialFilters,
    sort: [],
  },
  'list.6': {
    filters: [],
    sort: [],
  },
  'list.7': {
    filters: [{ id: 'communicationDaysBack', value: [{ id: '7', label: 'Past 7 days' }] }],
    sort: [],
  },
  'list.8': {
    filters: [...initialFilters, { id: 'dates', value: newDevCustomDefault }],
    sort: [],
  },
};

export const createUserStore = ({ id }: UserInitProp) => {
  const store = createStore(
    persist<UserState>(
      () => ({
        // users: getState().users.map((u) => (u.id === userRef ? { ...u } : { id: userRef, page: {} })),
        users: [
          {
            id,
            page: {
              ...defaultPageState,
            },
          },
        ],
      }),
      {
        name: 'lead.list',
        version: 4,
        migrate(persistedState, version) {
          if (version === 0) {
            try {
              const old = [
                'QWxs',
                'T3Bwb3J0dW5pdHk',
                'Tm90Q29udGFjdGVk',
                'TmV3RGV2Q3VzdG9tUmVwb3J0',
                'U3VtbWFyeVJlcG9ydA',
                'UHJvc3BlY3Q',
                'V2FpdGluZ0xpc3Q',
                'VW5hc3NpZ25lZA',
                'VW5yZXNwb25kZWQ',
              ];
              old.forEach((k) => {
                // eslint-disable-next-line no-param-reassign
                delete (persistedState as any)[k];
              });
            } catch (e) {
              return persistedState as UserState;
            }
          }
          if (version === 1 || version === 2 || version === 3) {
            try {
              // eslint-disable-next-line no-param-reassign
              delete (persistedState as any).users;
            } catch (e) {
              return persistedState as UserState;
            }
          }
          return persistedState as UserState;
        },
      },
    ),
  );
  return store;
};
