import ControlledInput from '~/components/team/components/ControlledInput';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { FormControl } from 'baseui/form-control';
import { styled, useStyletron } from 'baseui';
import Select from 'components/select/Select';
import { useFormContext } from 'react-hook-form';
import { ParagraphSmall } from 'baseui/typography';
import ControlledCheckbox from 'components/team/components/ControlledCheckbox';
import { makeNumberRange } from 'helpers/util';
import { FormStepPropsT, UseFormTeamWarningsT } from './types';

const StyledNotificationSubSetting = styled<any, { $disabled: boolean }>(
  'div',
  ({ $theme, $disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: $theme.sizing.scale900,
    marginTop: $theme.sizing.scale300,
    marginBottom: $theme.sizing.scale300,
    ...$theme.typography.ParagraphSmall,
    flexWrap: 'wrap',
    color: $disabled ? $theme.colors.mono400 : $theme.colors.mono900,
  }),
);

const StyledFieldGroup = styled('div', ({ $theme }) => ({
  display: 'flex',
  marginLeft: $theme.sizing.scale200,
  marginRight: $theme.sizing.scale200,
}));

const HOURS = makeNumberRange(25, 1);

const DAYS = makeNumberRange(31, 1);

const registerConfig = { setValueAs: (n: string) => parseInt(n, 10) };

const NotificationsForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit } = props;

  const [css, theme] = useStyletron();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useFormContext<UseFormTeamWarningsT>();

  const [
    useWarnings$,
    notAssigned$,
    notContacted$,
    communicationNotResponded$,
    notQualified$,
    noActivity$,
  ] = watch([
    'warningSettings.useWarnings',
    'warningSettings.notAssigned',
    'warningSettings.notContacted',
    'warningSettings.communicationNotResponded',
    'warningSettings.notQualified',
    'warningSettings.noActivity',
  ]);

  const [
    notAssignedUnit$,
    notContactedUnit$,
    communicationNotRespondedUnit$,
    notQualifiedUnit$,
    noActivityUnit$,
  ] = watch([
    'warningSettings.notAssignedTimePeriod.unit',
    'warningSettings.notContactedTimePeriod.unit',
    'warningSettings.communicationNotRespondedTimePeriod.unit',
    'warningSettings.notQualifiedTimePeriod.unit',
    'warningSettings.noActivityTimePeriod.unit',
  ]);

  const getInterval = (unit: any) => {
    if (unit === 'DAY') {
      return DAYS;
    }
    return HOURS;
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
        <FormControl label="New Communication Notification Delay">
          <>
            <div
              className={css({
                ...theme.typography.ParagraphSmall,
                marginBottom: theme.sizing.scale300,
              })}
            >
              Minutes to wait before sending a notification email between consecutive communications
              from the same lead.
            </div>
            <ControlledInput
              baseWebProps={{ size: 'compact', clearable: true, type: 'number', min: 0, max: 360 }}
              control={control}
              name="notificationDelayMins"
            />
          </>
        </FormControl>
        <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale600">
          <FlexGridItem>
            <FormControl label="Overdue Alerts">
              <ControlledCheckbox
                name="warningSettings.useWarnings"
                control={control}
                baseWebProps={{
                  error: errors?.warningSettings?.useWarnings,
                  overrides: {
                    Root: {
                      style: () => ({
                        alignItems: 'flex-start',
                      }),
                    },
                  },
                }}
                label={
                  <ParagraphSmall marginTop="0" marginBottom="0">
                    Receive an email alert when an action item on a lead is overdue. Choose the type
                    of notification the and number of hours or days overdue to trigger the alert.
                  </ParagraphSmall>
                }
              />
            </FormControl>
          </FlexGridItem>
          {/* NOT ASSIGNED */}
          <FlexGridItem>
            {useWarnings$ && (
              <>
                <ControlledCheckbox
                  name="warningSettings.notAssigned"
                  control={control}
                  baseWebProps={{
                    error: errors?.warningSettings?.notAssigned,
                  }}
                  label={<ParagraphSmall margin="0">Unassigned leads</ParagraphSmall>}
                />
                <StyledNotificationSubSetting $disabled={!notAssigned$}>
                  <div>Threshold:</div>
                  <StyledFieldGroup>
                    <Select
                      disabled={!notAssigned$}
                      placeholder="Select"
                      name="warningSettings.notAssignedTimePeriod.interval"
                      layout="compact"
                      registerConfig={registerConfig}
                      options={getInterval(notAssignedUnit$).map((n) => ({
                        id: `${n}`,
                        label: `${n}`,
                      }))}
                      rootStyles={{
                        marginRight: theme.sizing.scale200,
                      }}
                    />
                    <Select
                      disabled={!notAssigned$}
                      placeholder="Select"
                      name="warningSettings.notAssignedTimePeriod.unit"
                      layout="compact"
                      options={[
                        { id: 'HOUR', label: 'Hours' },
                        { id: 'DAY', label: 'Days' },
                      ]}
                    />
                  </StyledFieldGroup>
                  <div>after receiving a new lead.</div>
                </StyledNotificationSubSetting>
              </>
            )}
          </FlexGridItem>
          {/* NOT CONTACTED */}
          <FlexGridItem>
            {useWarnings$ && (
              <>
                <ControlledCheckbox
                  name="warningSettings.notContacted"
                  control={control}
                  baseWebProps={{
                    error: errors?.warningSettings?.notContacted,
                  }}
                  label={<ParagraphSmall margin="0">Leads waiting for response</ParagraphSmall>}
                />
                <StyledNotificationSubSetting $disabled={!notContacted$}>
                  <div>Threshold:</div>
                  <StyledFieldGroup>
                    <Select
                      disabled={!notContacted$}
                      placeholder="Select"
                      name="warningSettings.notContactedTimePeriod.interval"
                      layout="compact"
                      registerConfig={registerConfig}
                      options={getInterval(notContactedUnit$).map((n) => ({
                        id: `${n}`,
                        label: `${n}`,
                      }))}
                      rootStyles={{
                        marginRight: theme.sizing.scale200,
                      }}
                    />
                    <Select
                      disabled={!notContacted$}
                      placeholder="Select"
                      name="warningSettings.notContactedTimePeriod.unit"
                      layout="compact"
                      options={[
                        { id: 'HOUR', label: 'Hours' },
                        { id: 'DAY', label: 'Days' },
                      ]}
                    />
                  </StyledFieldGroup>
                  after receiving a new lead.
                </StyledNotificationSubSetting>
              </>
            )}
          </FlexGridItem>
          {/* FOLLOW UP NOT RESPONDED */}
          <FlexGridItem>
            {useWarnings$ && (
              <>
                <ControlledCheckbox
                  name="warningSettings.communicationNotResponded"
                  control={control}
                  baseWebProps={{
                    error: errors?.warningSettings?.communicationNotResponded,
                  }}
                  label={
                    <ParagraphSmall margin="0">
                      Follow up from lead, still waiting for response
                    </ParagraphSmall>
                  }
                />
                <StyledNotificationSubSetting $disabled={!communicationNotResponded$}>
                  <div>Threshold:</div>
                  <StyledFieldGroup>
                    <Select
                      disabled={!communicationNotResponded$}
                      placeholder="Select"
                      name="warningSettings.communicationNotRespondedTimePeriod.interval"
                      layout="compact"
                      registerConfig={registerConfig}
                      options={getInterval(communicationNotRespondedUnit$).map((n) => ({
                        id: `${n}`,
                        label: `${n}`,
                      }))}
                      rootStyles={{
                        marginRight: theme.sizing.scale200,
                      }}
                    />
                    <Select
                      disabled={!communicationNotResponded$}
                      placeholder="Select"
                      name="warningSettings.communicationNotRespondedTimePeriod.unit"
                      layout="compact"
                      options={[
                        { id: 'HOUR', label: 'Hours' },
                        { id: 'DAY', label: 'Days' },
                      ]}
                    />
                  </StyledFieldGroup>
                  after a follow up communication from lead.
                </StyledNotificationSubSetting>
              </>
            )}
          </FlexGridItem>
          {/* NOT QUALIFIED */}
          <FlexGridItem>
            {useWarnings$ && (
              <>
                <ControlledCheckbox
                  name="warningSettings.notQualified"
                  control={control}
                  baseWebProps={{
                    error: errors?.warningSettings?.notQualified,
                  }}
                  label={<ParagraphSmall margin="0">Not yet qualified</ParagraphSmall>}
                />
                <StyledNotificationSubSetting $disabled={!notQualified$}>
                  <div>Threshold:</div>
                  <StyledFieldGroup>
                    <Select
                      disabled={!notQualified$}
                      placeholder="Select"
                      name="warningSettings.notQualifiedTimePeriod.interval"
                      layout="compact"
                      registerConfig={registerConfig}
                      options={getInterval(notQualifiedUnit$).map((n) => ({
                        id: `${n}`,
                        label: `${n}`,
                      }))}
                      rootStyles={{
                        marginRight: theme.sizing.scale200,
                      }}
                    />
                    <Select
                      disabled={!notQualified$}
                      placeholder="Select"
                      name="warningSettings.notQualifiedTimePeriod.unit"
                      layout="compact"
                      options={[
                        { id: 'HOUR', label: 'Hours' },
                        { id: 'DAY', label: 'Days' },
                      ]}
                    />
                  </StyledFieldGroup>
                  after contacting a new lead and lead is still not qualified.
                </StyledNotificationSubSetting>
              </>
            )}
          </FlexGridItem>
          {/* NO ACTIVITY ON OPPORTUNITY */}
          <FlexGridItem>
            {useWarnings$ && (
              <>
                <ControlledCheckbox
                  name="warningSettings.noActivity"
                  control={control}
                  baseWebProps={{
                    error: errors?.warningSettings?.noActivity,
                  }}
                  label={<ParagraphSmall margin="0"> No activity on opportunity</ParagraphSmall>}
                />
                <StyledNotificationSubSetting $disabled={!noActivity$}>
                  <div>Threshold:</div>
                  <StyledFieldGroup>
                    <Select
                      disabled={!noActivity$}
                      placeholder="Select"
                      name="warningSettings.noActivityTimePeriod.interval"
                      layout="compact"
                      registerConfig={registerConfig}
                      options={getInterval(noActivityUnit$).map((n) => ({
                        id: `${n}`,
                        label: `${n}`,
                      }))}
                      rootStyles={{
                        marginRight: theme.sizing.scale200,
                      }}
                    />
                    <Select
                      disabled={!noActivity$}
                      placeholder="Select"
                      name="warningSettings.noActivityTimePeriod.unit"
                      layout="compact"
                      options={[
                        { id: 'HOUR', label: 'Hours' },
                        { id: 'DAY', label: 'Days' },
                      ]}
                    />{' '}
                  </StyledFieldGroup>
                  since last activity.
                </StyledNotificationSubSetting>
              </>
            )}
          </FlexGridItem>
        </FlexGrid>
      </form>
    </>
  );
};

export default NotificationsForm;
