import React, { ChangeEvent } from 'react';
import { Input } from 'baseui/input';
import { useController } from 'react-hook-form';

const ControlledInput = (props: any) => {
  const { name, control, customError, handleOnChange, baseWebProps = {} } = props;
  const {
    field: { ref, onChange, ...inputProps },
    formState: { errors },
  } = useController({ name, control });

  const { useRef, ...rest } = baseWebProps;

  return (
    <Input
      error={!!(customError || errors[name])}
      inputRef={useRef ? ref : null}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (handleOnChange) handleOnChange(e);
        onChange(e.target.value);
      }}
      {...rest}
      {...inputProps} // onBlur, value, name
    />
  );
};

export default ControlledInput;
