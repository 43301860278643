import { useQuery } from 'react-query';
import { Property } from '../../../backend/property';
import useAxios from '../../../hooks/useAxios';

function useProperties() {
  const axios = useAxios();
  return {
    async getProperties() {
      const { data } = await axios.get<Property[]>('api/property');
      return data;
    },
  };
}

function useGetProperties(options?: {}) {
  const { getProperties } = useProperties();
  return useQuery({
    queryFn: getProperties,
    queryKey: ['getAllProperties'],
    ...options,
  });
}

export function usePropertiesOptions() {
  return useGetProperties({
    select: (data: Property[]) => data.map(({ id, name }) => ({ id, label: name })),
  });
}

export default useGetProperties;
