import React from 'react';

const tooltipContent = {
  properties: 'Add and remove your properties on the team to make them available in campaigns.',
  teamMembers: 'Add and remove users on the team and manage their permissions.',
  campaigns: (
    <>
      <div>Create campaigns to capture leads from different sources:</div>
      <ul style={{ margin: 0, padding: '0 8px', listStyleType: 'none' }}>
        <li>Channels - phone calls, texts and email</li>
        <li>Feeds - listing services like Costar</li>
        <li>Forms - online forms</li>
      </ul>
    </>
  ),
  channelActivity: 'Communication log from channel campaigns. Useful for auditing.',
  channels:
    'Create and manage your tracking phone numbers and email campaigns. Click on a campaign to edit the phone greeting, users and download QR Codes.',
  feeds:
    'Track leads coming from listing services. Setup feed rules to match on a unique property identifier (PID) and determine which users have access to the lead.',
  forms:
    'Setup an online form to collect leads via web. Customize the landing page property image and text.',
};

export default tooltipContent;
