import { ColumnFiltersState, SortingState, Updater } from '@tanstack/react-table';
import { createContext, useContext, useRef } from 'react';
import { useStore } from 'zustand';
import {
  createUserStore,
  UserState,
  UserStore,
  UserInitProp,
  UserPageState,
  defaultPageState,
} from './store';

const StoreContext = createContext<UserStore | null>(null);

type ProviderProps = React.PropsWithChildren<UserInitProp>;

export function StoreProvider({ children, ...props }: ProviderProps) {
  const storeRef = useRef<UserStore>();

  if (!storeRef.current) {
    // Create the store
    storeRef.current = createUserStore(props);
  } else {
    const exists = storeRef.current.getState().users.find((user) => user.id === props.id);

    if (!exists) {
      // If user not in store, initialize with default page state.
      storeRef.current.setState((state) => ({
        users: [...state.users, { id: props.id, page: { ...defaultPageState } }],
      }));
    }
  }
  return <StoreContext.Provider value={storeRef.current}>{children}</StoreContext.Provider>;
}

export function useStoreContext<T>(selector: (state: UserState) => T): T {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing StoreContext.Provider in the tree');
  return useStore(store, selector);
}

export function useUpdateStore() {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing StoreContext.Provider in the tree');

  const setFilter = (
    update: Updater<ColumnFiltersState>,
    pagekey: keyof UserPageState,
    userRef: string,
  ) => {
    store.setState((state) => {
      if (typeof update === 'function') {
        const ref = state.users.find((user) => user.id === userRef);
        const currentFilters = ref ? ref.page?.[pagekey]?.filters : [];
        const filters = update(currentFilters);
        return {
          ...state,
          users: state.users.map((prev) => {
            if (prev.id === userRef) {
              return {
                ...prev,
                page: { ...prev.page, [pagekey]: { ...prev.page?.[pagekey], filters } },
              };
            }
            return prev;
          }),
        };
      }

      return {
        ...state,
        users: state.users.map((prev) => {
          if (prev.id === userRef) {
            return {
              ...prev,
              page: { ...prev.page, [pagekey]: { ...prev.page?.[pagekey], filters: update } },
            };
          }
          return prev;
        }),
      };
    });
  };

  const setSorting = (
    update: Updater<SortingState>,
    pagekey: keyof UserPageState,
    userRef: string,
  ) => {
    store.setState((state) => {
      if (typeof update === 'function') {
        const ref = state.users.find((user) => user.id === userRef);
        const currentSort = ref ? ref.page?.[pagekey]?.sort : [];
        const sort = update(currentSort);
        return {
          ...state,
          users: state.users.map((prev) => {
            if (prev.id === userRef) {
              return {
                ...prev,
                page: { ...prev.page, [pagekey]: { ...prev.page?.[pagekey], sort } },
              };
            }
            return prev;
          }),
        };
      }

      return {
        ...state,
        users: state.users.map((prev) => {
          if (prev.id === userRef) {
            return {
              ...prev,
              page: { ...prev.page, [pagekey]: { ...prev.page?.[pagekey], sort: update } },
            };
          }
          return prev;
        }),
      };
    });
  };
  return { setFilter, setSorting };
}
