import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { CampaignT } from '../../../backend/campaign';
import useAxios from '../../../hooks/useAxios';

const useGetCampaign = (
  campaignId: string | undefined,
  options?: Omit<UseQueryOptions<CampaignT | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getCampaign = async () => {
    if (typeof campaignId === 'undefined') return Promise.reject();
    const { data } = await axios.get<CampaignT>(`api/campaign/${campaignId}`);
    return data;
  };

  return useQuery<CampaignT | undefined, AxiosError>(
    ['getCampaign', campaignId],
    () => getCampaign(),
    {
      enabled: Boolean(campaignId),
      ...options,
    },
  );
};

export default useGetCampaign;
