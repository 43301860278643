import React from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';

const NumberFormatCustom = (props: any) => {
  const { inputRef, value, kind, ...rest } = props;
  if (Array.isArray(value)) {
    return null;
  }
  if (kind === 'pattern') {
    return <PatternFormat {...rest} getInputRef={inputRef} value={value} />;
  }
  return <NumericFormat {...rest} getInputRef={inputRef} value={value} />;
};

export default NumberFormatCustom;
