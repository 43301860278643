import React, { createContext, Dispatch, ReactNode, useContext, useState } from 'react';
import { Value } from 'baseui/select';
import { BaseUiOption } from 'shared/types';
import { useClient } from 'context/client-context';
import useGetTeamsForUser from 'components/team/hooks/useGetTeamsForUser';
import { Team } from 'backend/team';

type TeamCalendarProps = {
  children: ReactNode;
};

type TeamCalendarContextType = {
  isTeamCalendarScope: boolean;
  setTeamCalendar: Dispatch<Value>;
  teamCalendar: Value | undefined;
  teamsWithCalendar: BaseUiOption<string>[] | undefined;
  teamsConnectionFail: Team[] | undefined;
};

const TeamCalendarContext = createContext<TeamCalendarContextType | undefined>(undefined);

const TeamCalendarProvider = ({ children }: TeamCalendarProps) => {
  const { state } = useClient();

  const { data } = useGetTeamsForUser();

  const isTeamCalendarScope = state?.calendarScope === 'TEAM';

  const teamsWithCalendar = data
    ?.filter((team) => team.calendarConnected)
    .map((team) => ({ id: team.id, label: team.name }));

  const teamsConnectionFail = data?.filter((team) => team.calendarAuthFailed);

  const [teamCalendar, setTeamCalendar] = useState<Value | undefined>();

  return (
    <TeamCalendarContext.Provider
      value={{
        isTeamCalendarScope,
        teamsWithCalendar,
        teamCalendar,
        setTeamCalendar,
        teamsConnectionFail,
      }}
    >
      {children}
    </TeamCalendarContext.Provider>
  );
};

const useTeamCalendar = () => {
  const context = useContext(TeamCalendarContext);

  if (context === undefined) {
    throw new Error('useTeamCalendar must be used within a TeamCalendarProvider');
  }

  return { ...context };
};

export { TeamCalendarProvider, useTeamCalendar };
