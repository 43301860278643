import { Communication } from 'backend/communication';
import { MessageEvent } from 'backend/messages';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import TextFailedAlert from './text-failed-alert';

function useWebSocketMessageHandler() {
  const queryClient = useQueryClient();

  const handleMessage = useCallback(
    (message: MessageEvent<unknown>) => {
      const { eventType } = message;

      if (eventType === 'TEXT_FAILED') {
        const details = message.details as Communication;
        toast(<TextFailedAlert details={details} />);
        queryClient.invalidateQueries(['getTextThread', details.leadRef]);
      }
    },
    [queryClient],
  );

  return { handleMessage };
}
export default useWebSocketMessageHandler;
