import { PopoverOverrides } from 'baseui/popover';

export const tooltipOverrides: PopoverOverrides = {
  Inner: {
    style: ({ $theme }) => ({
      paddingTop: $theme.sizing.scale0,
      paddingBottom: $theme.sizing.scale0,
      paddingLeft: $theme.sizing.scale500,
      paddingRight: $theme.sizing.scale500,
      ...$theme.typography.ParagraphXSmall,
      borderTopRightRadius: $theme.sizing.scale100,
      borderTopLeftRadius: $theme.sizing.scale100,
      borderBottomRightRadius: $theme.sizing.scale100,
      borderBottomLeftRadius: $theme.sizing.scale100,
    }),
  },
};
