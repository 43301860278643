import React, { useState } from 'react';

const useFileReader = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataFile, setDataFile] = useState<string>('');
  const [error, setError] = useState<string | undefined>();
  const reader = React.useMemo(() => new FileReader(), []);
  const clearReaderError = () => setError(undefined);
  reader.onload = React.useCallback((e: any) => {
    setDataFile(e.target?.result as string);
  }, []);

  reader.onloadstart = () => setIsLoading(true);
  reader.onloadend = () => setIsLoading(false);
  reader.onerror = () => {
    setError(reader.error?.message);
    reader.abort();
  };

  return { isLoading, reader, dataFile, setDataFile, error, clearReaderError };
};

export default useFileReader;
