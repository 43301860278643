import React from 'react';
import { useStyletron } from 'baseui';
import { useCurrentUserContext } from 'context/current-user-context';

const Avatar = () => {
  const [css] = useStyletron();

  const currentUser = useCurrentUserContext();

  const name = currentUser.name?.split(' ');

  const firstInitial = name?.[0]?.substr(0, 1);

  const lastInitial = name?.[name?.length - 1]?.substr(0, 1);

  return (
    <div
      className={css({
        width: '36px',
        height: '36px',
        display: 'flex',
        color: 'white',
        flex: '0 0 auto',
        alignItems: 'center',
        borderRadius: '50% 50%',
        justifyContent: 'center',
        backgroundColor: `#${currentUser.colorSelection || '202020'}`,
      })}
    >
      {firstInitial}
      {lastInitial}
    </div>
  );
};

export default Avatar;
