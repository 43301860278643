import Auth0ProviderWithHistory from 'context/AuthProviderWithHistory';
import AxiosProvider from 'context/AxiosContext';
import React from 'react';
import { Outlet } from 'react-router-dom';

export default function CoreProviders() {
  return (
    <Auth0ProviderWithHistory>
      <AxiosProvider>
        <Outlet />
      </AxiosProvider>
    </Auth0ProviderWithHistory>
  );
}
