import React from 'react';
import { ListItem } from 'baseui/list';
import type { MenuAdapterProps } from 'baseui/list';

const MenuListItem = React.forwardRef<
HTMLLIElement,
MenuAdapterProps & { $isSelected?: boolean; $borderRadius?: number }
>((props: MenuAdapterProps & { $isSelected?: boolean; $borderRadius?: number }, ref) => (
  <ListItem
    ref={ref}
    sublist={props.sublist || props.$size === 'compact'}
    artwork={props.artwork}
    artworkSize={props.artworkSize}
    endEnhancer={props.endEnhancer}
    overrides={{
      Root: {
        props: { onMouseEnter: props.onMouseEnter, onClick: props.onClick },
        style: ({ $theme }) => ({
          borderTopLeftRadius: `${props.$borderRadius}px`,
          borderTopRightRadius: `${props.$borderRadius}px`,
          borderBottomLeftRadius: `${props.$borderRadius}px`,
          borderBottomRightRadius: `${props.$borderRadius}px`,
          backgroundColor: props.$isHighlighted ? $theme.colors.menuFillHover : '',
          cursor: props.$disabled ? 'not-allowed' : 'pointer',
        }),
      },
      ArtworkContainer: {
        style: () => ({
          width: '48px',
        }),
      },
      Content: {
        style: ({ $theme }) => ({
          minHeight: '36px',
          borderBottomStyle: 'none',
          // eslint-disable-next-line no-nested-ternary
          color: props.$disabled
            ? $theme.colors.mono400
            : props.$isSelected
              ? $theme.colors.mono900
              : $theme.colors.mono800,
          ...$theme.typography.ParagraphXSmall,
          fontSize: '13px',
          fontWeight: props.$isSelected ? 600 : 400,
        }),
      },
    }}
  >
    {props.children}
  </ListItem>
));

MenuListItem.defaultProps = {
  $isSelected: false,
  $borderRadius: 0,
};

export default MenuListItem;
