/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import React from 'react';

import {
  useNavigate,
  useRouteError,
  isRouteErrorResponse,
  Navigate,
  useLocation,
} from 'react-router-dom';

import { StyledErrorRoot, StyledErrorInner, StyledErrorLink } from './styled-components';
import { ReactComponent as Logo } from '../assets/logo.svg';

const RootErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  const [css, theme] = useStyletron();

  const navigate = useNavigate();

  const goHome = () => {
    navigate('/', { replace: true });
  };

  let message = 'Something went wrong';

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      message = 'Page not found';
    }

    if (error.status === 401) {
      message = 'Unauthorized';
    }

    if (error.status === 503) {
      message = 'Looks like our API is down';
    }

    if (error.status === 418) {
      message = '🫖';
    }
  }

  return (
    <StyledErrorRoot>
      <StyledErrorInner>
        <Logo
          title="SpaceIt"
          className={css({
            width: 'auto',
            height: '40px',
            flex: '0 0 40px',
            marginLeft: '-6px',
            marginBottom: theme.sizing.scale600,
          })}
        />
        <div>
          <span
            className={css({
              ...theme.typography.ParagraphSmall,
              textTransform: 'uppercase',
            })}
          >
            Error
          </span>
        </div>
        <h1
          className={css({
            ...theme.typography.HeadingXSmall,
            color: theme.colors.mono900,
            fontWeight: 500,
          })}
        >
          {message}
        </h1>
        <p>
          Return to the{' '}
          <StyledErrorLink role="button" tabIndex={0} onClick={goHome}>
            dashboard
          </StyledErrorLink>.
        </p>
      </StyledErrorInner>
    </StyledErrorRoot>
  );
};

export default RootErrorBoundary;
