import { styled, withStyle } from 'baseui';
import { CommunicationDirection } from '../../backend/communication';

export const StyledTextMessageContainer = styled<'div', { $isMinimized?: boolean }>(
  'div',
  ({ $isMinimized, $theme }) => ({
    top: $isMinimized ? 'auto' : 0,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 0,
    width: $isMinimized ? '260px' : '100%',

    [$theme.mediaQuery.medium]: {
      top: 'auto',
      right: 0,
      display: 'block',
      marginRight: '32px',
      width: $isMinimized ? '260px' : '400px',
    },

    bottom: 0,
    position: 'fixed',
    overflow: 'hidden',
    background: $theme.colors.white,
    boxShadow: $theme.lighting.shadow700,
    border: `1px solid ${$theme.colors.mono300}`,
    borderTopLeftRadius: $theme.sizing.scale200,
    borderTopRightRadius: $theme.sizing.scale200,
  }),
);

export const StyledTextMessageInfo = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  backgroundColor: $theme.colors.mono100,
  borderBottomStyle: 'solid',
  borderBottomColor: $theme.colors.mono200,
  borderBottomWidth: '1px',
  height: '32px',
  paddingTop: $theme.sizing.scale200,
  paddingBottom: $theme.sizing.scale200,
  // transition: 'all 200ms ease-in',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledTextMessagePanel = styled<'div', { $isMinimized?: boolean }>(
  'div',
  ({ $isMinimized }) => ({
    display: 'flex',
    flexGrow: 1,
    overflowX: 'hidden',
    flexDirection: 'column',
    height: $isMinimized ? '0px' : '472px',
    // transition: 'all 200ms ease-in',
  }),
);

export const StyledTextMessageScrollableBody = styled('div', ({ $theme }) => ({
  flexGrow: 1,
  overflowX: 'auto',
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
}));

const animation: any = {
  from: { opacity: 0 },
  to: { opacity: 1 },
};

export const StyledIconNewMessage = styled('div', ({ $theme }) => ({
  color: '#F35627',
  fontSize: '14px',
  animationDuration: '1s',
  animationName: animation,
  animationDirection: 'alternate',
  animationIterationCount: 'infinite',
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
}));

export const StyledIconContainer = styled('div', ({ $theme }) => ({
  width: '20px',
  height: '20px',
  color: $theme.colors.mono300,
  padding: $theme.sizing.scale200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: $theme.sizing.scale200,
  ':last-child': {
    marginRight: $theme.sizing.scale600,
  },
  cursor: 'pointer',
  borderRadius: '6px',
  ':hover': {
    color: $theme.colors.white,
    backgroundColor: $theme.colors.mono900,
  },
}));

export const StyledTextWrapper = styled('div', ({ $theme }) => ({
  alignItems: 'center',
  columnGap: $theme.sizing.scale0,
  display: 'flex',
  maxWidth: '65%',
}));

export const StyledTextMessage = styled<
'div',
{ $direction?: CommunicationDirection; $isMe?: boolean }
>('div', ({ $direction, $theme, $isMe }) => {
  const myColor = $isMe ? $theme.colors.white : $theme.colors.mono700;

  const myBackgroundColor = $isMe ? $theme.colors.primary400 : $theme.colors.warning200;

  return {
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    padding: $theme.sizing.scale400,
    ...$theme.typography.ParagraphSmall,
    borderRadius: $theme.borders.radius400,
    color: $direction === 'INCOMING' ? 'inherit' : myColor,
    backgroundColor: $direction === 'INCOMING' ? $theme.colors.mono100 : myBackgroundColor,
  };
});

export const WithStyledIncomingTextMessage = withStyle<typeof StyledTextMessage, {}>(
  StyledTextMessage,
  ({ $theme }) => ({
    backgroundColor: $theme.colors.mono100,
  }),
);

export const WithStyledOutgoingTextMessage = withStyle<typeof StyledTextMessage, {}>(
  StyledTextMessage,
  ({ $theme }) => ({
    color: $theme.colors.white,
    backgroundColor: $theme.colors.positive400,
  }),
);

export const StyledTextMessageOuter = styled<'div', { $direction?: CommunicationDirection }>(
  'div',
  ({ $direction, $theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    ...$theme.typography.ParagraphXSmall,
    marginTop: $theme.sizing.scale500,
    marginBottom: $theme.sizing.scale500,
    alignItems: $direction === 'OUTGOING' ? 'flex-end' : 'flex-start',
  }),
);

export const StyledTextMessageMeta = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  color: $theme.colors.mono500,
  paddingLeft: $theme.sizing.scale400,
  paddingRight: $theme.sizing.scale400,
}));

export const StyledTextMsgSenderText = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  textAlign: 'right',
}));

export const StyledTextFailed = styled('div', ({ $theme }) => ({
  color: $theme.colors.negative500,
}));
