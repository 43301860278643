import React, { ChangeEvent } from 'react';
import { Input, SIZE } from 'baseui/input';
import { RadioGroup, Radio } from 'baseui/radio';
import { StatefulTooltip } from 'baseui/tooltip';
import { FormControl } from 'baseui/form-control';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as S from './styled-components';
import RequiredFieldMarker from '../../../components/shared/RequiredFieldMarker';
import { WithStyledRadioRoot, WithStyledRadioMarkOuter } from '../../../components/shared/styles';
import ControlledRadioGroup from '../../../components/team/components/ControlledRadioGroup';
import { FormStepPropsT } from './types';

const radioOverrides = {
  Root: {
    component: WithStyledRadioRoot,
  },
  Label: {
    style: ({ $theme }: any) => ({
      ...$theme.typography.ParagraphSmall,
    }),
  },
  RadioMarkOuter: {
    component: WithStyledRadioMarkOuter,
  },
};

const AssignmentModeForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit } = props;

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const [assignmentMode$, lockMode$] = watch(['assignmentMode', 'lockMode']);

  const tipInfo = {
    assignmentMode: (
      <>
        <strong>Claim Mode</strong> - the first agent to contact the lead will take ownership.{' '}
        <br />
        <strong>Assignment Mode</strong> - leads are only visible to users with assignment rights.
      </>
    ),
    lockMode:
      "The amount of time an agent has to qualify a lead they claimed before it's released back to the lead pool.",
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
      <ControlledRadioGroup
        name="assignmentMode"
        control={control}
        handleOnChange={(e) => {
          if (e.target.value === 'ASSIGN') {
            setValue('lockMode', undefined);
            setValue('lockDuration', '');
          }
        }}
        // hasError={!!errors.assignmentMode}
        fcProps={{
          label: (
            <>
              <RequiredFieldMarker>Assignment Mode</RequiredFieldMarker>
              <StatefulTooltip
                content={() => <S.MoreInfoToolTip>{tipInfo.assignmentMode}</S.MoreInfoToolTip>}
              >
                <S.MoreInfoTip>
                  <FontAwesomeIcon icon={solid('circle-info')} />
                </S.MoreInfoTip>
              </StatefulTooltip>
            </>
          ),
          error: errors.assignmentMode?.message as string | undefined,
        }}
        baseWebRadioProps={{
          align: 'horizontal',
        }}
        radioOptions={[
          { value: 'CLAIM', displayValue: 'Claim' },
          { value: 'ASSIGN', displayValue: 'Assignment' },
        ]}
      />

      {assignmentMode$ === 'CLAIM' && (
        <Controller
          name="lockMode"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl
              label={
                <>
                  <RequiredFieldMarker>Lock Mode</RequiredFieldMarker>
                  <StatefulTooltip
                    content={() => <S.MoreInfoToolTip>{tipInfo.lockMode}</S.MoreInfoToolTip>}
                  >
                    <S.MoreInfoTip>
                      <FontAwesomeIcon icon={solid('circle-info')} />
                    </S.MoreInfoTip>
                  </StatefulTooltip>
                </>
              }
              error={errors?.lockMode?.message as string | undefined}
            >
              <RadioGroup
                align="horizontal"
                value={value}
                error={!!errors.lockMode}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.value);
                }}
              >
                <Radio value="TIMED" overrides={radioOverrides}>
                  Timed
                </Radio>
                <Radio value="UNLIMITED" overrides={radioOverrides}>
                  Unlimited
                </Radio>
              </RadioGroup>
            </FormControl>
          )}
        />
      )}

      {lockMode$ === 'TIMED' && assignmentMode$ === 'CLAIM' && (
        <>
          <Controller
            name="lockDuration"
            control={control}
            render={({ field: { value, ref, onChange } }) => (
              <FormControl
                caption="Enter number of days until a lock expires. Accepts up to 90 days."
                // label="Lock Duration (Days)"
                label={<RequiredFieldMarker>Lock Duration (Days)</RequiredFieldMarker>}
                error={errors?.lockDuration?.message as string | undefined}
              >
                <Input
                  min={1}
                  max={90}
                  type="number"
                  value={value}
                  inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
                  onChange={onChange}
                  size={SIZE.compact}
                  endEnhancer={<span>day(s)</span>}
                  error={!!errors.lockDuration}
                />
              </FormControl>
            )}
          />
        </>
      )}
    </form>
  );
};

export default AssignmentModeForm;
