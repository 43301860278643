import React from 'react';

type Initialized<T> = T | undefined;

type ContextPropsT = {
  isExpanded: boolean;
  open: boolean;
  setExpanded: (state: boolean) => void;
  setOpen: (state: boolean) => void;
};

type ProviderPropsT = { children: React.ReactNode };

const SideMenuContext = React.createContext<Initialized<ContextPropsT>>(undefined);

SideMenuContext.displayName = 'SideMenuContext';

function SideMenuProvider({ children }: ProviderPropsT) {
  const [open, setOpen] = React.useState<boolean>(false);

  const [isExpanded, setExpanded] = React.useState<boolean>(true);

  const value = { isExpanded, open, setExpanded, setOpen };

  return <SideMenuContext.Provider value={value}>{children}</SideMenuContext.Provider>;
}

function useSideMenuContext() {
  const context = React.useContext(SideMenuContext);
  if (context === undefined) {
    throw new Error('useSideMenucontext must be used within a SideMenuProvider');
  }
  return context;
}

export { SideMenuProvider, useSideMenuContext };
