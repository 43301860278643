import { useCurrentUserContext } from 'context/current-user-context';
import { useTeamContext } from '../context/team-context';

const useTeamPermissions = () => {
  const currentUser = useCurrentUserContext();

  const { id: teamRef } = useTeamContext();

  const { teamPermissions } = currentUser;

  const permissions = teamRef ? teamPermissions?.[teamRef] : undefined;

  if (teamRef && teamPermissions && permissions) {
    const { fullAccess, manageCampaigns, manageListings, manageTeamMembers, manageConfiguration } =
      permissions;

    // Team checks teams in token to guard /team/:id routes
    const canAccessTeam = fullAccess || (!!teamRef && !!teamPermissions[teamRef]);

    const canManageConfiguration = fullAccess || manageConfiguration;

    const canAccessCampaign = fullAccess || manageCampaigns;
    const canManageCampaigns = fullAccess || manageCampaigns;

    const canAccessListings = canAccessTeam;
    const canManageListings = fullAccess || manageListings;

    const canAccessTeamMembers = canAccessTeam;
    const canManageTeamMembers = fullAccess || manageTeamMembers;

    return {
      canAccessTeam,
      canAccessCampaign,
      canManageCampaigns,
      canAccessListings,
      canManageListings,
      canAccessTeamMembers,
      canManageTeamMembers,
      canManageConfiguration,
    };
  }
  return {};
};

export default useTeamPermissions;
