/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react';
// import { useStyletron } from 'baseui';
import ReactFocusLock from 'react-focus-lock';
import { css } from 'styled-system/css';
// import { Popover } from 'baseui/popover';
// import {
//   StyledPopoutBoundary,
//   StyledPopoutContent,
//   StyledPopoutHeader,
//   StyledPopoutStructure,
// } from './styled-components';
import PopoutHeader from './PopoutHeader';
import { usePopout } from './PopoutContextProvider';

const Popout = () => {
  const { component, isOpen, closePopout, setComponent, expanded, fullScreen } = usePopout();

  const popoutWidth = fullScreen ? '100%' : '600px';

  useEffect(() => () => setComponent(undefined), [setComponent]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      data-si="popout"
      className={css({
        position: {
          base: expanded ? 'fixed' : 'static',
          md: fullScreen && expanded ? 'fixed' : 'static',
        },
        inset: 0,
      })}
    >
      <div
        data-si="popout-overlay"
        className={css({
          background: 'black.a5',
          display: {
            base: expanded ? 'block' : 'none',
            md: fullScreen && expanded ? 'block' : 'none',
          },
          inset: 0,
          position: 'fixed',
        })}
      />
      <ReactFocusLock disabled={!expanded}>
        <div
          data-si="popout-container"
          className={css({
            width: {
              base: '100%',
              md: expanded ? popoutWidth : '260px',
            },
            // Fullscreen use 100% otherwise height of variable-content
            height: fullScreen && expanded ? '100%' : 'auto',
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            right: {
              base: 'auto',
              md: fullScreen && expanded ? 'auto' : '64px',
            },
            borderTopRadius: 'sm',
            overflow: 'hidden',
            boxShadow: '2xl',
          })}
        >
          <div
            data-si="popout-structure"
            className={css({
              alignSelf: 'stretch',
              display: 'flex',
              width: '100%',
              background: 'white',
              margin: {
                md: expanded && fullScreen ? 4 : 0,
              },
              flexDirection: 'column',
            })}
          >
            <div>
              <PopoutHeader onClose={closePopout} />
            </div>
            <div
              className={css({
                flexGrow: 1,
                height: expanded ? 'auto' : 0,
                overflow: expanded ? 'auto' : 'hidden',
              })}
            >
              <div
                data-si="boundary"
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                })}
              >
                <div
                  data-si="variable-content"
                  className={css({
                    height: {
                      base: '70vh',
                      md: fullScreen ? '100%' : 'auto',
                    },
                    display: 'flex',
                    flex: '1 1 auto',
                    minHeight: {
                      md: '496px',
                    },
                    maxHeight: {
                      md: fullScreen ? 'auto' : '502px',
                    },
                    flexDirection: 'column',
                  })}
                >
                  {component}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ReactFocusLock>
    </div>
  );
};

export default Popout;

// <Popover
//   isOpen={isOpen}
//   placement="topRight"
//   // focusLock
//   // renderAll
//   popoverMargin={0}
//   content={() => (
//     <StyledPopoutStructure data-si="PopoutStructure" $isExpanded={expanded}>
//       <StyledPopoutHeader data-si="PopoutHeader" $isExpanded={expanded}>
//         <PopoutHeader onClose={closePopout} isExpanded={expanded} toggleExpand={toggleExpand} />
//       </StyledPopoutHeader>
//       <StyledPopoutContent $isExpanded={expanded} data-si="PopoutContent">
//         <StyledPopoutBoundary data-si="PopoutBoundary">
//           <div
//             data-id="flexinner"
//             className={css({
//               display: 'flex',
//               flex: '1 1 auto',
//               minHeight: '1px',
//               flexDirection: 'column',
//             })}
//           >
//             {/* <div
//                 data-si="scrollable"
//                 className={css({
//                   overflowY: 'auto',
//                 })}
//               > */}
//             {/* {component} */}
//             {/* </div> */}
//           </div>
//         </StyledPopoutBoundary>
//       </StyledPopoutContent>
//     </StyledPopoutStructure>
//   )}
//   overrides={{
//     Body: {
//       style: ({ $theme }) => ({
//         maxHeight: '100%',
//         width: '100%',
//         backgroundColor: $theme.colors.white,
//         // height: expanded ? '100vh' : 'auto',
//         // height: expanded ? 'calc(100% - 80px)' : 'auto',
//         [$theme.mediaQuery.medium]: {
//           width: 'auto',
//           left: `-${$theme.sizing.scale900}`,
//           // top: 'auto',
//           // right: 0,
//           // display: 'block',
//         },
//       }),
//     },
//     Inner: {
//       style: ({ $theme }) => ({
//         overflow: 'hidden',
//         borderBottomLeftRadius: 0,
//         borderBottomRightRadius: 0,
//         backgroundColor: $theme.colors.white,
//       }),
//     },
//   }}
// >
//   <div data-si="popout" />
// </Popover>
