import React from 'react';
import { useTeamContext } from 'components/team/context/team-context';
import DeleteTeamForm from './DeleteTeamForm';

const DeleteTeam = () => {
  const { id, name } = useTeamContext();

  return (
    <>
      <DeleteTeamForm teamRef={id} teamName={name} />
    </>
  );
};

export default DeleteTeam;
