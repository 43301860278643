import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';

const GreetingControls = ({ openControlHandler, name, watch }: any) => {
  const [css, theme] = useStyletron();

  const $name = watch(name);

  const readTextControl = (
    <Button
      kind="secondary"
      size="mini"
      type="button"
      disabled={!$name.text}
      onClick={() => openControlHandler('PREVIEW')}
    >
      Preview Script
    </Button>
  );

  const playRecordingControl = (
    <>
      <Button
        kind="secondary"
        onClick={() => openControlHandler('UPLOAD')}
        size="mini"
        type="button"
      >
        Add New Greeting
      </Button>
      <Button
        disabled={!$name.greetingRef?.[0]?.id}
        kind="secondary"
        onClick={() => openControlHandler('RECORDING')}
        size="mini"
        type="button"
      >
        Preview
      </Button>
    </>
  );

  return (
    <div
      className={css({ display: 'flex', gap: theme.sizing.scale100, justifyContent: 'flex-end' })}
    >
      {$name.greetingMethod[0]?.id === 'READ_TEXT' && readTextControl}

      {$name.greetingMethod[0]?.id === 'PLAY_RECORDING' && playRecordingControl}
    </div>
  );
};

export default GreetingControls;
