import { styled, Theme } from 'baseui';
import { NavLink } from 'react-router-dom';

type StyledGenericProps = { [key: string]: any } & Partial<Theme>;

export const NavItemIcon = styled<any, StyledGenericProps>('div', ({ $isPinned, $theme }) => ({
  flexShrink: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: $theme.sizing.scale500,
  [$theme.mediaQuery.large]: {
    marginRight: $isPinned ? $theme.sizing.scale500 : 0,
  },
  lineHeight: $theme.sizing.scale600,
}));

export const NavigationLabel = styled<any, StyledGenericProps>('div', ({ $isPinned, $theme }) => ({
  flex: '1',
  opacity: 1,
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  [$theme.mediaQuery.large]: {
    display: $isPinned ? 'flex' : 'none',
  },
}));

export const NavigationListItem = styled<'li', StyledGenericProps>('li', ({ $theme }) => ({
  ...$theme.typography.LabelSmall,
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
  paddingBottom: $theme.sizing.scale100,
  listStyleType: 'none',
  display: 'block',
  cursor: 'pointer',
  boxSizing: 'border-box',
}));

export const Anchor = styled(NavLink, ({ $theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  color: $theme.colors.mono400,
  paddingLeft: $theme.sizing.scale400,
  paddingRight: $theme.sizing.scale400,
  '@media (hover:hover)': {
    ':hover': {},
  },
}));

export const NavLabel = styled<'span', StyledGenericProps>('span', ({ $isTopLevel }) => ({
  fontWeight: $isTopLevel ? 500 : 400,
}));

export const NavOptGroupHeader = styled<'div', StyledGenericProps>('div', ({ $theme }) => ({
  ...$theme.typography.LabelSmall,
  paddingLeft: $theme.sizing.scale400,
  paddingRight: $theme.sizing.scale400,
  paddingTop: $theme.sizing.scale500,
  paddingBottom: $theme.sizing.scale100,
}));

export const NavIconAnimate = styled<'div', { $open: boolean }>('div', ({ $open, $theme }) => ({
  display: 'flex',
  fontSize: $theme.sizing.scale500,
  transition: 'transform .266s',
  transform: $open ? 'rotate(90deg)' : 'rotate(0deg)',
}));

export const StyledSettingsAvatar = styled('div', ({ $theme }) => ({
  transform: 'scale(.70)',
  ...$theme.typography.LabelSmall,
}));

export const UpperNavMenu = styled('nav', () => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflowY: 'auto',
  overflowX: 'hidden',
  '::-webkit-scrollbar': {
    width: '3px',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.25)',
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
  },
}));

export const LowerNavMenu = styled<any, StyledGenericProps>('nav', ({ $theme, $alwaysShow }) => ({
  display: $alwaysShow ? 'block' : 'none',
  borderTop: '1px solid rgba(255, 255, 255, .1)',
  [$theme.mediaQuery.large]: {
    display: 'block',
  },
}));

export const LowerNavMenuItem = styled('div', ({ $theme }) => ({
  display: 'none',
  [$theme.mediaQuery.large]: {
    display: 'block',
  },
}));

export const List = styled('ul', ({ $theme }) => ({
  margin: 0,
  padding: `${$theme.sizing.scale600} 0`,
  listStyle: 'none',
}));

export const LinkContent = styled<any, StyledGenericProps>(
  'span',
  ({ $active, $disableBackground, $hover, $pinned, $reverse, $theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    lineHeight: '20px',
    flexDirection: $reverse ? 'row-reverse' : 'row',
    justifyContent: 'unset',
    padding: '7px calc(9.375% + 2px - 10px)',
    [$theme.mediaQuery.large]: {
      justifyContent: $pinned ? 'unset' : 'center',
      padding: $pinned ? '7px calc(9.375% + 2px - 10px)' : '10px 9.375%',
    },
    borderRadius: $theme.sizing.scale900,
    backgroundColor:
      $hover || (!$disableBackground && $active) ? 'rgba(255, 255, 255, .1)' : 'transparent',
  }),
);

export const PopoverAnchor = styled('span', () => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}));

export const SubMenuListItem = styled<'li', StyledGenericProps>(
  'li',
  ({ $theme, $isHighlighted }) => ({
    listStyle: 'none',
    color: $theme.colors.mono600,
    ...$theme.typography.LabelSmall,
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    paddingBottom: $theme.sizing.scale100,
    backgroundColor: $isHighlighted ? $theme.colors.mono100 : 'inherit',
    ':hover': {
      backgroundColor: $theme.colors.mono100,
    },
  }),
);

export const SubMenuAnchor = styled(NavLink, ({ $theme }) => ({
  display: 'flex',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  color: $theme.colors.mono500,
  paddingTop: $theme.sizing.scale400,
  paddingBottom: $theme.sizing.scale400,
  paddingLeft: $theme.sizing.scale400,
  paddingRight: $theme.sizing.scale400,
  '@media (hover:hover)': {
    ':hover': {},
  },
}));

export const SubMenuText = styled<any, StyledGenericProps>('span', ({ $isActive, $theme }) => ({
  color: $isActive ? $theme.colors.mono800 : 'inherit',
}));
