import { useStyletron } from 'baseui';
import { Heading, HeadingLevel } from 'baseui/heading';
import React from 'react';
import { Outlet, useMatches } from 'react-router-dom';

const SettingsLayout = () => {
  const [css, theme] = useStyletron();

  const matches = useMatches();

  const title = matches
    .filter((match: any) => Boolean(match.handle?.title))
    .map((match: any) => match.handle?.title);

  return (
    <div>
      <HeadingLevel>
        <Heading
          styleLevel={5}
          marginTop={0}
          className={css({ fontSize: theme.sizing.scale750, fontWeight: 600 })}
        >
          {title?.join()}
        </Heading>
        <div>
          <Outlet />
        </div>
      </HeadingLevel>
    </div>
  );
};

export default SettingsLayout;
