import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  ReactNode,
  SetStateAction,
} from 'react';

type OwnerContextProviderProps = {
  children: ReactNode;
};

type OwnerContextType = {
  recentlyAddedOwnerId: string | undefined;
  setRecentlyAddedOwnerId: Dispatch<SetStateAction<string | undefined>>;
};

const OwnerContext = createContext<OwnerContextType | undefined>(undefined);

const OwnerContextProvider = ({ children }: OwnerContextProviderProps) => {
  const [recentlyAddedOwnerId, setRecentlyAddedOwnerId] = useState<string | undefined>();

  return (
    <OwnerContext.Provider value={{ recentlyAddedOwnerId, setRecentlyAddedOwnerId }}>
      {children}
    </OwnerContext.Provider>
  );
};

const useRecentOwner = () => {
  const context = useContext(OwnerContext);
  if (context === undefined) {
    throw new Error('useRecentOwner must be used within a OwnerContextProvider');
  }
  return context;
};

export { OwnerContextProvider, useRecentOwner };
