import React, { useEffect } from 'react';
import { useStyletron } from 'baseui';
import { useSideMenuContext } from 'context/SideMenuContext';
import { useMediaQuery } from 'react-responsive';
import ReactFocusLock from 'react-focus-lock';
import * as Styled from './styles/styled-components';

export interface SidebarProps {
  content?: any;
  headerContent?: any;
}

const Sidebar = (props: SidebarProps) => {
  const { content, headerContent } = props;

  const { open, setOpen, isExpanded, setExpanded } = useSideMenuContext();

  const toggleSize = React.useCallback(() => {
    setExpanded(!isExpanded);
  }, [isExpanded, setExpanded]);

  const closeDrawer = () => setOpen(false);

  const [, theme] = useStyletron();

  const isLargeScreen = useMediaQuery({ minWidth: theme.breakpoints.large });

  useEffect(() => {
    const keydownHandler = (e: any) => {
      if (e.keyCode === 219 && toggleSize) {
        toggleSize();
      }
    };
    document.addEventListener('keydown', keydownHandler);
    return () => {
      document.removeEventListener('keydown', keydownHandler);
    };
  }, [toggleSize]);

  return (
    <Styled.SidebarRoot data-visible={open} $visible={open} data-si="sidebar-root">
      <Styled.Backdrop data-si="backdrop" onClick={closeDrawer} />
      <ReactFocusLock disabled={isLargeScreen || (!isLargeScreen && !open)}>
        <Styled.SidebarContainer data-si="sidebar-container" $isExpanded={isExpanded}>
          <Styled.SidebarMenuWrap data-si="sidebar-menu-wrap">
            {headerContent}
            {content()}
          </Styled.SidebarMenuWrap>
        </Styled.SidebarContainer>
      </ReactFocusLock>
    </Styled.SidebarRoot>
  );
};

export default Sidebar;
