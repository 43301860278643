import { useMutation, useQueryClient } from 'react-query';
import useAxios from './useAxios';

type PurchasePhoneMutation = {
  number: string;
  busProfile: string;
};

function usePurchasePhoneNumber() {
  const axios = useAxios();

  const queryClient = useQueryClient();

  const purchasePhoneNumber = ({ number, busProfile }: PurchasePhoneMutation) => {
    if (!number || !busProfile) return Promise.reject(new Error('Missing argument'));
    return axios.post(
      `api/purchase-phone-number?number=${encodeURIComponent(number)}&busProfile=${busProfile}`,
    );
  };

  return useMutation((vars: PurchasePhoneMutation) => purchasePhoneNumber(vars), {
    onSuccess: () => {
      queryClient.invalidateQueries(['availabePhoneNumbers']);
      queryClient.invalidateQueries(['getSavedPhoneNumbers']);
    },
    onError: (err: any) => err,
  });
}

export default usePurchasePhoneNumber;
