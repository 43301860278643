import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../../hooks/useAxios';

const useRemoveTeam = (teamId?: string) => {
  const axios = useAxios();

  const queryClient = useQueryClient();

  const removeTeam = (formData: any) => {
    if (!teamId) {
      return Promise.reject();
    }
    return axios.post(`api/team/${teamId}/remove`, formData);
  };

  return useMutation((formData: {}) => removeTeam(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getTeamsForUser']);
      // queryClient.invalidateQueries(['getTeam']);
    },
    onError: (err: any) => err,
  });
};

export default useRemoveTeam;
