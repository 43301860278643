import React, { ComponentType, ReactNode, Suspense } from 'react';
import AppLoadingScreen from 'components/screen/AppLoadingScreen';

function withSuspense<P>(WrappedComponent: ComponentType<P>, fallback?: ReactNode) {
  return (props: P) => (
    <Suspense fallback={fallback ?? <AppLoadingScreen />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
}

export default withSuspense;
