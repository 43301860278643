import { useStyletron } from 'baseui';
import { Button, ButtonOverrides } from 'baseui/button';

const buttonProps: ButtonOverrides = {
  BaseButton: {
    style: () => ({
      flex: 1,
      ':first-child': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
      ':nth-child(2)': {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    }),
  },
};

const PurchasePhoneToggle = ({ changePhoneType, phoneType$ }: any) => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        marginBottom: theme.sizing.scale600,
      })}
    >
      <Button
        type="button"
        size="mini"
        kind="secondary"
        isSelected={phoneType$ === 'SAVED'}
        onClick={() => changePhoneType('SAVED')}
        overrides={buttonProps}
      >
        Saved Phone Numbers
      </Button>

      <Button
        type="button"
        size="mini"
        kind="secondary"
        isSelected={phoneType$ === 'PURCHASE'}
        onClick={() => changePhoneType('PURCHASE')}
        overrides={buttonProps}
      >
        New Phone Number
      </Button>
    </div>
  );
};

export default PurchasePhoneToggle;
