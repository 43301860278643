import { Outlet } from 'react-router-dom';
import Modal from 'components/modal/Modal';
import useIncomingCommSubscription from 'components/alerts/useIncomingCommSubscription';
import UserIdentityMessage from 'components/screen/user-identity-message';
import useLeadSubscription from 'components/alerts/useLeadSubscription';
import useNewLeadSubscription from 'components/alerts/useNewLeadSubscription';
import { ModalProvider } from 'components/modal/ModalProvider';
import { TeamCalendarProvider } from 'components/dashboard/TeamCalendarProvider';
import TeamCalendarConnectionStatus from 'components/notifications/TeamCalendarlConnectionFail';
import TextMessagePopout from 'components/textMessage/text-message-popout';
import { SideMenuProvider } from 'context/SideMenuContext';
import useWebSocketMessage from 'components/alerts/use-web-socket-message';
import useWebSocketMessageHandler from 'components/alerts/use-web-socket-message-handler';
import { useCurrentUserId } from '~/context/current-user-context';
import { StoreProvider } from '~/core/store-context';
import { useWebSocket } from '~/context/web-socket-context';
import { useEffect } from 'react';
import Sidebar from '../components/sidebar';
import CallHeader from '../components/callHeader';
import Header from '../components/header/Header';
import Navigation from '../components/navigation';
import DoNotDisturb from '../components/screen/DoNotDisturb';
import SidebarHeader from '../components/sidebar/SidebarHeader';
import { NavigationProvider } from '../components/navigation/context/navigation-context';
import ReloadPrompt from '../components/screen/reload-prompt';
import EmailConnectionFail from '../components/notifications/EmailConnectionFail';
import CalendarlConnectionFail from '../components/notifications/CalendarlConnectionFail';
import Popout from '../components/popout';
import { PopoutContextProvider } from '../components/popout/PopoutContextProvider';
import * as Styled from './styles/styled-components';

const ApplicationLayout = () => {
  useIncomingCommSubscription();
  useLeadSubscription();
  useNewLeadSubscription();

  const id = useCurrentUserId();

  const { instance } = useWebSocket();

  const { createSubscription } = useWebSocketMessage();

  const { handleMessage } = useWebSocketMessageHandler();

  useEffect(() => {
    let timer: any;
    const interval = 45 * 60 * 1000;
    if (instance) {
      timer = setInterval(() => {
        console.log('close ws');
        instance?.complete();
      }, interval);
    }
    return () => clearInterval(timer);
  }, [instance]);

  useEffect(() => {
    const sub = createSubscription(handleMessage);
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [createSubscription, handleMessage]);

  return (
    <StoreProvider id={id}>
      <Styled.Main data-id="main">
        <Styled.ContentContainer data-id="content-container">
          <UserIdentityMessage />
          <ModalProvider>
            <CallHeader />
            <ReloadPrompt />
            <CalendarlConnectionFail />
            <TeamCalendarProvider>
              <TeamCalendarConnectionStatus />
            </TeamCalendarProvider>
            <EmailConnectionFail />
            <DoNotDisturb />
            <PopoutContextProvider>
              <SideMenuProvider>
                <Header />
                <Styled.ContentFrameWrap data-id="content-frame-wrap">
                  <Styled.ContentFrame data-id="content-frame">
                    <Styled.ContentInner id="app-content">
                      <Outlet />
                    </Styled.ContentInner>
                  </Styled.ContentFrame>
                  <NavigationProvider>
                    <Sidebar headerContent={<SidebarHeader />} content={() => <Navigation />} />
                  </NavigationProvider>
                </Styled.ContentFrameWrap>
              </SideMenuProvider>
              <Popout />
            </PopoutContextProvider>
            <Modal />
            <TextMessagePopout />
          </ModalProvider>
        </Styled.ContentContainer>
      </Styled.Main>
    </StoreProvider>
  );
};

export default ApplicationLayout;
