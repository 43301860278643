import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../hooks/useAxios';

const useDismissAlert = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const dismissAlert = (ids: string) => axios.put(`api/alert/dismiss?ids=${ids}`);

  return useMutation(({ ids }: any) => dismissAlert(ids), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAlerts']);
    },
    onError: (err: any) => err,
  });
};

export default useDismissAlert;
