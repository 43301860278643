import * as yup from 'yup';
import { BaseUiOption } from '../../../shared/types';
import { useValidTeamName, useValidEmail } from './custom';
import {
  EmailHosting,
  GreetingMethod,
  TeamAssignmentMode,
  TeamLockMode,
  TeamPhoneType,
  TeamType,
} from '../../../backend/team';
import { EMAIL_REGEXP } from '../../../constants';

export const arraySchema = yup
  .array()
  .nullable()
  .of(yup.object({ label: yup.string(), id: yup.string() }))
  .required('Selection Required')
  .min(1, 'Selection Required');

export const defaultGreetingSchema = yup.object({
  defaultGreeting: yup.object({
    // greetingMethod : yup.string().required('Selection Required'),
    greetingMethod: arraySchema,
    text: yup.string().when('greetingMethod', {
      is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'READ_TEXT',
      then: yup.string().required('Required'),
    }),
    voice: yup
      .array()
      .nullable()
      .when('greetingMethod', {
        is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'READ_TEXT',
        then: arraySchema,
      }),
    greetingRef: yup
      .array()
      .nullable()
      .when('greetingMethod', {
        is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'PLAY_RECORDING',
        then: arraySchema,
      }),
  }),
});

export const greetingSchema = yup.object({
  greeting: yup.object({
    // greetingMethod : yup.string().required('Selection Required'),
    greetingMethod: arraySchema,
    text: yup.string().when('greetingMethod', {
      is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'READ_TEXT',
      then: yup.string().required('Required'),
    }),
    voice: yup
      .array()
      .nullable()
      .when('greetingMethod', {
        is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'READ_TEXT',
        then: arraySchema,
      }),
    greetingRef: yup
      .array()
      .nullable()
      .when('greetingMethod', {
        is: (value: BaseUiOption<GreetingMethod>[]) => value?.[0]?.id === 'PLAY_RECORDING',
        then: arraySchema,
      }),
  }),
});

export const assignmentModeSchema = yup.object({
  assignmentMode: yup.string().required('Selection Required'),
});

export const lockModeSchema = yup.object({
  lockMode: yup
    .string()
    .nullable()
    .when('assignmentMode', {
      is: (value: TeamAssignmentMode) => value === 'CLAIM',
      then: yup
        .string()
        .required('Selection Required')
        .oneOf(['TIMED', 'UNLIMITED'], 'Selection Required'),
    }),
});

export const lockDurationSchema = yup.object({
  lockDuration: yup.string().when(['lockMode', 'assignmentMode'], {
    is: (lockMode: TeamLockMode, assignmentMode: TeamAssignmentMode) =>
      lockMode === 'TIMED' && assignmentMode === 'CLAIM',
    then: yup
      .string()
      .required('Required')
      .test({
        name: 'durationLock',
        message: 'Enter between 1 and 90 days',
        test: (input) => {
          if (input) {
            const int = parseInt(input, 10);
            return int > 0 && int <= 90;
          }
          return true;
        },
      }),
  }),
});

export const collaborationModeSchema = yup.object({
  collaborationMode: yup.string().required('Selection Required'),
});

export const areaCodeSchema = yup.object({
  areaCode: yup.string().when('phoneType', {
    is: 'PURCHASE',
    then: yup
      .string()
      .required('Phone number required. Enter an area code to search.')
      .matches(/\d{3}/, 'Invalid area code. '),
  }),
});

export const phoneSchema = yup.object().shape(
  {
    phone: yup
      .mixed()
      .when('phoneType', {
        is: (value: TeamPhoneType) => value === 'SAVED',
        then: arraySchema,
        // otherwise: yup.string().required('Phone number is required.'),
      })
      .when(['areaCode', 'phoneType'], {
        is: (ac: string, pt: TeamPhoneType) => ac && pt === 'PURCHASE',
        then: arraySchema.min(1, 'Phone number required.'),
      })
      .test(
        'isSaved',
        'Add the selected phone number to your account',
        function fn(value: any[] | undefined) {
          if (value?.[0]?.id && this.parent.phoneType === 'PURCHASE') {
            return false;
          }
          return true;
        },
      ),
  },
  [['areaCode', 'phoneType']],
);

export const teamLeadSchema = yup.object({
  teamLead: arraySchema,
});

export const nameSchema = yup.object({
  teamName: yup.string().required('Required'),
});

export const routingMethodSchema = yup.object({
  routingMethod: yup.string().required('Required'),
});

export const emailTypeSchema = yup.object({
  emailType: yup.string().required('Selection Required'),
});

export const emailRefSchema = yup.object({
  emailRef: yup
    .array()
    .of(yup.object({ label: yup.string(), id: yup.string() }))
    .when('emailType', {
      is: 'SAVED',
      then: arraySchema,
    }),
  // .min(1, 'Selection Required'),
});

export const useEmailAddressSchema = () => {
  const validEmail = useValidEmail();
  yup.addMethod(yup.string, 'uniqueEmail', validEmail);

  return yup.object({
    emailAddress: yup
      .string()
      .test('email', 'Enter a valid email address', function fn(value) {
        const customDomain = this.options.context?.suffix;
        if (value && customDomain) {
          const a = `${value}${customDomain}`;
          return EMAIL_REGEXP.test(a);
        }
        return true;
      })
      .when('emailType', {
        is: 'CREATE',
        // @ts-ignore
        then: yup.string().uniqueEmail().required('Required'),
      }),
  });
};

export const propertyRefSchema = yup.object({
  propertyRef: yup
    .array()
    .nullable()
    .when('$supportedTeamTypes', {
      is: (teamType: TeamType[]) => teamType[0] === 'OWNER',
      then: yup.array().min(1, 'Selection Required'),
    }),
});

export const propertyTypeSchema = yup.object({
  teamType: yup.string().required('Selection Required'),
  propertyTypes: yup.array().compact().of(yup.string()).min(1, 'Selection Required'),
});

export const useTeamNameSchema = () => {
  const validateUniqueTeamName = useValidTeamName();
  yup.addMethod(yup.string, 'uniqueTeamName', validateUniqueTeamName);
  return yup.object({
    // @ts-ignore
    teamName: yup.string().uniqueTeamName().required('Required'),
  });
};

export const useEmailSchema = () => {
  const validEmail = useValidEmail();
  yup.addMethod(yup.string, 'uniqueEmail', validEmail);
  return yup.object({
    tokenCode: yup.string().when('$emailHosting', {
      is: (v: EmailHosting) => v === 'EXTERNAL',
      then: yup.string().required('Authorize the account to complete the email integration.'),
    }),
    email: yup
      .string()
      .test('email', 'Enter a valid email address.', function fn(value) {
        const customDomain = this.options.context?.suffix;
        // const emailHosting = this.options.context?.emailHosting;
        if (value) {
          // if (emailHosting === 'EXTERNAL') {
          //   return EMAIL_REGEXP.test(value);
          // }
          const a = `${value}${customDomain}`;
          return EMAIL_REGEXP.test(a);
        }
        return true;
      })
      // @ts-ignore
      .uniqueEmail()
      .required('Required'),
  });
};

export const webChannelSchema = yup.object().shape(
  {
    pageName: yup.string().when('url', {
      is: (value: string) => value,
      then: yup.string().required('Required if setting up the web channel and a URL is entered.'),
    }),
    url: yup.string().when('pageName', {
      is: (value: string) => value,
      then: yup
        .string()
        .url('Must be a valid URL')
        .required('Required if setting up the web channel and a Page Name is entered.'),
    }),
  },
  [['pageName', 'url']],
);

export const useNewTeamSchema = () => {
  const validEmail = useValidEmail();

  const validateUniqueTeamName = useValidTeamName();

  yup.addMethod(yup.string, 'uniqueEmail', validEmail);
  yup.addMethod(yup.string, 'uniqueTeamName', validateUniqueTeamName);

  return yup
    .object({
      // @ts-ignore
      teamName: yup.string().uniqueTeamName().required('Required'),
    })
    .concat(propertyRefSchema)
    .concat(teamLeadSchema)
    .concat(propertyTypeSchema)
    .concat(areaCodeSchema)
    .concat(phoneSchema)
    .concat(defaultGreetingSchema)
    .concat(assignmentModeSchema)
    .concat(lockModeSchema)
    .concat(lockDurationSchema)
    .concat(collaborationModeSchema)
    .concat(
      yup.object({
        email: yup
          .string()
          .test('email', 'Enter a valid email address.', function fn(value) {
            const customDomain = this.options.context?.suffix;
            if (value && customDomain) {
              const a = `${value}${customDomain}`;
              return EMAIL_REGEXP.test(a);
            }
            return true;
          })
          // @ts-ignore
          .uniqueEmail()
          .required('Required'),
      }),
    );
};

export const deleteTeamSchema = yup.object({
  closeLeads: yup.string().oneOf(['true', 'false'], 'Selection Required').nullable(),
  leadsTargetTeam: yup
    .array()
    .nullable()
    .when('closeLeads', {
      is: (value: string) => value === 'false',
      then: arraySchema,
    }),
  leadsTargetUser: yup
    .array()
    .nullable()
    .when('closeLeads', {
      is: (value: string) => value === 'false',
      then: arraySchema,
    }),
  removeCampaigns: yup
    .array()
    .nullable()
    .of(
      yup.object({
        campaignRef: yup.string(),
        removeEmailChannel: yup.object({
          saveEmail: yup.string().oneOf(['true', 'false'], 'Selection Required').nullable(),
          forwardingEmail: yup
            .string()
            .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email address' }),
        }),
        removePhoneChannel: yup.object({
          savePhone: yup.string().oneOf(['true', 'false'], 'Selection Required').nullable(),
          forwardingNumber: yup.string().test('test-phone', 'Invalid phone number', (value) => {
            if (value) return yup.string().phone('US').isValidSync(value);
            return true;
          }),
        }),
      }),
    ),
});

export const connectedCalendarSchema = yup.object({
  connectedCalendarEmailAddress: yup.string().required('Required'),
});
