import React, { SyntheticEvent, useEffect, useReducer, useState } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useAsyncDebounce } from 'react-table';
import { FormProvider } from 'react-hook-form';
import CollaborationModeForm from 'modules/team/admin/CollaborationModeForm';
import { Stepper, Step } from 'components/stepper';
import { useClient } from 'context/client-context';
import * as schemas from 'components/team/validators/teamSchemas';
import useCreateTeam from 'components/team/hooks/useCreateTeam';
import CreateTeamReviewStep from './CreateTeamReviewStep';
import TeamNameForm from './TeamNameForm';
import TeamLeadForm from './TeamLeadForm';
import GreetingForm from './GreetingForm';
import AssignmentModeForm from './AssignmentModeForm';
import ProvisionEmailForm from './ProvisionEmailForm';
import SharedCalendarForm from './SharedCalendarForm';
import ProvisionPhoneForm from './ProvisionPhoneForm';
import {
  useStepAssignmentMode,
  useStepCollaborationMode,
  useStepConnectCalendar,
  useStepGreeting,
  useStepProvisionEmail,
  useStepProvisionPhone,
  useStepTeam,
  useStepTeamName,
  useStepTeamWarnings,
  useStepWebChannel,
} from './hooks/forms';
import NotificationsForm from './NotificationsForm';
import WebChannelRequestForm from './web-channel-request-form';

type PropsT = {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  setSelectedTeam?: React.Dispatch<any>;
};

const initialState = { step: 0, totalSteps: 10 };

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'NEXT':
      return {
        ...state,
        step: state.step + 1,
      };
    case 'BACK':
      return {
        ...state,
        step: state.step - 1,
      };
    case 'GOTO':
      return {
        ...state,
        step: action.step,
      };
    case 'RESET':
      return {
        ...state,
        step: 0,
      };
    default:
      throw new Error();
  }
};

const CreateBrokerTeam = ({ isOpen, setIsOpen, setSelectedTeam }: PropsT) => {
  const { state: client } = useClient();

  const { mutate } = useCreateTeam();

  const queryClient = useQueryClient();

  const { emailDomain } = client;

  const [state, dispatch] = useReducer(reducer, initialState);

  const [title, setTitle] = useState('Create New Team');

  const [isProcessing, setIsProcessing] = useState<boolean>();

  const [buttonLabel, setButtonLabel] = useState('Next');

  const teamNameSchema = schemas.useTeamNameSchema();

  const emailSchema = schemas.useEmailSchema();

  const formStep1 = useStepTeamName();

  const formStep2 = useStepTeam();

  const formStep3 = useStepAssignmentMode();

  const formStepCollaborationMode = useStepCollaborationMode();

  const formStep4 = useStepProvisionEmail();

  const [hasTeamCalendar$, calendarSameAsEmail$] = formStep4.watch([
    'hasTeamCalendar',
    'calendarSameAsEmail',
  ]);

  const formStepTeamCalendar = useStepConnectCalendar();

  const formStepPhoneProvision = useStepProvisionPhone();

  const { register } = formStepPhoneProvision;

  const formStep5 = useStepGreeting();

  const formStepWebChannel = useStepWebChannel();

  const formStepTeamWarnings = useStepTeamWarnings();

  const customValidate = (
    value: string,
    fieldname: string,
    schema: yup.AnyObjectSchema,
    formInstance: any,
  ) => {
    schema
      .validateAt(
        fieldname,
        { [fieldname]: value },
        { context: { suffix: `@${emailDomain}`, emailHosting: client?.emailHosting } },
      )
      .then(() => {
        formInstance.clearErrors(fieldname as any);
      })
      .catch((e) => {
        formInstance.setError(fieldname as any, { type: e.type, message: e.message });
      });
  };

  const handleValidateOnChange = useAsyncDebounce((value: string, name: string) => {
    if (!value) return;

    if (name === 'email') {
      customValidate(value, name, emailSchema, formStep4);
      return;
    }
    customValidate(value, name, teamNameSchema, formStep1);
  }, 500);

  const replaceInvalidEmailChars = React.useCallback((v: string) => {
    if (!v) return v;
    return v.replace(/[^a-zA-Z\d-]/g, '').toLowerCase();
  }, []);

  const getTeamName = () => {
    const teamName = formStep1.getValues('teamName');
    const value = replaceInvalidEmailChars(teamName);
    return value;
  };

  const handleNextStep = () => {
    // console.log(state.step, hasTeamCalendar$);
    if (state.step < state.totalSteps) {
      if ((!hasTeamCalendar$ || (hasTeamCalendar$ && calendarSameAsEmail$)) && state.step === 4) {
        // console.log('skip calendar setup and go to 6');
        dispatch({ type: 'GOTO', step: 6 });
        return;
      }
      dispatch({ type: 'NEXT' });
    }
  };

  const handlePrevStep = () => {
    if (state.step > 0) {
      if ((!hasTeamCalendar$ || (hasTeamCalendar$ && calendarSameAsEmail$)) && state.step === 6) {
        // console.log('skip 5, go to 4');
        dispatch({ type: 'GOTO', step: 4 });
        return;
      }
      dispatch({ type: 'BACK' });
    }
  };

  const cancelSteps = () => {
    setIsOpen(false);
  };

  const onSubmit = () => {
    handleNextStep();
  };

  const areStepsDirty = [
    formStep1.formState.isDirty,
    formStep2.formState.isDirty,
    formStep3.formState.isDirty,
    formStepCollaborationMode.formState.isDirty,
    formStep4.formState.isDirty, // email
    formStepTeamCalendar.formState.isDirty,
    formStepPhoneProvision.formState.isDirty,
    true, // greeting
    true, // webchannel
    true,
    true,
  ];

  const getAllValues = () => {
    const v = {
      ...formStep1.getValues(),
      ...formStep2.getValues(),
      ...formStep3.getValues(),
      ...formStepCollaborationMode.getValues(),
      ...formStep4.getValues(),
      ...formStepTeamCalendar.getValues(),
      ...formStepPhoneProvision.getValues(),
      ...formStep5.getValues(),
      ...formStepWebChannel.getValues(),
      ...formStepTeamWarnings.getValues(),
    };
    return v;
  };

  const submitAllForms = () => {
    const formData = getAllValues();

    const {
      areaCode,
      defaultGreeting,
      propertyRef,
      emailHosting,
      pageName,
      url,
      twilioBusinessProfileRef,
      ...rest
    } = formData;

    const model = {
      ...rest,
      teamLead: {
        userRef: rest.teamLead[0].id,
        acceptPhoneTransfers: rest.acceptPhoneTransfers,
      },
      email: `${rest.email.toLowerCase()}@${emailDomain}`,
      emailHosting,
      lockDuration: rest.lockDuration ? rest.lockDuration : '0',
      lockMode: rest.lockMode ?? 'NONE',
      phone: Array.isArray(rest.phone) ? rest.phone[0].id : rest.phone,
      defaultGreeting: {
        ...defaultGreeting,
        greetingMethod: defaultGreeting.greetingMethod[0].id,
        greetingRef:
          defaultGreeting.greetingMethod[0].id === 'PLAY_RECORDING'
            ? defaultGreeting.greetingRef?.[0].id
            : null,
        voice:
          defaultGreeting.greetingMethod[0].id === 'READ_TEXT'
            ? defaultGreeting.voice?.[0].id
            : null,
        text: defaultGreeting.text,
        greetingPlacement: defaultGreeting.greetingPlacement?.[0]?.id,
      },
      twilioBusinessProfileRef: twilioBusinessProfileRef[0].id,
    };

    if (pageName && url) {
      Object.assign(model, { webChannel: { pageName, url } });
    }

    mutate(model, {
      onSuccess: (data) => {
        const successMessage = 'Team Created';
        toast(successMessage, { theme: 'dark', position: 'top-center' });
        // setIsOpen(false);
        const location = data.headers?.location;

        if (location) {
          const id = location.split('/').pop();
          if (typeof setSelectedTeam === 'function') {
            setTimeout(() => {
              setIsOpen(false);
              setSelectedTeam(id);
            }, 300);
          }
        } else {
          setIsOpen(false);
        }
      },
      onError: () => {
        toast.error('Sorry, we encountered a problem processing your request. Please try again', {
          theme: 'dark',
          position: 'top-center',
        });
        setIsOpen(false);
      },
    });
  };

  const submitStep = (e: SyntheticEvent) => {
    if (state.step === state.totalSteps) {
      e.preventDefault();
      submitAllForms();
    }
    // stepSubmits[state.step](onSubmit)();
  };

  useEffect(() => {
    register('phoneType');
  }, [register]);

  useEffect(() => {
    if (!isOpen) {
      queryClient.removeQueries('getNumbersForPurchase');
    }
  }, [isOpen, queryClient]);

  useEffect(() => {
    const defaultTitle = 'Create New Team';
    const submitLabel = 'Create Team';
    switch (state.step) {
      case 0:
        setTitle(defaultTitle);
        setButtonLabel('Next');
        break;
      case 1:
        setTitle('Team Settings');
        setButtonLabel('Next');
        break;
      case 2:
        setTitle('Team Settings');
        setButtonLabel('Next');
        break;
      case 3:
        setTitle('Team Settings');
        setButtonLabel('Next');
        break;
      case 4:
        setTitle('Default Campaign Email');
        setButtonLabel('Next');
        break;
      case 5:
        setTitle('Team Calendar');
        setButtonLabel('Next');
        break;
      case 6:
        setTitle('Default Campaign Phone');
        setButtonLabel('Next');
        break;
      case 7:
        setTitle('Campaign Greeting');
        setButtonLabel('Next');
        break;
      case 8:
        setTitle('Web Channel');
        setButtonLabel('Next');
        break;
      case 9:
        setTitle('Notifications');
        setButtonLabel('Next');
        break;
      case 10:
        setTitle('Review');
        setButtonLabel(submitLabel);
        break;
      default:
        setTitle(defaultTitle);
        setButtonLabel('Next');
    }
  }, [state.step]);

  return (
    <>
      {/* <pre style={{ position: 'absolute', left: '100%', background: 'beige' }}>
        {JSON.stringify(getAllValues(), null, 2)}
      </pre> */}
      <Stepper
        isOpen={isOpen}
        steps={10}
        title={title}
        nextLabel={buttonLabel}
        disableNext={!areStepsDirty[state.step]}
        currentStep={state.step}
        onCancel={cancelSteps}
        onNextStep={submitStep}
        onPrevStep={handlePrevStep}
        isLoading={isProcessing}
        form={`step${state.step}`}
      >
        <Step data-si="0">
          <FormProvider {...formStep1}>
            <TeamNameForm
              validate={handleValidateOnChange}
              onSubmit={onSubmit}
              formRef={`step${state.step}`}
            />
          </FormProvider>
        </Step>

        <Step data-si="1">
          <FormProvider {...formStep2}>
            <TeamLeadForm onSubmit={onSubmit} formRef={`step${state.step}`} />
          </FormProvider>
        </Step>

        <Step data-si="2">
          <FormProvider {...formStep3}>
            <AssignmentModeForm onSubmit={onSubmit} formRef={`step${state.step}`} />
          </FormProvider>
        </Step>

        <Step data-si="3">
          <FormProvider {...formStepCollaborationMode}>
            <CollaborationModeForm onSubmit={onSubmit} formRef={`step${state.step}`} />
          </FormProvider>
        </Step>

        <Step data-si="4">
          <FormProvider {...formStep4}>
            <ProvisionEmailForm
              dispatchNextStep={handleNextStep}
              formRef={`step${state.step}`}
              onSubmit={onSubmit}
              getTeamName={getTeamName}
              validate={handleValidateOnChange}
              setIsProcessing={setIsProcessing}
            />
          </FormProvider>
        </Step>

        <Step data-si="5">
          <FormProvider {...formStepTeamCalendar}>
            <SharedCalendarForm
              dispatchNextStep={handleNextStep}
              formRef={`step${state.step}`}
              onSubmit={onSubmit}
              setIsBusy={setIsProcessing}
            />
          </FormProvider>
        </Step>

        <Step data-si="6">
          <FormProvider {...formStepPhoneProvision}>
            <ProvisionPhoneForm
              formRef={`step${state.step}`}
              onSubmit={onSubmit}
              getTeamName={getTeamName}
              validate={handleValidateOnChange}
            />
          </FormProvider>
        </Step>

        <Step data-si="7">
          <FormProvider {...formStep5}>
            <GreetingForm onSubmit={onSubmit} formRef={`step${state.step}`} />
          </FormProvider>
        </Step>

        <Step data-si="8">
          <FormProvider {...formStepWebChannel}>
            <WebChannelRequestForm
              // dispatchNextStep={handleNextStep}
              formRef={`step${state.step}`}
              onSubmit={onSubmit}
              showOptionalMessage
            />
          </FormProvider>
        </Step>

        <Step data-si="9">
          <FormProvider {...formStepTeamWarnings}>
            <NotificationsForm onSubmit={onSubmit} formRef={`step${state.step}`} />
          </FormProvider>
        </Step>

        <Step data-si="10">
          <CreateTeamReviewStep getAllValues={getAllValues} />
        </Step>
      </Stepper>
    </>
  );
};

export default CreateBrokerTeam;
