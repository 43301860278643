import React, { useMemo } from 'react';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconStar } from '@tabler/icons-react';
import useGetTeamsForUser from 'components/team/hooks/useGetTeamsForUser';
import { useCurrentUserContext } from 'context/current-user-context';
import { useClient } from 'context/client-context';
import { useSmartLists } from 'features/leads/use-smartlists';
import { useMatch } from 'react-router-dom';
import NavigationIconAlertCount from '../NavigationIconAlertCount';
import { NavigationEntry } from '../types/navigation';
import { useNavigationState } from '../context/navigation-context';

const useNavigationEntries = () => {
  const currentUser = useCurrentUserContext();

  const { state: client } = useClient();

  const {
    state: { filters },
  } = useNavigationState();

  const { userManageProperties, userManageUsers, role } = currentUser;

  const { data } = useGetTeamsForUser({ enabled: client?.teamType === 'BROKER' });

  const smartListsQuery = useSmartLists();

  const activateTeamSubnav = Number(data?.length) > 0;

  const match = useMatch('teams/:id/*');

  const tab = match?.params['*'];

  const showSummaryReport = client.isOwner && client.supportedPropertyTypes.includes('RESIDENTIAL');

  const teamsPath = React.useCallback(
    (id: string) => {
      try {
        const location = localStorage.getItem('teams.preferences.tab');
        return tab
          ? ['teams', id, tab].join('/')
          : ['teams', id, location].filter((v) => v).join('/');
      } catch (e) {
        return ['teams', id].join('/');
      }
    },
    [tab],
  );

  const smartLists = smartListsQuery.data
    ?.filter((list) => list.owner === currentUser.id)
    .map((list) => ({
      label: list.name,
      itemId: list.id,
      href: `/leads/list/${list.id}`,
      prefix: <IconStar size={12} stroke={1} style={{ color: 'yellow' }} />,
    }));

  if (tab) {
    localStorage.setItem('teams.preferences.tab', tab);
  }

  const teams = data
    ?.filter((v) => {
      if (!filters.team) return true;
      return String(v.name).toLocaleLowerCase().includes(String(filters.team).toLowerCase());
    })
    .map((team) => ({
      label: team.name,
      itemId: team.id,
      href: teamsPath(team.id),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const items: NavigationEntry[] = useMemo(
    () => [
      {
        label: 'Dashboard',
        itemId: 'dashboard',
        href: '/dashboard',
        prefix: <FontAwesomeIcon fixedWidth icon={solid('gauge-high')} />,
      },
      {
        label: 'Alerts',
        itemId: 'alerts',
        href: '/alerts',
        prefix: <NavigationIconAlertCount />,
        skipRender: client?.teamType === 'BROKER_SINGLE_PROP',
      },
      {
        label: 'Leads',
        itemId: 'leads',
        href: '/leads',
        prefix: <FontAwesomeIcon fixedWidth icon={regular('handshake')} />,
        subNav: [
          {
            label: 'Summary Report',
            itemId: '/leads/summary-report',
            href: '/leads/summary-report',
            skipRender: !showSummaryReport,
          },
          {
            label: 'All',
            itemId: '/leads/list/all',
            href: '/leads/list/all',
          },
          {
            label: 'Not Contacted',
            itemId: '/leads/list/not-contacted',
            href: '/leads/list/not-contacted',
          },
          {
            label: 'Unassigned',
            itemId: '/leads/list/unassigned',
            href: '/leads/list/unassigned',
          },
          // {
          //   label: 'Not Qualified',
          //   itemId: '/leads/unqualified',
          //   href: '/leads/unqualified',
          // },
          {
            label: 'Opportunities',
            itemId: '/leads/list/opportunities',
            href: '/leads/list/opportunities',
          },
          {
            label: 'Waiting List',
            itemId: '/leads/list/waiting-list',
            href: '/leads/list/waiting-list',
            skipRender: client?.teamType === 'BROKER',
          },
          {
            label: 'Prospects',
            itemId: '/leads/prospects',
            href: '/leads/prospects',
            skipRender: client?.teamType !== 'BROKER',
          },
          // {
          //   label: 'Closed',
          //   itemId: '/leads/closed',
          //   href: '/leads/closed',
          // },
          ...(smartLists || []),
        ],
      },
      {
        label: 'Properties',
        itemId: 'properties',
        href: '/properties',
        prefix: <FontAwesomeIcon fixedWidth icon={regular('building')} />,
        skipRender: client?.teamType === 'BROKER',
      },
      {
        label: 'Teams',
        itemId: 'teams',
        href: '/teams',
        prefix: <FontAwesomeIcon fixedWidth icon={solid('users')} />,
        skipRender: client?.teamType !== 'BROKER',
        subNav: activateTeamSubnav ? teams : undefined,
        filterable: true,
      },
      {
        label: 'Notifications',
        itemId: 'notifications',
        href: '/notifications',
        prefix: <FontAwesomeIcon fixedWidth icon={solid('satellite-dish')} />,
        skipRender: client?.teamType === 'BROKER_SINGLE_PROP',
      },
      {
        label: 'Reports',
        itemId: 'reports',
        href: '/reports',
        prefix: <FontAwesomeIcon fixedWidth icon={solid('chart-line')} />,
      },
      {
        label: 'Admin',
        itemId: 'admin',
        href: '/manage',
        skipRender: !userManageUsers && !userManageUsers && !userManageProperties,
        prefix: <FontAwesomeIcon fixedWidth icon={solid('user-cog')} />,
        subNav: [
          {
            label: 'Properties',
            itemId: '/manage/properties',
            href: '/manage/properties',
            skipRender:
              (role !== 'SUPERUSER' && !userManageProperties) || client?.teamType !== 'BROKER',
          },
          {
            label: 'Owners',
            itemId: '/manage/owners',
            href: '/manage/owners',
            skipRender:
              (role !== 'SUPERUSER' && !userManageProperties) ||
              client?.teamType === 'BROKER_SINGLE_PROP',
          },
          {
            label: 'Users',
            itemId: '/manage/users',
            href: '/manage/users',
            skipRender: role !== 'SUPERUSER' && !userManageUsers,
          },
        ],
      },
    ],
    [
      activateTeamSubnav,
      client?.teamType,
      userManageProperties,
      userManageUsers,
      showSummaryReport,
      smartLists,
      teams,
      role,
    ],
  );

  return items;
};

export default useNavigationEntries;
