import React from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { Controller, useFormContext } from 'react-hook-form';
import RequiredFieldMarker from '../../../components/shared/RequiredFieldMarker';

type FormStepPropsT = {
  onSubmit: (d: any) => any;
  validate?: (value: string, name: string) => any;
  formRef?: string;
};

// Broker Client
const TeamNameForm = (props: FormStepPropsT) => {
  const { onSubmit, validate, formRef } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
      <Controller
        name="teamName"
        control={control}
        render={({ field: { value, ref, onChange } }) => (
          <FormControl
            label={<RequiredFieldMarker>Team Name</RequiredFieldMarker>}
            error={errors?.teamName?.message as string | undefined}
          >
            <Input
              inputRef={ref as unknown as React.RefObject<HTMLInputElement>}
              value={value}
              onChange={(e: any) => {
                onChange(e.target.value);
                if (typeof validate === 'function') {
                  validate(e.target.value, 'teamName');
                }
              }}
              size={SIZE.compact}
              error={!!errors.teamName}
            />
          </FormControl>
        )}
      />
    </form>
  );
};

export default TeamNameForm;
