import React, { ChangeEvent } from 'react';
import { RadioGroup, Radio } from 'baseui/radio';
import { StatefulTooltip } from 'baseui/tooltip';
import { FormControl } from 'baseui/form-control';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as S from './styled-components';
import RequiredFieldMarker from '../../../components/shared/RequiredFieldMarker';
import { WithStyledRadioMarkOuter, WithStyledRadioRoot } from '../../../components/shared/styles';
import { FormStepPropsT } from './types';

const radioOverrides = {
  Root: {
    component: WithStyledRadioRoot,
  },
  Label: {
    style: ({ $theme }: any) => ({
      ...$theme.typography.ParagraphSmall,
    }),
  },
  RadioMarkOuter: {
    component: WithStyledRadioMarkOuter,
  },
};

const CollaborationModeForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext();

  const tipInfo = {
    collaboration: (
      <>
        <strong>Full</strong> - Lead is visible to all agents on the team.
        <br />
        <strong>Individual</strong> - Lead is only visible to the agent that claimed the lead.
        <br />
        <strong>Read-only</strong> - Lead is visible to all team member but only the assigned agent
        can update and contact the lead.
      </>
    ),
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
      <Controller
        name="collaborationMode"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl
            label={
              <>
                <RequiredFieldMarker>Collaboration Mode</RequiredFieldMarker>
                <StatefulTooltip
                  content={() => <S.MoreInfoToolTip>{tipInfo.collaboration}</S.MoreInfoToolTip>}
                >
                  <S.MoreInfoTip>
                    <FontAwesomeIcon icon={solid('circle-info')} />
                  </S.MoreInfoTip>
                </StatefulTooltip>
              </>
            }
            error={errors?.collaborationMode?.message as string | undefined}
          >
            <RadioGroup
              align="horizontal"
              value={value}
              error={!!errors.collaborationMode}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value);
              }}
            >
              <Radio value="FULL" overrides={radioOverrides}>
                Full
              </Radio>
              <Radio value="INDIVIDUAL" overrides={radioOverrides}>
                Individual
              </Radio>
              <Radio value="VISIBILITY" overrides={radioOverrides}>
                Read-only
              </Radio>
            </RadioGroup>
          </FormControl>
        )}
      />
    </form>
  );
};

export default CollaborationModeForm;
