import StarterKit from '@tiptap/starter-kit';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import { EditorContent } from '@tiptap/react';
import TextStyle from '@tiptap/extension-text-style';
import { Button } from 'baseui/button';
import { Spinner } from 'baseui/spinner';
import { ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { Select, Value } from 'baseui/select';
import { Div } from '~/components/textEditor/extensions/div';
import { Font } from '~/components/textEditor/extensions/font';
import { FontStyle } from '~/components/textEditor/extensions/fontStyle';
import { ShareMedia } from '~/components/textEditor/extensions/shareMedia';
import { useEditor } from '~/components/textEditor/useEditor';
import { useState } from 'react';
import { css } from 'styled-system/css';
import { useModal } from '~/components/modal/ModalProvider';
import { useGetTemplatedResponse, useGetTemplatedResponseForTeam } from './use-template-response';

type Props = {
  teamRef: string;
  templateType?: 'EMAIL' | 'TEXT';
  onInsert: (content: string) => void;
};

const extensions = [
  StarterKit,
  TextStyle,
  Color,
  Div,
  Link,
  Font,
  FontStyle,
  ShareMedia.configure({
    HTMLAttributes: {
      class: css({
        backgroundColor: 'gray.4',
        color: 'gray.12',
        borderRadius: '20px',
        paddingTop: '0.5',
        paddingBottom: '0.5',
      }),
    },
    InnerHTMLAttributes: {
      class: css({
        paddingLeft: '0.5',
        paddingRight: '0.5',
      }),
    },
  }),
];

function PickTemplate({ teamRef, templateType, onInsert }: Props) {
  const [value, setValue] = useState<Value>([]);

  const { data } = useGetTemplatedResponseForTeam({ teamRef, templateType });

  const { data: template, isLoading } = useGetTemplatedResponse(
    value?.[0]?.id as string | undefined,
  );

  const { closeModal } = useModal();

  const editor = useEditor(
    {
      extensions,
      content: template?.text,
      editable: false,
      editorProps: {
        attributes: {
          class: css({
            color: 'gray.12',
            outline: 'none',
            height: '220px',
            borderWidth: '1px',
            paddingTop: '1.5',
            paddingBottom: '1.5',
            paddingLeft: '2',
            paddingRight: '2',
          }),
        },
      },
    },
    [extensions, template?.text],
  );

  const list = data?.map((entry) => ({ id: entry.id, label: entry.name }));

  const updateEditorContent = () => {
    const templateContent = editor.getHTML();
    onInsert(templateContent);
  };

  return (
    <div>
      <ModalHeader>Pick a Template</ModalHeader>
      <ModalBody>
        <div className={css({ marginBottom: '2' })}>
          <Select
            clearable={false}
            options={list}
            size="compact"
            value={value}
            onChange={({ value: val }) => setValue(val)}
          />
        </div>
        <div
          className={css({
            fontSize: 'sm',
            minHeight: '220px',
          })}
        >
          {isLoading ? (
            <div
              className={css({
                alignItems: 'center',
                borderWidth: '1px',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                height: '220px',
              })}
            >
              <Spinner $borderWidth={2} />
            </div>
          ) : (
            <EditorContent editor={editor} />
          )}
        </div>
      </ModalBody>
      <ModalFooter className={css({ display: 'flex', gap: '4', justifyContent: 'flex-end' })}>
        <Button kind="tertiary" type="button" onClick={closeModal} size="compact">
          Cancel
        </Button>
        <Button type="button" onClick={updateEditorContent} size="compact" disabled={isLoading}>
          Insert
        </Button>
      </ModalFooter>
    </div>
  );
}

export default PickTemplate;
