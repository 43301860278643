import React from 'react';
import { Block } from 'baseui/block';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocation } from 'react-router-dom';
import * as Styled from './styled-components';
import NavigationItemLink from './NavigationItemLink';
import NavigationPopover from './NavigationPopover';
import { BaseNavigationItemProps } from './types/navigation';

const NavigationItem: React.FunctionComponent<BaseNavigationItemProps> = (
  props: BaseNavigationItemProps,
) => {
  const location = useLocation();

  const {
    disableBackground,
    hasSubMenu,
    href,
    isExpanded,
    isOpen,
    label,
    action,
    prefix,
    reverse,
    subNav,
    isLargeScreen,
    isHoverSupported,
    filterable,
  } = props;

  const isDefault = href === '/dashboard' && location.pathname === '/';

  return (
    <Styled.NavigationListItem>
      <NavigationPopover
        hasSubMenu={hasSubMenu}
        isExpanded={isExpanded}
        isLargeScreen={isLargeScreen}
        isHoverSupported={isHoverSupported}
        subNav={subNav}
        label={label}
        filterable={filterable}
      >
        <NavigationItemLink to={href} eventHandler={action} isDefault={isDefault}>
          {({ isHovered, isActive }) => (
            <Styled.LinkContent
              $disableBackground={disableBackground}
              $hover={isHovered}
              $active={isActive || isDefault}
              $pinned={isExpanded}
              $reverse={reverse}
            >
              <Styled.NavItemIcon $isPinned={isExpanded}>{prefix}</Styled.NavItemIcon>
              <Styled.NavigationLabel $isPinned={isExpanded}>
                <Block>
                  <Styled.NavLabel $isTopLevel={!!prefix}>{label}</Styled.NavLabel>
                </Block>
                <Block display="flex">
                  {hasSubMenu && (
                    <Styled.NavIconAnimate $open={isOpen}>
                      <FontAwesomeIcon fixedWidth icon={solid('chevron-right')} />
                    </Styled.NavIconAnimate>
                  )}
                </Block>
              </Styled.NavigationLabel>
            </Styled.LinkContent>
          )}
        </NavigationItemLink>
      </NavigationPopover>
    </Styled.NavigationListItem>
  );
};

export default NavigationItem;
