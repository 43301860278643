import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { ModalProps } from 'baseui/modal';

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextType = {
  isOpen: boolean;
  component?: ReactNode;
  config?: ModalProps;
  modalHandler: (content?: ReactNode, options?: ModalProps) => void;
  closeModal: () => void;
  message?: any;
  clearMessage: () => void;
  postMessage: (message: string) => void;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const [component, setComponent] = useState<ReactNode>();

  const [message, setMessage] = useState<any>(undefined);

  const [config, setConfig] = useState<ModalProps>();

  const modalHandler = useCallback(
    (content: ReactNode = undefined, options?: ModalProps) => {
      if (options) {
        setConfig({ ...options });
      }
      setIsOpen(!isOpen);

      setComponent(content);
    },
    [isOpen],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setComponent(undefined);
  }, []);

  const postMessage = useCallback((m: any) => {
    if (m) {
      setMessage(m);
    }
  }, []);

  const clearMessage = useCallback(() => {
    setMessage(undefined);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        isOpen,
        component,
        modalHandler,
        config,
        closeModal,
        message,
        postMessage,
        clearMessage,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContextProvider');
  }

  return { ...context };
};

export { ModalProvider, useModal };
