import React from 'react';
import { styled } from 'baseui';
import { isAfter } from 'date-fns';
import { ParagraphXSmall } from 'baseui/typography';
import useGetTransferRules from '../me/hooks/useGetTransferRules';

const StyledDndRoot = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: $theme.colors.negative100,
}));

const DoNotDisturb = () => {
  const transferRulesQuery = useGetTransferRules();

  const { data } = transferRulesQuery;

  const endTime = data?.doNotDisturb?.endTime;

  const now = new Date();

  const shouldEnd = endTime ? isAfter(now, new Date(endTime)) : false;

  if (!data?.doNotDisturb || shouldEnd) return null;

  // console.log(endTime, shouldEnd);

  return (
    <StyledDndRoot>
      <ParagraphXSmall color="mono900" marginBottom="scale300" marginTop="scale300">
        Do Not Disturb Mode
      </ParagraphXSmall>
    </StyledDndRoot>
  );
};

export default DoNotDisturb;
