import React, { lazy } from 'react';
import * as Sentry from '@sentry/react';
import { HttpClient as HttpClientIntegration } from '@sentry/integrations';
import {
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useNavigationType,
  matchRoutes,
} from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import ApplicationLayout from 'layouts/ApplicationLayout';
import ChromelessLayout from 'layouts/ChromelessLayout';
import ProtectedProviders from 'layouts/protected-providers';
import CoreProviders from 'layouts/core-providers';
import withRouteGuard from 'router/withRouteGuard';
import withSuspense from 'components/withSuspense';
import App from '~/App';
import Root from 'layouts/root';
import ErrorBoundary from 'error/error-boundary';
import RootBoundary from 'error/root-boundary';
import { propertiesRouter, reportsRouter, teamRouter } from './sentry-team-router';
// import Leads from 'pages/leads/lead';
// import Demo from 'routes/leads/pool/demo';
const ProtectedRoute = withAuthenticationRequired(ProtectedProviders);
const Dashboard = withSuspense(lazy(() => import('components/dashboard')));
const Telecom = withSuspense(withRouteGuard(lazy(() => import('routes/billing'))));
// const Leads = withSuspense(lazy(() => import('routes/leads')));
const All = withSuspense(lazy(() => import('pages/leads/all-leads')));
const SmartList = withSuspense(lazy(() => import('pages/leads/smart-list')));
const SummaryReport = withSuspense(lazy(() => import('pages/leads/summary-report')));
const NewDevCustomEllimanReport = withSuspense(
  lazy(() => import('~/components/reports/new-dev-custom-elliman/new-dev-custom-report')),
);
const NotContacted = withSuspense(lazy(() => import('pages/leads/not-contacted')));
const Unassigned = withSuspense(withRouteGuard(lazy(() => import('pages/leads/unassigned'))));
const Opportunities = withSuspense(withRouteGuard(lazy(() => import('pages/leads/opportunities'))));
const WaitingList = withSuspense(withRouteGuard(lazy(() => import('pages/leads/waiting-list'))));
const ProspectList = withSuspense(withRouteGuard(lazy(() => import('pages/leads/prospect-list'))));
// const LeadLoader = withSuspense(lazy(() => import('routes/leads/pool/all-leads')));
const Lead = withSuspense(lazy(() => import('pages/leads/lead')));
const LeadLight = withSuspense(lazy(() => import('pages/leads/lead-light')));
const TeamsDefault = withSuspense(lazy(() => import('components/team/TeamsDefault')));
const Team = withSuspense(lazy(() => import('components/team/Team')));
const Teams = withSuspense(withRouteGuard(lazy(() => import('components/team'))));
const MySettings = withSuspense(lazy(() => import('components/me')));
const Owners = withSuspense(withRouteGuard(lazy(() => import('components/owners'))));
const Owner = withSuspense(withRouteGuard(lazy(() => import('components/owners/OwnerDetail'))));
const Properties = withSuspense(withRouteGuard(lazy(() => import('components/properties'))));
const PropertyDetail = withSuspense(
  withRouteGuard(lazy(() => import('components/properties/PropertyDetail'))),
);
const PropertiesIndex = withSuspense(withRouteGuard(lazy(() => import('routes/properties'))));
const PropertyIdIndex = withSuspense(
  withRouteGuard(lazy(() => import('routes/properties/propertyId_index'))),
);
const Users = withSuspense(withRouteGuard(lazy(() => import('components/users'))));
const Reports = withSuspense(lazy(() => import('components/reports')));
const Login = withSuspense(lazy(() => import('components/Login')));
const RegistrationForm = withSuspense(lazy(() => import('components/registration')));
// const RegistrationPOC = withSuspense(lazy(() => import('components/registration/RegisterPoc')));
const ApproveScopes = withSuspense(lazy(() => import('components/me/ApproveScopes')));
const OAuthCodeRedirect = withSuspense(lazy(() => import('shared/OAuthCodeRedirect')));
const OverdueNotifications = withSuspense(
  lazy(() => import('modules/notifications/OverdueNotifications')),
);
const Alerts = withSuspense(lazy(() => import('modules/notifications/Alerts')));
const Help = withSuspense(withRouteGuard(lazy(() => import('routes/help'))));

const monitor = window.env.APP_ENV === 'production' || window.env.APP_ENV === 'staging';

const sampleRate = window.env.APP_ENV === 'production' ? 0.25 : 1.0;

if (monitor) {
  Sentry.init({
    dsn: 'https://90b1178e80e641d2aa3a6226454e46c4@o4505570587770880.ingest.sentry.io/4505590494527488',
    environment: window.env.APP_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromElements,
          matchRoutes,
        ),
      }),
      new HttpClientIntegration({
        failedRequestStatusCodes: [
          [400, 404],
          [500, 599],
        ],
        failedRequestTargets: ['https://apiv2.spaceit.com', 'https://stageapiv2.spaceit.com'],
      }),
      // new Sentry.Replay(),
    ],
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Performance Monitoring
    tracesSampleRate: sampleRate,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const sentryRouter = sentryCreateBrowserRouter([
  {
    element: <CoreProviders />,
    errorElement: <RootBoundary />,
    children: [
      {
        element: <Root />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            element: <App />,
            path: '/',
            children: [
              {
                element: <ChromelessLayout />,
                children: [
                  {
                    path: 'login',
                    element: <Login />,
                  },
                  {
                    path: 'registration/:id',
                    element: <RegistrationForm />,
                  },
                  {
                    path: 'approval',
                    element: <ApproveScopes />,
                  },
                  {
                    path: 'authorize',
                    element: <OAuthCodeRedirect />,
                  },
                ],
              },
              {
                element: <ProtectedRoute />,
                children: [
                  {
                    element: <ApplicationLayout />,
                    children: [
                      {
                        index: true,
                        element: <Dashboard />,
                      },
                      {
                        path: 'dashboard',
                        element: <Dashboard />,
                      },
                      {
                        path: 'billing/telecom',
                        element: <Telecom />,
                      },
                      {
                        path: 'help/open-house',
                        element: <Help />,
                      },
                      {
                        path: 'leads',
                        element: <Navigate to="list/all" replace />,
                      },
                      {
                        path: 'leads/list',
                        children: [
                          {
                            index: true,
                            element: <Navigate to="all" replace />,
                          },
                          {
                            path: 'all',
                            element: <All />,
                          },
                          {
                            path: 'not-contacted',
                            element: <NotContacted />,
                          },
                          {
                            path: 'unassigned',
                            element: <Unassigned />,
                          },
                          {
                            path: 'opportunities',
                            element: <Opportunities />,
                          },
                          {
                            path: 'waiting-list',
                            element: <WaitingList />,
                          },
                          {
                            path: 'prospects',
                            element: <ProspectList />,
                          },
                          {
                            path: ':id',
                            element: <SmartList />,
                          },
                        ],
                      },
                      {
                        path: 'leads/summary-report',
                        element: <SummaryReport />,
                      },
                      {
                        path: 'leads/new-dev-custom',
                        element: <NewDevCustomEllimanReport />,
                      },
                      {
                        path: 'leads/pool',
                        element: <Navigate to="/leads" replace />,
                        // OLD ROUTE
                      },
                      {
                        path: 'leads/opportunities',
                        element: <Navigate to="/leads/list/opportunities" replace />,
                        // OLD ROUTE
                      },
                      {
                        path: 'leads/waitlist',
                        element: <Navigate to="/leads/list/waiting-list" replace />,
                        // OLD ROUTE
                      },
                      {
                        path: 'leads/prospects',
                        element: <Navigate to="/leads/list/prospects" replace />,
                        // OLD ROUTE
                      },
                      {
                        path: 'leads/closed',
                        element: <Navigate to="/leads/list" replace />,
                        // OLD ROUTE
                      },
                      {
                        path: 'leads/:id',
                        element: <Lead />,
                      },
                      {
                        path: 'l/:id',
                        element: <LeadLight />,
                      },
                      {
                        path: 'properties',
                        element: <PropertiesIndex />,
                      },
                      {
                        path: 'properties/:id',
                        element: <PropertyIdIndex />,
                        children: propertiesRouter,
                      },
                      {
                        path: 'teams',
                        element: <Teams />,
                        children: [
                          {
                            index: true,
                            element: <TeamsDefault />,
                          },
                          {
                            path: ':id',
                            element: <Team />,
                            children: teamRouter,
                          },
                        ],
                      },
                      {
                        path: 'manage-settings',
                        element: <MySettings />,
                      },
                      {
                        path: 'manage/owners',
                        element: <Owners />,
                      },
                      {
                        path: 'manage/owners/:id',
                        element: <Owner />,
                      },
                      {
                        path: 'manage/properties',
                        element: <Properties />,
                      },
                      {
                        path: 'manage/properties/:id',
                        element: <PropertyDetail />,
                      },
                      {
                        path: 'manage/users',
                        element: <Users />,
                      },
                      {
                        path: 'reports',
                        element: <Reports />,
                        children: reportsRouter,
                      },
                      {
                        path: 'notifications',
                        element: <OverdueNotifications />,
                      },
                      {
                        path: 'alerts',
                        element: <Alerts />,
                      },
                    ],
                  },
                ],
              },
              {
                path: '*',
                element: <Navigate to="/" />,
              },
            ],
          },
        ],
      },
    ],
  },
]);

async function loadReply() {
  const { getCurrentHub, Replay } = await import('@sentry/browser');
  try {
    getCurrentHub().getClient()?.addIntegration?.(new Replay());
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}
loadReply();

export default sentryRouter;
