import { useQuery } from 'react-query';
import { useCurrentUserId } from 'context/current-user-context';
import useAxios from '../../../hooks/useAxios';
import { TransferRules } from '../../../backend/transferManager';

export default function useGetTransferRules() {
  const axios = useAxios();

  const id = useCurrentUserId();

  const getTransferRules = async () => {
    const { data } = await axios.get<TransferRules>(`api/user/${id}/transfer-rules`);
    return data;
  };

  return useQuery(['getTransferRules', id], getTransferRules, {
    retry: false,
    enabled: !!id,
  });
}
