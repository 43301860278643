import React, { useMemo, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

type Context = {
  instance: AxiosInstance;
  setIdentity: any;
  identity: any;
};

export const AxiosContext = React.createContext<Context | undefined>(undefined);

export default function AxiosProvider({ children }: React.PropsWithChildren<unknown>) {
  const { getAccessTokenSilently } = useAuth0();

  const savedIdentity = localStorage.getItem('identity');

  const [identity, setIdentity] = useState<string | null>(savedIdentity);

  const mem = useMemo(() => {
    const instance = axios.create({
      baseURL: window.env.APP_API,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    instance.interceptors.request.use(async (config) => {
      const accessToken = await getAccessTokenSilently();
      if (!accessToken) {
        console.warn('Need access token');
      }
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      if (identity) {
        Object.assign(headers, {
          SI_REPRESENTING: identity,
        });
      }
      return {
        ...config,
        headers,
      };
    });

    // instance.interceptors.response.use(
    //   (response) => response,
    //   (error: AxiosError<ApiError>) => {
    //     const message = error.response?.data.message;
    //     const detail = error.response?.data.detail;
    //     const status = error.response?.data.status;
    //     const type = error.response?.data.type;
    //     const path = error.response?.data.path;
    //     console.log(error);
    //     if (isAuthenticated) {
    //       return Promise.reject(new DataError(message, detail, status, type, path));
    //     }
    //     return Promise.reject(error);
    //   },
    // );

    return instance;
  }, [getAccessTokenSilently, identity]);

  return (
    <AxiosContext.Provider value={{ instance: mem, identity, setIdentity }}>
      {children}
    </AxiosContext.Provider>
  );
}
