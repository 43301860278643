import { useEffect, useCallback, useReducer } from 'react';
import { useQueryClient } from 'react-query';
import { Communication } from '../../backend/communication';
import { MessageEvent, EventType } from '../../backend/messages';
import { useWebSocketObservable } from '../../context/web-socket-context';

type CallHeaderPropsT = {
  event?: EventType;
  details?: Communication;
};

const initalState: CallHeaderPropsT = {
  event: undefined,
  details: undefined,
};

const useCallProgressSubscription = () => {
  const messages$ = useWebSocketObservable();

  const queryClient = useQueryClient();

  const reducer = (
    state: CallHeaderPropsT,
    action: { type: string; payload: CallHeaderPropsT },
  ) => {
    switch (action.type) {
      case 'EVENT':
        return {
          ...state,
          ...action.payload,
        };
      case 'CLEAR':
        return {
          ...state,
          ...initalState,
        };
      default:
        return state;
    }
  };

  const [message, dispatch] = useReducer(reducer, initalState);

  const emit = useCallback(({ details, eventType }: MessageEvent<Communication>) => {
    switch (eventType) {
      case 'CALL_TRANSFERRED':
      case 'OUTGOING_CALL_INITIATED':
        dispatch({ type: 'EVENT', payload: { event: eventType, details } });
        break;
      case 'CALL_COMPLETED':
      case 'OUTGOING_CALL_COMPLETED':
        dispatch({ type: 'EVENT', payload: { event: eventType, details } });
        break;
      // no default
    }
    // setTimeout(() => {
    //   queryClient.invalidateQueries(['getLead', details.leadRef]);
    // }, 500);
  }, []);

  const invalidateLead = useCallback(
    (id: string | undefined) => {
      if (id) {
        queryClient.invalidateQueries(['getLead', id]);
      }
    },
    [queryClient],
  );

  const reset = useCallback(() => {
    dispatch({ type: 'CLEAR', payload: {} });
    queryClient.cancelQueries(['callInProgress']);
    queryClient.resetQueries(['callInProgress']);
  }, [queryClient]);

  const dispatcEvent = useCallback((payload: CallHeaderPropsT) => {
    dispatch({ type: 'EVENT', payload });
  }, []);

  useEffect(() => {
    const sub = messages$?.subscribe({
      next: (messageEvent: MessageEvent<Communication>) => {
        emit({ ...messageEvent });
      },
      error: (e) => console.error(e),
    });

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [messages$, emit]);

  return { dispatcEvent, invalidateLead, message, reset };
};

export default useCallProgressSubscription;
