const useMobilePhoneDetection = () => {
  const ua = navigator.userAgent;

  const mediaQueryList =
    window.matchMedia &&
    window.matchMedia('(pointer: coarse) and (hover: none) and (max-width: 760px)');

  const isMobileUserAgent = /\b(BlackBerry|webOS|iPhone|IEMobile|Android|Windows Phone|iPad)\b/i.test(
    ua,
  );

  const isMobilePhone = mediaQueryList.matches && isMobileUserAgent;

  return { isMobilePhone, isMobileUserAgent, mediaQueryList };
};

export default useMobilePhoneDetection;
