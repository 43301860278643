import { useAuth0 } from '@auth0/auth0-react';
import axiosStatic from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useMatch } from 'react-router-dom';
import useAxios from './useAxios';
import { User } from '../backend/user';

const useGetAuthUser = (
  key?: string | any[],
  options?: Omit<UseQueryOptions<User, unknown, User, any[]>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const { user } = useAuth0();

  const matchLogin = useMatch('/login');

  const matchSignup = useMatch('/company/signup/');

  const matchRegistration = useMatch('/registration/:id');

  const disabled = matchLogin || matchSignup || matchRegistration;

  const isArray = Array.isArray(key);

  const getAuthUser = async (): Promise<User> => {
    const { data } = await axios.get('api/auth/user');
    return data;
  };

  const getQueryKey = () => {
    if (key) {
      return isArray ? ['getAuthUser', ...key] : ['getAuthUser', key];
    }
    return ['getAuthUser'];
  };

  return useQuery({
    queryKey: getQueryKey(),
    queryFn: getAuthUser,
    retry: false,
    staleTime: 60 * 1000 * 5,
    enabled: !!user && !disabled,
    suspense: true,
    useErrorBoundary: (error: unknown) => {
      if (axiosStatic.isAxiosError(error)) {
        return error.response?.status! >= 500 || error.response?.status === 412;
      }
      return false;
    },
    ...options,
  });
};

export default useGetAuthUser;
