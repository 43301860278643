import { useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { User } from 'backend/user';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { useAxiosIdentity } from 'hooks/useAxios';
import useGetAuthUser from 'hooks/useGetAuthUser';
import React, { useEffect } from 'react';

type Provider = {
  children: React.ReactNode;
};

const CurrentUserContext = React.createContext<User | undefined>(undefined);

export const useCurrentUserContext = () => {
  const currentUser = React.useContext(CurrentUserContext);
  if (!currentUser) {
    throw new Error('CurrentUserContext: No value provided');
  }
  return currentUser;
};

export const useCurrentUserId = () => {
  const currentUser = React.useContext(CurrentUserContext);
  if (!currentUser) {
    throw new Error('CurrentUserContext: No value provided');
  }
  return currentUser.id;
};

export function CurrenUserContextProvider({ children }: Provider) {
  const [css] = useStyletron();

  const { error, isLoading, loginWithRedirect } = useAuth0();

  const { identity } = useAxiosIdentity();

  const key = identity ? { identity } : undefined;

  const query = useGetAuthUser(key as any);

  useEffect(() => {
    if (error) {
      console.log(error);

      (async (): Promise<void> => {
        await loginWithRedirect();
      })();
    }
  }, [error, loginWithRedirect]);

  if (isLoading || query.isLoading) {
    return (
      <Spinner
        className={css({
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: '-20px',
          marginTop: '-20px',
        })}
      />
    );
  }

  if (query.isError) {
    const err = query.error;
    throw new Error((err as AxiosError).message);
  }

  return <CurrentUserContext.Provider value={query.data}>{children}</CurrentUserContext.Provider>;
}
