import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import useAxios from '../../../hooks/useAxios';
import { MarketingMaterial } from '../../../backend/client';

const useGetMarketingMaterials = (
  propertyRef: string | undefined,
  options?: Omit<
  UseQueryOptions<MarketingMaterial[] | undefined, AxiosError>,
  'queryKey' | 'queryFn'
  >,
) => {
  const axios = useAxios();

  const getMarketingMaterials = async () => {
    if (typeof propertyRef === 'undefined') return Promise.reject();
    const { data } = await axios.get<MarketingMaterial[]>(
      `api/property/${propertyRef}/marketing-materials`,
    );
    return data;
  };

  return useQuery<MarketingMaterial[] | undefined, AxiosError>(
    ['getMarketingMaterials', propertyRef],
    () => getMarketingMaterials(),
    {
      enabled: !!propertyRef,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetMarketingMaterials;
