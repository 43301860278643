import { createTheme, createThemedStyled, createThemedUseStyletron, Theme } from 'baseui';

export type CustomThemeT = Theme & { brandColor: string; brandColorDarker: string };

export const PRIMITIVES = {
  primaryFontFamily: 'Inter Variable',
  accent50: '#E1FCF8',
  accent100: '#C1FEF6',
  accent200: '#92FDF2',
  accent300: '#62F4EB',
  accent400: '#3AE7E1',
  accent500: '#1CD4D4',
  accent600: '#0FB5BA',
  accent700: '#099AA4',

  
  mono100: '#f9f9fb',
  mono200: '#e8e8ec',
  mono300: '#e0e1e6',
  mono400: '#b9bbc6',
  mono500: '#8b8d98',
  mono600: '#8b8d98',
  mono700: '#60646c',
  mono800: '#60646c',
  mono900: '#1c2024',
  mono1000: '#1c2024',

  primary50: '#e6f4fe',
  primary100: '#d5efff',
  primary200: '#c2e5ff',
  primary300: '#5eb1ef',
  primary400: '#0090ff',
  primary500: '#0090ff',
  primary600: '#0d74ce',
  primary700: '#0d74ce',
  primary800: '#113264',
  primary900: '#113264',
  // purlple interestin 281e3c
  // primary: '#292F4C', // #272848 // '#1F2451', // '#313772', //
  // '#1D2253', // #0E1343',
  // negative: ''
  negative50: '#FFEEEE',
  negative100: '#FACDCD',
  negative200: '#F29B9B',
  negative300: '#E66A6A',
  negative400: '#D64545',
  negative500: '#BA2525',
  negative600: '#A61B1B',
  negative700: '#911111',

  // Vivid Green
  // positive : '',
  positive50: '#E3F9E5',
  positive100: '#C1F2C7',
  positive200: '#91E697',
  positive300: '#51CA58',
  positive400: '#31B237',
  positive500: '#18981D',
  positive600: '#0F8613',
  positive700: '#0E7817',

  // Teal
  // positive50: '',
  // positive100: '#C6F7E9',
  // positive200: '#8EEDD1',
  // positive300: '#5FE3C0',
  // positive400: '#2DCCA7',
  // positive500: '#17B897',
  // positive600: '#079A82',
  // positive700: '#048271',

  // Yellow
  // warning:   "#FFC043"
  warning50: '#FFFBEA',
  warning100: '#FFF3C4',
  warning200: '#FCE588',
  warning300: '#FADB5F',
  warning400: '#F7C948',
  warning500: '#F0B429',
  warning600: '#DE911D',
  warning700: '#CB6E17',
  white: '#FFFFFF',
};

export const THEME_OVERRIDES = {
  borders: {
    inputBorderRadius: '4px',
    buttonBorderRadius: '4px',
    popoverBorderRadius: '4px',
    surfaceBorderRadius: '4px',
  },
  colors: {
    accentSecondary50: '#FFE8D9',
    // accent100: '#FFD0B5',
    // accent200: '#FFB088',
    // accent300: '#FF9466',
    // accent400: '#F9703E',
    // accent500: '#F35627',
    // accent600: '#DE3A11',
    // accent700: '#C52707',
    buttonPrimaryText: PRIMITIVES.white,
    buttonPrimaryFill: PRIMITIVES.primary400,
    buttonPrimaryActive: PRIMITIVES.primary400,
    buttonPrimaryHover: PRIMITIVES.primary300,
    buttonPrimarySelectedText: PRIMITIVES.white,
    buttonPrimarySelectedFill: PRIMITIVES.primary400,

    buttonSecondaryText: PRIMITIVES.primary300,
    buttonSecondaryFill: PRIMITIVES.primary50,
    buttonSecondaryActive: PRIMITIVES.primary100,
    buttonSecondaryHover: PRIMITIVES.primary100,
    buttonSecondarySelectedText: PRIMITIVES.white,
    buttonSecondarySelectedFill: PRIMITIVES.primary200,

    buttonTertiaryText: PRIMITIVES.mono900,
    buttonTertiaryFill: 'transparent',
    buttonTertiaryActive: PRIMITIVES.mono200,
    buttonTertiaryHover: PRIMITIVES.mono200,
    buttonTertiarySelectedFill: PRIMITIVES.mono300,
    buttonTertiarySelectedText: PRIMITIVES.mono900,

    fileUploaderBackgroundColor: PRIMITIVES.mono100,
    fileUploaderBorderColorDefault: PRIMITIVES.mono300,
    fileUploaderBackgroundColorActive: PRIMITIVES.primary400,
    fileUploaderBorderColorActive: PRIMITIVES.primary600,

    menuFill: PRIMITIVES.white,
    menuFillHover: PRIMITIVES.mono100,
    menuFontDisabled: PRIMITIVES.mono300,
    menuFontDefault: PRIMITIVES.mono800,
    menuFontHighlighted: PRIMITIVES.mono700,

    tickBorder: PRIMITIVES.mono400, // checkbox & radio
    tickFill: PRIMITIVES.white,
    tickFillDisabled: PRIMITIVES.mono300, // the border
    tickFillSelected: PRIMITIVES.primary400,
    tickFillSelectedHover: PRIMITIVES.primary500,
    tableBackground: PRIMITIVES.white,
    tableHeadBackgroundColor: PRIMITIVES.white,
    tableStripedBackground: PRIMITIVES.mono100,
    toggleFillChecked: PRIMITIVES.white,
    // toggleFillDisabled: '#616E7C'
    toggleTrackFill: PRIMITIVES.mono300,
    // toggleTrackFillDisabled: '#CBD2D9'

    borderFocus: PRIMITIVES.primary200,
    inputBorder: PRIMITIVES.mono200,
    // inputBorderError: '#F29B9B'
    // inputBorderPositive: '#66D19E'
    // inputEnhancerFill: 'red',
    // inputEnhancerFillDisabled: '#CBD2D9'
    // inputEnhancerTextDisabled: '#616E7C'
    inputFill: PRIMITIVES.white,
    inputFillActive: PRIMITIVES.white,
    // inputFillDisabled: '#E4E7EB'
    // inputFillError: '#FFEFED'
    // inputFillPositive: '#E6F2ED'
    inputPlaceholder: PRIMITIVES.mono400,
    // inputPlaceholderDisabled: '#616E7C'
    // inputTextDisabled: '#616E7C'
    toastInfoBackground: PRIMITIVES.primary500,
    toastPositiveBackground: PRIMITIVES.positive400,
    toastWarningBackground: PRIMITIVES.warning600,
    toastNegativeBackground: PRIMITIVES.negative400,
  },
};

const theme = createTheme(PRIMITIVES, THEME_OVERRIDES);

export const customTheme: CustomThemeT = {
  ...theme,
  brandColor: '#20253c',
  brandColorDarker: '#141624',
};

export const customStyled = createThemedStyled<CustomThemeT>();

export const customUseStyletron = createThemedUseStyletron<CustomThemeT>();

// brandColor: '#292F4C',
// * brandColor: '#212430',
// brandColor: '#1b1f32',
// brandColor: '#292f4c',
