import React, { useEffect } from 'react';
import { LeadForTeam } from '../../../backend/lead';
import { getCommunicationPrefillData } from '../utils';

type PrefillType = {
  name?: string;
  targetPhoneNumber?: string;
  targetEmailAddress?: string;
} & LeadForTeam;

const useAvailableLeadMergeList = (leadsForTeam: LeadForTeam[] | undefined) => {
  const [availableLeads, setAvailableLeads] = React.useState<PrefillType[] | undefined>([]);

  useEffect(() => {
    const records = leadsForTeam?.map((lead) => {
      if (!lead.tenantName) {
        const preFill = getCommunicationPrefillData(lead.firstIncomingCommunication);
        return {
          ...lead,
          ...preFill,
        };
      }
      return lead;
    });
    setAvailableLeads(records);
  }, [leadsForTeam]);

  return [availableLeads];
};

export default useAvailableLeadMergeList;
