import {
  ContactType,
  LeadPoolStatus,
  LeadStatus,
  TourNotInterestedReason,
  TourResult,
  TourStatus,
  TourType,
} from '../../backend/lead';
import { PropertyType } from '../../backend/property';
import { CommunicationType, CommunicationDirection } from '../../backend/communication';

export type PropertyKeys = { [key in PropertyType]?: string };

export const PROPERTY_TYPE: PropertyKeys = {
  COMMERCIAL: 'Commercial',
  // OFFICE: 'Office',
  RESIDENTIAL: 'Residential',
};

export const PROPERTY_TYPE_COLOR_VARIANTS: { [key in PropertyType]: string } = {
  COMMERCIAL: '#ffffff',
  OFFICE: '#ffffff',
  RESIDENTIAL: '#ffffff',
};

export const PROPERTY_TYPE_ALT_COLOR_VARIANTS: { [key in PropertyType]: string } = {
  COMMERCIAL: '#ffa12f',
  OFFICE: '#7b68ee',
  RESIDENTIAL: '#ff5722',
};

export const PROPERTY_STATUS = {
  ACTIVE: 'Interested',
  NOT_INTERESTED: 'Not Interested',
};

export const PROPERTY_USE = {
  OTHER: 'Other',
};

export const PHONE_TYPE = {
  MOBILE: 'Mobile',
  OFFICE: 'Office',
  HOME: 'Home',
  OTHER: 'Other',
};

export const CHANNEL = {
  EMAIL: 'Email',
  CALL: 'Phone',
  TEXT_MESSAGE: 'Text Message',
  WEBFORM: 'Web Form',
};

export const LEAD_TYPE = {
  TENANT: 'Tenant',
  REPRESENTATIVE: 'Representative',
};

export const LEAD_STATUS: { [key in LeadStatus]: string } = {
  CLOSED: 'Closed',
  NEW_LEAD: 'New',
  NOT_LEAD: 'Not a Lead',
  OPPORTUNITY: 'Opportunity',
  WAITING_LIST: 'Waiting List',
  MERGED: 'Merged',
  PROSPECT: 'Prospect',
};

export const LEAD_STATUS_COLOR_VARIANTS: { [key in LeadStatus]: string } = {
  CLOSED: 'brown',
  NEW_LEAD: 'purple',
  NOT_LEAD: 'red',
  OPPORTUNITY: 'green',
  WAITING_LIST: 'brown',
  MERGED: 'purple',
  PROSPECT: 'brown',
};

export const CALL_RESULT = {
  ANSWERED: 'Answered',
  BLOCKED: 'Blocked',
  HANGUP: 'Hangup',
  SPAM_BLOCKED: 'Spam',
  VOICEMAIL: 'Voicemail',
  NO_ANSWER: 'Not Answered',
  NOT_ANSWERED: 'Not Answered',
  TRANSFERRED: 'Transferred',
  INITIATED: 'Initiated',
};

export const DIRECTION = {
  OUTGOING: 'Outbound',
  INCOMING: 'Inbound',
};

export const CONTACT_TYPE: { [key in ContactType]: string } = {
  REPRESENTATIVE: 'REPRESENTATIVE',
  TENANT: 'TENANT',
  DIRECT_REPLY: 'DIRECT_REPLY',
};

export const TOUR_RESULT: { [key in TourResult]: string } = {
  INTERESTED: 'Interested',
  NOT_INTERESTED: 'Not Interested',
};

export const TOUR_TYPE: { [key in TourType]: string } = {
  IN_PERSON: 'In Person',
  VIRTUAL: 'Virtual',
};

export const TOUR_STATUS: { [key in TourStatus]: string } = {
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  NO_SHOW: 'No Show',
  SCHEDULED: 'Scheduled',
};

export const TOUR_NOT_INTERESTED_REASON: { [key in TourNotInterestedReason]: string } = {
  OTHER: 'Other',
};

export const LEAD_POOL_STATUS: { [key in LeadPoolStatus]: string } = {
  WITH_AGENT: 'Intake',
  AWAITING_ASSIGNMENT: 'Unassigned',
  CLAIM_ELIGIBLE: 'Unclaimed',
};

export const LEAD_POOL_DISPOSITION = {
  WITH_AGENT_CONTACTED: 'Intake',
  WITH_AGENT_UNCONTACTED: 'Uncontacted',
  AWAITING_ASSIGNMENT: 'Unassigned',
  CLAIM_ELIGIBLE: 'Unclaimed',
};

export const COMMUNICATION_TYPE: { [key in CommunicationType]: string } = {
  CALL: 'Call',
  EMAIL: 'Email',
  TEXT_MESSAGE: 'Text',
  WEBFORM: 'Web Form',
};

export const COMMUNICATION_DIRECTION: { [key in CommunicationDirection]: string } = {
  INCOMING: 'Incoming',
  OUTGOING: 'Outgoing',
};

export const LISTED_STATUS_COLOR = {
  LISTED: 'positive300',
  NOT_LISTED: 'warning400',
};
