import { Override } from 'baseui/overrides';
import { ButtonOverrides } from 'baseui/button';

export const groupedButtonBorderStyle: Override<any> = {
  style: {
    borderTopStyle: 'solid',
    borderRightStyle: 'solid',
    borderBottomStyle: 'solid',
    borderLeftStyle: 'solid',
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderLeftWidth: '2px',
  },
};

export const groupedButtonOverrides: { overrides: ButtonOverrides } = {
  overrides: {
    BaseButton: {
      style: ({ $theme, $isSelected }) => ({
        ...groupedButtonBorderStyle.style,
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: `${$theme.sizing.scale0}`,
        marginRight: `${$theme.sizing.scale0}`,
        backgroundColor: $isSelected ? $theme.colors.mono200 : $theme.colors.white,
        borderTopColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderRightColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderBottomColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderLeftColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
      }),
    },
  },
};

export const moveInButtonLeftOverrides: { overrides: ButtonOverrides } = {
  overrides: {
    BaseButton: {
      style: ({ $theme, $isSelected }) => ({
        ...groupedButtonBorderStyle.style,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
        marginLeft: 0,
        marginRight: `${$theme.sizing.scale300}`,
        borderTopColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderRightColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderBottomColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderLeftColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
      }),
    },
  },
};

export const moveInButtonRightOverrides: { overrides: ButtonOverrides } = {
  overrides: {
    BaseButton: {
      style: ({ $theme, $isSelected }) => ({
        ...groupedButtonBorderStyle.style,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '50%',
        marginLeft: 0,
        marginRight: 0,
        borderTopColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderRightColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderBottomColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
        borderLeftColor: $isSelected ? `${$theme.colors.primary300}` : `${$theme.colors.mono200}`,
      }),
    },
  },
};
