import { PhoneTypes } from 'backend/user';
import React, { useReducer } from 'react';

type ProviderPropsT = {
  children: React.ReactNode;
};

type ContextT = {
  state: Context;
  dispatch: React.Dispatch<Context>;
};

type Context = {
  isOpen: boolean;
  targetEmailAddress?: string;
  number?: string;
  phoneType?: PhoneTypes;
  contactType: any;
  targetContactRef: string | null;
  leadRef: string;
  targetPhoneNumber?: string;
  name?: string;
};

const initialState: Context = {
  isOpen: false,
  contactType: '',
  leadRef: '',
  targetContactRef: null,
};

const reducer = (state: Context, newState: Context) => ({
  ...state,
  ...newState,
});

export const TextMessageContext = React.createContext({} as ContextT);

export const TextMessageContextProvider = ({ children }: ProviderPropsT) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <TextMessageContext.Provider value={{ state, dispatch }}>
      {children}
    </TextMessageContext.Provider>
  );
};

TextMessageContext.displayName = 'TextMessageContext';
