import React from 'react';
import { useStyletron } from 'baseui';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledIconContainer, StyledIconNewMessage } from './styled-components';

type TextMessageHeaderPropsT = {
  isUnread: boolean;
  isMinimized: boolean;
  onClose: () => void;
  handleMinimize: () => void;
};

function TextMessageHeader({
  isUnread,
  isMinimized,
  handleMinimize,
  onClose,
}: TextMessageHeaderPropsT) {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.white,
        height: theme.sizing.scale1000,
        justifyContent: 'space-between',
        backgroundColor: theme.colors.mono800,
      })}
    >
      {isMinimized && isUnread ? (
        <StyledIconNewMessage>
          <FontAwesomeIcon size="sm" icon={solid('circle')} />
        </StyledIconNewMessage>
      ) : (
        <span />
      )}
      <div className={css({ display: 'flex' })}>
        <StatefulTooltip
          showArrow
          content={isMinimized ? 'Maximize' : 'Minimize'}
          placement={PLACEMENT.bottom}
        >
          <StyledIconContainer
            role="button"
            tabIndex={-1}
            onKeyDown={() => {}}
            onClick={handleMinimize}
          >
            <FontAwesomeIcon icon={regular('window-minimize')} />
          </StyledIconContainer>
        </StatefulTooltip>

        <StatefulTooltip showArrow content={() => 'Close'} placement={PLACEMENT.bottom}>
          <StyledIconContainer role="button" tabIndex={-1} onKeyDown={() => {}} onClick={onClose}>
            <FontAwesomeIcon icon={solid('times')} />
          </StyledIconContainer>
        </StatefulTooltip>
      </div>
    </div>
  );
}

export default TextMessageHeader;
