/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'baseui/button';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { toast } from 'react-toastify';
import { css } from 'styled-system/css';

const intervalMS = 60 * 60 * 1000; // hourly, 20000 (20s)

const toaster = 'reload-prompt';

const ReloadPrompt = () => {
  // const [css, theme] = useStyletron();

  const buildDate = '__DATE__';

  const reloadSW = '__RELOAD_SW__';

  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      console.log(`Service Worker at: ${swUrl}`);
      // @ts-expect-error just ignore
      if (reloadSW === 'true') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        r &&
          setInterval(() => {
            console.log('Checking for sw update');
            r.update();
          }, intervalMS);
      } else {
        // eslint-disable-next-line prefer-template
        console.log('SW Registered: ' + r);
      }
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
    onNeedRefresh: () => {
      toast(
        <div
          className={css({
            alignItems: 'flex-start',
            display: 'flex',
            flexDirection: 'column',
            gap: '1',
          })}
        >
          <div className={css({ color: 'slate.12', fontWeight: 500 })}>
            New app version available, click reload to update.
          </div>
          <div className={css({ fontSize: 'smaller', color: 'slate.8' })}>{buildDate}</div>
          <Button
            type="button"
            size="compact"
            onClick={() =>
              updateServiceWorker(true)
                .then(() => {
                  window.location.reload();
                })
                .catch((err) => {
                  console.log(err);
                })
            }
            overrides={{ BaseButton: { style: { alignSelf: 'flex-end' } } }}
          >
            Reload
          </Button>
        </div>,
        { autoClose: false, toastId: toaster, position: 'bottom-right' },
      );
    },
  });

  const close = () => {
    setOfflineReady(false);
    setNeedRefresh(false);
  };

  return null;

  if (needRefresh) {
    return (
      <div
        data-refresh={needRefresh}
        className={css({
          backgroundColor: 'blue',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '36px',
          flex: '0 0 36px',
          fontSize: 'sm',
        })}
      >
        {/*  */}
      </div>
    );
  }
};

export default ReloadPrompt;
