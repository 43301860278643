import { ContactDetails } from 'backend/lead';
import { Spinner } from 'baseui/spinner';
import { StyledEventBody } from './styled-components';
import useGetProperty from '../properties/hooks/useGetProperty';
import { Alert } from '../../backend/messages';
import { COMMUNICATION_TYPE } from '../lead/constants';
import { CommunicationType } from '../../backend/communication';
import useNameFromCommunication from '../dashboard/dashboardAlerts/useNameFromCommunication';

const IncomingComm = (props: Alert & { contact: ContactDetails | undefined }) => {
  const { lead, communication, contact } = props;

  const communicationTypeKey = communication?.communicationType;

  const { tenantName, assignedProperty } = lead;

  const propertyRef = assignedProperty?.propertyRef;

  const propertyQuery = useGetProperty(propertyRef);

  const property = propertyQuery.data;

  const { lookupName } = useNameFromCommunication();

  const nameResult = lookupName(
    communication,
    lead.initialCommunicationDetails,
    lead.mergedInitialCommunicationDetails,
  );

  const name = contact?.name || nameResult;

  if (propertyQuery.isLoading) {
    return <Spinner $size="small" />;
  }

  return (
    <>
      <StyledEventBody>
        {COMMUNICATION_TYPE?.[communicationTypeKey as CommunicationType]} from {name}.
      </StyledEventBody>{' '}
      {!lead.assignedAgentRef && (
        <StyledEventBody>Lead still waiting for response.</StyledEventBody>
      )}
      <StyledEventBody style={{ display: 'block' }}>{property?.name}</StyledEventBody>
      {tenantName && <StyledEventBody style={{ display: 'block' }}>{tenantName}</StyledEventBody>}
    </>
  );
};

export default IncomingComm;
