/* eslint-disable react/no-unused-prop-types */
import { useEffect } from 'react';
import { Block } from 'baseui/block';
import { useQueryClient } from 'react-query';
import { useFormContext } from 'react-hook-form';
import { PhoneTypeT } from '../../../backend/campaign';
import { ProvisionPhoneNumbers } from '../../../backend/team';
import AvailablePhoneNumbers from '../../../components/team/components/AvailablePhoneNumbers';
import PurchasePhoneNumberSelect from '../../../components/team/components/PurchasePhoneNumberSelect';
import PurchasePhoneToggle from '../../../components/team/components/PurchasePhoneToggle';
import useAvailablePhoneNumbers from '../../../components/team/hooks/useAvailablePhoneNumbers';
import TwilioBusinessProfileForm from './twilio-business-profile-form';

type FormStepPropsT = {
  onSubmit: (d: any) => any;
  getTeamName: () => string;
  validate: (value: string, name: string) => any;
  formRef?: string;
};

const ProvisionPhoneForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit } = props;

  const queryClient = useQueryClient();

  const availablePhoneNumbers = useAvailablePhoneNumbers();

  const hasAvailablePhoneNumbers =
    availablePhoneNumbers.data && availablePhoneNumbers.data.length > 0;

  const formMethods = useFormContext();

  const { clearErrors, handleSubmit, getValues, setValue, watch } = formMethods;

  // phoneType is registered in parent component
  const [phoneType$, twilioBusinessProfileRef$] = watch(['phoneType', 'twilioBusinessProfileRef']);

  const changePhoneType = (val: PhoneTypeT) => {
    clearErrors(['areaCode', 'phone', 'phoneType']);
    setValue('phoneType', val);
    setValue('areaCode', '');
    setValue('phone', val === 'SAVED' ? [] : '');
    queryClient.removeQueries('getNumbersForPurchase');
  };

  const onPurchaseSuccess = (result: ProvisionPhoneNumbers | undefined) => {
    if (result) {
      clearErrors(['areaCode', 'phone', 'phoneType']);
      setValue('areaCode', '');
      setValue('phone', [{ id: result.id, label: result.number }]);
      setValue('phoneType', 'SAVED');
    }
  };

  useEffect(
    () => () => {
      // Clear areaCode and phone state on incomplete phone purchase
      const [phoneType] = getValues(['phoneType']);
      if (phoneType === 'PURCHASE') {
        setValue('areaCode', '');
        setValue('phone', []);
        clearErrors(['areaCode', 'phone', 'phoneType']);
      }
    },
    [clearErrors, getValues, setValue],
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
      <Block font="ParagraphSmall" color="mono800" marginBottom="scale600">
        Choose a phone number for the default campaign. Available numbers are based on your area
        code preference.
      </Block>

      {hasAvailablePhoneNumbers && (
        <PurchasePhoneToggle changePhoneType={changePhoneType} phoneType$={phoneType$} />
      )}
      <TwilioBusinessProfileForm />
      {phoneType$ === 'SAVED' ? (
        <AvailablePhoneNumbers />
      ) : (
        <PurchasePhoneNumberSelect
          onPurchaseSuccess={onPurchaseSuccess}
          twilioBusinessProfileRef={twilioBusinessProfileRef$?.[0]?.id}
        />
      )}
    </form>
  );
};

export default ProvisionPhoneForm;
