import { withStyle } from 'baseui';
import {
  StyledRoot,
  StyledTableBody,
  StyledTableHeadCell,
  StyledTableBodyRow,
  StyledTableBodyCell,
} from 'baseui/table-semantic';

export const WithStyleTableRoot = withStyle(StyledRoot, ({ $theme }) => ({
  height: '100%',
  borderTopWidth: 0,
  borderRightWidth: 0,
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  minHeight: $theme.sizing.scale1200,
}));

export const WithStyleTableHeadCell = withStyle<any, { $freeze?: 'header' | 'column' | 'both' }>(
  StyledTableHeadCell,
  ({ $theme, $freeze }) => ({
    ...$theme.typography.LabelXSmall,
    textTransform: 'uppercase',
    color: $theme.colors.mono800,
    '::after': {
      height: '2px',
    },
    '::before': {
      borderLeftWidth: '0',
    },
    verticalAlign: 'center',
    paddingTop: $theme.sizing.scale550,
    paddingBottom: $theme.sizing.scale550,
    zIndex: 1,
    position: $freeze ? 'sticky' : 'static',
    top: $freeze === 'header' || $freeze === 'both' ? 0 : 'auto',
    ':first-child': {
      left: $freeze === 'column' || $freeze === 'both' ? 0 : 'auto',
      zIndex: $freeze === 'column' || $freeze === 'both' ? 2 : 'auto',
    },
  }),
);

export const WithStyleTableBody = withStyle(StyledTableBody, ({ $theme }) => ({
  borderBottomColor: $theme.colors.mono200,
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  backgroundColor: $theme.colors.white,
}));

export const WithStyleTableBodyRow = withStyle<any, { $disableRowHoverColor?: boolean }>(
  StyledTableBodyRow,
  ({ $theme, $disableRowHoverColor }) => ({
    borderBottomColor: $theme.colors.mono200,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    backgroundColor: '#fff',
    ':hover': {
      backgroundColor: $disableRowHoverColor ? 'none' : $theme.colors.mono100,
    },
  }),
);

export const WithStyleTableBodyCell = withStyle<
any,
{ $freeze: 'header' | 'column' | 'both' | undefined }
>(StyledTableBodyCell, ({ $theme, $freeze }) => ({
  verticalAlign: 'center',
  color: $theme.colors.mono800,
  ...$theme.typography.ParagraphSmall,
  paddingTop: $theme.sizing.scale550,
  paddingBottom: $theme.sizing.scale550,
  left: 0,
  ':first-child': {
    position: $freeze === 'column' || $freeze === 'both' ? 'sticky' : 'static',
    backgroundColor: $freeze === 'column' || $freeze === 'both' ? $theme.colors.white : 'unset',
  },
}));

export const WithStyleExpandedTableBodyCell = withStyle<
any,
{ $freeze?: 'header' | 'column' | 'both' }
>(StyledTableBodyCell, ({ $theme, $freeze }) => ({
  verticalAlign: 'center',
  color: $theme.colors.mono800,
  ...$theme.typography.ParagraphSmall,
  paddingTop: $theme.sizing.scale550,
  paddingBottom: $theme.sizing.scale550,
  borderBottomWidth: $theme.sizing.scale100,
  left: 0,
  ':first-child': {
    position: $freeze === 'column' || $freeze === 'both' ? 'sticky' : 'static',
    backgroundColor: $freeze === 'column' || $freeze === 'both' ? $theme.colors.white : 'unset',
  },
}));
