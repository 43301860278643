import React, { useEffect } from 'react';
import { Block } from 'baseui/block';
import { Modal, ModalHeader, ModalBody } from 'baseui/modal';
import { useFormContext } from 'react-hook-form';
import GreetingControls from '../../../components/team/components/GreetingControls';
import GreetingSelectField from '../../../components/team/components/GreetingSelectField';
import { POLLY_VOICES } from '../../../components/team/constants';
import FileUpload from '../../../components/team/FileUpload';
import PlayRecording from '../../../components/team/PlayRecording';
import PreviewTextSpeech from '../../../components/team/PreviewTextSpeech';
import { FormStepPropsT } from './types';

type PreviewModesT = 'UPLOAD' | 'PREVIEW' | 'RECORDING';

const GreetingForm = (props: FormStepPropsT) => {
  const { formRef, onSubmit } = props;

  const [isOpen, setIsOpen] = React.useState(false);

  const [targetFile, setTargetFile] = React.useState<{}[]>();

  const [previewMode, setPreviewMode] = React.useState<PreviewModesT>('UPLOAD');

  const { watch, setValue, getValues, handleSubmit } = useFormContext();

  const greetingText$ = watch('defaultGreeting');

  const openModal = (mode: 'UPLOAD' | 'PREVIEW') => {
    setPreviewMode(mode);
    setIsOpen(true);
  };

  const getGreetingFilename = () => getValues().defaultGreeting?.greetingRef?.[0]?.id;

  useEffect(() => {
    if (targetFile && targetFile.length) {
      setValue('defaultGreeting.greetingRef', targetFile as any);
    }
  }, [setValue, targetFile]);

  return (
    <>
      <Block font="ParagraphSmall" color="mono900" marginBottom="scale600">
        The campaign greeting is an automated message that answers all your phone calls. It&apos;s
        played on the initial inbound call.
      </Block>
      <form onSubmit={handleSubmit(onSubmit)} id={formRef}>
        <GreetingSelectField name="defaultGreeting" />
        <GreetingControls openControlHandler={openModal} name="defaultGreeting" watch={watch} />
      </form>

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        overrides={{
          Dialog: {
            style: {
              // width: '50vw',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px',
              borderTopLeftRadius: '4px',
              borderTopRightRadius: '4px',
              marginBottom: 'auto',
              marginTop: '120px',
              // width: '600px',
            },
          },
        }}
      >
        <ModalHeader>{previewMode === 'UPLOAD' ? 'Add Greeting' : 'Preview'}</ModalHeader>
        <ModalBody>
          {previewMode === 'UPLOAD' && (
            <FileUpload onSave={setIsOpen} setTargetFile={setTargetFile} />
          )}
          {previewMode === 'PREVIEW' && (
            <PreviewTextSpeech
              text={greetingText$.text}
              voice={greetingText$.voice?.[0].id || POLLY_VOICES[0]}
            />
          )}
          {previewMode === 'RECORDING' && <PlayRecording id={getGreetingFilename()} />}
        </ModalBody>
      </Modal>
    </>
  );
};

export default GreetingForm;
