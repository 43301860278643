/* eslint-disable arrow-body-style */
import React, { useCallback, useEffect, useState } from 'react';
import { OnChangeParams } from 'baseui/select';
import { StatefulPopover } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import MenuListItem from 'components/lead/MenuListItem';
import { MenuAdapterProps } from 'baseui/list';
import { StatefulMenu } from 'baseui/menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTwilio } from './TwilioProvider';

const SpeakerDevices = () => {
  const [css] = useStyletron();

  const { device } = useTwilio();

  const [value, setValue] = useState<any>([]);

  const [options, setOptions] = useState<any[]>([]);

  const getAvailabelAudioDevices = useCallback(() => {
    if (device) {
      const list: any = [];

      const speakerDevices = device.audio?.speakerDevices.get();

      device.audio?.availableOutputDevices.forEach((mediaDevice, id) => {
        const option = {
          id,
          label: mediaDevice.label,
        };

        list.push(option);

        speakerDevices?.forEach((speakerDevice) => {
          if (speakerDevice.deviceId === id) {
            setValue([option]);
          }
        });
      });

      setOptions(list);
    }
  }, [device]);

  const setSpeakerDevice = (params: OnChangeParams) => {
    setValue(params.value);
    if (device) {
      device.audio?.speakerDevices.set([params.value[0].id as string]);
    }
  };

  useEffect(() => {
    if (device) {
      device.audio?.on('deviceChange', getAvailabelAudioDevices);
    }
    return () => {
      device?.audio?.removeListener('deviceChange', getAvailabelAudioDevices);
    };
  }, [device, getAvailabelAudioDevices]);

  useEffect(() => {
    if (device) {
      getAvailabelAudioDevices();
    }
  }, [device, getAvailabelAudioDevices]);

  if (!options.length) {
    return null;
  }

  return (
    <StatefulPopover
      content={({ close }) => (
        <StatefulMenu
          onItemSelect={({ item }) => {
            setSpeakerDevice({ value: [item], option: item, type: 'select' });
            close();
          }}
          items={options}
          overrides={{
            Option: {
              props: {
                overrides: {
                  ListItem: {
                    component: React.forwardRef<HTMLLIElement, MenuAdapterProps>(
                      (internalProps: React.PropsWithChildren<MenuAdapterProps>, ref) => {
                        // console.log(internalProps);
                        return (
                          <MenuListItem
                            {...internalProps}
                            $isHighlighted={
                              internalProps.$isHighlighted || internalProps.item.id === value[0].id
                            }
                            ref={ref}
                            $borderRadius={4}
                            $isSelected={internalProps.item.id === value[0].id}
                          >
                            {internalProps.children}
                          </MenuListItem>
                        );
                      },
                    ),
                  },
                },
              },
            },
          }}
        />
      )}
      overrides={{
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
            minWidth: '128px',
          }),
        },
      }}
    >
      <Button
        size="mini"
        kind="tertiary"
        startEnhancer={
          <FontAwesomeIcon
            className={css({ fontSize: '16px' })}
            size="1x"
            icon={solid('volume-high')}
          />
        }
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              marginRight: $theme.sizing.scale100,
            }),
          },
        }}
      >
        Device
      </Button>
    </StatefulPopover>
    // <Select
    //   value={value}
    //   size="mini"
    //   clearable={false}
    //   searchable={false}
    //   options={options}
    //   onChange={setSpeakerDevice}
    //   overrides={{
    //     Root: {
    //       style: () => ({
    //         width: '192px',
    //       }),
    //     },
    //     StatefulMenu: {
    //       style: () => ({
    //         width: '320px',
    //       }),
    //     },
    //   }}
    // />
  );
};

export default SpeakerDevices;
