import { NavigationState, NavigationAction } from '../types/navigation';

const reducer = (state: NavigationState, action: NavigationAction) => {
  switch (action.type) {
    case 'SET_INIT_STATUS': {
      return {
        ...state,
        status: [...action.payload],
      };
    }
    case 'UPDATE_STATUS':
      return {
        ...state,
        status: state.status.map((item: any, i: number) => ({
          ...item,
          isOpen: action.payload === i ? !item.isOpen : false,
        })),
      };
    case 'SET_TEAM_FILTER':
      return {
        ...state,
        filters: {
          ...state.filters,
          team: action.payload,
        },
      };

    default:
      return state;
  }
};

export default reducer;
