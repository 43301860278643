import { styled } from 'baseui';

export const MoreInfoTip = styled('span', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale300,
}));

export const MoreInfoToolTip = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  maxWidth: '128px',
}));

export const StyledItem = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: $theme.sizing.scale300,
  marginBottom: $theme.sizing.scale300,
  paddingTop: $theme.sizing.scale300,
  paddingBottom: $theme.sizing.scale300,
  ...$theme.typography.ParagraphXSmall,
  ':not(:last-child)': {
    borderBottom: `1px solid ${$theme.colors.mono200}`,
  },
}));

export const StyledItemLabel = styled('div', () => ({
  flex: '1 1',
  fontWeight: 500,
  textTransform: 'capitalize',
}));

export const StyledItemValue = styled('div', () => ({
  flex: '1 1',
  textAlign: 'right',
}));

export const StyleRowGroup = styled('div', ({ $theme }) => ({
  marginBottom: '16px',
  backgroundColor: $theme.colors.white,
}));

export const StyleRowGroupHeader = styled('div', ({ $theme }) => ({
  ...$theme.typography.LabelMedium,
  paddingTop: $theme.sizing.scale300,
  paddingBottom: $theme.sizing.scale300,
  borderBottom: `2px solid ${$theme.colors.mono900}`,
}));

export const StyledDeleteTeamRow = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${$theme.colors.mono200}`,
  paddingTop: $theme.sizing.scale300,
  paddingBottom: $theme.sizing.scale300,
  fontSize: $theme.sizing.scale550,
  ':last-child': {
    borderBottomWidth: 0,
  },
}));

export const StyledMiniLabel = styled<'div', { $err?: string }>('div', ({ $theme, $err }) => ({
  background: 'white',
  color: $err ? $theme.colors.negative400 : $theme.colors.mono900,
  fontSize: '11px',
  padding: '0 4px',
  position: 'absolute',
  transform: 'translate(9px, -18px) scale(1)',
  whiteSpace: 'nowrap',
}));

export const StyledDeleteTeamCell = styled('div', () => ({
  display: 'flex',
  flex: '100 0 auto',
  minWidth: '50px',
  width: '100px',

  ':nth-child(1)': {
    // textOverflow: 'ellipsis',
    wordBreak: 'break-all',
  },

  ':nth-child(2)': {
    flex: '75 0 auto',
    minWidth: '150px',
    width: '75px',
    position: 'relative',
  },

  ':nth-child(3)': {
    alignItems: 'center',
    position: 'relative',
    paddingTop: '6px',
  },
}));
