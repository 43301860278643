import { styled, Theme } from 'baseui';
import { customStyled } from '~/theme';

type StyledGenericProps = { [key: string]: any } & Partial<Theme>;

export const WIDTH_OPEN = '256';

export const WIDTH_MINIMIZED = '64';

export const SidebarMenuWrap = customStyled('div', ({ $theme }) => ({
  top: 0,
  backgroundColor: $theme.brandColor,
  height: '100%',
  display: 'flex',
  flex: '0 0 auto',
  flexDirection: 'column',
  // transitionTimingFunction: $theme.animation.easeInOutCurve,
  // transitionDuration: $theme.animation.timing300,
}));

export const SidebarRoot = styled<'div', StyledGenericProps>('div', ({ $theme, $visible }) => ({
  display: 'flex',
  flexDirection: 'row',
  minWidth: `${WIDTH_OPEN}px`,
  width: '100vw',
  // marginTop: '56px',
  flex: '0 0 auto',
  transform: $visible ? 'none' : 'translateX(-100%)',
  transition: 'transform 0ms',

  [$theme.mediaQuery.large]: {
    width: 'unset',
    transform: 'none',
    marginTop: 0,
    minWidth: 0,
  },
}));

export const SidebarContainer = styled<any, StyledGenericProps>(
  'div',
  ({ $isExpanded, $theme }) => ({
    display: 'flex',
    position: 'relative',
    // zIndex: '1',
    flex: '0 0 100%',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    transitionTimingFunction: $theme.animation.easeInOutCurve,
    transitionDuration: $theme.animation.timing100,
    width: `${WIDTH_OPEN}px`,
    maxWidth: `${WIDTH_OPEN}px`,
    [$theme.mediaQuery.large]: {
      zIndex: 'auto',
      width: $isExpanded ? `${WIDTH_OPEN}px` : `${WIDTH_MINIMIZED}px`,
      maxWidth: $isExpanded ? `${WIDTH_OPEN}px` : `${WIDTH_MINIMIZED}px`,
    },
  }),
);

export const Backdrop = styled('div', ({ $theme }) => ({
  position: 'absolute',
  // flex: 1,
  bottom: 0,
  left: 0,
  right: 0,
  top: 0,
  // zIndex: '1',
  // display: 'block',
  backgroundColor: 'rgba(0, 0, 0, .5)',
  [$theme.mediaQuery.large]: {
    display: 'none',
  },
}));

export const SidebarHeaderContainer = customStyled<'div', StyledGenericProps>(
  'div',
  ({ $theme, $isExpanded }) => ({
    display: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: $theme.brandColorDarker,
    paddingTop: $theme.sizing.scale500,
    paddingBottom: $theme.sizing.scale500,
    [$theme.mediaQuery.large]: {
      display: 'flex',
      justifyContent: $isExpanded ? 'space-between' : 'center',
    },
  }),
);
