import React from 'react';
import { Spinner } from 'baseui/spinner';
import { Lead } from '../../backend/lead';
import { StyledEventBody } from './styled-components';
import useGetUser from '../../hooks/useGetUserById';
import useGetProperty from '../properties/hooks/useGetProperty';

const SharedLead = (props: Lead) => {
  const { assignedProperty, tenantName, shareDetails } = props;

  const propertyRef = assignedProperty?.propertyRef;

  const propertyQuery = useGetProperty(propertyRef);

  const property = propertyQuery.data;

  const userQuery = useGetUser(shareDetails?.sharedByRef);

  const sharedByUser = userQuery.data;

  if (propertyQuery.isLoading || userQuery.isLoading) {
    return <Spinner $size="small" />;
  }

  return (
    <>
      <StyledEventBody>{property?.name}</StyledEventBody>
      {tenantName && <StyledEventBody>{tenantName}</StyledEventBody>}
      <StyledEventBody>Shared by {sharedByUser?.name}</StyledEventBody>
    </>
  );
};

export default SharedLead;
