import { useQuery } from 'react-query';
import useAxios from '../../../hooks/useAxios';
import { Lead } from '../../../backend/lead';

export function useLead() {
  const axios = useAxios();
  return {
    async getLead({ queryKey }: any) {
      const [, id] = queryKey;
      if (typeof id === 'undefined') return Promise.reject();
      const { data } = await axios.get<Lead>(`api/lead/${id}`);
      return data;
    },
  };
}

function useGetLead(id: string | undefined, ...keys: any[]) {
  const { getLead } = useLead();
  return useQuery({
    queryFn: getLead,
    queryKey: ['getLead', id, ...keys],
    enabled: Boolean(id),
    retry: false,
  });
}

export default useGetLead;
