import React, { useRef, useEffect } from 'react';

type ScrollToBottomProps = {
  options?: ScrollIntoViewOptions;
};

function ScrollToBottom({ options }: ScrollToBottomProps) {
  const eleRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (eleRef.current) {
      eleRef.current.scrollIntoView(options);
    }
  });
  return <div ref={eleRef} />;
}

export default ScrollToBottom;
