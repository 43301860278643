import React from 'react';
import {
  StyledContent,
  StyledMessage,
  StyledMessageContainer,
  StyledTitle,
} from './styled-components';
import useGetUserById from '../../hooks/useGetUserById';
import { LeadChangeDetail } from '../../backend/messages';

type LeadChangedAlertPropsT = {
  details: LeadChangeDetail;
};

const LeadChangedAlert = ({ details }: LeadChangedAlertPropsT) => {
  const { by, description } = details;

  const initiatedByUser = useGetUserById(by);

  if (initiatedByUser.isSuccess) {
    return (
      <StyledMessageContainer>
        <StyledContent>
          <StyledTitle>{description}</StyledTitle>
          {description && <StyledMessage>By {initiatedByUser.data?.name}</StyledMessage>}
        </StyledContent>
      </StyledMessageContainer>
    );
  }
  return null;
};

export default LeadChangedAlert;
