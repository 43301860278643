import React, { useEffect } from 'react';
import { User } from '../../../backend/user';
import { TeamMember } from '../../../backend/team';
import useGetTeam from '../../team/hooks/useGetTeam';
import useGetUsers from '../../../hooks/useGetUsers';

export interface TeamMemberUser extends TeamMember {
  user: User | undefined;
}

const useTeamMemberUsers = (teamId: string | undefined) => {  
  const userQuery = useGetUsers({ enabled: true });

  const teamQuery = useGetTeam(teamId);

  const users = userQuery.data;

  const team = teamQuery.data;

  const [teamMemberUsers, setTeamMemberUsers] = React.useState<TeamMemberUser[]>([]);
  
  const isLoading = userQuery.isLoading || teamQuery.isLoading;

  useEffect(() => {
    if (users && team) {
      const merged = team?.teamMembers
        .filter((member) => member.status === 'ACTIVE')
        .map((teamMember) => {
          const user = users?.filter((attr) => attr.id === teamMember.userRef)[0];
          return {
            ...teamMember,
            user,
          };
        });
      setTeamMemberUsers(merged);
    }
  }, [users, team]);

  return { teamMemberUsers, isLoading };
};

export default useTeamMemberUsers;
