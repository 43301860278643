import { Communication } from 'backend/communication';
import { Button } from 'baseui/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  StyledContent,
  // StyledMessage,
  // StyledDescription,
  StyledCaption,
  StyledMessageContainer,
  StyledTitle,
  StyledAction,
} from './styled-components';

function TextFailedAlert({ details }: { details: Communication }) {
  const navigate = useNavigate();

  const location = useLocation();

  const openLead = () => {
    toast.dismiss();
    const to = `/leads/${details.leadRef}`;
    if (location.pathname !== to) {
      navigate(to);
    }
  };

  return (
    <StyledMessageContainer>
      <StyledContent>
        <StyledTitle>Text Message Not Delivered</StyledTitle>
        <StyledCaption>Send failure, see lead for details.</StyledCaption>
        {/* <StyledDescription>Text message failed to send</StyledDescription> */}
        {/* <StyledMessage>message</StyledMessage> */}
        <StyledAction>
          <Button type="button" shape="pill" size="mini" onClick={openLead}>
            View Lead
          </Button>
        </StyledAction>
      </StyledContent>
    </StyledMessageContainer>
  );
}

export default TextFailedAlert;
