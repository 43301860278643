import {
  StyledListPageContainer,
  StyledListPageContentContainer,
  StyledListPageHeaderContainer,
} from 'components/layout/styled-components';
import Tabnavigation from 'components/team/TabNavigation';
import TeamHeader from 'components/team/TeamHeader';
import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {
  hideHeader?: boolean;
  hideTabs?: boolean;
};
const TeamLayout = (props: Props) => {
  const { hideHeader, hideTabs } = props;

  return (
    <StyledListPageContainer data-id="CONTAINER" $style={{ overflow: 'hidden' }}>
      <StyledListPageHeaderContainer>{!hideHeader && <TeamHeader />}</StyledListPageHeaderContainer>
      <StyledListPageContentContainer data-id="WRAPPER"
        $style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
      >
        {!hideTabs && <Tabnavigation />}
        <Outlet />
      </StyledListPageContentContainer>
    </StyledListPageContainer>
  );
};

export default TeamLayout;
