import { useClient } from 'context/client-context';
import React from 'react';
import { Navigate } from 'react-router-dom';

const NavigateSupportedType = () => {
  const { state } = useClient();

  const type = state?.teamType;

  return type === 'BROKER_SINGLE_PROP' ? (
    <Navigate to="open-houses" replace />
  ) : (
    <Navigate to="campaigns" replace />
  );
};

const ReportsIndexRoute = () => {
  const { state } = useClient();

  const type = state?.teamType;

  return type === 'BROKER_SINGLE_PROP' ? (
    <Navigate to="campaign" replace />
  ) : (
    <Navigate to="pipeline" replace />
  );
};

export { NavigateSupportedType, ReportsIndexRoute };
