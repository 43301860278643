import React from 'react';
import { Modal as BaseModal } from 'baseui/modal';
import { useModal } from './ModalProvider';

const Modal = () => {
  const { component, isOpen, config, closeModal } = useModal();

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={closeModal}
      overrides={{
        Dialog: {
          style: {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            marginBottom: 'auto',
            marginTop: '120px',
          },
        },
      }}
      {...config}
    >
      {component}
    </BaseModal>
  );
};

export default Modal;
