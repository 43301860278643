import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

type MutationVariablesT = {
  url: string;
  file: File;
  contentType: string;
  queryKeys?: string[];
};

const useUpload = () => {
  const queryClient = useQueryClient();

  const upload = (url: string, file: File, contentType: string) => {
    const fileUploadInstance = axios.create({
      headers: {
        'Content-Type': contentType,
      },
    });
    return fileUploadInstance.put(url, file, {});
  };

  return useMutation(
    ({ url, file, contentType }: MutationVariablesT) => upload(url, file, contentType),
    {
      retry: false,
      onSuccess: async () =>
        // Delay invalidation so our service and AWS can sync
        new Promise<void>((res) => {
          setTimeout(() => res(), 2000);
        }),
      onSettled: (_d, _e, v) => {
        v.queryKeys?.forEach((name: string) => {
          console.log(name);
          queryClient.invalidateQueries([name]);
        });
      },
      onError: (err: any) => {
        console.log(err);
        return err;
      },
    },
  );
};

export default useUpload;
