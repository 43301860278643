import React from 'react';
import { Block } from 'baseui/block';
import { StatefulTooltip } from 'baseui/tooltip';
import { StyledLink } from 'components/shared/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTeamCalendar } from 'components/dashboard/TeamCalendarProvider';
import Banner from '../screen/Banner';
import { useClient } from '../../context/client-context';

const getDelimiter = (i: number, l: number, omitConjunction: boolean = false) => {
  if (omitConjunction) {
    return ', ';
  }
  if (i !== l - 1) {
    if (i === l - 2) {
      return ' and ';
    }
    return ', ';
  }
  return undefined;
};

const TeamCalendarConnectionStatus = () => {
  const client = useClient();

  const { teamsConnectionFail } = useTeamCalendar();

  const isOpen = Boolean(teamsConnectionFail?.length);

  const list = teamsConnectionFail?.map((team) => ({ name: team.name, id: team.id }));

  const split = list?.slice(0, 3);

  const rest = list?.slice(3);

  return (
    <Banner
      appearance="warning100"
      isOpen={isOpen}
      icon={<FontAwesomeIcon size="1x" icon={regular('flag')} />}
      title="Team Calendar Connection Failed."
    >
      Calendar authorization needed for{' '}
      {split?.map((entry, i) => (
        <React.Fragment key={entry.id}>
          <StyledLink to={`/${client.state?.displayValueAlt.toLowerCase()}/${entry?.id}/settings`}>
            {entry.name}
          </StyledLink>
          {getDelimiter(i, split.length, !!rest?.length)}
        </React.Fragment>
      ))}
      {!!rest?.length && (
        <span>
          and{' '}
          <StatefulTooltip
            overrides={tooltipOverrides}
            content={() => (
              <Block font="ParagraphXSmall">
                {rest.map((entry) => (
                  <Block font="ParagraphXSmall" key={entry.id}>
                    {entry.name}
                  </Block>
                ))}
              </Block>
            )}
          >
            <span>{rest.length} more</span>
          </StatefulTooltip>
        </span>
      )}
    </Banner>
  );
};

export default TeamCalendarConnectionStatus;
