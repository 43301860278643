import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { Radio, RadioGroup, RadioGroupProps } from 'baseui/radio';
import { FormControl, FormControlProps } from 'baseui/form-control';
import {
  WithStyledRadioMarkOuter,
  WithStyledRadioRoot,
  WithStyledRadioLabel,
} from '../../shared/styles';

const radioOverrides = {
  Root: {
    component: WithStyledRadioRoot,
  },
  Label: {
    component: WithStyledRadioLabel,
  },
  RadioMarkOuter: {
    component: WithStyledRadioMarkOuter,
  },
  // RadioMarkInner : {
  //   style : ({$checked} : any) => ({
  //     width: $checked ? '6px' : '18px',
  //     height: $checked ? '6px' : '18px',
  //   }),
  // },
};
// interface ControlledRadioGroupProps {
//   name: any;
//   control: any;
//   baseWebRadioProps: RadioGroupProps;
//   handleOnChange? : (e: ChangeEvent<HTMLInputElement>) => void,
//   fcProps: Omit<FormControlProps, 'children'>;
//   radioOptions: { value: string; displayValue: string }[];
// }
type ControlledRadioGroupPropsT = {
  name: any;
  control: any;
  baseWebRadioProps: RadioGroupProps;
  handleOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  fcProps: Omit<FormControlProps, 'children'>;
  radioOptions: { value: string; displayValue: string }[];
};

const ControlledRadioGroup = ({
  name,
  fcProps,
  radioOptions,
  baseWebRadioProps,
  control,
  handleOnChange,
}: ControlledRadioGroupPropsT) => {
  const {
    field: { ref, onChange, ...radioProps },
    formState: { errors },
  } = useController({ name, control });
  const props = { ...radioProps, ...baseWebRadioProps };

  return (
    <FormControl {...fcProps}>
      <RadioGroup
        onChange={(e) => {
          if (handleOnChange) handleOnChange(e);
          onChange(e.target.value);
        }}
        error={!!errors[name]}
        {...props}
      >
        {radioOptions.map((option) => (
          <Radio
            key={`${option.value}_${option.displayValue}`}
            value={option.value}
            overrides={radioOverrides}
          >
            {option.displayValue}
          </Radio>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default ControlledRadioGroup;

ControlledRadioGroup.defaultProps = {
  handleOnChange: undefined,
};
