import { QueryCache, QueryClient } from 'react-query';
import { toast } from 'react-toastify';

const instance = new QueryClient({
  defaultOptions: {
    queries: {
      notifyOnChangeProps: 'tracked',
      retry: false,
      staleTime: 1000 * 20,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data === undefined) {
        toast.error(`Something went wrong: ${(error as unknown as any)?.message}`);
      }
    },
  }),
});

export default instance;
