import { useMutation } from 'react-query';
import { FileUpload } from 'backend/fileUpload';
import useAxios from '../../../hooks/useAxios';

const useGreetingUploadUrl = () => {
  const api = useAxios();

  const getSignedUrl = (fileName: string) =>
    api.get<FileUpload>(`/api/greetings/upload-url?filename=${fileName}`);

  return useMutation(({ fileName }: any) => getSignedUrl(fileName));
};

export default useGreetingUploadUrl;
