/* eslint-disable arrow-body-style */
import { withStyle, styled } from 'baseui';
import { Link, NavLink } from 'react-router-dom';
import { StyledRoot } from 'baseui/input';
import { StyledBaseButton } from 'baseui/button';
import { StyledCheckmark, StyledToggleTrack, StyledToggle } from 'baseui/checkbox';
import { StyledRadioMarkOuter, StyledRoot as StyledRadioRoot, StyledLabel } from 'baseui/radio';
import { StyledDropdownContainer } from 'baseui/select';

// INPUT
export const InputRootWithStyle = withStyle(StyledRoot, ({ $theme }) => ({
  transitionProperty: 'all',
  transitionDelay: '0s',
  transitionTimingFunction: $theme.animation.easeInCurve,
  transitionDuration: $theme.animation.timing300,
}));

// CHECKMARK
export const CheckMarkWithStyle = withStyle<any, { $isFocused: boolean }>(
  StyledCheckmark,
  ({ $isFocused, $theme }) => ({
    borderTopWidth: '2px',
    borderRightWidth: '2px',
    borderBottomWidth: '2px',
    borderLeftWidth: '2px',
    boxShadow: $isFocused ? `0px 0px 0px 2px ${$theme.colors.primary100}` : 'none',
    transitionProperty: 'all',
    transitionDelay: '0s',
    transitionTimingFunction: 'ease',
    transitionDuration: $theme.animation.timing200,
  }),
);

// TOGGLE
interface CheckboxProps {
  $checked?: boolean;
  $isFocused?: boolean;
}

export const ToggleWithStyle = withStyle<any, CheckboxProps>(StyledToggle, ({ $checked }) => ({
  transform: $checked ? 'translateX(100%)' : 'translateX(10%)',
}));

export const ToggleTrackWithStyle = withStyle<any, CheckboxProps>(
  StyledToggleTrack,
  ({ $theme, $checked, $isFocused }) => ({
    backgroundColor: $checked ? $theme.colors.primary400 : $theme.colors.mono300,
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    marginTop: '0',
    marginBottom: '0',
    height: '24px',
    width: '42px',
    boxShadow: $isFocused ? `0px 0px 0px 3px ${$theme.colors.primary200}` : 'none',
    transitionProperty: 'all',
    transitionDelay: '0s',
    transitionTimingFunction: 'ease',
    transitionDuration: $theme.animation.timing200,
  }),
);

// RADIO
export const WithStyledRadioMarkOuter = withStyle<any, { $isFocused: boolean }>(
  StyledRadioMarkOuter,
  ({ $isFocused, $theme }) => ({
    boxShadow: $isFocused ? `0px 0px 0px 2px ${$theme.colors.primary100}` : 'none',
    width: $theme.sizing.scale600,
    height: $theme.sizing.scale600,
    marginTop: 0,
    marginBottom: 0,
    // padding: $theme.sizing.scale0,
  }),
);

export const WithStyledRadioLabel = withStyle<any, { $checked: boolean }>(
  StyledLabel,
  ({ $theme, $checked }) => ({
    ...$theme.typography.ParagraphSmall,
    color: $checked ? $theme.colors.primary400 : $theme.colors.mono800,
  }),
);

export const WithStyledRadioRoot = withStyle<any, { $checked: boolean }>(
  StyledRadioRoot,
  ({ $theme, $checked }) => ({
    flex: '1 1 0',
    marginTop: 0,
    marginBottom: 0,
    padding: $theme.sizing.scale200,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: $checked ? $theme.colors.primary300 : $theme.colors.mono200,
    // border: `2px solid ${$theme.colors.mono200}`,
    borderRadius: $theme.borders.buttonBorderRadius,
    backgroundColor: $checked ? $theme.colors.mono100 : $theme.colors.white,
  }),
);

// BUTTONS
export const BaseButtonWithStyle = withStyle<
  typeof StyledBaseButton,
{ $groupIndex: number; $count: number }
>(StyledBaseButton, ({ $theme, $count }) => {
  return {
    outline: 'none',
    ':not(:last-child)': {
      marginRight: $count > 1 ? $theme.sizing.scale600 : 0,
    },
    ':active': {
      transform: 'scale(0.95) translateZ(0)',
    },
    transitionProperty: 'all',
    transitionDelay: '0s',
    transitionTimingFunction: 'ease',
    transitionDuration: $theme.animation.timing200,
  };
});

export const StyledButtonNegative = withStyle(StyledBaseButton, ({ $theme }) => ({
  color: $theme.colors.negative400,
}));

export const BaseButtonWarnWithStyle = withStyle(StyledBaseButton, ({ $theme }) => ({
  backgroundColor: $theme.colors.warning300,
}));

// SELECT
export const DropdownContainerWithStyle = withStyle(StyledDropdownContainer, () => ({
  // MozOsxFontSmoothing: 'grayscale',
  // WebkitFontSmoothing: 'antialiased',
  // textRendering: 'optimizeLegibility',
}));

export const StyledLink = styled(Link, ({ $theme }) => ({
  color: $theme.colors.primary400,
  cursor: 'pointer',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
}));

export const StyledNavLink = styled(NavLink, () => {
  return {
    textDecoration: 'none',
    transition: 'opacity color .2s',
    ':hover': {
      opacity: `${1} !important`,
      textDecoration: 'underline',
    },
  };
});

export const StyledToolbarRoot = styled('section', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  // minHeight: $theme.sizing.scale1200,
  // paddingLeft: $theme.sizing.scale900,
  // paddingRight: $theme.sizing.scale900,
  // backgroundColor : $theme.colors.white,
  marginBottom: $theme.sizing.scale500,
  // borderBottom: `1px solid ${$theme.colors.mono200}`,
}));

export const StyledH1 = styled('h1', ({ $theme }) => ({
  ...$theme.typography.HeadingSmall,
  marginBottom: $theme.sizing.scale900,
  fontWeight: 'bold',
  color: $theme.colors.mono900,
  marginTop: 0,
}));

export const StyledH2 = styled('h2', ({ $theme }) => ({
  ...$theme.typography.HeadingXSmall,
  fontWeight: 'bold',
  color: $theme.colors.mono900,
  marginTop: 0,
  marginBottom: 0,
}));

export const StyledContainer = styled('div', ({ $theme }) => ({
  // height: '100%',
  // padding : $theme.sizing.scale600,
  // margin : `${$theme.sizing.scale900} 0`,
  backgroundColor: $theme.colors.white,
  // borderRadius: $theme.sizing.scale100,
  boxShadow: '0px 0px 8px rgb(0 0 0 / 8%)',
}));
