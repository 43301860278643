import { useStyletron } from 'baseui';
import { MenuOverrides, StatefulMenu, StyledListItem } from 'baseui/menu';
import { useAxiosIdentity } from 'hooks/useAxios';
import React from 'react';
import { useCurrentUserContext } from 'context/current-user-context';
import { useQueryClient } from 'react-query';
import UseAdminFor from './hooks/use-admin-for';

const statefulMenuOverrides: MenuOverrides = {
  List: {
    style: {
      boxShadow: 'none',
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  ListItem: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    component: React.forwardRef((props: any, _ref) => (
      <StyledListItem {...props}>
        {props.item.icon}
        <div>{props.children}</div>
      </StyledListItem>
    )),
    style: ({ $theme }) => ({
      display: 'flex',
      alignItems: 'center',
      color: $theme.colors.mono500,
      fontSize: '13px',
      borderRadius: '4px',
      paddingTop: $theme.sizing.scale200,
      paddingBottom: $theme.sizing.scale200,
      paddingLeft: $theme.sizing.scale500,
      paddingRight: $theme.sizing.scale500,
    }),
  },
};

function SwitchIdentity({ onIdentityChange }: any) {
  const [css, theme] = useStyletron();

  const currentUser = useCurrentUserContext();

  const isAdminMode = Boolean(currentUser.representingUser?.id);

  const { data, isSuccess } = UseAdminFor();

  const { identity, setIdentity } = useAxiosIdentity();

  const queryClient = useQueryClient();

  const onItemSelect = (e: any) => {
    const { id } = e.item;

    if (id) {
      setIdentity(e.item.id);
      localStorage.setItem('identity', e.item.id);
      queryClient.removeQueries(['getAuthUser']);
      queryClient.removeQueries(['getLeads']);
      queryClient.removeQueries(['newDevSummary']);
      queryClient.removeQueries(['getTeamsForUser']);
    }

    if (identity === currentUser.id) {
      setIdentity(null);
      localStorage.removeItem('identity');
      queryClient.removeQueries(['getAuthUser', { identity: currentUser.id }]);
      queryClient.removeQueries(['getLeads']);
      queryClient.removeQueries(['newDevSummary']);
      queryClient.removeQueries(['getTeamsForUser']);
    }

    onIdentityChange();
    window.location.reload();
  };

  React.useEffect(
    () => () => {
      queryClient.removeQueries(['administratorFor']);
    },
    [queryClient],
  );

  if (isSuccess) {
    const cancelOption = {
      id: '',
      label: isAdminMode ? 'Switch back to my account' : 'Cancel',
      name: 'Cancel',
    };

    const options = [...data.map((u) => ({ ...u, label: u.name })), cancelOption];

    const items = isAdminMode ? [cancelOption] : options;

    if (data.length === 0 && !isAdminMode) {
      return (
        <div
          className={css({
            ...theme.typography.ParagraphSmall,
            textAlign: 'center',
            color: theme.colors.mono400,
            fontWeight: 400,
          })}
        >
          You do not have access to other user accounts at this time.
        </div>
      );
    }
    return (
      <StatefulMenu items={items} overrides={statefulMenuOverrides} onItemSelect={onItemSelect} />
    );
  }

  return null;
}

export default SwitchIdentity;
