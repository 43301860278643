import React from 'react';
import { Button } from 'baseui/button';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledAction,
  StyledContent,
  // StyledIconContainer,
  StyledMessage,
  StyledMessageContainer,
  StyledTitle,
} from './styled-components';
import useGetUserById from '../../hooks/useGetUserById';
import { LeadChangeDetail } from '../../backend/messages';

type LeadChangedAlertPropsT = {
  details: LeadChangeDetail;
  close: (id?: string) => void;
};

const LeadChangedAlert = ({ details, close }: LeadChangedAlertPropsT) => {
  const navigate = useNavigate();

  const location = useLocation();

  const { by, description, to, id } = details;

  const initiatedByUser = useGetUserById(by);

  const assignedToUser = useGetUserById(to);

  const handleClick = () => {
    close();
    const path = `/leads/${id}`;
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  if (initiatedByUser.isSuccess && assignedToUser.isSuccess) {
    return (
      <StyledMessageContainer>
        <StyledContent>
          <StyledTitle>{description} to you</StyledTitle>
          {description && <StyledMessage>By {initiatedByUser.data?.name}</StyledMessage>}
          <StyledAction>
            <Button type="button" shape="pill" size="mini" onClick={handleClick}>
              View
            </Button>
          </StyledAction>
        </StyledContent>
      </StyledMessageContainer>
    );
  }
  return null;
};

export default LeadChangedAlert;
