import React from 'react';
import { Button } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useModal } from 'components/modal/ModalProvider';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import MoveCommunicationForm from 'components/lead/activity/MoveCommunicationForm';
import useGetLead from 'components/lead/hooks/useGetLead';
import usePermissions from 'components/lead/hooks/usePermissions';

const MoveCommunication = ({ leadId, id }: any) => {
  const leadQuery = useGetLead(leadId);

  const { closeModal, modalHandler } = useModal();

  const lead = leadQuery.data;

  const { canEdit } = usePermissions(lead?.id);

  const communications = lead?.communications;

  const firstIncomingCommunication = communications?.filter(
    (item) => item.communicationDirection === 'INCOMING',
  )[0];

  const com = communications?.filter((comm) => comm.id === id)[0];

  const canMove =
    canEdit &&
    com?.communicationDirection === 'INCOMING' &&
    com.id !== firstIncomingCommunication?.id;

  const handleOpen = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    modalHandler(<MoveCommunicationForm leadId={leadId} id={id} teamRef={lead?.teamRef} />, {
      closeable: true,
    });
  };

  React.useEffect(
    () => () => {
      closeModal();
    },
    [closeModal],
  );

  if (!canMove) {
    return null;
  }

  return (
    <>
      <StatefulTooltip content="Move communication" overrides={tooltipOverrides}>
        <Button
          onClick={handleOpen}
          type="button"
          kind="tertiary"
          size="mini"
          startEnhancer={<FontAwesomeIcon icon={regular('folder-open')} />}
          overrides={{
            BaseButton: {
              style: ({ $theme }) => ({
                marginRight: $theme.sizing.scale100,
                // backgroundColor: $theme.colors.negative400,
              }),
            },
            StartEnhancer: {
              style: { fontSize: '16px' },
            },
          }}
        >
          Move
        </Button>
      </StatefulTooltip>
    </>
  );
};

export default MoveCommunication;
