import { ComponentType } from 'react';
import { useClient } from 'context/client-context';
import { Navigate, useMatch } from 'react-router-dom';
import { useCurrentUserContext } from 'context/current-user-context';

function withRouteGuard<P>(Component: ComponentType<P>) {
  return function WithRouteGuard(props: P) {
    const match = useMatch('/:domain/*');

    const domain = match?.params.domain;

    const pathname = match?.pathname;

    const { userManageProperties, userManageUsers, role } = useCurrentUserContext();

    const { state: client } = useClient();

    const isOwner = client?.isOwner;

    const isBroker = client?.isBroker;

    const isBrokerSingleProp = client?.teamType === 'BROKER_SINGLE_PROP';

    let canActivate = true;

    switch (domain) {
      case 'manage':
        if (pathname === '/manage/users' && !userManageUsers && role !== 'SUPERUSER') {
          canActivate = false;
          break;
        }
        if (pathname?.includes('/manage/properties')) {
          if (
            (!userManageProperties && role !== 'SUPERUSER') ||
            client?.isOwner ||
            isBrokerSingleProp
          ) {
            canActivate = false;
            break;
          }
        }
        if (pathname?.includes('/manage/owners')) {
          if ((!userManageProperties && role !== 'SUPERUSER') || isBrokerSingleProp) {
            canActivate = false;
            break;
          }
        }
        break;

      case 'leads': {
        if (pathname === '/leads/list/prospects' && (isOwner || isBrokerSingleProp)) {
          canActivate = false;
          break;
        }
        if (pathname === '/leads/list/waiting-list' && isBroker) {
          canActivate = false;
          break;
        }
        break;
      }

      case 'properties':
        if (isBroker) {
          canActivate = false;
          break;
        }
        if (match?.pathname.includes('open-houses') && isOwner) {
          canActivate = false;
          break;
        }
        if (match?.pathname.includes('units') && isBrokerSingleProp) {
          canActivate = false;
        }
        break;

      case 'teams':
        if (isOwner || isBrokerSingleProp) {
          canActivate = false;
        }
        break;

      case 'help':
        if (isOwner || isBroker) {
          canActivate = false;
        }
        break;
      default:
        canActivate = true;
    }

    if (!canActivate) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
}

export default withRouteGuard;
