import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStyletron } from 'baseui';
import React from 'react';

const SubNavEmptyContent = () => {
  const [css, theme] = useStyletron();

  return (
    <div
      className={css({
        marginLeft: theme.sizing.scale400,
        marginRight: theme.sizing.scale500,
        marginBottom: theme.sizing.scale100,
        marginTop: theme.sizing.scale100,
        color: theme.colors.warning400,
        ...theme.typography.ParagraphXSmall,
      })}
    >
      <div
        className={css({
          borderWidth: '2px',
          paddingLeft: theme.sizing.scale500,
          paddingRight: theme.sizing.scale500,
          paddingTop: theme.sizing.scale500,
          paddingBottom: theme.sizing.scale500,
          borderBottomLeftRadius: '4px',
          borderBottomRightRadius: '4px',
        })}
      >
        <div
          className={css({
            alignItems: 'baseline',
            display: 'flex',
            columnGap: theme.sizing.scale500,
          })}
        >
          <FontAwesomeIcon icon={solid('triangle-exclamation')} />
          <span
            className={css({
              color: theme.colors.mono400,
            })}
          >
            No team matched your search. Try a different phrase.
          </span>
        </div>
      </div>
    </div>
  );
};

export default SubNavEmptyContent;
