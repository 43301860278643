import { styled } from 'baseui';

export const StyledMessageContainer = styled<'div', { $kind?: string }>('div', () => ({
  minWidth: 0,
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'flex-end',
}));

export const StyledTitle = styled('div', ({ $theme }) => ({
  color: $theme.colors.black,
  ...$theme.typography.LabelSmall,
}));

export const StyledDescription = styled('div', ({ $theme }) => ({
  ...$theme.typography.ParagraphSmall,
  marginTop: $theme.sizing.scale200,
  marginBottom: $theme.sizing.scale200,
}));

export const StyledMessage = styled('div', ({ $theme }) => ({
  width: '266px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...$theme.typography.ParagraphSmall,
  marginTop: $theme.sizing.scale200,
  marginBottom: $theme.sizing.scale200,
}));

export const StyledCaption = styled('div', ({ $theme }) => ({
  color: $theme.colors.warning600,
  marginTop: $theme.sizing.scale100,
  marginBottom: $theme.sizing.scale100,
  ...$theme.typography.ParagraphXSmall,
}));

// export const StyledPreview = styled<{}, 'div'>('div', ({ $theme }) => ({
//   width: '266px',
//   overflow: 'hidden',
//   whiteSpace: 'nowrap',
//   textOverflow: 'ellipsis',
//   ...$theme.typography.ParagraphSmall,
//   marginTop: $theme.sizing.scale200,
//   marginBottom: $theme.sizing.scale200,
// }));

export const StyledContent = styled('div', () => ({
  flex: 1,
  minWidth: 0,
  display: 'block',
}));

export const StyledIconContainer = styled('div', ({ $theme }) => ({
  flexShrink: 0,
  width: '20px',
  height: '20px',
  alignSelf: 'flex-start',
  marginRight: $theme.sizing.scale500,
}));

export const StyledAction = styled('div', () => ({
  textAlign: 'right',
}));
