import React, { ReactNode } from 'react';
import { useUID } from 'react-uid';
import { Button } from 'baseui/button';
import { ProgressBar } from 'baseui/progress-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import * as S from './styled-components';

type StepperPropsT = {
  children: any;
  currentStep: number;
  isOpen?: boolean;
  renderAll?: boolean;
  disableNext?: boolean;
  steps: number;
  title?: string | ReactNode;
  nextLabel?: string | ReactNode;
  hidePrevButton?: boolean;
  hideProgressBar?: boolean;
  onNextStep: (d?: any) => any;
  onPrevStep: () => any;
  onCancel: () => any;
  isLoading?: boolean;
  form?: string;
};

function InternalStep({ childKey, childIndex, renderAll, ...props }: any) {
  const key = String(childIndex);
  const { children, currentStep, uid } = props;
  const isActive = key === String(currentStep);
  return isActive || renderAll ? (
    <S.Step id={`stepper-${uid}-step-${key}`}>{children}</S.Step>
  ) : null;
}

const defaultProps = {
  isOpen: false,
  renderAll: false,
  title: undefined,
  nextLabel: 'Next',
  disableNext: false,
  hidePrevButton: false,
  hideProgressBar: false,
  isLoading: false,
};

const Stepper = ({
  title,
  children,
  steps,
  renderAll = false,
  currentStep = 0,
  nextLabel = 'Next',
  onNextStep,
  onPrevStep,
  onCancel,
  disableNext,
  hidePrevButton,
  hideProgressBar,
  isLoading,
  form,
}: StepperPropsT) => {
  const uid = useUID();

  return (
    <S.StepperRoot data-spaceit="stepper" id={`stepper-${uid}`}>
      <S.StepperHeader>
        {currentStep > 0 && !hidePrevButton ? (
          <Button size="compact" kind="tertiary" onClick={onPrevStep} type="button" shape="circle">
            <FontAwesomeIcon icon={solid('angle-left')} />
          </Button>
        ) : (
          <S.StepperHeaderPadding />
        )}
        <S.StepperTitle>{title}</S.StepperTitle>
        <Button size="compact" kind="tertiary" onClick={onCancel} type="button" shape="circle">
          <FontAwesomeIcon icon={solid('times')} />
        </Button>
      </S.StepperHeader>
      <S.StepperProgressBar>
        {!hideProgressBar && (
          <ProgressBar
            value={(currentStep / steps) * 100}
            overrides={{
              BarProgress: {
                style: ({ $theme }) => ({
                  backgroundColor: $theme.colors.positive400,
                }),
              },
            }}
          />
        )}
      </S.StepperProgressBar>
      <S.StepperBody>
        {React.Children.map(children, (child, index) => {
          if (!child) return null;
          return (
            <InternalStep
              childIndex={index}
              childKey={child.key}
              currentStep={currentStep}
              renderAll={renderAll}
              uid={uid}
              {...child.props}
            />
          );
        })}
      </S.StepperBody>
      <S.StepperFooter>
        <Button
          isLoading={isLoading}
          onClick={onNextStep}
          type="submit"
          kind="primary"
          disabled={disableNext}
          form={form}
        >
          {nextLabel}
        </Button>
      </S.StepperFooter>
    </S.StepperRoot>
  );
};

Stepper.defaultProps = defaultProps;

export default Stepper;
