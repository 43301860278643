import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../../hooks/useAxios';

const useRemoveTeamWatch = () => {
  const axios = useAxios();

  const removeTeamWatch = async (teamId: string | undefined) => {
    if (!teamId) return Promise.reject();
    return axios.put(`api/team/${teamId}/remove-watch-warnings`);
  };
  const queryClient = useQueryClient();

  return useMutation((teamId: string | undefined) => removeTeamWatch(teamId), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(['getTeam', variables]);
      queryClient.invalidateQueries(['getTeamsForUser']);
    },
  });
};

export default useRemoveTeamWatch;
