import React, { useState, useEffect } from 'react';
import { Button } from 'baseui/button';
import { ParagraphSmall } from 'baseui/typography';
import useFindContact from 'components/lead/activity/useFindContact';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledAction,
  StyledCaption,
  StyledContent,
  // StyledMessage,
  // StyledPreview,
  StyledDescription,
  StyledMessageContainer,
  StyledTitle,
} from './styled-components';
import useGetLead from '../lead/hooks/useGetLead';
import { Communication } from '../../backend/communication';
import useGetProperties from '../properties/hooks/useGetProperties';
import useNameFromCommunication from '../dashboard/dashboardAlerts/useNameFromCommunication';

type IncomingCallAlertPropsT = {
  details: Communication;
  unassignedFollowup: boolean;
  close: (id?: string) => void;
};

const IncomingCallAlert = ({
  details,
  unassignedFollowup,
  close,
}: IncomingCallAlertPropsT) => {
  const [name, setName] = useState<any>();

  const leadQuery = useGetLead(details.leadRef);

  const lead = leadQuery.data;

  const propertiesQuery = useGetProperties();

  const { reversePhoneLookup } = useFindContact(lead);

  const { lookupName } = useNameFromCommunication('Unknown Contact');

  const properties = propertiesQuery.data;

  const assignedPropertyRef = lead?.assignedProperty?.propertyRef;

  const leadAssignedProperty = properties?.filter(
    (property) => property.id === assignedPropertyRef,
  )[0];

  const { voicemailUrl } = details;

  const contact = reversePhoneLookup(details);

  const nameResult = lookupName(
    details,
    lead?.initialCommunicationDetails,
    lead?.mergedInitialCommunicationDetails,
  );

  const navigate = useNavigate();

  const location = useLocation();

  const openLead = () => {
    close();
    const to = `/leads/${details.leadRef}`;
    if (location.pathname !== to) {
      navigate(to);
    }
    // console.log(campaign, unassignedFollowup);
  };

  useEffect(() => {
    if (contact?.name) {
      setName(contact.name);
      return;
    }
    setName(nameResult);
  }, [contact?.name, nameResult]);

  return (
    <StyledMessageContainer>
      <StyledContent>
        <StyledTitle>Missed Call{voicemailUrl && <span>, Left Voicemail</span>}</StyledTitle>
        {unassignedFollowup && <StyledCaption>Lead still waiting for a response</StyledCaption>}
        {leadAssignedProperty && (
          <ParagraphSmall marginTop="scale100" marginBottom="scale100">
            {leadAssignedProperty.name}
          </ParagraphSmall>
        )}
        <StyledDescription>
          {name} {lead?.tenantName && <span>for {lead.tenantName}</span>}
        </StyledDescription>
        <StyledAction>
          <Button type="button" shape="pill" size="mini" onClick={openLead}>
            Listen
          </Button>
        </StyledAction>
      </StyledContent>
    </StyledMessageContainer>
  );
};

export default IncomingCallAlert;
