import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { IconArrowLeft, IconSettings, IconLogout, IconStatusChange } from '@tabler/icons-react';
import { useCurrentUserContext } from 'context/current-user-context';
import { useNavigate } from 'react-router-dom';
import { StatefulTooltip } from 'baseui/tooltip';
import { Popover, PopoverOverrides } from 'baseui/popover';
import { MenuOverrides, StatefulMenu, StyledListItem } from 'baseui/menu';
import { Button, ButtonOverrides } from 'baseui/button';
import { ParagraphSmall, ParagraphXSmall } from 'baseui/typography';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import ReactFocusLock from 'react-focus-lock';
import Avatar from '../avatar/Avatar';
import useSyncLogoutMutation from '../../hooks/useSyncLogoutMutation';
import SwitchIdentity from './switch-identity';

const popoverOverrides: PopoverOverrides = {
  Body: {
    style: ({ $theme }) => ({
      backgroundColor: 'white',
      boxShadow: $theme.lighting.shadow700,
    }),
  },
  Inner: {
    style: () => ({
      backgroundColor: 'transparent',
      overflow: 'hidden',
    }),
  },
};

const statefulMenuOverrides: MenuOverrides = {
  List: {
    style: {
      boxShadow: 'none',
      paddingTop: 0,
      paddingBottom: 0,
      ':focus': {
        outline: 'none',
      },
    },
  },
  ListItem: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    component: React.forwardRef((props: any, _ref) => (
      <StyledListItem {...props}>
        {props.item.icon}
        <div>{props.children}</div>
      </StyledListItem>
    )),
    style: ({ $theme }) => ({
      display: 'flex',
      alignItems: 'center',
      color: $theme.colors.mono500,
      fontSize: '13px',
      borderRadius: '4px',
      paddingTop: $theme.sizing.scale200,
      paddingBottom: $theme.sizing.scale200,
      paddingLeft: $theme.sizing.scale500,
      paddingRight: $theme.sizing.scale500,
    }),
  },
  MenuDivider: {
    style: ({ $theme }) => ({
      marginTop: $theme.sizing.scale300,
      marginRight: $theme.sizing.scale300,
      marginBottom: $theme.sizing.scale300,
      marginLeft: $theme.sizing.scale300,
    }),
  },
};

const buttonOverrides: ButtonOverrides = {
  BaseButton: {
    style: ({ $theme, $isSelected }) => ({
      backgroundColor: $isSelected ? $theme.colors.mono400 : 'transparent',
      ':hover': {
        backgroundColor: $isSelected ? $theme.colors.mono400 : $theme.colors.mono300,
      },
    }),
  },
};

const UserSettingsMenu = () => {
  const [css, theme] = useStyletron();

  const [slide, setSlide] = useState(false);

  const { mutate } = useSyncLogoutMutation();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = React.useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const currentUser = useCurrentUserContext();

  const close = () => {
    setSlide(false);
    setIsOpen(false);
  };

  const onSlideMenu = () => {
    setSlide(!slide);
  };

  const menu = [
    { id: 'div1', divider: true },
    {
      id: 'SETTINGS',
      label: 'Settings',
      icon: <IconSettings size={16} className={css({ marginRight: theme.sizing.scale100 })} />,
    },
    {
      id: 'IDENTITY',
      label: 'Switch to User',
      icon: <IconStatusChange size={16} className={css({ marginRight: theme.sizing.scale100 })} />,
    },
    { id: 'div2', divider: true },
    {
      id: 'LOGOUT',
      label: 'Log Out',
      icon: <IconLogout size={16} className={css({ marginRight: theme.sizing.scale100 })} />,
    },
  ];

  const handleMenuClick = (selected: any) => {
    if (selected.item.id === 'SETTINGS') {
      navigate('/manage-settings');
      close();
    }
    if (selected.item.id === 'IDENTITY') {
      onSlideMenu();
    }
    if (selected.item.id === 'LOGOUT') {
      mutate();
    }
  };

  return (
    <>
      <Popover
        placement="topLeft"
        accessibilityType="menu"
        onClick={handleClick}
        isOpen={isOpen}
        onClickOutside={close}
        overrides={popoverOverrides}
        content={() => (
          // <div className={css({ width: '290px', overflow: 'hidden' })}>
            <div
              className={css({
                display: 'flex',
                width: '314px',
                alignItems: 'flex-start',
                willChange: 'transform',
                transitionProperty: 'transform',
                transitionDuration: theme.animation.timing200,
                transitionTimingFunction: theme.animation.easeInOutCurve,
                transform: slide ? 'translateX(-100%)' : 'translateX(0)',
              })}
            >
              <div
                className={css({
                  boxSizing: 'border-box',
                  flexGrow: 0,
                  flexShrink: 0,
                  padding: theme.sizing.scale500,
                  width: '314px',
                })}
              >
                <ReactFocusLock disabled={slide}>
                  <div className={css({ paddingLeft: theme.sizing.scale200 })}>
                    <>
                      <ParagraphSmall
                        marginTop="scale0"
                        marginBottom="scale0"
                        $style={{ wordBreak: 'break-all', outline: 'none' }}
                      >
                        {currentUser.name}
                      </ParagraphSmall>
                    </>
                    <ParagraphXSmall
                      marginTop="scale0"
                      color="mono700"
                      $style={{ wordBreak: 'break-all' }}
                    >
                      {currentUser.email}
                    </ParagraphXSmall>
                  </div>

                  <StatefulMenu
                    items={menu}
                    overrides={statefulMenuOverrides}
                    onItemSelect={handleMenuClick}
                  />
                </ReactFocusLock>
              </div>

              <div
                className={css({
                  boxSizing: 'border-box',
                  flexGrow: 0,
                  flexShrink: 0,
                  padding: theme.sizing.scale500,
                  width: '314px',
                })}
              >
                <ReactFocusLock disabled={!slide} focusOptions={{ preventScroll: true }}>
                  <div
                    className={css({
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                    })}
                  >
                    <Button kind="tertiary" size="mini" onClick={onSlideMenu}>
                      <IconArrowLeft size={22} />
                    </Button>
                    <div
                      className={css({
                        flex: 1,
                        ...theme.typography.LabelSmall,
                        textAlign: 'center',
                        textIndent: '-60px',
                      })}
                    >
                      Switch to User
                    </div>
                  </div>

                  <div
                    className={css({
                      ...theme.typography.ParagraphXSmall,
                      marginBottom: theme.sizing.scale200,
                    })}
                  >
                    {currentUser.representingUser?.id
                      ? `You are currently working on behalf of ${currentUser.name}. Click below to return to your account.`
                      : ' Pick a user to assume thier identity. Only users that have granted you permission to work on their behalf are shown.'}
                  </div>
                  <SwitchIdentity onIdentityChange={close} />
                </ReactFocusLock>
              </div>
            </div>
          // </div>
        )}
      >
        <div
          className={css({
            display: 'flex',
            justifyContent: 'center',
            width: '64px',
            height: '36px',
          })}
        >
          <StatefulTooltip
            overrides={tooltipOverrides}
            placement="bottom"
            popoverMargin={8}
            content={() => <div>Account Settings</div>}
          >
            <Button
              overrides={buttonOverrides}
              isSelected={isOpen}
              type="button"
              kind="primary"
              size="compact"
              shape="circle"
            >
              <div className={css({ ...theme.typography.LabelSmall })}>
                <Avatar />
              </div>
            </Button>
          </StatefulTooltip>
        </div>
      </Popover>
    </>
  );
};

export default UserSettingsMenu;
