import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useWebSocketObservable } from 'context/web-socket-context';
import { Ring } from 'components/notifications/styled-components';
import { Communication } from 'backend/communication';
import { LeadChangeDetail, MessageEvent } from 'backend/messages';
import { useMatch } from 'react-router-dom';
import useGetAlerts from '../notifications/useGetAlerts';

const NavigationIconAlertCount = () => {
  const [css, theme] = useStyletron();

  const { data } = useGetAlerts();

  const match = useMatch('/alerts');

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const messages$ = useWebSocketObservable();

  const filteredAlerts = data?.filter((alert) => {
    if (alert.alertType === 'INCOMING_COMMUNICATION') {
      return !alert.lead?.assignedAgentRef;
    }
    return alert;
  });

  const count = filteredAlerts?.length;

  useEffect(() => {
    const sub = messages$.subscribe({
      next: (messageEvent: MessageEvent<Communication | LeadChangeDetail>) => {
        switch (messageEvent.eventType) {
          case 'LEAD_ASSIGNED':
          case 'LEAD_CREATED':
          case 'LEAD_SHARED':
            if (!match?.pathname) {
              setShouldAnimate(true);
            }
            break;
          case 'INCOMING_COMMUNICATION':
            if (messageEvent.unassignedFollowup && !match?.pathname) {
              setShouldAnimate(true);
            }
            break;
          default:
            setShouldAnimate(false);
        }
      },
    });
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [match?.pathname, messages$]);

  useEffect(() => {
    if (match?.pathname && shouldAnimate) {
      setShouldAnimate(false);
    }
  }, [match?.pathname, shouldAnimate]);

  return (
    <div className={css({ position: 'relative' })}>
      <Ring $shouldAnimate={shouldAnimate}>
        <FontAwesomeIcon fixedWidth icon={solid('bell')} />
      </Ring>

      {!!count && count > 0 && (
        <div
          className={css({
            cursor: 'pointer',
            left: '10px',
            pointerEvents: 'none',
            position: 'absolute',
            right: 'auto',
            top: '-8px',
          })}
        >
          <div
            className={css({
              alignItems: 'center',
              background: theme.colors.negative400,
              borderRadius: '50%',
              color: theme.colors.white,
              display: 'flex',
              fontSize: theme.sizing.scale400,
              height: '12px',
              justifyContent: 'center',
              lineHeight: 1,
              padding: '2px',
              width: '12px',
              boxSizing: 'content-box',
            })}
          >
            <>
              {count && count < 100 ? (
                count
              ) : (
                <FontAwesomeIcon fixedWidth icon={solid('exclamation')} />
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavigationIconAlertCount;
