import { useForm, Resolver } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useClient } from 'context/client-context';
import * as schemas from 'components/team/validators/teamSchemas';
import { POLLY_VOICES, POLLY_VOICE_LABEL } from 'components/team/constants';
import {
  UseFormStep1T,
  UseFormStep2T,
  UseFormStep3aT,
  UseFormStep3T,
  UseFormStep4T,
  UseFormStep5T,
  UseFormTeamCalendarT,
  UseFormTeamWarningsT,
} from '../types';

// Broker Client Flow
export const useStepTeamName = (domain?: string | null) => {
  const { state: client } = useClient();

  const { teamTypes } = client;

  const emailDomain = domain || client.emailDomain;

  const validationSchema = schemas.useTeamNameSchema();

  const internalForm = useForm<UseFormStep1T>({
    context: {
      suffix: `@${emailDomain}`,
      supportedTeamTypes: teamTypes,
    },
    defaultValues: {
      teamName: '',
    },
    resolver: yupResolver(validationSchema) as Resolver<UseFormStep1T>,
  });

  return internalForm;
};

// Create BROKER Team Flow
export const useStepTeam = () => {
  const { state: client } = useClient();

  const { emailDomain, teamTypes } = client;

  const validationSchema = schemas.teamLeadSchema.concat(schemas.propertyTypeSchema);

  const internalForm = useForm<UseFormStep2T>({
    context: {
      suffix: `@${emailDomain}`,
      supportedTeamTypes: teamTypes,
    },
    defaultValues: {
      teamLead: [],
      acceptPhoneTransfers: true,
      teamType: undefined,
      propertyTypes: [],
      propertyRef: [],
    },
    resolver: yupResolver(validationSchema) as Resolver<UseFormStep2T>,
  });

  return internalForm;
};

// Create OWNER Team Flow
export const useStepTeamAndProperty = () => {
  const { state: client } = useClient();

  const { emailDomain, teamTypes } = client;

  const validationSchema = schemas.propertyRefSchema
    .concat(schemas.teamLeadSchema)
    .concat(schemas.propertyTypeSchema);

  const internalForm = useForm<UseFormStep2T & { teamName: string }>({
    context: {
      suffix: `@${emailDomain}`,
      supportedTeamTypes: teamTypes,
    },
    defaultValues: {
      teamLead: [],
      teamName: '',
      acceptPhoneTransfers: true,
      teamType: undefined,
      propertyTypes: [],
      propertyRef: [],
    },
    resolver: yupResolver(validationSchema) as Resolver<UseFormStep2T & { teamName: string }>,
  });

  return internalForm;
};

// Broker & Owner Client Flow
export const useStepAssignmentMode = () => {
  const validationSchema = schemas.assignmentModeSchema
    .concat(schemas.lockModeSchema)
    .concat(schemas.lockDurationSchema);

  const internalForm = useForm<UseFormStep3T>({
    defaultValues: {
      assignmentMode: undefined,
      lockMode: undefined,
      lockDuration: '',
    },
    resolver: yupResolver(validationSchema) as Resolver<UseFormStep3T>,
  });

  return internalForm;
};

export const useStepCollaborationMode = () => {
  const internalForm = useForm<UseFormStep3aT>({
    defaultValues: {
      collaborationMode: undefined,
    },
    resolver: yupResolver(schemas.collaborationModeSchema) as Resolver<UseFormStep3aT>,
  });

  return internalForm;
};

export const useStepProvisionEmail = (domain?: string | null) => {
  const { state: client } = useClient();

  const { teamTypes } = client;

  const emailDomain = domain || client.emailDomain;

  const emailSchema = schemas.useEmailSchema();

  const internalForm = useForm<UseFormStep4T & UseFormTeamCalendarT>({
    context: {
      suffix: `@${emailDomain}`,
      supportedTeamTypes: teamTypes,
      emailHosting: client?.emailHosting,
    },
    defaultValues: {
      email: '',
      emailType: 'CREATE',
      hasTeamCalendar: false,
      calendarSameAsEmail: false,
    },
    resolver: yupResolver(emailSchema) as Resolver<UseFormStep4T & UseFormTeamCalendarT>,
  });

  return internalForm;
};

export const useStepConnectCalendar = () => {
  const internalForm = useForm<any>({
    defaultValues: {
      connectedCalendarEmailAddress: '',
    },
    resolver: yupResolver(schemas.connectedCalendarSchema) as Resolver<any>,
  });

  return internalForm;
};

export const useStepProvisionPhone = () => {
  const { state: client } = useClient();

  const { teamTypes } = client;

  const validationSchema = schemas.areaCodeSchema.concat(schemas.phoneSchema);

  const internalForm = useForm<any>({
    context: {
      supportedTeamTypes: teamTypes,
    },
    defaultValues: {
      areaCode: '',
      phone: [],
      phoneType: 'PURCHASE',
      twilioBusinessProfileRef: [],
    },
    resolver: yupResolver(validationSchema) as Resolver<any>,
  });

  return internalForm;
};

export const useStepGreeting = () => {
  const internalForm = useForm<UseFormStep5T>({
    defaultValues: {
      defaultGreeting: {
        text: '',
        greetingRef: [],
        voice: [{ id: POLLY_VOICES[0], label: POLLY_VOICE_LABEL[POLLY_VOICES[0]] }],
        greetingMethod: [{ id: 'NO_GREETING', label: 'None' }],
        greetingPlacement: [{ id: 'BEGINNING', label: 'Beginning' }],
      },
    },
    resolver: yupResolver(schemas.defaultGreetingSchema) as Resolver<UseFormStep5T>,
  });

  return internalForm;
};

export const useStepTeamWarnings = () => {
  const internalForm = useForm<UseFormTeamWarningsT>({
    defaultValues: {
      notificationDelayMins: '',
      warningSettings: {
        useWarnings: false,
        notAssigned: false,
        notAssignedTimePeriod: {
          interval: 1,
          unit: 'DAY',
        },
        notContacted: false,
        notContactedTimePeriod: {
          interval: 1,
          unit: 'DAY',
        },
        communicationNotResponded: false,
        communicationNotRespondedTimePeriod: {
          interval: 1,
          unit: 'DAY',
        },
        notQualified: false,
        notQualifiedTimePeriod: {
          interval: 1,
          unit: 'DAY',
        },
        noActivity: false,
        noActivityTimePeriod: {
          interval: 1,
          unit: 'DAY',
        },
      },
    },
  });
  return internalForm;
};

export const useStepWebChannel = () => {
  const internalForm = useForm<any>({
    defaultValues: {
      pageName: '',
      url: '',
    },
    resolver: yupResolver(schemas.webChannelSchema) as Resolver<any>,
  });

  return internalForm;
};
