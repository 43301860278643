import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import useAxios from './useAxios';

type TwilioNumberT = {
  number: string;
  zip?: string;
  region?: string;
};

type Params = {
  areaCode: string;
  businessProfileId: string | null | undefined;
  count?: string;
};

const regex = /\d{3}/;

function useGetNumbersForPurchase(
  { areaCode, businessProfileId, count = '5' }: Params,
  options?: {},
) {
  const axios = useAxios();

  const getPhoneNumbers = async () => {
    const { data } = await axios.get(
      `/api/phone-numbers/search/${areaCode}?businessProfileId=${businessProfileId}&count=${count}`,
    );
    return data;
  };

  return useQuery<TwilioNumberT[] | undefined, AxiosError>(
    ['getNumbersForPurchase'],
    () => getPhoneNumbers(),
    {
      enabled: regex.test(areaCode),
      refetchOnWindowFocus: false,
      staleTime: 0,
      ...options,
    },
  );
}

export default useGetNumbersForPurchase;
