import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';
import { useStyletron } from 'baseui';
import { ParagraphSmall } from 'baseui/typography';
import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useRouteError, isRouteErrorResponse } from 'react-router-dom';

import {
  StyledErrorRoot,
  StyledErrorInner,
  // StyledButtonContainer,
  StyledErrorTechnicalDetails,
  StyledStackContainer,
  StyledErrorLink,
} from './styled-components';
import { ReactComponent as Logo } from '../assets/logo.svg';

const ErrorBoundary = () => {
  const error = useRouteError();

  Sentry.captureException(error);

  const queryClient = useQueryClient();

  const [css, theme] = useStyletron();

  const navigate = useNavigate();

  const { logout } = useAuth0();

  const goHome = () => {
    navigate('/', { replace: true });
  };

  const processErrorMessage = () => {
    if (axios.isAxiosError(error)) {
      return error.message;
    }

    if (error instanceof Error) {
      return error.message;
    }

    if (isRouteErrorResponse(error)) {
      try {
        const err = JSON.stringify(error.data);
        return err;
      } catch (c) {
        return 'Unknown error';
      }
    }

    return 'Unknown error';
  };

  const message = processErrorMessage();

  const statusCode = (error as unknown as any)?.response?.status;

  React.useEffect(() => {
    queryClient.resetQueries({ queryKey: 'getAuthUser' });
  }, [queryClient]);

  // if (axios.isAxiosError(error)) {
  //   if (error.response?.status === 412) {
  //     return <Navigate to="/company/signup" state={{ redirectTo: pathname }} replace />;
  //   }
  // }

  return (
    <StyledErrorRoot>
      <StyledErrorInner>
        <Logo
          title="SpaceIt"
          className={css({
            width: 'auto',
            height: '40px',
            flex: '0 0 40px',
            // alignSelf: 'start',
            marginLeft: '-6px',
            marginBottom: theme.sizing.scale600,
          })}
        />
        <div>
          <span
            className={css({
              ...theme.typography.ParagraphSmall,
              textTransform: 'uppercase',
            })}
          >
            Error
          </span>
          {statusCode && `: ${statusCode}`}
        </div>
        <h1
          className={css({
            ...theme.typography.HeadingXSmall,
            color: theme.colors.mono900,
            fontWeight: 500,
          })}
        >
          Sorry, something went wrong on our end.
        </h1>
        <p>We&apos;ve notified support.</p>
        <p>
          Try{' '}
          <StyledErrorLink role="button" tabIndex={0} onClick={() => window.location.reload()}>
            reloading
          </StyledErrorLink>{' '}
          this page, return to the{' '}
          <StyledErrorLink role="button" tabIndex={0} onClick={goHome}>
            homepage
          </StyledErrorLink>{' '}
          or{' '}
          <StyledErrorLink role="button" tabIndex={0} onClick={() => logout()}>
            log out
          </StyledErrorLink>{' '}
          to start over.
        </p>

        <ParagraphSmall color="mono900">
          The following techinical details, along with a description of what happened, can be used
          when communicating with SpaceIt support.
        </ParagraphSmall>

        <StyledErrorTechnicalDetails>
          <StyledStackContainer>
            <div className={css({ display: 'inline-block' })}>{message}</div>
            <div>{JSON.stringify(error, null, 2)}</div>
          </StyledStackContainer>
        </StyledErrorTechnicalDetails>
      </StyledErrorInner>

      {/* <StyledButtonContainer>
          <Button type="button" size="mini" kind="primary" onClick={goHome}>
            Home
          </Button>

          <Button type="button" size="compact" onClick={() => window.location.reload()}>
            Reload
          </Button>

          <Button type="button" size="compact" onClick={() => logout()}>
            Log In
          </Button>
        </StyledButtonContainer> */}
    </StyledErrorRoot>
  );
};

export default ErrorBoundary;
