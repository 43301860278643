import React, { useReducer } from 'react';
import { ContactType } from '../../backend/lead';

type ProviderPropsT = {
  children: React.ReactNode;
};

type Action = { type: 'NEW_EMAIL' | 'RESET'; payload?: State };

type Context = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

type State = {
  cc?: string[] | null;
  leadRef?: string;
  subject?: string;
  targetEmailAddress?: string;
  message?: string;
  htmlMessage?: string;
  targetContactRef?: string;
  contactType?: ContactType;
  replyToEmailRef?: string;
  communicationRef?: string;
  name?: string;
  reply?: boolean;
  replyAll?: boolean;
};

const initialState: State = {
  leadRef: '',
  subject: '',
  targetEmailAddress: '',
  message: '',
  htmlMessage: '',
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'NEW_EMAIL':
      return {
        ...action.payload,
      };
    case 'RESET':
      return {
        ...initialState,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const EmailContext = React.createContext({} as Context);

export const EmailContextProvider = ({ children }: ProviderPropsT) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <EmailContext.Provider value={{ state, dispatch }}>{children}</EmailContext.Provider>;
};

export const useEmailReplyConfig = () => {
  const context = React.useContext(EmailContext);

  if (!context) {
    throw new Error('useEmailReplyConfig: missing context');
  }

  const { replyAll, reply } = context.state;

  return { replyAll, reply };
};

export const useEmail = () => {
  const context = React.useContext(EmailContext);

  if (!context) {
    throw new Error('useEmail: missing context');
  }

  const {
    dispatch,
    state: { replyAll, reply, ...props },
  } = context;

  return { setMessage: dispatch, ...props };
};

EmailContext.displayName = 'EmailContext';
