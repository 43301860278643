import '@tiptap/extension-text-style';

import { Extension } from '@tiptap/react';

export type ColorOptions = {
  types: string[];
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    fontStyle: {
      setFontColor: (color: string) => ReturnType;
      setFontSize: (size: string) => ReturnType;
      unsetFontColor: () => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

export const FontStyle = Extension.create<ColorOptions>({
  name: 'fontStyle',

  addOptions() {
    return {
      types: ['font'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          color: {
            default: null,
            parseHTML: (element) => (element as HTMLFontElement || HTMLElement).color?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.color) {
                return {};
              }
              
              return {
                color: `${attributes.color}`,
              };
            },
          },
          size: {
            default: null,
            parseHTML: (element) => (element as HTMLFontElement || HTMLElement).size?.replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes.size) {
                return {};
              }
              
              return {
                size: `${attributes.size}`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontColor:
        (color) =>
          ({ chain }) => chain().setMark('font', { color }).run(),
      setFontSize:
        (size) =>
          ({ chain }) => chain().setMark('font', { size }).run(),
      unsetFontColor:
        () =>
          ({ chain }) => chain().setMark('font', { color: null }).removeEmptyTextStyle().run(),
      unsetFontSize:
        () =>
          ({ chain }) => chain().setMark('font', { size: null }).removeEmptyTextStyle().run(),
    };
  },
});
