import React from 'react';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';

const AppLoadingScreen = ({ bgColor, height = '100vh' }: { bgColor?: string; height?: string }) => {
  const [show, setShow] = React.useState(false);

  const [css, theme] = useStyletron();

  React.useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {show && (
        <div
          className={css({
            flex: '1 1',
            height,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: bgColor ?? theme.colors.mono100,
          })}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};

export default AppLoadingScreen;

AppLoadingScreen.defaultProps = {
  bgColor: undefined,
  height: '100vh',
};
