import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { Radio, RadioGroup, RadioGroupProps, RadioProps } from 'baseui/radio';

type ControlledRadioGroupPropsT = {
  name: string;
  control: any;
  customError?: boolean;
  radioProps?: RadioProps;
  radioGroupProps?: RadioGroupProps;
  handleOnChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  radioOptions: { value: string; displayValue: string | React.ReactNode }[];
};

const ControlledRadioButtons = ({
  name,
  control,
  customError,
  radioProps,
  radioOptions,
  radioGroupProps,
  handleOnChange,
}: ControlledRadioGroupPropsT) => {
  const {
    field: { ref, onChange, ...rest },
    formState: { errors },
  } = useController({ name, control });

  const props = { ...rest, ...radioGroupProps };

  return (
    <RadioGroup
      onChange={(e) => {
        if (handleOnChange) handleOnChange(e);
        onChange(e.target.value);
      }}
      error={!!customError || !!errors[name]}
      {...props}
    >
      {radioOptions.map((option) => (
        <Radio key={`${option.value}_${option.displayValue}`} value={option.value} {...radioProps}>
          {option.displayValue}
        </Radio>
      ))}
    </RadioGroup>
  );
};

export default ControlledRadioButtons;

ControlledRadioButtons.defaultProps = {
  handleOnChange: undefined,
  radioGroupProps: {},
  radioProps: {},
};
