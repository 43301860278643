import { styled } from 'baseui';

export const StyledTopbar = styled('div', ({ $theme }) => ({
  background: $theme.colors.white,
  paddingLeft: $theme.sizing.scale900,
  paddingRight: $theme.sizing.scale900,
  borderBottom: `1px solid ${$theme.colors.mono200}`,
}));

export const StyledListPageContainer = styled('div', () => ({
  isolation: 'isolate', // remove
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const StyledListPageHeaderContainer = styled('div', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale900,
  marginRight: $theme.sizing.scale900,
  marginBottom: $theme.sizing.scale600,
  marginTop: $theme.sizing.scale600,
}));

export const StyledFlexSpaceBetween = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledListPageContentContainer = styled('div', () => ({
  isolation: 'isolate', // remove
}));

export const StyledListPageFooterContainer = styled('div', ({ $theme }) => ({
  minHeight: '32px',
  marginLeft: $theme.sizing.scale900,
  marginRight: $theme.sizing.scale900,
}));

export const StyledSpacer = styled('div', ({ $theme }) => ({
  flex: '0 0 auto',
  ':not(:first-child)': {
    marginLeft: $theme.sizing.scale300,
  },
  ':not(last-child)': {
    marginRight: $theme.sizing.scale300,
  },
}));

export const StyledListPageContent = styled('div', ({ $theme }) => ({
  flex: 1,
  [$theme.mediaQuery.large]: {
    padding: '16px',
  },
}));