export const REDIRECT_TO = 'redirectTo';

export const EVENT_LOGOUT = 'logout';

// A modified Angular email regex to impose 3 lengh minimum for local
// part and only alphanumeric, period, hyphen and underscore
// https://github.com/angular/angular.js/commit/f3f5cf72e
// https://github.com/angular/angular/blob/master/packages/forms/src/validators.ts
export const EMAIL_REGEXP =
  /^(?=.{1,254}$)(?=.{1,64}@)[+a-zA-Z0-9_-]+(?:\.[+a-zA-Z0-9_-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const CLIENTS_WITH_FORM_CAMPAIGN_TYPE = [
  '620b1aca5d0fd87f99091961',
  '6241635969c1033d4687530f',
  '62736220ca34aa933a3a1c47',
  '636b0f5a7213ff28fbf9b5b4',
];

// Clients requiring textingAllowed on WEBFORM
export const TEXT_OPT_IN_REQUIRED = ['65657808ca2677528422fd03'];

// DE with additional lead and search criteria fields
export const CLIENT_DE_ALT_FIELDS = [
  '650d07e6d96cae4260da9f5b',
  '65657808ca2677528422fd03',
  '61f89d6c691068addc7ea042', // stage
];

export const PAST_DATE_FILTER_OPTIONS = [
  // { id: 'ALL', label: 'All Dates' },
  { id: 'TODAY', label: 'Today' },
  { id: 'YESTERDAY', label: 'Yesterday' },
  { id: 'PAST_WEEK', label: 'Past Week' },
  { id: 'PAST_MONTH', label: 'Past Month' },
  { id: 'CUSTOM', label: 'Custom Range' },
];

export const ELLIMAN_CUSTOM_DATE_FILTER_OPTIONS = [
  { id: '', label: 'All Time' },
  { id: 'TODAY', label: 'Today' },
  { id: 'YESTERDAY', label: 'Yesterday' },
  { id: 'LAST_WEEK', label: 'Last Week' },
  { id: 'MTD', label: 'Month to Date' },
  { id: 'LAST_MONTH', label: 'Last Month' },
  { id: 'YTD', label: 'Year to date' },
  { id: 'CUSTOM', label: 'Custom Range' },
];

export const FUTURE_DATE_FILTER_OPTIONS = [
  { id: 'TODAY', label: 'Immediately' },
  { id: 'TOMORROW', label: 'Tomorrow' },
  { id: 'THIS_WEEK', label: 'This Week' },
  { id: 'THIS_MONTH', label: 'This Month' },
  { id: 'CUSTOM', label: 'Custom' },
];

export const MOVE_IN_RANGE = [
  { id: '', label: 'Select' },
  { id: '0-30 Days', label: '0-30 Days' },
  { id: '30-60 Days', label: '30-60 Days' },
  { id: '60-90 Days', label: '60-90 Days' },
  { id: '90 + Days', label: '90 + Days' },
];
