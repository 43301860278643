import { concatName } from 'helpers/util';
import { Communication } from '../../backend/communication';

export const getCommunicationPrefillData = (comm: Communication | undefined) => {
  const communicationType = comm?.communicationType;

  const callerIdName = concatName(comm?.callerId?.firstName, comm?.callerId?.lastName);

  switch (communicationType) {
    case 'CALL': {
      return {
        name: callerIdName,
        targetPhoneNumber: comm?.calledFromNumber,
      };
    }
    case 'TEXT_MESSAGE': {
      return {
        name: callerIdName,
        targetPhoneNumber: comm?.textedFromNumber,
      };
    }
    case 'EMAIL':
      return {
        name: comm?.name,
        targetPhoneNumber: comm?.phoneNumber,
        targetEmailAddress: comm?.fromAddress,
      };
    case 'WEBFORM': {
      return {
        name: comm?.name,
        targetPhoneNumber: comm?.phoneNumbers?.[0],
        targetEmailAddress: comm?.fromAddress,
      };
    }
    default: {
      return {
        name: '',
        targetPhoneNumber: '',
      };
    }
  }
};
