import { AxiosError } from 'axios';
import { useStyletron } from 'baseui';
import { Spinner } from 'baseui/spinner';
import { useClientStore } from 'core/client-store';
import useGetClient from 'hooks/useGetClient';
import React from 'react';
import { Client } from '../backend/client';
import { useCurrentUserContext } from './current-user-context';
import {
  CLIENTS_WITH_FORM_CAMPAIGN_TYPE,
  CLIENT_DE_ALT_FIELDS,
  TEXT_OPT_IN_REQUIRED,
} from '../constants';

type Provider = { children: React.ReactNode };

const ClientContext = React.createContext<Client | undefined>(undefined);

function ClientProvider({ children }: Provider) {
  const [css] = useStyletron();

  const { clientRef } = useCurrentUserContext();

  const query = useGetClient(clientRef, { staleTime: 60 * 1000 * 5 });

  const update = useClientStore((s) => s.updater);

  if (query.isLoading) {
    return (
      <Spinner
        className={css({
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: '-20px',
          marginTop: '-20px',
        })}
      />
    );
  }

  if (query.isError) {
    const err = query.error;
    throw new Error((err as AxiosError).message);
  }

  if (query.isSuccess) {
    update({
      compactList: CLIENT_DE_ALT_FIELDS.includes(query.data.id),
      teamTourVisibility: CLIENT_DE_ALT_FIELDS.includes(query.data.id),
      webformTextStrict: TEXT_OPT_IN_REQUIRED.includes(query.data.id),
    });
  }

  return <ClientContext.Provider value={query.data}>{children}</ClientContext.Provider>;
}

function useClient() {
  const client = React.useContext(ClientContext);

  if (!client) {
    throw new Error('useClient: No value provide or not called within a ClientProvider');
  }

  const teamType = client.supportedTeamTypes[0];

  const isOwner = teamType === 'OWNER';

  const isBroker = teamType === 'BROKER';

  const featureCampaignTypes = CLIENTS_WITH_FORM_CAMPAIGN_TYPE.indexOf(client.id) !== -1;

  const displayValue = isBroker ? 'Team' : 'Property';

  const displayValueAlt = isBroker ? 'Teams' : 'Properties';

  const state = {
    ...client,
    clientRef: client.id,
    displayValue,
    displayValueAlt,
    isOwner,
    isBroker,
    teamType,
    propertyTypes: client.supportedPropertyTypes,
    teamTypes: client.supportedTeamTypes,
    featureCampaignTypes,
  };

  return { state };
}

export { ClientProvider, useClient };
