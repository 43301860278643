import React, { useEffect, useState } from 'react';
import { useStyletron } from 'baseui';
import { Popover } from 'baseui/popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMatch } from 'react-router-dom';
import { useWebSocketObservable } from '../../context/web-socket-context';
import { Communication } from '../../backend/communication';
import { LeadChangeDetail, MessageEvent } from '../../backend/messages';
import WrappedButton from './WrappedButton';
import useGetAlerts from './useGetAlerts';
import DashboardAlerts from './DashboardAlerts';

const NotificationsButton = () => {
  const [css, theme] = useStyletron();

  const ref = React.useRef();

  const match = useMatch('/alerts');

  const localRef = React.useRef<any>();

  const countWrapperRef = React.useRef<any>();

  const { data } = useGetAlerts();

  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = () => setIsOpen(!isOpen);

  const messages$ = useWebSocketObservable();

  const [shouldAnimate, setShouldAnimate] = useState(false);

  const filteredAlerts = data?.filter((alert) => {
    if (alert.alertType === 'INCOMING_COMMUNICATION') {
      return !alert.lead?.assignedAgentRef;
    }
    return alert;
  });

  const count = filteredAlerts?.length;

  useEffect(() => {
    // const eventTypes = ['INCOMING_COMMUNICATION', 'LEAD_ASSIGNED', 'LEAD_CREATED', 'LEAD_SHARED'];
    const sub = messages$.subscribe({
      next: (messageEvent: MessageEvent<Communication | LeadChangeDetail>) => {
        switch (messageEvent.eventType) {
          case 'LEAD_ASSIGNED':
          case 'LEAD_CREATED':
          case 'LEAD_SHARED':
            if (!match?.pathname) {
              setShouldAnimate(true);
            }
            break;
          case 'INCOMING_COMMUNICATION':
            if (messageEvent.unassignedFollowup) {
              if (!match?.pathname) {
                setShouldAnimate(true);
              }
            }
            break;
          default:
            setShouldAnimate(false);
        }
      },
    });
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [match?.pathname, messages$]);

  useEffect(() => {
    if (match?.pathname && shouldAnimate) {
      setShouldAnimate(false);
    }
  }, [match?.pathname, shouldAnimate]);

  return (
    <div className={css({ position: 'relative', flex: 0 })}>
      <Popover
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onClickOutside={() => {
          setIsOpen(false);
        }}
        placement="topRight"
        content={() => (
          <DashboardAlerts
            swipeableRef={ref}
            setShouldAnimate={setShouldAnimate}
            close={togglePopover}
          />
        )}
      >
        <WrappedButton shouldAnimate={shouldAnimate} localRef={localRef} />
      </Popover>
      {!!count && count > 0 && (
        <div
          role="button"
          tabIndex={-1}
          onClick={togglePopover}
          onKeyDown={() => {}}
          className={css({
            position: 'absolute',
            top: '3px',
            right: 'auto',
            left: '-8px',
            cursor: 'pointer',
            pointerEvents: 'none',
          })}
        >
          <div
            ref={countWrapperRef}
            className={css({
              width: '12px',
              height: '12px',
              padding: '4px',
              color: 'white',
              lineHeight: 1,
              display: 'flex',
              borderRadius: '50%',
              alignItems: 'center',
              justifyContent: 'center',
              border: '2px solid #fff',
              fontSize: theme.sizing.scale400,
              background: theme.colors.negative400,
              boxSizing: 'content-box',
            })}
          >
            <>
              {count && count.toString().length < 3 ? (
                count
              ) : (
                <FontAwesomeIcon size="lg" icon={solid('exclamation')} />
              )}
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationsButton;
