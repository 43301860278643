import { styled } from 'baseui';

export const StyledStackingContext = styled('div', ({ $theme }) => ({
  backgroundColor: 'white',
  borderRadius: $theme.sizing.scale100,
  height: 'calc(100vh - 100px)',
  overflow: 'auto',
  width: '375px',
  [$theme.mediaQuery.medium]: {
    width: '540px',
  },
}));

export const StyledListHeader = styled('div', ({ $theme }) => ({
  top: 0,
  zIndex: 1,
  display: 'flex',
  background: '#fff',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  borderBottom: `1px solid ${$theme.colors.mono200}`,
}));

export const StyledActionOuter = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
}));

export const StyledNotificationBubble = styled('div', ({ $theme }) => ({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...$theme.typography.ParagraphXSmall,
  fontSize: '14px',
  margin: `0 ${$theme.sizing.scale300}`,
  color: $theme.colors.white,
  background: $theme.colors.negative400,
  padding: `${$theme.sizing.scale100}`,
  width: '24px',
  height: '24px',
}));

export const StyledList = styled('ul', () => ({
  marginTop: '0px',
  marginBottom: '16px',
  padding: 0,
}));

export const StyledListItem = styled<any, { $isInvalidated: boolean }>(
  'li',
  ({ $theme, $isInvalidated }) => ({
    listStyle: 'none',
    minHeight: '64px',
    position: 'relative',
    color: $isInvalidated ? $theme.colors.mono600 : 'inherit',
    padding: `${$theme.sizing.scale600} ${$theme.sizing.scale600}`,
    ':not(:last-child)': {
      borderBottom: `1px solid ${$theme.colors.mono200}`,
    },
    ':hover': {
      // color: $theme.colors.accent300
      // boxShadow: `inset 4px 0px 0px 0px ${$theme.colors.mono400}`,
      backgroundColor: $theme.colors.mono100,
    },
  }),
);

export const StyledInner = styled('div', () => ({
  display: 'flex',
}));

export const StyledListItemIcon = styled('div', ({ $theme }) => ({
  width: '20px',
  height: '20px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: $theme.sizing.scale100,
}));

export const StyledListItemContent = styled('div', ({ $theme }) => ({
  flex: 1,
  alignItems: 'center',
  ':hover': {
    cursor: 'pointer',
    color: $theme.colors.primary400,
  },
}));

export const StyledDate = styled('div', ({ $theme }) => ({
  fontWeight: 400,
  flex: '1 0 auto',
  color: $theme.colors.mono600,
}));

export const StyledEventHeader = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  ...$theme.typography.LabelSmall,
  lineHeight: '20px',
}));

export const StyledEventType = styled<'div', { $isLeadTaken: boolean }>(
  'div',
  ({ $theme, $isLeadTaken }) => ({
    flex: '0 0 auto',
    // cursor: 'pointer',
    ...$theme.typography.LabelSmall,
    fontWeight: 600,
    // marginTop: $theme.sizing.scale0,
    marginRight: $theme.sizing.scale100,
    textDecorationLine: $isLeadTaken ? 'line-through' : 'none',
  }),
);

export const StyledEventBody = styled('div', ({ $theme }) => ({
  display: 'inline-block',
  color: $theme.colors.mono600,
  ...$theme.typography.ParagraphSmall,
  marginTop: $theme.sizing.scale100,
  marginBottom: $theme.sizing.scale100,
}));

const ring: any = {
  '0%': {
    transform: 'rotate(-15deg)',
  },
  '2%': {
    transform: 'rotate(15deg)',
  },
  '12%,4%': {
    transform: 'rotate(-18deg)',
  },
  '14%,6%': {
    transform: 'rotate(18deg)',
  },
  '8%': {
    transform: 'rotate(-22deg)',
  },
  '10%': {
    transform: 'rotate(22deg)',
  },
  '16%': {
    transform: 'rotate(-12deg)',
  },
  '18%': {
    transform: 'rotate(12deg)',
  },
  '100%,20%': {
    transform: 'rotate(0deg)',
  },
};

export const Ring = styled<'div', { $shouldAnimate: boolean }>(
  'div',
  ({ $shouldAnimate, $theme }) => ({
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationName: $shouldAnimate ? ring : 'none',
    color: $shouldAnimate ? $theme.colors.mono200 : 'inherit',
  }),
);
