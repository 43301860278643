import { customUseStyletron as useStyletron } from '~/theme';
import UserSettingsMenu from 'components/me/UserSettingsMenu';
import { useSideMenuContext } from 'context/SideMenuContext';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import * as Styled from './styles/styled-components';
// import smallLogo from '../../assets/icon-152x152.png';

const SidebarHeader = () => {
  const [css, theme] = useStyletron();

  const { isExpanded } = useSideMenuContext();

  return (
    <Styled.SidebarHeaderContainer $isExpanded={isExpanded}>
      <Logo
        className={css({
          width: '80px',
          height: 'auto',
          flexShrink: 0,
          marginLeft: '16px',
          display: 'none',
          [theme.mediaQuery.large]: {
            display: isExpanded ? 'block' : 'none',
          },
        })}
      />
      <UserSettingsMenu />
    </Styled.SidebarHeaderContainer>
  );
};

export default SidebarHeader;
