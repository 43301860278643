import React from 'react';
import { FormControl } from 'baseui/form-control';
import { useFormContext } from 'react-hook-form';
import { StatefulTooltip } from 'baseui/tooltip';
import { MAX_TEXT_AREA_LENGTH } from 'shared/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import useGetGreetings from '../hooks/useGetGreetings';
import ControlledTextArea from './ControlledTextArea';
import ControlledSelect from './ControlledSelect';
import { POLLY_VOICES, POLLY_VOICE_LABEL } from '../constants';
import { StyledToolTip, StyledToolTipInfo } from '../styled-components';

const GreetingSelectField = ({ name, includeDefault }: { name: string; includeDefault?: boolean }) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const $name = watch(name as any);

  const greetingDataService = useGetGreetings({ refetchOnWindowFocus: false });

  const options = [
    { id: 'NO_GREETING', label: 'None' },
    { id: 'PLAY_RECORDING', label: 'Play Recording' },
    { id: 'READ_TEXT', label: 'Read Text' },
  ];

  if (includeDefault) {
    options.splice(1, 0, { id: 'USE_DEFAULT', label: 'Team Default' });
  }

  const showGreetingPlacement =
    $name.greetingMethod?.[0]?.id === 'PLAY_RECORDING' ||
    $name.greetingMethod?.[0]?.id === 'READ_TEXT';

  const label = includeDefault ? 'Greeting' : 'Default Greeting';

  const resetDetails = () => {
    clearErrors(name as any);
    setValue(`${name}.text` as any, '');
  };
  return (
    <>
      {/* {JSON.stringify($name)} */}
      <FormControl
        label={
          <>
            <span>{label}</span>
            <StatefulTooltip
              content={() => (
                <StyledToolTip>
                  {' '}
                  <>
                    <strong>None</strong> - No greeting.
                    <br />
                    <strong>Play Recording</strong> - Use a saved audio file.
                    <br />
                    <strong>Read Text</strong> - Write your own script.
                  </>
                </StyledToolTip>
              )}
            >
              <StyledToolTipInfo>
                <FontAwesomeIcon icon={solid('circle-info')} />
              </StyledToolTipInfo>
            </StatefulTooltip>
          </>
        }
        error={(errors?.[name] as any)?.greetingMethod?.message}
      >
        <ControlledSelect
          control={control}
          name={`${name}.greetingMethod`}
          errPath={(errors?.[name] as any)?.greetingMethod}
          onChangeHandler={resetDetails}
          baseuiProps={{
            size: 'compact',
            clearable: false,
            searchable: false,
            options,
          }}
        />
      </FormControl>

      {showGreetingPlacement && (
        <FormControl
          label={
            <>
              <span>Greeting Placement</span>
              <StatefulTooltip
                content={() => (
                  <StyledToolTip>
                    {' '}
                    Allows you to set when the greeting is played, if present.
                    <>
                      <br />
                      <strong>Beginning</strong> (default) - Play at the begininng of call or when
                      no one answers.
                      <br />
                      <strong>End</strong> - Play at end, caller can skip the greeting and go
                      directly to voicemail.
                      <br />
                    </>
                  </StyledToolTip>
                )}
              >
                <StyledToolTipInfo>
                  <FontAwesomeIcon icon={solid('circle-info')} />
                </StyledToolTipInfo>
              </StatefulTooltip>
            </>
          }
        >
          <ControlledSelect
            control={control}
            name={`${name}.greetingPlacement`}
            baseuiProps={{
              clearable: false,
              searchable: false,
              size: 'compact',
              options: [
                { id: 'BEGINNING', label: 'Beginning' },
                { id: 'END', label: 'End' },
              ],
            }}
          />
        </FormControl>
      )}

      {$name.greetingMethod?.[0]?.id === 'READ_TEXT' && (
        <>
          <FormControl
            caption={`${$name.text?.length}/${MAX_TEXT_AREA_LENGTH}`}
            label="Greeting Script"
            error={(errors?.[name] as any)?.text?.message}
          >
            <ControlledTextArea
              control={control}
              name={`${name}.text`}
              errPath={(errors?.[name] as any)?.text}
            />
          </FormControl>

          <FormControl label="Voice" error={(errors?.[name] as any)?.voice?.message}>
            <ControlledSelect
              control={control}
              name={`${name}.voice`}
              errPath={(errors?.[name] as any)?.voice}
              baseuiProps={{
                size: 'compact',
                clearable: false,
                options: POLLY_VOICES.map((voice) => ({
                  id: voice,
                  label: POLLY_VOICE_LABEL[voice],
                })),
              }}
            />
          </FormControl>
        </>
      )}

      {$name.greetingMethod?.[0]?.id === 'PLAY_RECORDING' && (
        <>
          <FormControl
            label="Saved Audio Greetings"
            error={(errors?.[name] as any)?.greetingRef?.message}
          >
            <ControlledSelect
              control={control}
              name={`${name}.greetingRef`}
              errPath={(errors?.[name] as any)?.greetingRef}
              baseuiProps={{
                size: 'compact',
                maxDropdownHeight: '25vh',
                isLoading: greetingDataService.isLoading,
                options: !greetingDataService.data
                  ? []
                  : greetingDataService.data.map((opt: any) => ({
                    label: opt.name,
                    id: opt.id,
                  })),
              }}
            />
          </FormControl>
        </>
      )}
    </>
  );
};

GreetingSelectField.defaultProps = {
  includeDefault: false,
};

export default GreetingSelectField;
