// import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import sentryRouter from 'router/sentry-router';
import { BaseProvider } from 'baseui';
import { RouterProvider } from 'react-router-dom';
import { engine } from 'core/styletron-engine';
import queryClient from 'core/query-client';
import { QueryClientProvider } from 'react-query';
import { Provider as StyletronProvider } from 'styletron-react';
import reportWebVitals from './reportWebVitals';
import { customTheme } from './theme';
import '@fontsource-variable/inter';
import './index.css';
// import '../styled-system/styles.css';

// console.log(`BASE_URL:${import.meta.env.BASE_URL}`);
// console.log(`ENV:${import.meta.env.PROD}`);
// console.log(`MODE:${import.meta.env.MODE}`);

const container = document.getElementById('root');

const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <StyletronProvider value={engine}>
      <BaseProvider
        theme={customTheme}
        overrides={{
          AppContainer: {
            style: () => ({
              minHeight: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
            }),
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={sentryRouter} />
        </QueryClientProvider>
      </BaseProvider>
    </StyletronProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
