import { useStyletron } from 'baseui';
import useHover from 'hooks/useHover';
import React from 'react';
import * as Styled from './styled-components';
import { NavigationItemLinkProps } from './types/navigation';

const NavigationItemLink = React.forwardRef((props: NavigationItemLinkProps, forwardedRef: any) => {
  const { callbackRef: ref, value: isHovered }  = useHover();

  // Popover props are forwarded, included onClick!
  const { children, to, eventHandler, isDefault, ...fProps } = props;

  const [, theme] = useStyletron();

  if (!props.to) {
    return (
      <Styled.Anchor ref={ref} $as="a" onClick={eventHandler}>
        <Styled.PopoverAnchor ref={forwardedRef} {...fProps}>
          {children({ isHovered })}
        </Styled.PopoverAnchor>
      </Styled.Anchor>
    );
  }

  return (
    <Styled.Anchor
      ref={ref}
      style={({ isActive }: any) => {
        const on = isActive || isDefault;
        return {
          color: on ? theme.colors.white : theme.colors.mono500,
        };
      }}
      onClick={eventHandler}
      to={to as string}
    >
      {(navLinkProps: any) => (
        <Styled.PopoverAnchor ref={forwardedRef} {...fProps}>
          {children({ isHovered, ...navLinkProps })}
        </Styled.PopoverAnchor>
      )}
    </Styled.Anchor>
  );
});

export default NavigationItemLink;
