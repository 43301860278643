import React, { useReducer, useContext } from 'react';
import { NavStateContext, NavUpdateContext } from '../types/navigation';
import reducer from '../utils/navStateReducer';

const NavigationStateContext = React.createContext({} as NavStateContext);

const NavigationUpdateContext = React.createContext({} as NavUpdateContext);

NavigationStateContext.displayName = 'NavigationContext';

function NavigationProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, { status: [], filters: { team: '' } });

  return (
    <NavigationStateContext.Provider value={{ state }}>
      <NavigationUpdateContext.Provider value={{ dispatch }}>
        {children}
      </NavigationUpdateContext.Provider>
    </NavigationStateContext.Provider>
  );
}

const useNavigationState = () => {
  const context = useContext(NavigationStateContext);

  if (context === undefined) {
    throw new Error('useNavigationState was used outside of its Provider');
  }
  return context;
};

const useNavigationUpdater = () => {
  const context = useContext(NavigationUpdateContext);

  if (context === undefined) {
    throw new Error('useNavigationUpdater was used outside of its Provider');
  }
  return context;
};

export { NavigationProvider, useNavigationState, useNavigationUpdater };
