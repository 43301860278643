import React from 'react';
import { PatternFormat } from 'react-number-format';
import { useClient } from '../../../context/client-context';
import {
  TEAM_ASSIGNMENT_MODE,
  TEAM_LOCK_MODE,
  TEAM_COLLABORATION_MODE,
} from '../../../components/team/constants';
import { StyledItem, StyledItemLabel, StyledItemValue } from './styled-components';
// import { TEAM_ASSIGNMENT_MODE, TEAM_COLLABORATION_MODE, TEAM_LOCK_MODE } from '../constants';

type FormStepPropsT = {
  getAllValues: () => any;
  emailDomain?: string | null;
};

const CreateTeamReviewStep = (props: FormStepPropsT) => {
  const { emailDomain: domain, getAllValues } = props;

  const { state: clientState } = useClient();

  const emailDomain = domain || clientState.emailDomain;

  const values = getAllValues();

  const phoneLabel = Array.isArray(values.phone) ? values.phone?.[0]?.label : values.phone;

  return (
    <>
      {clientState?.isBroker && (
        <StyledItem>
          <StyledItemLabel>Team Name</StyledItemLabel>
          <StyledItemValue>{values.teamName}</StyledItemValue>
        </StyledItem>
      )}
      {/* Property name is the team name */}
      {clientState?.isOwner && (
        <StyledItem>
          <StyledItemLabel>Property</StyledItemLabel>
          <StyledItemValue>{values.teamName}</StyledItemValue>
        </StyledItem>
      )}
      <StyledItem>
        <StyledItemLabel>Team Leader</StyledItemLabel>
        <StyledItemValue>{values.teamLead?.[0].label}</StyledItemValue>
      </StyledItem>
      <StyledItem>
        <StyledItemLabel>Include Team Leader in Phone Transfers</StyledItemLabel>
        <StyledItemValue>{values.acceptPhoneTransfers === 'true' ? 'Yes' : 'No'}</StyledItemValue>
      </StyledItem>
      <StyledItem>
        <StyledItemLabel>Property Types</StyledItemLabel>
        <StyledItemValue>{values.propertyTypes?.map((v: any) => v).join(', ')}</StyledItemValue>
      </StyledItem>

      <StyledItem>
        <StyledItemLabel>Assignment Mode</StyledItemLabel>
        <StyledItemValue>{(TEAM_ASSIGNMENT_MODE as any)[values.assignmentMode]}</StyledItemValue>
      </StyledItem>
      {values.assignmentMode === 'CLAIM' && (
        <>
          <StyledItem>
            <StyledItemLabel>LockMode</StyledItemLabel>
            <StyledItemValue>{(TEAM_LOCK_MODE as any)[values.lockMode]}</StyledItemValue>
          </StyledItem>
          {values.lockMode === 'TIMED' && (
            <StyledItem>
              <StyledItemLabel>Lock Duration</StyledItemLabel>
              <StyledItemValue>{values.lockDuration} days</StyledItemValue>
            </StyledItem>
          )}
        </>
      )}
      <StyledItem>
        <StyledItemLabel>Collaboration Mode</StyledItemLabel>
        <StyledItemValue>
          {(TEAM_COLLABORATION_MODE as any)[values.collaborationMode]}
        </StyledItemValue>
      </StyledItem>

      <StyledItem>
        <StyledItemLabel>Email</StyledItemLabel>
        <StyledItemValue>
          <StyledItemValue>{`${values.email}@${emailDomain}`}</StyledItemValue>
        </StyledItemValue>
      </StyledItem>
      <StyledItem>
        <StyledItemLabel>Phone</StyledItemLabel>
        <StyledItemValue>
          <PatternFormat displayType="text" value={phoneLabel} format="+# (###) ###-####" />
        </StyledItemValue>
      </StyledItem>

      <StyledItem>
        <StyledItemLabel>Default Greeting Method</StyledItemLabel>
        <StyledItemValue>{values.defaultGreeting?.greetingMethod?.[0].label}</StyledItemValue>
      </StyledItem>

      {values.defaultGreeting?.greetingMethod?.[0]?.id === 'READ_TEXT' && (
        <StyledItem>
          <StyledItemLabel>Greeting Voice</StyledItemLabel>
          <StyledItemValue>{values.defaultGreeting?.voice?.[0].label}</StyledItemValue>
        </StyledItem>
      )}

      {values.url && (
        <>
          <StyledItem>
            <StyledItemLabel>Web</StyledItemLabel>
            <StyledItemValue>{values.url}</StyledItemValue>
          </StyledItem>
        </>
      )}

      <StyledItem>
        <StyledItemLabel>Notifications</StyledItemLabel>
        <StyledItemValue>{values.warningSettings?.useWarnings ? 'On' : 'Off'}</StyledItemValue>
      </StyledItem>
    </>
  );
};

export default CreateTeamReviewStep;
