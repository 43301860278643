import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useStyletron } from 'baseui';
import { Button, ButtonOverrides } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import useFindContact from 'components/lead/activity/useFindContact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import { useClient } from 'context/client-context';
import { concatName, decodeParameter } from 'helpers/util';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTwilio } from 'context/twilio/TwilioProvider';
import { useCallProgressContext } from 'context/call/CallProgressProvider';
import SpeakerDevices from 'context/twilio/SpeakerDevices';
import {
  StyledCallHeaderRoot,
  StyledContent,
  StyledVoipControlContainer,
  StyledControlContainer,
  StyledControlGroup,
  StyledTitle,
} from './styled-components';
import useGetLead from '../lead/hooks/useGetLead';
import MoveCommunication from './MoveCommunication';
import useCallProgressSubscription from '../alerts/useCallProgressSubscription';

const buttonOverrides: ButtonOverrides = {
  BaseButton: {
    style: ({ $theme }) => ({
      marginRight: $theme.sizing.scale100,
    }),
  },
  StartEnhancer: {
    style: { fontSize: '16px' },
  },
};

const CallHeader = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const nodeRef = useRef<any>();

  const { state: client } = useClient();

  const { answer, rejectCall, hangup, isMuted, mute, callStatus, call } = useTwilio();

  const [css, theme] = useStyletron();

  const onVoip = callStatus && callStatus !== 'closed';

  const [name, setName] = useState<any>();

  const [toggle, setToggle] = useState(false);

  const {
    dispatcEvent,
    message: { details, event },
    reset,
  } = useCallProgressSubscription();

  const leadRef = details?.leadRef;

  const communicationId = details?.id;

  const { data: lead } = useGetLead(leadRef);

  const { reversePhoneLookup } = useFindContact(lead);

  const contact = reversePhoneLookup(details);

  const mergedCommunicationDetail = lead?.mergedInitialCommunicationDetails?.filter(
    (mc) => mc.phoneNumber === details?.calledFromNumber,
  )[0];

  const fallback = mergedCommunicationDetail
    ? mergedCommunicationDetail?.name
    : lead?.initialCommunicationDetails?.name;

  const customInboundParams = call?.parameters;

  const callerName = decodeParameter(customInboundParams?.Params);

  const callerIdName = concatName(details?.callerId?.firstName, details?.callerId?.lastName);

  const contactName = contact?.name || callerName || callerIdName || fallback || 'New Lead';

  const {
    cancelTimer,
    endTracking,
    isBusy,
    data: communication,
    setIsBusy,
  } = useCallProgressContext();

  const open = useCallback(() => {
    setToggle(true);
  }, []);

  const close = useCallback(() => {
    setToggle(false);
  }, []);

  useEffect(() => {
    if (isBusy) {
      open();
    }
  }, [isBusy, open]);

  useEffect(() => {
    // !isBusy && toggle
    if (!isBusy) {
      console.log('close not busy');
      close();
    }
  }, [close, isBusy]);

  useEffect(() => {
    if (contactName) {
      setName(contactName);
    }
  }, [contactName]);

  useEffect(() => {
    if (
      communication?.callStatus === 'IN_PROGRESS' &&
      !details &&
      client?.outgoingCallMethod === 'DIALBACK'
    ) {
      console.log('Update event communication');
      dispatcEvent({ details: communication });
      open();
    }
  }, [communication, details, open, dispatcEvent, client?.outgoingCallMethod]);

  useEffect(() => {
    // Event emitted call detected > start busy state
    if (event === 'OUTGOING_CALL_INITIATED' || event === 'CALL_TRANSFERRED') {
      console.log('init or xfer call');
      setIsBusy(true);
      cancelTimer();
    }
  }, [cancelTimer, event, setIsBusy]);

  useEffect(() => {
    // Event emitted call completed > end busy state and clear event
    if (
      (!callStatus || callStatus === 'closed') &&
      (event === 'OUTGOING_CALL_COMPLETED' || event === 'CALL_COMPLETED')
    ) {
      console.log('outbound/inbound complete');
      endTracking();
      reset();
    }
  }, [callStatus, endTracking, event, reset]);

  // if (!renderAll) {
  //   return null;
  // }

  return (
    <>
      <StyledCallHeaderRoot ref={nodeRef} data-si="call" $isActive={toggle}>
        <StyledContent>
          <StyledControlContainer>
            <div className={css({ minHeight: '28px' })}>
              <StyledTitle>Call in Progress:</StyledTitle>
              <span
                className={css({
                  verticalAlign: 'baseline',
                  ...theme.typography.LabelSmall,
                })}
              >
                {name}
              </span>
            </div>
            <StyledControlGroup>
              {leadRef && location.pathname !== `/leads/${leadRef}` && (
                <StatefulTooltip content="View lead detail" overrides={tooltipOverrides}>
                  <Button
                    kind="tertiary"
                    size="mini"
                    startEnhancer={<FontAwesomeIcon icon={solid('up-right-from-square')} />}
                    onClick={() => {
                      navigate(`/leads/${leadRef}`);
                    }}
                    overrides={buttonOverrides}
                  >
                    View
                  </Button>
                </StatefulTooltip>
              )}
              <MoveCommunication leadId={leadRef} id={communicationId} />
            </StyledControlGroup>
          </StyledControlContainer>
          {onVoip && (
            <StyledVoipControlContainer>
              <Button
                size="mini"
                type="button"
                onClick={mute}
                kind="tertiary"
                overrides={buttonOverrides}
                startEnhancer={
                  isMuted ? (
                    <FontAwesomeIcon icon={solid('microphone-slash')} />
                  ) : (
                    <FontAwesomeIcon icon={solid('microphone')} />
                  )
                }
              >
                {isMuted ? <>Unmute</> : <>Mute</>}
              </Button>
              <SpeakerDevices />

              <Button
                size="mini"
                type="button"
                onClick={callStatus === 'pending' ? rejectCall : hangup}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      marginRight: $theme.sizing.scale100,
                      backgroundColor: $theme.colors.negative300,
                      ':hover': {
                        backgroundColor: $theme.colors.negative400,
                      },
                    }),
                  },
                  StartEnhancer: {
                    style: { fontSize: '16px' },
                  },
                }}
                startEnhancer={
                  <FontAwesomeIcon icon={solid('phone')} transform={{ rotate: 135 }} />
                }
              >
                {callStatus === 'pending' ? 'Reject Call' : 'End Call'}
              </Button>

              {/* <Button
                size="mini"
                type="button"
                onClick={rejectCall}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      backgroundColor: $theme.colors.negative300,
                      ':hover': {
                        backgroundColor: $theme.colors.negative400,
                      },
                    }),
                  },
                  StartEnhancer: {
                    style: { fontSize: '16px' },
                  },
                }}
                startEnhancer={
                  <FontAwesomeIcon icon={solid('phone')} transform={{ rotate: 135 }} />
                }
              >
                Reject
              </Button> */}

              <Button
                size="mini"
                type="button"
                disabled={callStatus !== 'pending'}
                onClick={answer}
                overrides={{
                  BaseButton: {
                    style: ({ $theme, $disabled }) => ({
                      backgroundColor: $theme.colors.positive300,
                      ':hover': {
                        backgroundColor: $disabled
                          ? $theme.colors.mono100
                          : $theme.colors.positive400,
                      },
                    }),
                  },
                  StartEnhancer: {
                    style: { fontSize: '16px' },
                  },
                }}
                startEnhancer={<FontAwesomeIcon icon={solid('phone')} />}
              >
                Accept Call
              </Button>
            </StyledVoipControlContainer>
          )}
        </StyledContent>
      </StyledCallHeaderRoot>
    </>
  );
};

CallHeader.defaultProps = {
  leadId: '',
  comRef: '',
};

export default CallHeader;
