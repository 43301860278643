import React from 'react';
import {
  StyledContent,
  // StyledIconContainer,
  StyledMessage,
  StyledMessageContainer,
  StyledTitle,
} from './styled-components';
import useGetUserById from '../../hooks/useGetUserById';
import { LeadChangeDetail } from '../../backend/messages';

type LeadChangedAlertPropsT = {
  details: LeadChangeDetail;
};

const LeadTaken = ({ details }: LeadChangedAlertPropsT) => {
  const { by, description, to } = details;

  const initiatedByUser = useGetUserById(by);

  const assignedToUser = useGetUserById(to);

  if (initiatedByUser.isSuccess && assignedToUser.isSuccess) {
    return (
      <StyledMessageContainer>
        <StyledContent>
          <StyledTitle>
            {description} to {assignedToUser.data?.name}
          </StyledTitle>
          {description && <StyledMessage>By {initiatedByUser.data?.name}</StyledMessage>}
        </StyledContent>
      </StyledMessageContainer>
    );
  }
  return null;
};

export default LeadTaken;
