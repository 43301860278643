import React, { useMemo, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Observable,
  switchMap,
  catchError,
  from,
  single,
  Subject,
  map,
  defer,
  retry,
  Subscription,
} from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

type SocketMessage = { [key: string]: string | number | boolean };

type ConnectionStatus = 'OPEN' | 'CLOSING' | 'CLOSED' | 'UNINSTANTIATED';

type Context<T> = {
  status: ConnectionStatus;
  message$: Observable<T>;
  webSocketSubject: WebSocketSubject<T> | undefined;
  sendMessage: (message: SocketMessage) => void;
};

const WebSocketContext = React.createContext<Context<any> | undefined>(undefined);

const messageSubject = new Subject();

const message$ = messageSubject.asObservable();

const WebSocketProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const ref = React.useRef<WebSocketSubject<any> | undefined>();

  const [status, setStatus] = React.useState<ConnectionStatus>('UNINSTANTIATED');

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getToken = useMemo(
    () =>
      defer(() =>
        from(getAccessTokenSilently()).pipe(
          map((v) => v),
          catchError((e) => {
            console.log(e);
            throw e;
          }),
        ),
      ),
    [getAccessTokenSilently],
  );

  const getWebSocket = useCallback((token: string) => {
    let timer: any;

    const socket = webSocket({
      url: `${window.env.APP_WSS}?t=${token}`,
      openObserver: {
        next: () => {
          setStatus('OPEN');
        },
      },
      closingObserver: {
        next: () => {
          setStatus('CLOSING');
        },
      },
      closeObserver: {
        next: () => {
          setStatus('CLOSED');
          if (timer) {
            clearInterval(timer);
          }
          // const err = new Error('Connection closed');
          // return throwError(() => err);
        },
      },
    }); // .pipe(retryWhen((errors) => errors.pipe(delay(5000)))),
    timer = setInterval(() => {
      socket.next({ msg: 'keepalive' });
    }, 30000);

    return socket;
  }, []);

  const createSocket = useMemo(() => {
    const wssObservable = getToken
      .pipe(
        single(),
        switchMap((token) => {
          const wssWithAuth = getWebSocket(token);
          ref.current = wssWithAuth;
          return wssWithAuth;
        }),
        catchError((e) => {
          console.log(e);
          return e;
        }),
      )
      .pipe(retry({ delay: 10000 }));

    return wssObservable;
  }, [getToken, getWebSocket]);

  const sendMessage = useCallback(
    (message: SocketMessage) => {
      if (ref.current && status === 'OPEN') {
        ref.current.next(message);
      }
    },
    [status],
  );

  const connect = useCallback(
    () =>
      createSocket?.subscribe({
        next: (v: any) => messageSubject.next(v),
        error: (e) => console.log(e),
        complete: () => {
          setTimeout(() => {
            console.log('reconnect');
            connect();
          }, 5000);
        },
      }),
    [createSocket],
  );

  React.useEffect(() => {
    let sub: Subscription;
    if (isAuthenticated) {
      sub = connect();
    }
    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [connect, isAuthenticated]);

  const context = { message$, sendMessage, status, webSocketSubject: ref.current };

  return <WebSocketContext.Provider value={context}>{children}</WebSocketContext.Provider>;
};

export default WebSocketProvider;

export const useWebSocketObservable = () => {
  const context = React.useContext(WebSocketContext);
  if (!context) {
    throw new Error('WebSocketContext: No value provided');
  }
  const { message$: observable } = context;
  return observable;
};

export const useWebSocket = () => {
  const context = React.useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket: No value provided');
  }
  return {
    sendMessage: context.sendMessage,
    status: context.status,
    instance: context.webSocketSubject,
  };
};
