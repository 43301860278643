import useGetLead from 'components/lead/hooks/useGetLead';
import AppLoadingScreen from 'components/screen/AppLoadingScreen';
import { useCurrentUserId } from 'context/current-user-context';
import { useWebSocket, useWebSocketObservable } from 'context/web-socket-context';
import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Subscription } from 'rxjs';
import FailedMessageIcon from './failed-message-icon';
import ScrollToBottom from './scroll-to-bottom';
import {
  StyledTextMessageScrollableBody,
  StyledTextMessageOuter,
  StyledTextMessageMeta,
  StyledTextWrapper,
  StyledTextMessage,
  StyledTextMsgSenderText,
  StyledTextFailed,
  // WithStyledIncomingTextMessage,
  // WithStyledOutgoingTextMessage,
} from './styled-components';
import { TextMessageContext } from './text-message-context';
import TextMessageFooter from './text-message-footer';
import useTextThread from './use-text-thread';
import useSendTextMessage from './use-send-text-message';

type TextMessagerProps = {
  scrollOptions?: ScrollIntoViewOptions;
  onSend?: () => void;
};

type TextMessageFormT = {
  message: string;
};

function TextMessage({ scrollOptions, onSend }: TextMessagerProps) {
  const currentUserId = useCurrentUserId();

  const { sendMessage } = useWebSocket();

  const {
    state: { contactType, leadRef: leadId, targetContactRef, targetPhoneNumber = '' },
  } = useContext(TextMessageContext);

  const leadQuery = useGetLead(leadId);

  const [, setUnread] = useState(false);

  const formMethods = useForm<TextMessageFormT>({
    defaultValues: {
      message: '',
    },
  });

  const { mutate } = useSendTextMessage();

  const { data = [], refetch, isLoading } = useTextThread(leadId, targetPhoneNumber);

  const { reset } = formMethods;

  const messages$ = useWebSocketObservable();

  // const handleClose = () => {
  //   dispatch({
  //     isOpen: false,
  //     contactType: '',
  //     leadRef: '',
  //     targetContactRef: null,
  //     targetPhoneNumber: '',
  //   });
  //   queryClient.invalidateQueries(['getLead', leadId]);
  //   reset();
  // };

  const onSubmit = (formData: TextMessageFormT) => {
    const model = {
      ...formData,
      targetPhoneNumber,
      contactType,
      targetContactRef,
    };

    mutate(
      { leadId, model },
      {
        onSuccess: () => {
          reset();
          if (typeof onSend === 'function') {
            onSend();
          }
        },
      },
    );
  };

  useEffect(() => {
    let sub: Subscription;

    if (leadId) {
      sub = messages$.subscribe({
        next: (event: any) => {
          const { eventType, details } = event;

          const shouldRefetch =
            details?.leadRef === leadId &&
            details?.textedFromNumber &&
            eventType === 'INCOMING_COMMUNICATION';

          const shouldRefetchOnOutGoingTextMessage =
            details?.leadRef === leadId &&
            details.sentByUserRef !== currentUserId &&
            eventType === 'OUTGOING_TEXT_MESSAGE';

          const isTexting =
            details.leadRef === leadId && eventType === 'INCOMING_COMMUNICATION';

          if (shouldRefetch || shouldRefetchOnOutGoingTextMessage) {
            setUnread(true);
            refetch().then(() => {
              if (isTexting) {
                sendMessage({
                  communicationId: details.id,
                  userId: currentUserId,
                  displayed: true,
                });
              }
            });
          }
        },
        error: (e: any) => console.error(e),
        complete: () => console.info('[TEXTMESSAGE] complete'),
      });
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [messages$, leadId, refetch, currentUserId, sendMessage]);

  const getSenderInformation = (
    sentByUserRef: any,
    sentByUserName: any,
    currentUserRef: string,
    sentByRepUserName: string | null,
  ) => {
    if (sentByUserRef === currentUserRef) {
      if (sentByRepUserName) {
        return `${sentByRepUserName} as ${sentByUserName}`;
      }
      return null;
    }
    if (sentByRepUserName) {
      return `${sentByRepUserName} as ${sentByUserName}`;
    }
    return sentByUserName;
  };

  return (
    <>
      {/* {import.meta.env.DEV && (
        <div>
          <pre>{JSON.stringify({ leadId, targetPhoneNumber }, null, 2)}</pre>
        </div>
      )} */}
      <StyledTextMessageScrollableBody data-struct="text-message-scrollable">
        {isLoading && <AppLoadingScreen height="100%" />}
        {data.map((textMessage) => {
          const {
            communicationDirection,
            message,
            createdTs,
            sentByUserName,
            sentByUserRef,
            sentByRepUserName,
          } = textMessage;

          const isSenderCurrentUser =
            communicationDirection === 'OUTGOING' && sentByUserRef === currentUserId;

          return (
            <StyledTextMessageOuter key={textMessage.id} $direction={communicationDirection}>
              <StyledTextMessageMeta>
                {format(new Date(createdTs), 'M/d/yy, h:mm a')}
              </StyledTextMessageMeta>

              <StyledTextWrapper>
                <StyledTextMessage $direction={communicationDirection} $isMe={isSenderCurrentUser}>
                  {message}
                </StyledTextMessage>
                {textMessage.textStatus === 'FAILED' && communicationDirection === 'OUTGOING' && (
                  <FailedMessageIcon />
                )}
              </StyledTextWrapper>

              {communicationDirection === 'OUTGOING' && (
                <StyledTextMessageMeta>
                  <StyledTextMsgSenderText>
                    {textMessage.textStatus === 'FAILED' && (
                      <StyledTextFailed>Not Delivered</StyledTextFailed>
                    )}
                    {getSenderInformation(
                      sentByUserRef,
                      sentByUserName,
                      currentUserId,
                      sentByRepUserName,
                    )}
                  </StyledTextMsgSenderText>
                </StyledTextMessageMeta>
              )}
            </StyledTextMessageOuter>
          );
        })}

        {/* <StyledTextMessageOuter>
          <StyledTextMessageMeta>2/3/21, 4:30 PM</StyledTextMessageMeta>
          <WithStyledIncomingTextMessage>
            Enforce onClick is accompanied by at least one of the following: onKeyUp, onKeyDown,
            onKeyPress. Coding for the keyboard is important for users with physical disabilities
            who cannot use a mouse, AT compatibility, and screenreader users. This does not apply
            for interactive or hidden elements.
          </WithStyledIncomingTextMessage>
        </StyledTextMessageOuter>

        <StyledTextMessageOuter $direction="OUTGOING">
          <StyledTextMessageMeta>2/3/21, 4:30 PM - Collin Rodolitz</StyledTextMessageMeta>
          <WithStyledOutgoingTextMessage>
            Now we no longer need to perform additional type annotation simply to appease flow, and
            can focus on the task at hand with all of the added benefits to type checking.
          </WithStyledOutgoingTextMessage>
        </StyledTextMessageOuter>

        <StyledTextMessageOuter>
          <WithStyledIncomingTextMessage>We’re excited to announce</WithStyledIncomingTextMessage>
        </StyledTextMessageOuter>

        <StyledTextMessageOuter $direction="OUTGOING">
          <WithStyledOutgoingTextMessage>Hello</WithStyledOutgoingTextMessage>
        </StyledTextMessageOuter> */}
        <ScrollToBottom options={scrollOptions} />
        {/* <div ref={scrollerRef} /> */}
      </StyledTextMessageScrollableBody>
      <TextMessageFooter
        {...formMethods}
        onSubmit={onSubmit}
        teamRef={leadQuery.data?.teamRef}
        assignedPropertyRef={leadQuery.data?.assignedProperty?.propertyRef}
      />
    </>
  );
}

export default TextMessage;
