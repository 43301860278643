import React, { useEffect } from 'react';
import { Communication } from 'backend/communication';
import useInProgressCallCheck from 'components/callHeader/useInProgressCallCheck';
import { useCurrentUserId } from 'context/current-user-context';

type CallProgressContextT = {
  isBusy: boolean;
  cancelTimer: () => void;
  endTracking: () => void;
  startTracking: () => void;
  setIsBusy: (value: boolean) => void;
  data?: Communication;
};

type CallProgressProviderProps = {
  children: React.ReactNode;
};

const CallProgressContext = React.createContext<CallProgressContextT | undefined>(undefined);

const CallProgressProvider = ({ children }: CallProgressProviderProps) => {
  const timerRef = React.useRef<any>();

  const currentUserId = useCurrentUserId();

  const [isBusy, setBusy] = React.useState(false);

  const { data } = useInProgressCallCheck(currentUserId, {
    enabled: !!currentUserId && import.meta.env.MODE !== 'development',
    retry: false,
    refetchInterval: isBusy ? 2000 : false,
  });

  /**
   * @param value Sets isBusy, intended for TwilioProvider
   */
  const setIsBusy = React.useCallback((value: boolean) => {
    setBusy(value);
  }, []);

  /**
   * Sets isBusy to false if no active call is found in in progress call data
   */
  const isBusyTimeout = React.useCallback(() => {
    console.log('check call');
    if (!data) {
      setBusy(false);
    }
  }, [data]);

  /**
   * Cancel isBusy timeout
   */
  const cancelTimer = React.useCallback(() => {
    if (timerRef.current) {
      console.log('cancel timer');
      clearTimeout(timerRef.current);
    }
  }, []);

  /**
   * Set isBusy to true when starting a call and begin high interval
   * polling for in progress communication. A timeout will set isBusy to
   * false if a call has not been initiated or transferred
   */
  const startTracking = React.useCallback(() => {
    setBusy(true);
    timerRef.current = setTimeout(isBusyTimeout, 15000);
  }, [isBusyTimeout]);

  /**
   * Set isBusy to false when ending a call and ends high interval
   * polling for in progress communication.
   */
  const endTracking = React.useCallback(() => {
    cancelTimer();
    setBusy(false);
  }, [cancelTimer]);

  useEffect(() => {
    // on mount (refresh, load) check for active call
    if (data?.callStatus) {
      setBusy(true);
    }
  }, [data?.callStatus]);

  useEffect(() => clearTimeout(timerRef.current), []);

  return (
    <CallProgressContext.Provider
      value={{ cancelTimer, endTracking, data, isBusy, setIsBusy, startTracking }}
    >
      {children}
    </CallProgressContext.Provider>
  );
};

const useCallProgressContext = () => {
  const context = React.useContext(CallProgressContext);
  if (context === undefined) {
    throw new Error('useCallContext must be used within a CallProvider');
  }
  return context;
};

export { CallProgressProvider, useCallProgressContext };
