import { styled } from 'baseui';

export const StyledFlexContainer = styled('div', () => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const StyledInnerGridCell = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: $theme.colors.mono700,
}));

export const StyledToolTip = styled('span', ({ $theme }) => ({
  ...$theme.typography.ParagraphXSmall,
  maxWidth: '128px',
}));

export const StyledToolTipInfo = styled('span', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale300,
}));
