import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useMutation, useQueryClient } from 'react-query';

function useSyncLogoutMutation() {
  const { logout, user } = useAuth0();
  const queryClient = useQueryClient();

  const signout = useCallback(
    () =>
      new Promise<void>((resolve) => {
        const status = btoa(`${user?.sub}:${Date.now()}`);
        localStorage.setItem('status', status);
        resolve();
        logout({
          returnTo: window.location.origin,
        });
      }),
    [logout, user?.sub],
  );

  return useMutation(signout, {
    onMutate: () => {
      queryClient.cancelQueries('getStorageValue');
    },
    onSuccess: () => {
      queryClient.invalidateQueries('getStorageValue');
    },
  });
}

export default useSyncLogoutMutation;
