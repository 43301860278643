import { Outlet } from 'react-router-dom';
import ReloadPrompt from '../components/screen/reload-prompt';

const ChromelessLayout = () => (
  <>
    <ReloadPrompt />
    <Outlet />
  </>
);

export default ChromelessLayout;
