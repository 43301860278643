import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { Property } from '../../../backend/property';
import useAxios from '../../../hooks/useAxios';

const usePropertiesByTeam = (
  ids: string[] | undefined,
  options?: Omit<UseQueryOptions<Property[] | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getPropertyByTeam = async () => {
    if (!ids) Promise.reject();
    const { data } = await axios.get<Property[]>('/api/property/ids', {
      params: { ids: ids?.join(',') },
    });
    return data;
  };

  return useQuery<Property[] | undefined, AxiosError>(
    ['properties', { filters: { ids } }],
    getPropertyByTeam,
    {
      enabled: Array.isArray(ids) && ids.length > 0,
      retry: false,
      ...options,
    },
  );
};

export default usePropertiesByTeam;
