import { useQuery } from 'react-query';
import { Team } from '../../../backend/team';
import useAxios from '../../../hooks/useAxios';

function useTeam() {
  const axios = useAxios();
  return {
    async getTeam({ queryKey }: any) {
      const [, id] = queryKey;
      if (typeof id === 'undefined') return Promise.reject();
      const { data } = await axios.get<Team>(`api/team/${id}`);
      return data;
    },
  };
}

function useGetTeam(teamId: string | undefined, options?: {}) {
  const { getTeam } = useTeam();
  return useQuery({
    queryFn: getTeam,
    queryKey: ['getTeam', teamId],
    enabled: Boolean(teamId),
    retry: false,
    ...options,
  });
}

export default useGetTeam;
