import { MenuOverrides, StatefulMenu, StyledEmptyState } from 'baseui/menu';
import { StatefulPopover } from 'baseui/popover';
import { StatefulTooltip } from 'baseui/tooltip';
import { MenuAdapterProps } from 'baseui/list';
import { MoveFocusInside } from 'react-focus-lock';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import React from 'react';
import { NavigationPopoverProps } from './types/navigation';
import * as Styled from './styled-components';
import NavigationFilter from './NavigationFilter';

const statefulMenuOverrides: MenuOverrides = {
  EmptyState: {
    component: () => (
      <StyledEmptyState>No team matched your search. Try a different phrase.</StyledEmptyState>
    ),
  },
  List: {
    style: {
      minWidth: '220px',
      boxShadow: 'none',
      maxHeight: '25vh',
    },
  },
  Option: {
    props: {
      overrides: {
        ListItem: {
          component: React.forwardRef<HTMLLIElement, MenuAdapterProps>(
            (props: React.PropsWithChildren<MenuAdapterProps>, ref: any) => {
              const { children, item, ...rest } = props;
              return (
                <Styled.SubMenuListItem {...rest}>
                  <Styled.SubMenuAnchor to={props.item.href} ref={ref}>
                    {({ isActive }: any) => (
                      <Styled.SubMenuText $isActive={isActive}>
                        {props.item.label}
                      </Styled.SubMenuText>
                    )}
                  </Styled.SubMenuAnchor>
                </Styled.SubMenuListItem>
                // <MenuListItem
                //   {...props}
                //   endEnhancer={() =>
                //     isSelected ? <FontAwesomeIcon icon={solid('check')} /> : null
                //   }
                //   $isSelected={isSelected}
                //   ref={ref}
                // >
                //   {props.children}
                // </MenuListItem>
              );
            },
          ),
        },
      },
    },
  },
};

const NavigationPopover = ({ children, ...props }: NavigationPopoverProps) => {
  const {
    filterable,
    hasSubMenu,
    label,
    isExpanded: isPinned,
    isHoverSupported,
    isLargeScreen,
    subNav,
  } = props;

  if (!hasSubMenu) {
    return !isPinned && isLargeScreen && isHoverSupported ? (
      <StatefulTooltip
        placement="left"
        showArrow
        overrides={tooltipOverrides}
        content={label}
        onMouseEnterDelay={50}
        onMouseLeaveDelay={0}
        popoverMargin={12}
        popperOptions={{
          modifiers: {
            preventOverflow: {
              boundariesElement: document.querySelector('#main'),
            },
          },
        }}
      >
        {children}
      </StatefulTooltip>
    ) : (
      <>{children}</>
    );
  }

  const subMenu = subNav?.filter((n) => !n.skipRender);

  return (
    <StatefulPopover
      triggerType={isHoverSupported ? 'hover' : 'click'}
      popperOptions={{
        modifiers: {
          preventOverflow: { boundariesElement: document.querySelector('#main') },
        },
      }}
      content={({ close }) =>
        subMenu &&
        !isPinned &&
        isLargeScreen && (
          <>
            <Styled.NavOptGroupHeader>{label}</Styled.NavOptGroupHeader>
            {filterable && <NavigationFilter />}
            <MoveFocusInside>
              <StatefulMenu
                items={subMenu}
                overrides={statefulMenuOverrides}
                onItemSelect={() => {
                  console.log('SELECT');
                  close();
                }}
              />
            </MoveFocusInside>
          </>
        )
      }
      overrides={{
        Body: {
          style: {
            maxWidth: '256px',
          },
        },
        Inner: {
          style: ({ $theme }) => ({
            backgroundColor: $theme.colors.white,
          }),
        },
      }}
      placement="leftTop"
      popoverMargin={12}
    >
      {children}
    </StatefulPopover>
  );
};

export default React.memo(NavigationPopover);
