import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ProvisionPhoneNumbers } from '../../../backend/team';
import useAxios from '../../../hooks/useAxios';

const useAvailablePhoneNumbers = (
  options?: Omit<
  UseQueryOptions<ProvisionPhoneNumbers[] | undefined, AxiosError>,
  'queryKey' | 'queryFn'
  >,
) => {
  const axios = useAxios();

  const getAvailablePhoneNumbers = async () => {
    const { data } = await axios.get<ProvisionPhoneNumbers[]>('/api/available-phone-numbers');
    return data;
  };

  return useQuery<ProvisionPhoneNumbers[] | undefined, AxiosError>(
    ['availabePhoneNumbers'],
    getAvailablePhoneNumbers,
    options,
  );
};

export default useAvailablePhoneNumbers;
