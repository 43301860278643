import React from 'react';
import { toast } from 'react-toastify';

const useNavigatorMediaPermissions = () => {
  const checkMicrophoneAccess = React.useCallback(() => {
    navigator.permissions
      .query({
        name: 'microphone' as any,
      })
      .then(({ state }) => {
        if (state === 'denied') {
          toast('Please update your browser settings to allow SpaceIt use your microphone.', {
            autoClose: false,
          });
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  }, []);

  return { checkMicrophoneAccess };
};

export default useNavigatorMediaPermissions;
