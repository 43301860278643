import { TabOverrides, TabsOverrides } from 'baseui/tabs-motion';

export const tabsOverrides2: { overrides: TabsOverrides } = {
  overrides: {
    Root: {
      style: () => ({
        background: 'transparent',
      }),
    },
    TabList: {
      style: ({ $theme }) => ({
        marginBottom: '-1px',
        paddingBottom: '4px',
        paddingLeft: $theme.sizing.scale900,
        paddingRight: $theme.sizing.scale900,
        background: 'transparent',
      }),
    },
    TabHighlight: {
      style: ({ $theme }) => ({
        height: '4px',
        bottom: 0,
        backgroundColor: $theme.colors.primary400,
      }),
    },
    TabBorder: {
      style: ({ $theme }) => ({
        height: '1px',
        backgroundColor: $theme.colors.mono300,
      }),
    },
  },
};

export const tabOverrides2: TabOverrides = {
  Tab: {
    style: ({ $theme }) => ({
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: '16px',
      paddingRight: '16px',
      whiteSpace: 'nowrap',
      // textTransform: 'uppercase',
      '-webkit-font-smoothing': 'antialiased',
      background: $theme.colors.mono100,
      ...$theme.typography.ParagraphSmall,
    }),
  },
  TabPanel: {
    style: ({ $theme }) => ({
      backgroundColor: $theme.colors.mono100,
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  },
};
