import React from 'react';
import { Button } from 'baseui/button';
import { toast } from 'react-toastify';
import { Block } from 'baseui/block';
import { useNavigate } from 'react-router-dom';
import { FormControl } from 'baseui/form-control';
import { useForm, Resolver } from 'react-hook-form';
import { ParagraphSmall } from 'baseui/typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { ModalHeader, ModalBody, ModalFooter, FocusOnce } from 'baseui/modal';
import { useModal } from 'components/modal/ModalProvider';
import { BaseUiOption } from '../../../shared/types';
import ButtonGroup from '../../buttonGroup/ButtonGroup';
import ModalBoxButton from '../../modalBox/ModalBoxButton';
import useMoveCommunication from '../hooks/useMoveCommunication';
import { moveCommunicationSchema } from '../validators/leadSchema';
import ControlledSelect from '../../team/components/ControlledSelect';
import useAvailableLeadMergeList from '../hooks/useAvailableLeadMergeList';
import useGetLeadsForTeam from '../hooks/useGetLeadsForTeam';

interface MoveCommForm {
  targetLeadRef: BaseUiOption<string>[];
}

type Props = {
  leadId: string;
  id: string;
  teamRef: string | undefined;
};

const MoveCommunicationForm = ({ id, leadId, teamRef }: Props) => {
  const navigate = useNavigate();

  const { closeModal, isOpen } = useModal();

  const leadsForTeamQuery = useGetLeadsForTeam(teamRef, { enabled: isOpen && !!teamRef });

  const [availableLeads] = useAvailableLeadMergeList(leadsForTeamQuery.data);

  const filteredLeads = availableLeads?.filter(
    (item) => item.id !== leadId && !!(item.tenantName || item.name),
  );

  const { mutate } = useMoveCommunication();

  const options = () => {
    const a = [{ id: '', label: 'Create New Lead' }];
    const o = filteredLeads?.map((item) => ({
      id: item.id,
      label: item.tenantName || item.name,
    }));
    if (o) {
      return [...a, ...o];
    }
    return a;
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<MoveCommForm>({
    defaultValues: {
      targetLeadRef: [],
    },
    resolver: yupResolver(moveCommunicationSchema) as Resolver<MoveCommForm>,
  });

  const handleClose = () => {
    closeModal();
  };

  const handleLocation = (lid: string) => {
    navigate(`/leads/${lid}`);
    toast.dismiss();
  };

  const confirmToast = (lid: string) => {
    toast(
      <>
        <Block marginTop="0" marginBottom="scale300">
          Communication moved
        </Block>
        <Button kind="secondary" size="mini" onClick={() => handleLocation(lid)}>
          See Lead
        </Button>
      </>,
      { autoClose: false, position: 'top-center', theme: 'dark', delay: 400 },
    );
  };

  const onSubmit = (payload: MoveCommForm) => {
    const model = {
      ...payload,
      leadId,
      commId: id,
      targetLeadRef: payload.targetLeadRef[0].id,
    };

    mutate(model, {
      onSuccess: ({ data }) => {
        handleClose();
        confirmToast(data?.targetLeadRef);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FocusOnce>
        <ModalHeader>Move Communication</ModalHeader>
      </FocusOnce>
      <ModalBody>
        <ParagraphSmall>
          Pick a lead or select the Create New Lead option to move this communication to a different
          lead.
        </ParagraphSmall>
        <FormControl label="" error={errors?.targetLeadRef?.message}>
          <ControlledSelect
            control={control}
            name="targetLeadRef"
            baseuiProps={{
              size: 'compact',
              isLoading: leadsForTeamQuery.isLoading,
              // disabled: !filteredLeads.length,
              placeholder: filteredLeads?.length
                ? 'Select...'
                : 'This communication cannot be moved',
              clearable: false,
              searchable: true,
              options: options(),
            }}
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup>
          <ModalBoxButton type="button" size="default" kind="tertiary" onClick={handleClose}>
            Cancel
          </ModalBoxButton>
          <ModalBoxButton type="submit" size="default" disabled={!isDirty}>
            Save
          </ModalBoxButton>
        </ButtonGroup>
      </ModalFooter>
    </form>
  );
};

export default MoveCommunicationForm;
