import { styled } from 'baseui';

export const StepperRoot = styled('div', () => ({
  display: 'block',
}));

export const StepperHeader = styled('div', ({ $theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: $theme.sizing.scale800,
  marginRight: $theme.sizing.scale800,
  marginTop: $theme.sizing.scale800,
  marginBottom: $theme.sizing.scale600,
}));

export const StepperHeaderPadding = styled('div', ({ $theme }) => ({
  paddingRight: $theme.sizing.scale950,
}));

export const StepperBody = styled('div', ({ $theme }) => ({
  marginLeft: $theme.sizing.scale800,
  marginRight: $theme.sizing.scale800,
  marginTop: $theme.sizing.scale600,
  marginBottom: $theme.sizing.scale600,
}));

export const StepperFooter = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: $theme.sizing.scale600,
  marginLeft: $theme.sizing.scale800,
  marginRight: $theme.sizing.scale800,
  marginBottom: $theme.sizing.scale800,
}));

export const StepperTitle = styled('div', ({ $theme }) => ({
  flex: '1 1',
  textAlign: 'center',
  ...$theme.typography.HeadingXSmall,
  marginLeft: $theme.sizing.scale300,
  marginRight: $theme.sizing.scale300,
}));

export const StepperProgressBar = styled('div', ({ $theme }) => ({
  paddingLeft: $theme.sizing.scale1200,
  paddingRight: $theme.sizing.scale1200,
  marginTop: $theme.sizing.scale300,
  marginBottom: $theme.sizing.scale900,
}));

export const Step = styled('div', ({ $theme }) => ({
  marginTop: $theme.sizing.scale900,
  marginBottom: $theme.sizing.scale900,
}));
