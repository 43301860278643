import { useStyletron } from 'baseui';
import React, { useEffect } from 'react';
import { useClient } from 'context/client-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSideMenuContext } from 'context/SideMenuContext';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMediaQuery } from 'react-responsive';
import NavigationAccordian from './NavigationAccordian';
import NavigationItem from './NavigationItem';
import * as Styled from './styled-components';
import { useNavigationState, useNavigationUpdater } from './context/navigation-context';
import NavigationFilter from './NavigationFilter';
import useNavigationEntries from './hooks/useNavigationEntries';
import SubNavEmptyContent from './SubNavEmptyContent';

const Navigation: React.FunctionComponent = () => {
  const [, theme] = useStyletron();

  const client = useClient();

  const { isExpanded, setExpanded, setOpen } = useSideMenuContext();

  const closeDrawer = () => setOpen(false);

  const collapseHandler = () => setExpanded(!isExpanded);

  const isLargeScreen = useMediaQuery({ minWidth: theme.breakpoints.large });

  const isHoverSupported = useMediaQuery({ query: '(pointer: fine) and (hover: hover)' });

  const { state } = useNavigationState();

  const { dispatch } = useNavigationUpdater();

  const items = useNavigationEntries();

  const menu = items.filter((x) => !x.skipRender);

  const numEntries = menu.length;

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number,
    shouldDispatch: boolean,
  ) => {
    const item = menu[index];
    if (item.subNav) {
      e.preventDefault();
      if (shouldDispatch) {
        dispatch({ type: 'UPDATE_STATUS', payload: index });
      }
      return;
    }
    if (item.href) {
      closeDrawer();
    }
  };

  useEffect(() => {
    if (numEntries) {
      const initialItems = Array.from(Array(numEntries)).map(() => ({ isOpen: false }));
      dispatch({ type: 'SET_INIT_STATUS', payload: initialItems });
    }
  }, [numEntries, dispatch]);

  return (
    <>
      <Styled.UpperNavMenu>
        <Styled.List data-id="ul">
          {menu.map((item, index) => (
            <React.Fragment key={item.itemId}>
              <NavigationItem
                label={item.label}
                prefix={item.prefix}
                isOpen={state.status?.[index]?.isOpen}
                href={item.href}
                hasSubMenu={Boolean(item.subNav)}
                isExpanded={isExpanded}
                isLargeScreen={isLargeScreen}
                isHoverSupported={isHoverSupported}
                action={(e) => {
                  handleClick(e, index, isExpanded);
                }}
                subNav={item.subNav}
                filterable={item.filterable}
              />
              {item.subNav && (
                <NavigationAccordian
                  isOpen={state.status?.[index]?.isOpen}
                  isSidebarPinned={isExpanded}
                >
                  {item.filterable && <NavigationFilter />}
                  {!item.subNav.length && <SubNavEmptyContent />}
                  {item.subNav
                    .filter((x) => !x.skipRender)
                    .map((collapsableItem) => (
                      <NavigationItem
                        disableBackground
                        key={collapsableItem.itemId}
                        label={collapsableItem.label}
                        prefix={collapsableItem.prefix}
                        isOpen={state.status?.[index]?.isOpen}
                        href={collapsableItem.href}
                        isExpanded={isExpanded}
                        isLargeScreen={isLargeScreen}
                        isHoverSupported={isHoverSupported}
                        action={(e) => {
                          if (collapsableItem.subNav) {
                            e.preventDefault();
                            return;
                          }
                          if (collapsableItem.href) {
                            closeDrawer();
                          }
                        }}
                      />
                    ))}
                </NavigationAccordian>
              )}
            </React.Fragment>
          ))}
        </Styled.List>
      </Styled.UpperNavMenu>
      <Styled.LowerNavMenu $alwaysShow={client.state?.teamType === 'BROKER_SINGLE_PROP'}>
        <Styled.List data-id="ul">
          {client.state?.teamType === 'BROKER_SINGLE_PROP' && (
            <NavigationItem
              isLargeScreen={isLargeScreen}
              isHoverSupported={isHoverSupported}
              href="/help/open-house"
              action={closeDrawer}
              label="Open House Help"
              prefix={<FontAwesomeIcon icon={regular('circle-question')} />}
              isOpen={false}
              isExpanded={isExpanded}
            />
          )}
          <Styled.LowerNavMenuItem>
            <NavigationItem
              isLargeScreen={isLargeScreen}
              isHoverSupported={isHoverSupported}
              action={(e) => {
                e.preventDefault();
                collapseHandler();
              }}
              label="Collapse"
              prefix={
                <FontAwesomeIcon
                  transform={{ rotate: isExpanded ? 180 : 0 }}
                  icon={solid('right-to-bracket')}
                />
              }
              isOpen={false}
              isExpanded={isExpanded}
            />
          </Styled.LowerNavMenuItem>
        </Styled.List>
      </Styled.LowerNavMenu>
    </>
  );
};

export default Navigation;
