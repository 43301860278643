import React from 'react';
import { useStyletron } from 'baseui';
import { StatefulInput, SIZE } from 'baseui/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAsyncDebounce } from 'react-table';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

type TableSearchInputPropsT = {
  setGlobalFilter: (value: string) => void;
};

const GlobalFilter = (props: TableSearchInputPropsT) => {
  const [, theme] = useStyletron();
  const { setGlobalFilter } = props;

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <>
      <StatefulInput
        startEnhancer={
          <FontAwesomeIcon
            color={theme.colors.mono400}
            title="Search"
            fixedWidth
            icon={solid('search')}
          />
        }
        clearable
        clearOnEscape
        size={SIZE.compact}
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
        overrides={{
          Root: {
            style: () => ({
              borderTopStyle: 'none',
              borderLeftStyle: 'none',
              borderRightStyle: 'none',
              borderBottomStyle: 'none',
            }),
          },
        }}
      />
    </>
  );
};

export default GlobalFilter;
