import { Node, mergeAttributes } from '@tiptap/react';

type ShareOptions = {
  id: string;
  propertyName: string;
  name: string;
  count: number;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    share: {
      insertShareMedia: (options: ShareOptions) => ReturnType;
    };
  }
}

export type ShareMediaType = {
  HTMLAttributes: Record<string, any>;
  InnerHTMLAttributes: Record<string, any>;
  renderLabel: (props: { options: ShareMediaType; node: any }) => string;
};

export const ShareMedia = Node.create<ShareMediaType>({
  name: 'share',

  addOptions() {
    return {
      HTMLAttributes: {},
      InnerHTMLAttributes: {
        'data-si': 'share',
      },
      renderLabel({ node }) {
        if (node.attrs) {
          return `${node.attrs.propertyName} - ${node.attrs?.name}`;
        }
        return '';
      },
    };
  },
  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  content: 'text*',

  parseHTML() {
    return [
      {
        tag: '[data-si="share"]',
      },
    ];
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-share-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            'data-share-id': attributes.id,
          };
        },
      },
      propertyName: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-property-name'),
        renderHTML: (attributes) => {
          if (!attributes.propertyName) {
            return {};
          }
          return {
            'data-property-name': attributes.propertyName,
          };
        },
      },
      name: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-name'),
        renderHTML: (attributes) => {
          if (!attributes.name) {
            return {};
          }
          return {
            'data-name': attributes.name,
          };
        },
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes),
      [
        'span',
        mergeAttributes(this.options.InnerHTMLAttributes, HTMLAttributes),
        this.options.renderLabel({
          options: this.options,
          node,
        }),
      ],
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addCommands() {
    return {
      insertShareMedia:
        (options) =>
          ({ commands }) => {
            // more than 1 attachment, insert hardBreaks
            const hardBreaks =
              options.count > 1 ? [{ type: 'hardBreak' }, { type: 'hardBreak' }] : [];
            // commands.insertContent({
            //   type: this.name,
            //   attrs: options,
            // }),
            return commands.insertContent([
              {
                type: this.name,
                attrs: options,
              },
              {
                type: 'text',
                text: ' ',
              },
              ...hardBreaks,
            ]);
          },
      // commands.insertContent([
      //   {
      //     type: this.name,
      //     attrs: options,
      //     content: [
      //       {
      //         type: 'text',
      //         text: 'hello',
      //       },
      //       {
      //         type: 'text',
      //         text: ' ',
      //       },
      //     ],
      //   },
      // ]),
    };
  },
});
