import { FilterCriteria, SavedList } from 'backend/lead';
import useAxios from 'hooks/useAxios';
import { useMutation, useQuery, useQueryClient } from 'react-query';

function useSmartListApi() {
  const axios = useAxios();

  return {
    async getSmartLists() {
      const { data } = await axios.get<SavedList[]>('api/smartlist');
      return data;
    },
    async saveSmartList(list: SavedList) {
      return axios.post('api/smartlist', list);
    },
    async deleteSmartList(id: string) {
      return axios.delete(`api/smartlist/${id}`);
    },
    async editSmartList(id: string, filters: Partial<FilterCriteria>) {
      return axios.patch(`api/smartlist/${id}/edit-criteria`, filters);
    },
  };
}

function useSaveSmartlist() {
  const queryClient = useQueryClient();

  const { saveSmartList } = useSmartListApi();

  return useMutation(
    (data: any) => {
      console.log(data);
      return saveSmartList(data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getSmartLists']);
      },
    },
  );
}

function useEditSmartlist() {
  const queryClient = useQueryClient();

  const { editSmartList } = useSmartListApi();

  return useMutation(
    (data: Partial<FilterCriteria> & { id: string }) => editSmartList(data.id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getSmartLists']);
      },
    },
  );
}

function useDeleteSmartlist() {
  const queryClient = useQueryClient();

  const { deleteSmartList } = useSmartListApi();

  return useMutation((id: string) => deleteSmartList(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getSmartLists']);
    },
  });
}

function useSmartLists(options?: {}) {
  const { getSmartLists } = useSmartListApi();

  return useQuery({
    queryFn: getSmartLists,
    queryKey: ['getSmartLists'],
    retry: false,
    staleTime: 60000,
    ...options,
  });
}

export { useDeleteSmartlist, useEditSmartlist, useSmartLists, useSaveSmartlist };
