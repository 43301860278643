import { useStyletron } from 'baseui';
import { StatefulTooltip, PLACEMENT } from 'baseui/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconArrowsDiagonal, IconArrowsDiagonalMinimize2 } from '@tabler/icons-react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledPopoutIconWrapper } from './styled-components';
import { useEmail } from '../email/email-context';
import { tooltipOverrides } from '../shared/tooltip-overrides';
import { usePopout } from './PopoutContextProvider';

type PopoutHeaderPropsT = {
  heading?: string;
  onClose: () => void;
};

const PopoutHeader = (props: PopoutHeaderPropsT) => {
  const [css, theme] = useStyletron();

  const { setMessage } = useEmail();

  const { fullScreen, setFullScreen, expanded, setExpanded } = usePopout();

  const { onClose, heading } = props;

  const toggleFullScreen = () => {
    if (!expanded) {
      setExpanded(true);
      setFullScreen(false);
      return;
    }
    setFullScreen(!fullScreen);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        color: theme.colors.white,
        height: theme.sizing.scale1000,
        justifyContent: 'space-between',
        backgroundColor: theme.colors.mono800,
      })}
    >
      <div className={css({ ...theme.typography.LabelXSmall, paddingLeft: theme.sizing.scale600 })}>
        {heading}
      </div>
      <div className={css({ display: 'inline-flex' })}>
        <StatefulTooltip
          content={expanded ? 'Minimize' : 'Maximize'}
          placement={PLACEMENT.bottom}
          overrides={tooltipOverrides}
        >
          <StyledPopoutIconWrapper role="button" onClick={toggleExpand}>
            <FontAwesomeIcon
              icon={solid('window-minimize')}
              rotation={expanded ? undefined : 180}
            />
          </StyledPopoutIconWrapper>
        </StatefulTooltip>

        <StatefulTooltip
          content={fullScreen ? 'Exit full screen' : 'Full screen'}
          placement={PLACEMENT.bottom}
          overrides={tooltipOverrides}
        >
          <StyledPopoutIconWrapper role="button" onClick={toggleFullScreen}>
            {fullScreen ? (
              <IconArrowsDiagonalMinimize2 size={16} />
            ) : (
              <IconArrowsDiagonal size={16} />
            )}
          </StyledPopoutIconWrapper>
        </StatefulTooltip>

        <StatefulTooltip
          content={() => 'Close'}
          placement={PLACEMENT.bottom}
          overrides={tooltipOverrides}
        >
          <StyledPopoutIconWrapper
            role="button"
            onClick={() => {
              setMessage({ type: 'RESET' });
              onClose();
            }}
          >
            <FontAwesomeIcon icon={solid('close')} />
          </StyledPopoutIconWrapper>
        </StatefulTooltip>
      </div>
    </div>
  );
};

PopoutHeader.defaultProps = {
  heading: 'New Message',
};

export default PopoutHeader;
