import { StyledTab } from 'baseui/tabs-motion';
import { StatefulTooltip } from 'baseui/tooltip';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import React from 'react';

const CustomTab = React.forwardRef(InternalTab);

export default function TooltipTab(props: any) {
  const { content, myref, ...more } = props;

  return (
    <StatefulTooltip
      ignoreBoundary
      content={content}
      onMouseEnterDelay={500}
      popperOptions={{
        modifiers: {
          preventOverflow: { boundariesElement: document.querySelector('#main') },
        },
      }}
      overrides={{
        ...tooltipOverrides,
        Body: {
          style: () => ({
            maxWidth: '256px',
          }),
        },
      }}
    >
      <CustomTab tabRef={myref} {...more} />
    </StatefulTooltip>
  );
}

function InternalTab(props: any, ref: React.ForwardedRef<any>) {
  const { children, tabRef, ...rest } = props;
  return (
    <StyledTab
      ref={(node: any) => {
        tabRef.current = node;
        // eslint-disable-next-line no-param-reassign
        (ref as React.MutableRefObject<any>).current = node;
      }}
      {...rest}
    >
      <span>{children}</span>
    </StyledTab>
  );
}
