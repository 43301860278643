/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'baseui/button';
import { RadioOverrides } from 'baseui/radio';
import { OnChangeParams } from 'baseui/select';
import { LabelMedium, ParagraphSmall } from 'baseui/typography';
import { ModalBody, ModalFooter, ModalHeader } from 'baseui/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { PatternFormat } from 'react-number-format';
import { useFieldArray, useForm } from 'react-hook-form';
import { BaseUiOption } from 'shared/types';
import { useModal } from 'components/modal/ModalProvider';
import { FormControl, FormControlOverrides } from 'baseui/form-control';
import { useClient } from 'context/client-context';
import useRemoveTeam from 'components/team/hooks/useRemoveTeam';
import RequiredFieldMarker from 'components/shared/RequiredFieldMarker';
import ControlledInput from 'components/team/components/ControlledInput';
import ControlledSelect from 'components/team/components/ControlledSelect';
import { deleteTeamSchema } from 'components/team/validators/teamSchemas';
import useGetTeamsForUser from 'components/team/hooks/useGetTeamsForUser';
import ControlledRadioButtons from 'components/team/components/ControlledRadioButtons';
import ControlledNumberFormat from 'components/team/components/ControlledNumberFormat';
import useGetCampaignsForTeam from 'components/team/hooks/useGetCampaignsForTeam';
import useTeamMemberUsers from 'components/lead/hooks/useTeamMemberUsers';
import {
  StyledDeleteTeamCell,
  StyledDeleteTeamRow,
  StyledMiniLabel,
  StyleRowGroup,
  StyleRowGroupHeader,
} from './styled-components';

const radioOverrides: RadioOverrides = {
  Label: {
    style: ({ $theme }) => ({
      ...$theme.typography.LabelSmall,
      fontWeight: 400,
    }),
  },
};

const fcOverrides: FormControlOverrides = {
  ControlContainer: { style: { marginBottom: 0 } },
  Caption: { style: { marginBottom: 0 } },
};

type RemoveCampaignT = {
  id: string;
  name: string;
  emailAddress: string;
  phoneNumber: string;
  campaignRef: string;
  removeEmailChannel: {
    forwardingEmail: string;
    saveEmail: boolean | string | null;
  };
  removePhoneChannel: {
    forwardingNumber: string;
    savePhone: boolean | string | null;
  };
};

type UseFormT = {
  closeLeads: string | boolean | null;
  leadsTargetTeam: BaseUiOption<string>[];
  leadsTargetUser: BaseUiOption<string>[];
  removeCampaigns: RemoveCampaignT[];
};

const DeleteTeamForm = ({ teamRef, teamName }: any) => {
  const { mutate } = useRemoveTeam(teamRef);

  const { closeModal } = useModal();

  const client = useClient();

  const [selectedTeam, setSelectedTeam] = useState<string | undefined>();

  const { data: rows } = useGetCampaignsForTeam(teamRef);

  const teams = useGetTeamsForUser();

  const { teamMemberUsers, isLoading: isLoadingTeamMembers } = useTeamMemberUsers(selectedTeam);

  const leadsTargetTeamOptions = teams.data
    ?.filter((item) => item.id !== teamRef && item.teamStatus === 'ACTIVE')
    .map((item) => ({ id: item.id, label: item.name }));

  const leadsTargetUserOptions = teamMemberUsers
    .filter(() => selectedTeam)
    .map((item) => ({
      id: item.userRef,
      label: item.user?.name,
      test: item.user?.id,
    }));

  const {
    handleSubmit,
    control,
    getValues,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm<UseFormT>({
    defaultValues: {
      closeLeads: null,
      leadsTargetTeam: [],
      leadsTargetUser: [],
      removeCampaigns: [],
    },
    resolver: yupResolver(deleteTeamSchema),
  });

  const [closeLeads$] = watch(['closeLeads']);

  const { fields } = useFieldArray({
    control,
    name: 'removeCampaigns',
  });

  const onSubmit = (formData: UseFormT) => {
    const { closeLeads, leadsTargetTeam, leadsTargetUser, removeCampaigns } = formData;

    const model = {
      closeLeads: closeLeads === 'true',
      leadsTargetTeam: closeLeads === 'true' ? null : leadsTargetTeam?.[0]?.id,
      leadsTargetUser: closeLeads === 'true' ? null : leadsTargetUser?.[0]?.id,
      removeCampaigns: removeCampaigns.map((values) => {
        const { id, name, emailAddress, phoneNumber, ...v } = values;
        return {
          ...v,
          removeEmailChannel: {
            ...v.removeEmailChannel,
            saveEmail: v.removeEmailChannel.saveEmail === 'true',
          },
          removePhoneChannel: {
            ...v.removePhoneChannel,
            savePhone: v.removePhoneChannel.savePhone === 'true',
            forwardingNumber: v.removePhoneChannel?.forwardingNumber?.replace(/\D+/g, ''),
          },
        };
      }),
    };

    console.log(model);
    
    mutate(model, {
      onSuccess: (p) => {
        console.log('success', p);
        toast(`${client.state?.displayValue} deleted`);
        closeModal();
      },
      onError: (e) => {
        console.log(e);
        toast('Sorry, we encountered a problem processing your request. Please try again');
        closeModal();
      },
    });
  };

  useEffect(() => {
    if (teamRef) {
      reset();
    }
  }, [reset, teamRef]);

  useEffect(() => {
    if (rows) {
      reset({
        ...getValues(),
        removeCampaigns: rows
          ?.filter((row) => row.status === 'ACTIVE')
          .map((row) => {
            const removeEmailChannel = row.emailChannel?.emailAddress
              ? {
                removeEmailChannel: {
                  forwardingEmail: '',
                  saveEmail: null,
                },
              }
              : {};
            const removePhoneChannel = row.phoneChannel?.phoneNumber
              ? {
                removePhoneChannel: {
                  forwardingNumber: '',
                  savePhone: null,
                },
              }
              : {};
            // Only return channel if campaign includes the channel (to
            // bypass validation of empty channel)
            return {
              name: row.name,
              id: row.id,
              emailAddress: row.emailChannel?.emailAddress,
              phoneNumber: row.phoneChannel?.phoneNumber,
              campaignRef: row.id,
              ...removeEmailChannel,
              ...removePhoneChannel,
            };
          }),
      });
    }
  }, [getValues, reset, rows]);

  const handleTeamSelect = ({ value }: OnChangeParams) => {
    if (value?.[0]?.id) {
      setSelectedTeam(`${value[0].id}`);
    } else {
      setSelectedTeam(undefined);
    }
    setValue('leadsTargetUser', []);
  };

  // const handleCloseLeadsSelect = (event: ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.value === 'false') {
  //     setSelectedTeam(undefined);
  //     setValue('leadsTargetTeam', []);
  //     setValue('leadsTargetUser', []);
  //   }
  // };

  console.log(errors);

  if (!teamRef) return null;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ModalHeader>Delete: {teamName}</ModalHeader>
      <ModalBody>
        <LabelMedium>Team Leads</LabelMedium>
        <ParagraphSmall>
          To delete a {client.state?.displayValue?.toLowerCase()}, all leads must either be closed
          or reassigned to another {client.state?.displayValue?.toLowerCase()} and user.
          Additionally, you must save or release all phone numbers and email addresses that were
          created for each campaign.
        </ParagraphSmall>
        <FormControl
          label={
            <RequiredFieldMarker>
              Do you want to close or reassign the leads for this{' '}
              {client.state?.displayValue?.toLowerCase()}?
            </RequiredFieldMarker>
          }
          error={errors.closeLeads?.message}
        >
          <ControlledRadioButtons
            control={control}
            name="closeLeads"
            radioOptions={[
              { value: 'true', displayValue: 'Close Leads' },
              { value: 'false', displayValue: 'Reassign Leads' },
            ]}
            radioGroupProps={{
              align: 'horizontal',
            }}
            radioProps={{
              overrides: radioOverrides,
            }}
          />
        </FormControl>

        {closeLeads$ === 'false' && (
          <>
            <FormControl
              label={
                <RequiredFieldMarker>Reassign to {client.state?.displayValue}</RequiredFieldMarker>
              }
              error={errors.leadsTargetTeam?.message}
            >
              <ControlledSelect
                name="leadsTargetTeam"
                control={control}
                onChangeHandler={handleTeamSelect}
                baseuiProps={{
                  isLoading: teams.isLoading,
                  size: 'compact',
                  options: leadsTargetTeamOptions,
                }}
              />
            </FormControl>

            <FormControl
              label={<RequiredFieldMarker>Reassign to Team Member</RequiredFieldMarker>}
              error={errors.leadsTargetUser?.message}
            >
              <ControlledSelect
                name="leadsTargetUser"
                control={control}
                baseuiProps={{
                  isLoading: isLoadingTeamMembers,
                  size: 'compact',
                  options: leadsTargetUserOptions,
                  noResultsMsg: `Select a ${client.state?.displayValue?.toLocaleLowerCase()} to load team members`,
                }}
              />
            </FormControl>
          </>
        )}

        <LabelMedium>Campaigns</LabelMedium>
        <ParagraphSmall>
          Save or release the following channels on each campaign listed below. Saved phone numbers
          and emails can be reused on other {client.state?.displayValueAlt?.toLowerCase()} and
          campaigns. If you would like to continue receiveing calls and emails, input the fowarding
          details (optional).
        </ParagraphSmall>
        {fields.map((item, index) => {
          return (
            <StyleRowGroup key={item.id}>
              <StyleRowGroupHeader>{item.name}</StyleRowGroupHeader>
              {item.phoneNumber && (
                <StyledDeleteTeamRow>
                  <StyledDeleteTeamCell>
                    <PatternFormat
                      displayType="text"
                      value={item.phoneNumber}
                      format="+# (###) ###-####"
                    />
                  </StyledDeleteTeamCell>
                  <StyledDeleteTeamCell>
                    <FormControl
                      // label={<RequiredFieldMarker />}
                      error={
                        errors.removeCampaigns?.[index]?.removePhoneChannel?.savePhone?.message
                      }
                      overrides={fcOverrides}
                    >
                      <ControlledRadioButtons
                        control={control}
                        name={`removeCampaigns.${index}.removePhoneChannel.savePhone`}
                        customError={
                          !!errors.removeCampaigns?.[index]?.removePhoneChannel?.savePhone
                        }
                        radioOptions={[
                          {
                            value: 'true',
                            displayValue: (
                              <RequiredFieldMarker styles={{ paddingRight: 0 }}>
                                Save
                              </RequiredFieldMarker>
                            ) as any,
                          },
                          {
                            value: 'false',
                            displayValue: (
                              <RequiredFieldMarker styles={{ paddingRight: 0 }}>
                                Release
                              </RequiredFieldMarker>
                            ) as any,
                          },
                        ]}
                        radioGroupProps={{
                          align: 'horizontal',
                        }}
                        radioProps={{
                          overrides: radioOverrides,
                        }}
                      />
                    </FormControl>
                  </StyledDeleteTeamCell>
                  <StyledDeleteTeamCell>
                    <StyledMiniLabel
                      $err={
                        errors.removeCampaigns?.[index]?.removePhoneChannel?.forwardingNumber
                          ?.message
                      }
                    >
                      {errors.removeCampaigns?.[index]?.removePhoneChannel?.forwardingNumber
                        ?.message || 'Forward calls to:'}
                    </StyledMiniLabel>
                    <ControlledNumberFormat
                      name={`removeCampaigns.${index}.removePhoneChannel.forwardingNumber`}
                      kind="pattern"
                      customError={
                        errors.removeCampaigns?.[index]?.removePhoneChannel?.forwardingNumber
                      }
                      control={control}
                      baseWebProps={{
                        size: 'compact',
                        placeholder: 'Phone Number (Optional)',
                        format: '+1 (###) ###-####',
                        overrides: {
                          Root: {
                            style: () => ({
                              outline: 'red',
                            }),
                          },
                        },
                      }}
                    />
                  </StyledDeleteTeamCell>
                </StyledDeleteTeamRow>
              )}

              {item.emailAddress && (
                <StyledDeleteTeamRow>
                  <StyledDeleteTeamCell>{item.emailAddress}</StyledDeleteTeamCell>
                  <StyledDeleteTeamCell>
                    <FormControl
                      // label={<RequiredFieldMarker />}
                      error={
                        errors.removeCampaigns?.[index]?.removeEmailChannel?.saveEmail?.message
                      }
                      overrides={fcOverrides}
                    >
                      <ControlledRadioButtons
                        control={control}
                        name={`removeCampaigns.${index}.removeEmailChannel.saveEmail`}
                        customError={
                          !!errors.removeCampaigns?.[index]?.removeEmailChannel?.saveEmail
                        }
                        radioOptions={[
                          {
                            value: 'true',
                            displayValue: (
                              <RequiredFieldMarker styles={{ paddingRight: 0 }}>
                                Save
                              </RequiredFieldMarker>
                            ) as any,
                          },
                          {
                            value: 'false',
                            displayValue: (
                              <RequiredFieldMarker styles={{ paddingRight: 0 }}>
                                Release
                              </RequiredFieldMarker>
                            ) as any,
                          },
                        ]}
                        radioGroupProps={{
                          align: 'horizontal',
                        }}
                        radioProps={{
                          overrides: radioOverrides,
                        }}
                      />
                    </FormControl>
                  </StyledDeleteTeamCell>
                  <StyledDeleteTeamCell>
                    <StyledMiniLabel
                      $err={
                        errors.removeCampaigns?.[index]?.removeEmailChannel?.forwardingEmail
                          ?.message
                      }
                    >
                      {errors.removeCampaigns?.[index]?.removeEmailChannel?.forwardingEmail
                        ?.message || 'Forward email to:'}
                    </StyledMiniLabel>
                    <ControlledInput
                      name={`removeCampaigns.${index}.removeEmailChannel.forwardingEmail`}
                      customError={
                        errors.removeCampaigns?.[index]?.removeEmailChannel?.forwardingEmail
                      }
                      control={control}
                      baseWebProps={{
                        size: 'compact',
                        placeholder: 'Email (Optional)',
                      }}
                    />
                  </StyledDeleteTeamCell>
                </StyledDeleteTeamRow>
              )}
            </StyleRowGroup>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          kind="tertiary"
          onClick={closeModal}
          overrides={{
            BaseButton: {
              style: {
                marginRight: '20px',
              },
            },
          }}
        >
          Cancel
        </Button>
        <Button type="submit">Delete</Button>
      </ModalFooter>
    </form>
  );
};

export default DeleteTeamForm;
