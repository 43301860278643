import React from 'react';
import * as S from './styled-components';

type StepperPropsT = {
  children: React.ReactNode;
};

const Step = (props: StepperPropsT) => {
  const { children } = props;
  return <S.Step data-spaceit="step">{children}</S.Step>;
};

export default Step;
