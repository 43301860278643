/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Ring } from './styled-components';

const CustomButton = (props: any) => {
  const [css, theme] = useStyletron();

  const {
    forwardProps: { shouldAnimate, localRef, ...parentProps },
    forwardRef,
  } = props;

  return (
    <Button
      ref={(node: any) => {
        forwardRef.current = node;
        localRef.current = node;
      }}
      kind="tertiary"
      shape="circle"
      size="compact"
      {...parentProps}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            color: theme.colors.mono400,
            ':hover': {
              backgroundColor: $theme.colors.mono800,
            },
          }),
        },
      }}
    >
      <Ring $shouldAnimate={shouldAnimate}>
        <FontAwesomeIcon
          className={css({ })}
          size="lg"
          icon={solid('bell')}
        />
      </Ring>
    </Button>
  );
};

// eslint-disable-next-line arrow-body-style
const WrappedButton = React.forwardRef((props: any, ref) => {
  return (
    <StatefulTooltip
      placement="bottom"
      popoverMargin={8}
      content={() => 'Alerts'}
      onMouseEnterDelay={700}
      overrides={{
        Inner: {
          style: ({ $theme }) => ({
            paddingTop: $theme.sizing.scale0,
            paddingBottom: $theme.sizing.scale0,
            paddingLeft: $theme.sizing.scale500,
            paddingRight: $theme.sizing.scale500,
            ...$theme.typography.ParagraphXSmall,
            borderTopRightRadius: $theme.sizing.scale100,
            borderTopLeftRadius: $theme.sizing.scale100,
            borderBottomRightRadius: $theme.sizing.scale100,
            borderBottomLeftRadius: $theme.sizing.scale100,
          }),
        },
      }}
    >
      <div>
        <CustomButton forwardProps={props} forwardRef={ref} />
      </div>
    </StatefulTooltip>
  );
});

export default WrappedButton;
