import React, { ChangeEvent, useEffect } from 'react';
import { LabelLarge, ParagraphSmall } from 'baseui/typography';
import { Button } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Checkbox } from 'baseui/checkbox';
import { FocusOnce } from 'baseui/modal';
import useAlerts from 'modules/notifications/useAlerts';
import DashboardAlertItem from './DashboardAlertItem';
import {
  StyledList,
  StyledListHeader,
  StyledStackingContext,
  // StyledNotificationBubble,
  StyledActionOuter,
} from './styled-components';

type Props = {
  setShouldAnimate: React.Dispatch<boolean>;
  swipeableRef: React.MutableRefObject<any>;
  close: () => any;
};

const DashboardAlerts = ({ swipeableRef, setShouldAnimate, close }: Props) => {
  const [checked, setChecked] = React.useState(true);

  const [sortDesc, setSortDesc] = React.useState(true);

  const { alerts, count: totalCount } = useAlerts(sortDesc, !checked);

  useEffect(() => {
    swipeableRef?.current?.updateHeight();
  }, [alerts, swipeableRef]);

  useEffect(() => {
    setShouldAnimate(false);
  }, [setShouldAnimate]);

  return (
    <StyledStackingContext>
      <>
        <StyledListHeader>
          <div style={{ flex: '1' }}>
            <FocusOnce>
              <LabelLarge
                $style={{ outline: 'none' }}
                color="mono1000"
                marginTop="scale600"
                marginBottom="scale600"
              >
                Alerts
              </LabelLarge>
            </FocusOnce>
            {/* <StyledNotificationBubble>{totalCount}</StyledNotificationBubble> */}
          </div>
          <StyledActionOuter>
            <Checkbox
              checked={checked}
              // eslint-disable-next-line baseui/deprecated-component-api
              checkmarkType="toggle"
              onChange={(e: ChangeEvent<any>) => setChecked(e.target.checked)}
              labelPlacement="left"
              overrides={{
                Root: {
                  style: {
                    alignItems: 'center',
                  },
                },
                Label: {
                  style: ({ $theme }) => ({
                    ...$theme.typography.LabelXSmall,
                    color: $theme.colors.mono900,
                  }),
                },
                Toggle: {
                  style: ({ $theme, $checked }) => ({
                    width: '12px',
                    height: '12px',
                    transform: `translateX(${$checked ? 13 : 1}px)`,
                    backgroundColor: $checked ? $theme.colors.white : $theme.colors.white,
                  }),
                },
                ToggleTrack: {
                  style: ({ $theme, $checked }) => ({
                    width: '26px',
                    marginTop: $theme.sizing.scale100,
                    marginLeft: $theme.sizing.scale100,
                    backgroundColor: $checked ? $theme.colors.positive400 : $theme.colors.mono500,
                  }),
                },
              }}
            >
              Only available leads
            </Checkbox>
            <Button
              size="mini"
              type="button"
              kind="tertiary"
              onClick={() => setSortDesc(!sortDesc)}
              endEnhancer={
                sortDesc ? (
                  <FontAwesomeIcon icon={solid('arrow-down')} />
                ) : (
                  <FontAwesomeIcon icon={solid('arrow-up')} />
                )
              }
            >
              Sort
            </Button>
          </StyledActionOuter>
        </StyledListHeader>
      </>
      <StyledList>
        {alerts?.map((alert) => (
          <DashboardAlertItem key={alert.id} {...alert} onClick={close} />
        ))}
      </StyledList>
      {!totalCount && (
        <ParagraphSmall $style={{ textAlign: 'center' }} color="mono500">
          You have no alerts
        </ParagraphSmall>
      )}
    </StyledStackingContext>
  );
};

export default DashboardAlerts;
