import { Editor, EditorOptions } from '@tiptap/react';
import { DependencyList, useEffect, useState } from 'react';

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

// https://github.com/ueberdosis/tiptap/issues/2403#issuecomment-1062712162
export const useEditor = (options: Partial<EditorOptions> = {}, deps: DependencyList = []) => {
  const [editor, setEditor] = useState<Editor>(() => new Editor(options));
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    let instance: Editor;

    if (editor.isDestroyed) {
      instance = new Editor(options);
      setEditor(instance);
    } else {
      instance = editor;
    }

    instance.on('transaction', () => {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          forceUpdate();
        });
      });
    });

    return () => {
      instance.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return editor;
};
