import React from 'react';
import type { SelectHTMLAttributes } from 'react';
import { useStyletron } from 'baseui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { StyleObject } from 'styletron-react';

type Sizes = 'default' | 'large' | 'compact' | 'mini';

type SelectPropsT = {
  name: string;
  layout?: Sizes;
  options: { id: string; label: string }[];
  registerConfig?: RegisterOptions;
  rootStyles?: StyleObject;
};

const Select = React.forwardRef<
HTMLSelectElement,
SelectPropsT & SelectHTMLAttributes<HTMLSelectElement>
>((props, r) => {
  const {
    layout,
    name,
    options,
    onChange: onChangeHandler,
    disabled,
    placeholder,
    registerConfig,
    rootStyles,
    ...rest
  } = props;

  const [css, theme] = useStyletron();

  const {
    register,
    formState: { errors },
  } = useFormContext<any>();

  const [focus, setFocus] = React.useState(false);

  const error = Boolean(errors[name]);

  const { onChange, ref, ...registerField } = register(name, registerConfig);

  const getStyles = () => {
    switch (layout) {
      case 'large':
        return {
          paddingTop: theme.sizing.scale550,
          paddingBottom: theme.sizing.scale550,
          ...theme.typography.ParagraphLarge,
        };
      case 'compact':
        return {
          paddingTop: theme.sizing.scale200,
          paddingBottom: theme.sizing.scale200,
          ...theme.typography.ParagraphSmall,
        };
      case 'mini':
        return {
          paddingTop: theme.sizing.scale100,
          paddingBottom: theme.sizing.scale100,
          ...theme.typography.ParagraphXSmall,
        };
      default:
        return {
          paddingTop: theme.sizing.scale400,
          paddingBottom: theme.sizing.scale400,
          ...theme.typography.ParagraphMedium,
        };
    }
  };

  return (
    <div
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={css({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: error ? theme.colors.inputFillError : theme.colors.inputFill,
        borderWidth: theme.sizing.scale0,
        borderStyle: 'solid',
        // eslint-disable-next-line no-nested-ternary
        borderColor: error
          ? theme.colors.inputBorderError
          : focus
            ? theme.colors.borderSelected
            : theme.colors.inputBorder,
        borderRadius: theme.borders.inputBorderRadius,
        transitionProperty: 'border, box-shadow, background-color',
        transitionTimingFunction: theme.animation.easeInCurve,
        transitionDuration: theme.animation.timing200,
        ':focus-visible': {
          borderColor: theme.colors.borderSelected,
          backgroundColor: theme.colors.inputFillActive,
        },
        ...rootStyles,
      })}
    >
      <select
        disabled={disabled}
        {...registerField}
        ref={(n) => {
          if (n) {
            ref(n);
          }
          if (typeof r === 'function') {
            r(n);
          } else if (r) {
            // eslint-disable-next-line no-param-reassign
            r.current = n;
          }
        }}
        onChange={(e) => {
          if (onChangeHandler) {
            onChangeHandler(e);
          }
          onChange(e);
        }}
        className={css({
          display: 'block',
          width: '100%',
          outline: 'none',
          backgroundColor: 'transparent',
          ...getStyles(),
          paddingLeft: theme.sizing.scale400,
          paddingRight: theme.sizing.scale800,
          border: 'none',
          borderRadius: theme.borders.inputBorderRadius,
          appearance: 'none',
          cursor: disabled ? 'not-allowed' : 'default',
        })}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.label} value={option.id}>
            {option.label}
          </option>
        ))}
      </select>
      <span
        className={css({
          display: 'inline-flex',
          fontSize: theme.sizing.scale500,
          pointerEvents: 'none',
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
        })}
      >
        <FontAwesomeIcon icon={solid('caret-down')} />
      </span>
    </div>
  );
});

export default Select;
