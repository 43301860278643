import React, { useEffect, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useCurrentUserId } from 'context/current-user-context';
import { Communication } from '../../backend/communication';
import IncomingTextAlert from './IncomingTextAlert';
import IncomingEmailAlert from './IncomingEmailAlert';
import { MessageEvent } from '../../backend/messages';
import IncomingWebFormAlert from './IncomingWebFormAlert';
import { useWebSocketObservable } from '../../context/web-socket-context';
import IncomingCallAlert from './IncomingCallAlert';
import { TextMessageContext } from '../textMessage/text-message-context';

const useIncomingCommSubscription = () => {
  const currentUserId = useCurrentUserId();

  const queryClient = useQueryClient();

  const messages$ = useWebSocketObservable();

  const textMsgContext = useContext(TextMessageContext);

  const currentConversation = textMsgContext.state;

  const closeToast = useCallback(() => {
    toast.dismiss();
  }, []);

  const invalidateQueries = useCallback(
    (leadRef: string) => {
      queryClient.invalidateQueries(['getAlerts']);
      queryClient.invalidateQueries(['getLead', leadRef]);
      queryClient.invalidateQueries(['getIncomingNotDismissed']);
    },
    [queryClient],
  );

  const openToast = useCallback(
    ({ assignedAgent, details, eventType, unassignedFollowup }: MessageEvent<Communication>) => {
      const key = `${eventType}_${details.communicationType}_${unassignedFollowup}`.toUpperCase();
      const isNotMyLead = currentUserId !== assignedAgent;

      switch (key) {
        case 'INCOMING_COMMUNICATION_TEXT_MESSAGE_TRUE':
        case 'INCOMING_COMMUNICATION_TEXT_MESSAGE_FALSE':
          invalidateQueries(details.leadRef);
          if (
            (currentConversation?.isOpen && currentConversation?.leadRef === details.leadRef) ||
            isNotMyLead
          ) {
            return;
          }
          toast(
            <IncomingTextAlert
              details={details}
              close={closeToast}
              unassignedFollowup={unassignedFollowup}
            />,
          );
          break;

        case 'INCOMING_COMMUNICATION_EMAIL_TRUE':
        case 'INCOMING_COMMUNICATION_EMAIL_FALSE':
          invalidateQueries(details.leadRef);
          if (isNotMyLead) return;
          toast(
            <IncomingEmailAlert
              details={details}
              close={closeToast}
              unassignedFollowup={unassignedFollowup}
            />,
          );
          break;

        case 'INCOMING_COMMUNICATION_WEBFORM':
          invalidateQueries(details.leadRef);
          if (isNotMyLead) return;
          toast(
            <IncomingWebFormAlert
              details={details}
              close={closeToast}
              unassignedFollowup={unassignedFollowup}
            />,
          );
          break;

        case 'INCOMING_COMMUNICATION_CALL_TRUE':
        case 'INCOMING_COMMUNICATION_CALL_FALSE':
          invalidateQueries(details.leadRef);
          if (isNotMyLead) return;
          toast(
            <IncomingCallAlert
              details={details}
              close={closeToast}
              unassignedFollowup={unassignedFollowup}
            />,
          );
          break;

        // no default
      }
    },
    [closeToast, currentConversation, invalidateQueries, currentUserId],
  );

  useEffect(() => {
    const sub = messages$?.subscribe({
      next: (messageEvent: MessageEvent<Communication>) => {
        if (messageEvent.eventType === 'INCOMING_COMMUNICATION') {
          openToast({ ...messageEvent });
        }
      },
      error: (e) => console.error(e),
      complete: () => console.info('[useTextMessageAlert] complete'),
    });

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [messages$, openToast]);

  return { closeToast };
};

export default useIncomingCommSubscription;
