import * as yup from 'yup';
import useAxios from '../../../hooks/useAxios';

export const useValidEmail = () => {
  const api = useAxios();

  return function test(this: yup.StringSchema) {
    return this.test('unique', 'The email address is unavailable', async function validator(value) {
      const customDomain = this.options.context?.suffix;
      // const emailHosting = this.options.context?.emailHosting;
      if (value) {
        const address = value.toLowerCase() + customDomain;
        const { data } = await api.get(`/api/email/exists?email=${encodeURIComponent(address)}`);
        if (data) {
          return this.createError();
        }
      }
      return true;
    });
  };
};

export const useValidTeamName = () => {
  const api = useAxios();

  return function test(this: yup.StringSchema) {
    return this.test(
      'unique',
      'This team name already exists, please try a different name',
      async function validator(value) {
        if (value) {
          const { data } = await api.get(`/api/team/name-exists?name=${encodeURIComponent(value)}`);
          if (data) {
            return this.createError();
          }
        }
        return true;
      },
    );
  };
};

export const useValidCampaignName = () => {
  const api = useAxios();

  return function test(this: yup.StringSchema) {
    return this.test(
      'unique',
      'This campaign name already exists, please try a different name',
      async function validator(value) {
        const listingRefFromParent = this.parent.listingRef?.[0]?.id;
        const teamId = this.options.context?.teamId;
        const category = this.options.context?.category;
        const listingRef = this.options.context?.listingRef || listingRefFromParent;
        const url = [`api/team/${teamId}/campaign-name-exists?name=${value}&category=${category}`];
        if (listingRef) {
          url.push(`listing=${listingRef}`);
        }
        const { data } = await api.get(`${url.join('&')}`);
        if (data) {
          return this.createError();
        }
        return true;
      },
    );
  };
};
