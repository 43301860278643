import { styled } from 'baseui';

export const StyledCallHeaderRoot = styled<'div', { $isActive: boolean }>(
  'div',
  ({ $theme, $isActive }) => ({
    flex: '0 0 auto',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    color: $theme.colors.black,
    justifyContent: 'space-between',
    willChange: 'transform, max-height',
    maxHeight: $isActive ? '150px' : '0px',
    transition: 'max-height 500ms 400ms',
    backgroundColor: $theme.colors.mono200,
    // borderBottom: `1px solid ${$theme.colors.mono200}`,
    boxShadow: `0 0 1px ${$theme.colors.mono700}`,
  }),
);

export const StyledContent = styled('div', ({ $theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flex: 1,
  flexWrap: 'wrap',
  paddingLeft: $theme.sizing.scale600,
  paddingRight: $theme.sizing.scale600,
  paddingTop: $theme.sizing.scale400,
  paddingBottom: $theme.sizing.scale400,
}));

export const StyledControlContainer = styled('div', () => ({
  alignItems: 'center',
  display: 'flex',
  flex: '1 1 auto',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}));

export const StyledTitle = styled('span', ({ $theme }) => ({
  color: $theme.colors.white,
  backgroundColor: $theme.colors.positive300,
  borderRadius: '4px',
  display: 'inline-block',
  marginRight: $theme.sizing.scale100,
  padding: $theme.sizing.scale100,
  verticalAlign: 'middle',
  ...$theme.typography.ParagraphXSmall,
}));

export const StyledControlGroup = styled('div', () => ({
  alignItems: 'center',
  display: 'flex',
}));

export const StyledVoipControlContainer = styled('div', () => ({
  alignItems: 'center',
  display: 'flex',
  flex: '0 0 auto',
}));

// export const StyledHeight = styled<'div', { $showCall: boolean }>('div', ({ $showCall }) => ({
//   flex: '0 0 auto',
//   height: $showCall ? '45px' : '0px',
// }));

// const animation: any = {
//   from: { opacity: 0.5 },
//   to: { opacity: 1 },
// };

// export const StyledIconAnimation = styled('div', ({ $theme }) => ({
//   display: 'flex',
//   width: '32px',
//   height: '32px',
//   fontSize: '14px',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: $theme.colors.white,
//   borderRadius: '50% 50%',
//   animationDuration: '1s',
//   animationName: animation,
//   animationDirection: 'alternate',
//   animationIterationCount: 'infinite',
//   marginRight: $theme.sizing.scale300,
//   background: $theme.colors.positive400,
// }));
