import { useMutation, useQuery, useQueryClient } from 'react-query';
import { TemplatedResponse } from '../../../backend/team';
import useAxios from '../../../hooks/useAxios';

type Params = Pick<TemplatedResponse, 'teamRef'> & Partial<TemplatedResponse>;

function useTemplateResponse() {
  const axios = useAxios();
  return {
    async getTemplatedResponse({ queryKey }: any) {
      const [, id] = queryKey;
      if (typeof id === 'undefined') return Promise.reject();
      const url = `api/templated-response/${id}`;
      const { data } = await axios.get<TemplatedResponse>(url);
      return data;
    },
    async updateTemplatedResponse(data: Params) {
      if (!data.id) return Promise.reject(new Error('Missing id'));
      return axios.put('api/templated-response/', data);
    },
    async updateTemplatedResponsePrivacy({
      id,
      privateAccess,
    }: Pick<Params, 'id' | 'privateAccess'>) {
      if (!id) return Promise.reject(new Error('Missing id'));
      return axios.put(`api/templated-response/${id}/privacy?privateAccess=${privateAccess}`);
    },
    async getTemplatedResponseForTeam({ queryKey }: any) {
      const [, teamRef, filters] = queryKey;
      if (typeof teamRef === 'undefined') return Promise.reject();
      const url = [`api/team/${teamRef}/templated-response`];
      if (filters.templateType) {
        url.push(`?templateType=${filters.templateType}`);
      }
      const { data } = await axios.get<TemplatedResponse[]>(url.join(''));
      return data;
    },
    async addTemplatedRespons({ teamRef, ...data }: Params) {
      console.log({ teamRef, data });
      if (!teamRef) return Promise.reject(new Error('Missing teamRef'));
      return axios.post(`api/team/${teamRef}/templated-response`, data);
    },
  };
}

function useGetTemplatedResponse(id: string | undefined) {
  const { getTemplatedResponse } = useTemplateResponse();
  return useQuery({
    queryFn: getTemplatedResponse,
    queryKey: ['getTemplatedResponse', id],
    enabled: Boolean(id),
    retry: false,
  });
}

function useUpdateTemplatedResponse() {
  const { updateTemplatedResponse } = useTemplateResponse();
  const queryClient = useQueryClient();
  return useMutation((variables: Params) => updateTemplatedResponse(variables), {
    onSuccess: (_d, variables) => {
      queryClient.invalidateQueries(['getTemplatedResponse', variables.id]);
      queryClient.invalidateQueries(['getTemplatedResponseForTeam', variables.teamRef]);
    },
  });
}

function useUpdateTemplatedResponsePrivacy() {
  const { updateTemplatedResponsePrivacy } = useTemplateResponse();
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Params) =>
      updateTemplatedResponsePrivacy({
        id: variables.id,
        privateAccess: variables.privateAccess,
      }),
    {
      onSuccess: (_d, variables) => {
        queryClient.invalidateQueries(['getTemplatedResponse', variables.id]);
        queryClient.invalidateQueries(['getTemplatedResponseForTeam', variables.teamRef]);
      },
    },
  );
}

function useGetTemplatedResponseForTeam(params: Params) {
  const { getTemplatedResponseForTeam } = useTemplateResponse();
  const { teamRef, ...filters } = params;
  return useQuery({
    queryFn: getTemplatedResponseForTeam,
    queryKey: ['getTemplatedResponseForTeam', teamRef, filters],
    enabled: Boolean(params.teamRef),
    retry: false,
  });
}

function useAddTemplatedResponse() {
  const { addTemplatedRespons } = useTemplateResponse();
  const queryClient = useQueryClient();
  return useMutation((variables: Params) => addTemplatedRespons(variables), {
    onSuccess: (_d, variables) => {
      console.log('successfully added!');
      queryClient.invalidateQueries(['getTemplatedResponseForTeam', variables.teamRef]);
    },
  });
}

export {
  useAddTemplatedResponse,
  useUpdateTemplatedResponse,
  useUpdateTemplatedResponsePrivacy,
  useGetTemplatedResponse,
  useGetTemplatedResponseForTeam,
};
