export const GREETING_METHOD = {
  NO_GREETING: 'No Greeting',
  READ_TEXT: 'Read Text',
  PLAY_RECORDING: 'Play Recording',
  USE_DEFAULT: 'Default',
};

export const ROUTING_METHOD = {
  RING_ALL: 'Ring All',
  DISTRIBUTED: 'Distributed',
  SPECIFIC_ORDER: 'Specific Order',
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const PHONE_TYPE = {
  PURCHASE: 'Purchase',
  SAVED: 'Saved',
};

export const CAMPAIGN_TYPE = {
  CHANNEL: 'Channel',
  FORM: 'Form',
  FEED: 'Feed',
};

export const LISTING_STATUS = {
  ACTIVE: 'Available',
  INACTIVE: 'Unavailable',
};

export const STATUS_CIRCLE = {
  ACTIVE: 'positive300',
  INACTIVE: 'negative300',
};

export const TEAM_MEMBER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const ROLE = {
  TEAM_LEAD: 'Team Leader',
  GENERAL: 'General',
};

export const TEAM_TYPE = {
  BROKER: 'Broker',
  OWNER: 'Owner',
};

export const POLLY_VOICES = ['Joanna', 'Matthew'];

export const POLLY_VOICE_LABEL: { [key: string]: string } = {
  Joanna: 'Female',
  Matthew: 'Male',
};

export const TEAM_ASSIGNMENT_MODE = {
  CLAIM: 'Claim',
  ASSIGN: 'Assign',
};

export const TEAM_LOCK_MODE = {
  NONE: 'None',
  TIMED: 'Timed',
  UNLIMITED: 'Unlimited',
};

export const TEAM_COLLABORATION_MODE = {
  FULL: 'Full',
  INDIVIDUAL: 'Individual',
  VISIBILITY: 'Read-only',
};

export const DEFAULT_TEAM_HOME = '/team/';

export const EMAIL_AUTH_STATUS = {
  AUTHED: 'Connected',
  FAILED: 'Failed',
};

export const CAMPAIGN_SCOPE = {
  WHOLE_TEAM: 'Everyone',
  SPECIFIC_MEMBERS: 'Specific Team Members',
  FEED_ADDRESS_OWNER: 'Feed Owner',
};