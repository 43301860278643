import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import useAxios from '../../../hooks/useAxios';
import { Greeting } from '../../../backend/team';

const useGetGreetings = (
  options?: Omit<UseQueryOptions<Greeting[] | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getGreetings = async () => {
    const { data } = await axios.get('api/greetings');
    return data;
  };

  return useQuery<Greeting[] | undefined, AxiosError>('getGreetings', getGreetings, {
    refetchOnWindowFocus: false,
    ...options,
  });
};

export default useGetGreetings;
