import React, { useState } from 'react';
import { useStyletron } from 'baseui';
import { Input } from 'baseui/input';
import { FormControl } from 'baseui/form-control';
import ReactAudioPlayer from 'react-audio-player';
import { FileUploader } from 'baseui/file-uploader';
import { Controller, useForm } from 'react-hook-form';
import { Button, KIND, SIZE } from 'baseui/button';
import { LabelSmall, LabelXSmall } from 'baseui/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Spinner, SIZE as SPINNERSIZE } from 'baseui/spinner';
import useFileReader from './useFileReader';
import useUpload from './hooks/useUpload';
import useGreetingUploadUrl from './hooks/useGreetingUploadUrl';

interface Props {
  onSave: (value: any) => void;
  setTargetFile: (value: any) => void;
}

const FileUpload = (props: Props) => {
  const [css, theme] = useStyletron();

  const { onSave, setTargetFile } = props;

  const [fileObj, setFileObj] = useState<File>();

  const uploadFile = useUpload();

  const getSignedGreetingUrl = useGreetingUploadUrl();

  const { isLoading, reader, dataFile, setDataFile, clearReaderError } = useFileReader();

  const removeFile = () => {
    setDataFile('');
  };

  const formInstance = useForm({
    defaultValues: {
      fileName: '',
    },
  });
  const {
    control,
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = formInstance;

  const onSubmit = async (data: { fileName: string }) => {
    if (fileObj) {
      getSignedGreetingUrl
        .mutateAsync(data)
        .then(({ data: { uploadUrl, filename, contentType } }) => {
          uploadFile.mutate(
            { url: uploadUrl, file: fileObj, contentType, queryKeys: ['getGreetings'] },
            {
              onSuccess: () => {
                reset();
                onSave(false); // close modal
                setTargetFile([{ id: filename, label: data.fileName }]);
              },
              onError: (err) => {
                console.log(err);
              },
            },
          );
        })
        .catch((err) => {
          setError('fileName', {
            ...err,
            message: err.detail || err.message,
          });
        });
    }
  };

  return (
    <div className={css({ minHeight: '230px' })}>
      {dataFile ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          {uploadFile.isLoading ? (
            <div className={css({ display: 'flex', justifyContent: 'center' })}>
              <Spinner $size={SPINNERSIZE.medium} />
            </div>
          ) : (
            <>
              <FormControl label="Name" error={errors.fileName?.message}>
                <Controller
                  name="fileName"
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field }) => (
                    <Input
                      endEnhancer={() => (
                        <Button kind={KIND.tertiary} size={SIZE.mini} onClick={removeFile}>
                          <FontAwesomeIcon
                            icon={regular('trash-alt')}
                            color={theme.colors.mono500}
                          />{' '}
                          <LabelXSmall color="mono500" paddingLeft="scale200">
                            Remove
                          </LabelXSmall>
                        </Button>
                      )}
                      disabled={isLoading}
                      size="default"
                      error={!!errors?.fileName}
                      {...field}
                      placeholder="Enter a name for this file"
                      overrides={{
                        Root: {
                          style: { flex: '1 1' },
                        },
                      }}
                    />
                  )}
                />
              </FormControl>

              <LabelSmall marginBottom="scale600">Preview Audio</LabelSmall>
              <ReactAudioPlayer
                className={css({
                  width: '100%',
                })}
                controls
                src={dataFile}
              />
              <div
                className={css({
                  alignItems: 'center',
                  display: 'flex',
                  marginTop: theme.sizing.scale600,
                  justifyContent: 'flex-end',
                })}
              >
                <Button
                  size="compact"
                  type="button"
                  kind={KIND.tertiary}
                  onClick={() => onSave(false)}
                >
                  Cancel
                </Button>
                <Button
                  size="compact"
                  disabled={uploadFile.isLoading || !dataFile}
                  type="submit"
                  kind={KIND.primary}
                >
                  Upload
                </Button>
              </div>
            </>
          )}
        </form>
      ) : (
        <FileUploader
          accept={['audio/mpeg', 'audio/wav']}
          // errorMessage={errors?.fileUpload?.message ? 'Upload Failed' : ''}
          progressMessage={isLoading ? 'Uploading...hang tight..' : ''}
          onDrop={(acceptedFiles) => {
            const file = acceptedFiles[0];
            setFileObj(file);
            reader.readAsDataURL(file);
            setValue('fileName', acceptedFiles[0].name);
          }}
          onRetry={() => {
            clearReaderError();
          }}
          overrides={{
            Root: {
              style: () => ({
                // border : '1px solid red',
              }),
            },
            ContentMessage: {
              style: ({ $isDragActive, $theme }) => ({
                color: $isDragActive ? $theme.colors.mono800 : $theme.colors.mono600,
              }),
            },
            FileDragAndDrop: {
              style: ({ $isDragActive, $theme }) => ({
                paddingTop: $theme.sizing.scale1200,
                paddingRight: $theme.sizing.scale1200,
                paddingBottom: $theme.sizing.scale1200,
                paddingLeft: $theme.sizing.scale1200,
                backgroundColor: $isDragActive ? $theme.colors.white : $theme.colors.white,
                borderLeftColor: $isDragActive ? $theme.colors.positive300 : $theme.colors.mono300,
                borderRightColor: $isDragActive ? $theme.colors.positive300 : $theme.colors.mono300,
                borderTopColor: $isDragActive ? $theme.colors.positive300 : $theme.colors.mono300,
                borderBottomColor: $isDragActive
                  ? $theme.colors.positive300
                  : $theme.colors.mono300,
              }),
            },
          }}
        />
      )}
    </div>
  );
};

export default FileUpload;
