import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { TeamGreetingSample } from '../../../backend/team';
import useAxios from '../../../hooks/useAxios';

const useGetGreetingSample = (
  text: string | undefined,
  voice: string = 'Kendra',
  options?: Omit<
  UseQueryOptions<TeamGreetingSample | undefined, AxiosError>,
  'queryKey' | 'queryFn'
  >,
) => {
  const axios = useAxios();

  const getGreetingUrl = async () => {
    if (typeof text === 'undefined') return Promise.reject();
    const { data } = await axios.get<TeamGreetingSample>(
      `/api/greeting/sample-url?say=${encodeURIComponent(text)}&voice=${voice}`,
    );
    return data;
  };

  return useQuery<TeamGreetingSample | undefined, AxiosError>(
    ['getGreetingUrl', text, voice],
    getGreetingUrl,
    {
      staleTime: 0,
      enabled: !!text,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetGreetingSample;
