import React, { useEffect, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import NewLeadAlert from './NewLeadAlert';
import { useWebSocketObservable } from '../../context/web-socket-context';
import { LeadCreated, MessageEvent } from '../../backend/messages';

const useNewLeadSubscription = () => {
  const queryClient = useQueryClient();

  const messages$ = useWebSocketObservable();

  const closeToast = useCallback(() => {
    toast.dismiss();
  }, []);

  const openToast = useCallback(
    ({ details, eventType, assignmentMode }: MessageEvent<LeadCreated>) => {
      try {
        switch (eventType) {
          case 'LEAD_CREATED': {
            const isLeadAssigned = !!details.assignedAgentRef;
            const isWebform = details.initialCommunicationDetails?.communicationType === 'WEBFORM';
            const title =
              assignmentMode === 'ASSIGN'
                ? 'New Lead Available for Assignment'
                : 'New Lead Available for Claiming';

            queryClient.invalidateQueries(['getLead', details.id]);
            queryClient.invalidateQueries(['getAlerts']);
            queryClient.invalidateQueries(['getLeadPool']);
            queryClient.invalidateQueries(['openHouseLeadAttendance', details.campaignRef]);
            if (!isLeadAssigned || isWebform) {
              toast(<NewLeadAlert details={details} title={title} />);
            }
            break;
          }

          case 'LEAD_SHARED': {
            const title = 'New Lead';
            const message = 'A lead has been shared with you';
            queryClient.invalidateQueries(['getLead', details.id]);
            queryClient.invalidateQueries(['getAlerts']);
            queryClient.invalidateQueries(['getLeadPool']);
            toast(<NewLeadAlert details={details} title={title} message={message} />);
          }

          // no default
        }
      } catch (e) {
        console.log(e);
      }
    },
    [queryClient],
  );

  useEffect(() => {
    const sub = messages$?.subscribe({
      next: (messageEvent: MessageEvent<LeadCreated>) => {
        openToast({ ...messageEvent });
      },
      error: (e) => console.error(e),
      complete: () => console.info('[useLeadSubscription] complete'),
    });

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [messages$, openToast]);

  return { closeToast };
};

export default useNewLeadSubscription;
