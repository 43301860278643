import React from 'react';
import { styled } from 'baseui';
import { Colors } from 'baseui/theme';
import { LabelSmall } from 'baseui/typography';
import { Grid, Cell } from 'baseui/layout-grid';

type BannerProps = {
  children: React.ReactNode;
  isOpen: boolean;
  icon: React.ReactNode;
  title: React.ReactNode;
  appearance: keyof Colors;
};

const StyledOuter = styled<'div', { $appearence: keyof Colors }>(
  'div',
  ({ $theme, $appearence }) => ({
    backgroundColor: $theme.colors[$appearence],
  }),
);

const StyledInner = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  color: $theme.colors.mono900,
  alignItems: 'center',
  justifyContent: 'flex-start',
  ...$theme.typography.ParagraphMedium,
  fontSize: '14px',
  marginTop: $theme.sizing.scale300,
  marginBottom: $theme.sizing.scale300,
}));

const StyledContent = styled('span', () => ({
  flex: '0 1 auto',
}));

const Banner = ({ children, icon, isOpen, title, appearance }: BannerProps) => {
  if (isOpen) {
    return (
      <StyledOuter $appearence={appearance}>
        <Grid>
          <Cell span={[12]}>
            <StyledInner>
              {icon}
              <LabelSmall
                color="mono900"
                marginLeft="scale200"
                marginRight="scale200"
                flex="0 0 auto"
              >
                {title}
              </LabelSmall>
              <StyledContent>{children}</StyledContent>
            </StyledInner>
          </Cell>
        </Grid>
      </StyledOuter>
    );
  }

  return null;
};

export default Banner;
