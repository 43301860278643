import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClient } from '../../../../context/client-context';

const useTeamLocation = () => {
  const client = useClient();

  const location = useLocation();

  const navigate = useNavigate();

  const [selectedTeam, setSelectedTeam] = useState<string | undefined>();

  const currentPath = location.pathname;

  useEffect(() => {
    if (selectedTeam) {
      try {
        const [, root, , ...r] = [...currentPath.split('/')];
        const newLocation = [root, selectedTeam, ...r].join('/');
        setSelectedTeam(undefined);
        navigate(`/${newLocation}`);
      } catch {
        setSelectedTeam(undefined);
        navigate(`/${client.state?.displayValueAlt.toLocaleLowerCase()}`);
      }
    }
  }, [currentPath, client.state?.displayValueAlt, selectedTeam, navigate]);

  return {
    setSelectedTeam,
  };
};

export default useTeamLocation;
