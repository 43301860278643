import { concatName } from 'helpers/util';
import { Communication } from '../../../backend/communication';
import { InitialCommunicationT } from '../../../backend/lead';

const useNameFromCommunication = (defaultName: string = 'unknown contact') => {
  const lookupName = (
    communication: Communication | undefined,
    initialCommunicationDetails?: InitialCommunicationT | null,
    mergedInitialCommunicationDetails?: InitialCommunicationT[],
  ) => {
    if (!communication) return defaultName;

    const type = communication.communicationType;

    switch (type) {
      case 'CALL': {
        const mergedCommunicationDetail = mergedInitialCommunicationDetails?.filter(
          (mc) => mc.phoneNumber === communication.calledFromNumber,
        )[0];

        const name = mergedCommunicationDetail
          ? mergedCommunicationDetail.name
          : initialCommunicationDetails?.name;

        const callerIdName = concatName(
          communication?.callerId?.firstName,
          communication?.callerId?.lastName,
        );

        return callerIdName || name || defaultName;
      }
      case 'TEXT_MESSAGE': {
        const mergedCommunicationDetail = mergedInitialCommunicationDetails?.filter(
          (mc) => mc.phoneNumber === communication.textedFromNumber,
        )[0];

        const name = mergedCommunicationDetail
          ? mergedCommunicationDetail.name
          : initialCommunicationDetails?.name;

        const callerIdName = concatName(
          communication?.callerId?.firstName,
          communication?.callerId?.lastName,
        );
        return callerIdName || name || defaultName;
      }
      case 'EMAIL':
      case 'WEBFORM': {
        const mergedCommunicationDetail = mergedInitialCommunicationDetails?.filter(
          (mc) => mc.emailAddress === communication.fromAddress,
        )[0];

        const name = mergedCommunicationDetail
          ? mergedCommunicationDetail.name
          : initialCommunicationDetails?.name;

        return communication.name || name || defaultName;
      }
      default:
        return defaultName;
    }
  };

  return { lookupName };
};

export default useNameFromCommunication;
