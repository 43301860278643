import React, { useState, useEffect, useMemo, ChangeEvent } from 'react';
import { Tag } from 'baseui/tag';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { Select } from 'baseui/select';
import { Checkbox } from 'baseui/checkbox';
import usePropertiesByTeam from 'components/properties/hooks/usePropertiesByTeam';
import { Row, useTable, useSortBy, useRowSelect, useGlobalFilter, Column } from 'react-table';
import { FormControl } from 'baseui/form-control';
import { ParagraphSmall } from 'baseui/typography';
import useGetTeam from '../team/hooks/useGetTeam';
import GlobalFilter from './GlobalFilter';
import StandardTable from '../table/StandardTable';
import { MarketingMaterial, MarketingMaterialType } from '../../backend/client';
import useGetProperties from '../properties/hooks/useGetProperties';
import useGetMarketingMaterials from '../properties/hooks/useGetMarketingMaterials';

type MarketingPropsT = {
  propertyRef?: string;
  teamRef: string | undefined;
  onClose: () => void;
  onAttach: (value: any[]) => any;
};

const Marketing = (prop: MarketingPropsT) => {
  const { teamRef, onAttach, propertyRef, onClose } = prop;

  const [css, theme] = useStyletron();

  const { data: team } = useGetTeam(teamRef);

  const propertiesQuery = useGetProperties();

  const [selectedMedia, setSelectedMedia] = useState<any>([]);

  const [selectedProperty, setSelectedProperty] = useState<any>([]);

  const mediaQuery = useGetMarketingMaterials(selectedProperty?.[0]?.id);

  // TODO - lead's assignedProperty may no longer be on team when
  // populating the team property <select>. May not matter so we can
  // possibly remove this query for the default property and just use propertiesByTeam
  const properties = propertiesQuery.data;

  const { data: teamListedProperties } = usePropertiesByTeam(team?.properties);

  const defaultProperty = propertyRef
    ? properties?.filter((property) => property.id === propertyRef)[0]
    : undefined;

  const toggleRowSelect = (event: ChangeEvent<HTMLInputElement>, row: Row<MarketingMaterial>) => {
    if (event.target.checked) {
      setSelectedMedia((prev: any) => [...prev, row.original]);
    } else {
      setSelectedMedia((prev: any) => prev.filter((media: any) => media.id !== row.original.id));
    }
  };

  const toggleAllRowsSelected = (
    event: ChangeEvent<HTMLInputElement>,
    rows: Row<MarketingMaterial>[],
  ) => {
    const originalRowsIds = rows.map((r) => r.original.id);
    if (event.target.checked) {
      setSelectedMedia((prev: any) => [...prev, ...rows.map((r) => r.original)]);
    } else {
      setSelectedMedia((prev: any) =>
        prev.filter((media: any) => !originalRowsIds.includes(media.id)),
      );
    }
  };

  const data = useMemo(
    () =>
      mediaQuery.data?.map((media) => {
        const propertyName = properties?.filter(
          (property) => property.id === media.propertyRef,
        )?.[0].name;
        return {
          ...media,
          propertyName,
        };
      }) || [],
    [mediaQuery.data, properties],
  );

  const columns = useMemo(() => {
    const tableColumns = [
      {
        Header: 'Name',
        accessor: 'name' as const,
      },
      {
        Header: 'Type',
        accessor: 'marketingMaterialType' as const,
        Cell: ({ value }: { value: MarketingMaterialType }) => {
          if (value === 'FILE') {
            return 'PDF';
          }
          return 'URL';
        },
      },
    ] as Column<MarketingMaterial>[];
    return tableColumns;
  }, []);

  const tableInstance = useTable(
    {
      data,
      columns,
      autoResetSelectedRows: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
    },
    useGlobalFilter,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((cols) => [
        {
          Header: ({ getToggleAllRowsSelectedProps, rows }: any) => {
            const { onChange, ...rest } = getToggleAllRowsSelectedProps();
            return (
              <Checkbox
                {...rest}
                disabled={rows.length === 0}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  if (typeof onChange === 'function') {
                    onChange(ev);
                  }
                  toggleAllRowsSelected(ev, rows);
                }}
              />
            );
          },
          id: 'selection',
          width: '5%',
          Cell: ({ row }: { row: Row<MarketingMaterial> }) => {
            const { onChange, ...rowProps } = row.getToggleRowSelectedProps();
            return (
              <Checkbox
                {...rowProps}
                onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                  onChange!(ev);
                  toggleRowSelect(ev, row);
                }}
              />
            );
          },
        },
        ...cols,
      ]);
    },
  );

  const { setGlobalFilter, rows, toggleRowSelected } = tableInstance;

  const handleTagClick = (id: string) => {
    // Remove tag & row if in view
    const match = rows.filter((row) => row.original.id === id)[0];
    setSelectedMedia((prev: any) => prev.filter((media: any) => media.id !== id));
    if (match) {
      toggleRowSelected(match.id, false);
    }
  };

  useEffect(() => {
    // sync table state with selected state
    selectedMedia.forEach((media: MarketingMaterial) => {
      const match = rows.filter((row) => row.original.id === media.id)[0];
      if (match) {
        toggleRowSelected(match.id, true);
      }
    });
  }, [rows, selectedMedia, toggleRowSelected]);

  useEffect(() => {
    if (defaultProperty) {
      setSelectedProperty([{ id: defaultProperty.id, label: defaultProperty.name }]);
    }
  }, [defaultProperty]);

  return (
    <>
      <div className={css({ flex: '1 1 100%' })}>
        {selectedMedia?.length > 0 ? (
          selectedMedia?.map((item: any) => (
            <Tag
              key={item.id}
              kind="primary"
              variant="solid"
              onActionClick={() => handleTagClick(item.id)}
              overrides={{
                Text: {
                  style: ({ $theme }) => ({
                    ...$theme.typography.ParagraphXSmall,
                    maxWidth: 'max-content',
                  }),
                },
              }}
            >
              {item.name}
            </Tag>
          ))
        ) : (
          <ParagraphSmall color="mono400">None Selected</ParagraphSmall>
        )}
      </div>

      <FormControl label="Property">
        <Select
          size="compact"
          clearable={false}
          options={teamListedProperties?.map((option) => ({ id: option.id, label: option.name }))}
          value={selectedProperty}
          onChange={({ value }) => {
            setSelectedProperty(value);
          }}
        />
      </FormControl>
      <GlobalFilter setGlobalFilter={setGlobalFilter} />
      <div
        className={css({
          height: '250px',
          overflow: 'auto',
          border: `1px solid ${theme.colors.mono200}`,
        })}
      >
        <StandardTable {...tableInstance} freeze="header" />
      </div>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: theme.sizing.scale300,
          marginBottom: theme.sizing.scale300,
        })}
      >
        <Button size="compact" kind="tertiary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          size="compact"
          onClick={() => onAttach(selectedMedia)}
          disabled={!selectedMedia?.length}
        >
          Attach {selectedMedia.length ? `(${selectedMedia.length})` : ''} Selected
        </Button>
      </div>
    </>
  );
};

Marketing.defaultProps = {
  propertyRef: undefined,
};

export default Marketing;
