import { useQuery } from 'react-query';
import useAxios from '../../hooks/useAxios';
import { Alert } from '../../backend/messages';

const useGetAlerts = (options?: {}) => {
  const axios = useAxios();

  const getAlerts = async () => {
    const { data } = await axios.get<Alert[]>('api/alert');
    return data;
  };

  return useQuery<Alert[]>(['getAlerts'], getAlerts, {
    staleTime: 300 * 1000,
    ...options,
  });
};

export default useGetAlerts;
