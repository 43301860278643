import React from 'react';
import useAxios from 'hooks/useAxios';

const useTwilioToken = (businessProfileId: string) => {
  const axios = useAxios();

  const requestAccess = React.useCallback(
    () =>
      axios
        .get(`api/twilio/voip-token?busProfileId=${businessProfileId}`)
        .then((response) => response)
        .catch((e) => Promise.reject(e)),
    [axios, businessProfileId],
  );

  return { requestAccess };
};

export default useTwilioToken;
