import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../hooks/useAxios';

type TextMessageT = {
  message: string;
  contactType?: string;
  targetContactRef?: string | null;
  targetPhoneNumber: string;
};

const useSendTextMessage = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const sendMessage = (leadId: string, model: TextMessageT) =>
    axios.post(`api/lead/${leadId}/text-msg/`, model);

  return useMutation(
    ({ leadId, model }: { leadId: string; model: TextMessageT }) => sendMessage(leadId, model),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries([
          'getTextThread',
          variables.leadId,
          variables.model.targetPhoneNumber,
        ]);
      },
      onError: (err: any) => err,
    },
  );
};

export default useSendTextMessage;
