import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import useAxios from '../../../hooks/useAxios';
import { LeadForTeam } from '../../../backend/lead';

const useGetLeadsForTeam = (
  teamId: string | undefined,
  options?: Omit<UseQueryOptions<LeadForTeam[] | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getLeadsForTeam = async () => {
    const { data } = await axios.get<LeadForTeam[]>(`api/leads/team/${teamId}`);
    return data;
  };

  return useQuery<LeadForTeam[] | undefined, AxiosError>(
    ['getLeadsForTeam', teamId],
    () => getLeadsForTeam(),
    {
      enabled: !!teamId,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetLeadsForTeam;
