import { create, StateCreator } from 'zustand';
import { persist } from 'zustand/middleware';

export type ClientStateSlice = {
  features: {
    compactList: boolean;
    teamTourVisibility: boolean;
    webformTextStrict: boolean;
  };
  updater: (v: object) => void;
};

const createSettingsSlice: StateCreator<ClientStateSlice> = (set) => ({
  features: {
    compactList: false,
    teamTourVisibility: false,
    webformTextStrict: false,
  },
  updater: (newState) => set((state) => ({ features: { ...state.features, ...newState } })),
});

export const useClientStore = create(
  persist<ClientStateSlice>(
    (...a) => ({
      ...createSettingsSlice(...a),
    }),
    { name: 'client.settings' },
  ),
);
