import React from 'react';
import { useStyletron } from 'baseui';
import ReactAudioPlayer from 'react-audio-player';
import { Spinner } from 'baseui/spinner';
import useGetGreetings from './hooks/useGetGreetings';

interface Props {
  id: any;
}

const PlayRecording = ({ id }: Props) => {
  const [css] = useStyletron();

  const greetingsQuery = useGetGreetings();

  const { data, isLoading } = greetingsQuery;

  const greeting = data?.filter((entry: any) => entry.id === id)[0];

  const src = `${window.env.APP_GREETINGS_BASE_PATH}/${greeting?.clientRef}/${greeting?.id}`;

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {id && (
        <ReactAudioPlayer
          className={css({
            width: '100%',
          })}
          controls
          src={src}
        />
      )}
    </>
  );
};

export default PlayRecording;
