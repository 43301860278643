import React, { ChangeEvent } from 'react';
import { Checkbox } from 'baseui/checkbox';
import { useController } from 'react-hook-form';
import { Theme } from 'baseui/theme';

const overrides = {
  Root: {
    style: () => ({
      alignItems: 'center',
      display: 'inline-flex',
    }),
  },
  Label: {
    style: ({ $theme }: { $theme: Theme }) => ({
      ...$theme.typography.LabelSmall,
      alignItems: 'center',
    }),
  },
};

const ControlledCheckbox = (props: any) => {
  const { name, label, control, baseWebProps, handleOnChange, defaultValue } = props;
  const {
    field: { ref, onChange, value, ...fieldProps },
    formState: { errors },
  } = useController({ name, control, defaultValue });
  return (
    <Checkbox
      error={!!errors[name]}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        if (handleOnChange) handleOnChange(e);
        onChange(e.target.checked);
      }}
      checked={value}
      overrides={overrides}
      {...baseWebProps}
      {...fieldProps}
    >
      {label}
    </Checkbox>
  );
};

export default ControlledCheckbox;
