import React from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { ImperativeMethods, Select, SelectProps } from 'baseui/select';

type ControlledSelectPropsT = {
  errPath?: any;
  onChangeHandler?: any;
  onInputChange?: (a: any) => any;
  baseuiProps?: SelectProps;
  useImperativeControl?: boolean;
};

const ControlledSelect = ({
  control,
  name,
  onChangeHandler,
  onInputChange,
  errPath,
  baseuiProps,
  defaultValue,
  useImperativeControl,
}: UseControllerProps<any> & ControlledSelectPropsT) => {
  const {
    field: { ref, onChange, ...fieldProps },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const controlRef = React.useRef<ImperativeMethods>(null);

  React.useEffect(() => {
    if (errors) {
      if (controlRef.current && errors[name]) {
        controlRef.current.setInputFocus();
      }
    }
  }, [errors, name]);

  return (
    <Select
      controlRef={useImperativeControl ? controlRef : undefined}
      error={!!errPath || !!errors?.[name]}
      onInputChange={onInputChange}
      onChange={(params) => {
        if (onChangeHandler) {
          onChangeHandler(params);
        }
        onChange(params.value);
      }}
      {...fieldProps}
      {...baseuiProps}
    />
  );
};

ControlledSelect.defaultProps = {
  errPath: undefined,
  onChangeHandler: undefined,
  onInputChange: undefined,
  baseuiProps: undefined,
};

export default ControlledSelect;
