import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../hooks/useAxios';

const useAuthCalendar = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const authCalendar = (code: string) => {
    if (!code) {
      return Promise.reject(new Error('Authorization code required'));
    }
    return axios.post(`api/user/auth-calendar?code=${code}`);
  };

  return useMutation<any, any, any, any>((code: string) => authCalendar(code), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAuthUser']);
    },
    onError: (e) => {
      console.log(e);
    },
  });
};

export default useAuthCalendar;
