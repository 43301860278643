import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }: any) => {
  const scope = 'profile email'; // 'openid profile email';
  const domain = window.env.APP_AUTH0_DOMAIN as string;
  const clientId = window.env.APP_AUTH0_CLIENT as string;
  const audience = window.env.APP_AUTH0_AUDIENCE;
  const advancedOptions = {
    defaultScope: '',
  };

  const navigate = useNavigate();

  let organizationId;

  if (import.meta.env.MODE === 'development') {
    organizationId = 'org_KrUMI9dsSDlxsiMm';
  }

  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      advancedOptions={advancedOptions}
      scope={scope}
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      organization={organizationId}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
