import React from 'react';
import { customUseStyletron as useStyletron } from '~/theme';
import { Button, SIZE } from 'baseui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useSideMenuContext } from 'context/SideMenuContext';
import { ReactComponent as Logo } from '../../assets/logo.svg';
import UserSettingsMenu from '../me/UserSettingsMenu';
import NotificationsButton from '../notifications/NotificationsButton';

const Header = () => {
  const [css, theme] = useStyletron();

  const { open, setOpen } = useSideMenuContext();

  const toggleOpen = () => setOpen(!open);

  return (
    <header>
      <div
        className={css({
          position: 'relative',
          // zIndex: 1,
          width: '100%',
          height: '56px',
          display: 'flex',
          alignItems: 'center',
          boxSizing: 'border-box',
          paddingLeft: theme.sizing.scale300,
          paddingRight: theme.sizing.scale900,
          backgroundColor: theme.brandColorDarker,
          justifyContent: 'space-between',
          [theme.mediaQuery.large]: {
            display: 'none',
          },
        })}
      >
        <div className={css({ flex: '1 0 auto' })}>
          <Button
            kind="tertiary"
            onClick={toggleOpen}
            size={SIZE.compact}
            overrides={{
              BaseButton: {
                style: {
                  color: theme.colors.white,
                  verticalAlign: 'middle',
                  ...theme.typography.HeadingXSmall,
                  borderTopLeftRadius: '3px',
                  borderTopRightRadius: '3px',
                  borderBottomRightRadius: '3px',
                  borderBottomLeftRadius: '3px',
                  backgroundColor: 'transparent',
                  ':hover': {
                    backgroundColor: theme.brandColor,
                  },
                  [theme.mediaQuery.large]: {
                    display: 'none',
                  },
                },
              },
            }}
          >
            {open ? (
              <FontAwesomeIcon fixedWidth icon={solid('times')} />
            ) : (
              <FontAwesomeIcon fixedWidth icon={solid('bars')} />
            )}
          </Button>
          <Logo
            title="SpaceIt"
            className={css({
              display: 'inline',
              width: '100px',
              height: 'auto',
              verticalAlign: 'middle',
              marginLeft: theme.sizing.scale300,
            })}
          />
        </div>

        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <NotificationsButton />
          <UserSettingsMenu />
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
