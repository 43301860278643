import { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

type PopoutContextProviderProps = {
  children: ReactNode;
};

type PopoutContextType = {
  isOpen: boolean;
  expanded: boolean;
  component: ReactNode | undefined;
  setComponent: Dispatch<SetStateAction<ReactNode | undefined>>;
  setExpanded: Dispatch<SetStateAction<boolean>>;
  openPopout: (component?: ReactNode) => void;
  closePopout: () => void;
  fullScreen: boolean;
  setFullScreen: Dispatch<SetStateAction<boolean>>;
};

const PopoutContext = createContext<PopoutContextType | undefined>(undefined);

const PopoutContextProvider = ({ children }: PopoutContextProviderProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [expanded, setExpanded] = useState<boolean>(true);

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const [component, setComponent] = useState<ReactNode | undefined>();

  const openPopout = (content: any) => {
    setComponent(content);
    setExpanded(true);
    setFullScreen(false);
    setIsOpen(true);
  };

  const closePopout = () => {
    setComponent(undefined);
    setIsOpen(false);
  };

  return (
    <PopoutContext.Provider
      value={{
        component,
        expanded,
        isOpen,
        openPopout,
        closePopout,
        setComponent,
        setExpanded,
        fullScreen,
        setFullScreen,
      }}
    >
      {children}
    </PopoutContext.Provider>
  );
};

const usePopout = () => {
  const context = useContext(PopoutContext);
  if (context === undefined) {
    throw new Error('usePopout must be used within a PopoutProvider');
  }
  return context;
};

export { PopoutContextProvider, usePopout };
