import { getMarkAttributes, Mark, mergeAttributes } from '@tiptap/react';

export interface FontStyleOptions {
  HTMLAttributes: Record<string, any>;
}

// declare module '@tiptap/core' {
//   interface Commands<ReturnType> {
//     font: {
//       removeEmptyTextStyle: () => ReturnType;
//     };
//   }
// }

export const Font = Mark.create<FontStyleOptions>({
  name: 'font',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        tag: 'font',
      },
    ];
  },

  // addAttributes() {
  //   return {
  //     color: {
  //       parseHTML: (element: any) => element.getAttribute('color'),
  //     },
  //     size: {
  //       parseHTML: (element: any) => element.getAttribute('sizedd'),
  //     },
  //   };
  // },

  renderHTML({ HTMLAttributes }) {
    return ['font', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      removeEmptyTextStyle:
        () =>
          ({ state, commands }) => {
            const attributes = getMarkAttributes(state, this.type);
            const hasStyles = Object.entries(attributes).some(([, value]) => !!value);

            if (hasStyles) {
              return true;
            }

            return commands.unsetMark(this.name);
          },
    };
  },
});
