import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { useStyletron } from 'baseui';
import { Button, KIND } from 'baseui/button';
import { Spinner, SIZE as SPINNERSIZE } from 'baseui/spinner';
import useGetGreetingSample from './hooks/useGetGreetingSample';

type PropsT = {
  text: string;
  voice: string;
};

const PreviewTextSpeech = ({ text, voice }: PropsT) => {
  const [css, theme] = useStyletron();
  const { data, error, isLoading, refetch } = useGetGreetingSample(text, voice);

  return (
    <>
      {isLoading && (
        <>
          <div>Preparing File...please wait</div>
          <Spinner $size={SPINNERSIZE.large} />
        </>
      )}

      {error && (
        <>
          <div
            className={css({
              display: 'flex',
              width: '100%',
              padding: theme.sizing.scale900,
              flexDirection: 'row',
              alignSelf: 'center',
              flexWrap: 'wrap',
              boxSizing: 'border-box',
              border: `1px solid ${theme.colors.mono300}`,
              justifyContent: 'center',
            })}
          >
            <p>Something went wrong.</p>
            <Button
              style={{ alignSelf: 'center', flexGrow: 0, flexShrink: 0, flexBasis: '100%' }}
              kind={KIND.secondary}
              type="button"
              onClick={() => refetch()}
              size="default"
            >
              Try Again
            </Button>
          </div>
        </>
      )}

      {data?.url && (
        <ReactAudioPlayer
          className={css({
            width: '100%',
          })}
          controls
          src={data.url}
        />
      )}
    </>
  );
};

export default PreviewTextSpeech;
