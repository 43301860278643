import React, { ReactNode } from 'react';
import { useStyletron } from 'baseui';

type PropsT = {
  children?: ReactNode;
  styles?: {};
};

const RequiredFieldMarker = ({ children, styles }: PropsT) => {
  const [css, theme] = useStyletron();

  return (
    <>
      <span
        className={css({
          color: theme.colors.negative400,
          paddingRight: theme.sizing.scale0,
          ...styles,
        })}
      >
        *
      </span>
      {children}
    </>
  );
};

export default RequiredFieldMarker;
