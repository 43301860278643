import { CallProgressProvider } from 'context/call/CallProgressProvider';
import { ClientProvider } from 'context/client-context';
import WebSocketProvider from 'context/web-socket-context';
import { TwilioProvider } from 'context/twilio/TwilioProvider';
import { Outlet } from 'react-router-dom';

export default function ProtectedProviders() {
  return (
    <ClientProvider>
      <WebSocketProvider>
        <CallProgressProvider>
          <TwilioProvider>
            <Outlet />
          </TwilioProvider>
        </CallProgressProvider>
      </WebSocketProvider>
    </ClientProvider>
  );
}
