import React from 'react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Heading, HeadingLevel } from 'baseui/heading';
import { tooltipOverrides } from 'components/shared/tooltip-overrides';
import useGetProperties from 'components/properties/hooks/useGetProperties';
import { useTeamContext } from './context/team-context';
import TeamMenu from './menu/TeamMenu';
import { useClient } from '../../context/client-context';

const TeamHeader = () => {
  const [css, theme] = useStyletron();

  const [, copy] = useCopyToClipboard();

  const { state: clientState } = useClient();

  const team = useTeamContext();

  const { data: properties } = useGetProperties();

  const property = properties?.find((p) => p.id === team?.properties?.[0]);

  const deWebFormId = property?.shortExternalListingId;

  const deWebFormUrl = `${window.env.APP_ELLIMAN_FORM_URL}/contact/${deWebFormId}`;

  const handleCopy = async (str: string | undefined) => {
    if (!str) {
      return;
    }
    const isCopied = await copy(str);
    if (isCopied) {
      toast('URL copied');
    }
  };

  return (
    <>
      {!clientState?.isBroker && (
        <div className={css({ color: theme.colors.mono500, ...theme.typography.font100 })}>
          <NavLink to="/properties" className={css({})}>
            Properties
          </NavLink>
          <span
            className={css({
              ':before': {
                content: "'>'",
                marginLeft: theme.sizing.scale300,
                marginRight: theme.sizing.scale300,
              },
            })}
          >
            {team?.name}
          </span>
        </div>
      )}
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          paddingTop: theme.sizing.scale200,
          paddingBottom: theme.sizing.scale200,
          flexWrap: 'wrap-reverse',
          columnGap: theme.sizing.scale200,
        })}
      >
        <HeadingLevel>
          <Heading styleLevel={5} margin={0}>
            {team?.name ? <span>{team.name}</span> : null}
          </Heading>
        </HeadingLevel>
        <div className={css({ display: 'flex', alignItems: 'center' })}>
          <TeamMenu />
        </div>
      </div>
      {deWebFormId && (
        <div
          className={css({
            ...theme.typography.ParagraphSmall,
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            columnGap: theme.sizing.scale0,
          })}
        >
          <div>
            <span className={css({ fontWeight: '500', marginRight: theme.sizing.scale100 })}>
              Contact Form URL:
            </span>
            <a
              className={css({
                textDecoration: 'none',
                color: theme.colors.primary400,
                ':hover': { textDecoration: 'underline' },
              })}
              href={deWebFormUrl}
              target="_blank"
              rel="noreferrer"
            >
              {deWebFormUrl}
            </a>
          </div>
          <StatefulTooltip content="Copy URL" overrides={tooltipOverrides}>
            <Button kind="tertiary" size="compact" onClick={() => handleCopy(deWebFormUrl)}>
              <FontAwesomeIcon icon={regular('copy')} />
            </Button>
          </StatefulTooltip>
        </div>
      )}
    </>
  );
};

export default TeamHeader;
