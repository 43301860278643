import React from 'react';
import { useStyletron } from 'baseui';
import { NavigationAccordianProps } from './types/navigation';

const NavigationAccordian = ({ children, isOpen, isSidebarPinned }: NavigationAccordianProps) => {
  const [css, theme] = useStyletron();

  return (
    <ul
      data-id="nav-accordian"
      className={css({
        marginTop: 0,
        marginRight: 0,
        marginBottom: 0,
        marginLeft: '17.5px',
        padding: 0,
        listStyle: 'none',
        transitionProperty: 'grid-template-rows',
        transitionDuration: theme.animation.timing100,
        transitionTimingFunction: theme.animation.easeInCurve,
        display: 'grid',
        gridTemplateRows: isOpen ? '1fr' : '0fr',
        [theme.mediaQuery.large]: {
          gridTemplateRows: isSidebarPinned && isOpen ? '1fr' : '0fr',
        },
      })}
    >
      <div className={css({ overflow: 'hidden' })}>{children}</div>
    </ul>
  );
};

export default NavigationAccordian;
