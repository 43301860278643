import React from 'react';
import { Input } from 'baseui/input';
import { useController } from 'react-hook-form';
import NumberFormatCustom from './NumberFormatCustom';

const ControlledNumberFormat = (props: any) => {
  const {
    name,
    control,
    customError,
    kind,
    baseWebProps: { ...rest },
  } = props;
  const {
    field: { ref, onChange, ...inputProps },
    formState: { errors },
  } = useController({ name, control });

  return (
    <NumberFormatCustom
      customInput={Input}
      kind={kind}
      error={!!(customError || errors[name])}
      onChange={onChange}
      {...rest}
      {...inputProps}
    />
  );
};

export default ControlledNumberFormat;
