import { Node, mergeAttributes } from '@tiptap/react';

export const Div = Node.create({
  name: 'div',
  group: 'block',
  content: 'block*',
  addOptions() {
    return {
      HTMLAttributes: {
        'data-si': 'div',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'div',
        // getAttrs: (node) => {
        //   console.log(node);
        //   const colorAttr = (node as HTMLElement).getAttribute('color');
        //   console.log(colorAttr);
        //   // if (colorAttr) {
        //   //   (node as HTMLElement).style.color = colorAttr;
        //   // }
        //   // const a = (node as HTMLElement).style.fontWeight !== 'normal' && null;
        //   return colorAttr !== '#000000' && null;
        // },
      },
    ];
  },

  // addAttributes() {
  //   return {
  //     style : {
  //       parseHTML: element => `color:${element.getAttribute('color')}`,
  //     },
  //   };
  // },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  // addCommands() {
  //   return {
  //     setBold: () => ({ commands }) => {
  //       return commands.setMark(this.name)
  //     },
  //     toggleBold: () => ({ commands }) => {
  //       return commands.toggleMark(this.name)
  //     },
  //     unsetBold: () => ({ commands }) => {
  //       return commands.unsetMark(this.name)
  //     },
  //   }
  // },
});
