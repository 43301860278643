import { UserAdminFor } from 'backend/user';
import { useQuery } from 'react-query';
import useAxios from '../../../hooks/useAxios';

function UseAdministratorFor() {
  const axios = useAxios();
  return {
    async getAdministratorFor() {
      const { data } = await axios.get<UserAdminFor[]>('api/user/administrator-for');
      return data;
    },
  };
}

function UseAdminFor() {
  const { getAdministratorFor } = UseAdministratorFor();
  return useQuery({
    queryFn: getAdministratorFor,
    queryKey: ['administratorFor'],
    retry: false,
  });
}

export default UseAdminFor;
