import React from 'react';
import { ButtonProps } from 'baseui/button';
import { ModalButton } from 'baseui/modal';
import { StyledStandardButton } from './styled-components';
import { BaseButtonWithStyle } from '../shared/styles';

const buttonOverridesProp = {
  overrides: {
    BaseButton: {
      component: BaseButtonWithStyle,
    },
  },
};
const ModalBoxButton = (props: ButtonProps & { form?: string, style?: any }) => (
  <StyledStandardButton $as={ModalButton} {...props} {...buttonOverridesProp} />
);

export default ModalBoxButton;

ModalBoxButton.defaultProps = {
  form: undefined,
};
