import React from 'react';

export type ButtonGroupPropsT = {
  $groupIndex?: number;
  $count?: number;
};

const ButtonGroup = ({ children }: any) => {
  const count = React.Children.count(children);
  if (!children) return null;
  return React.Children.map(children, (child, index) =>
    React.cloneElement(child, { $groupIndex: index, $count: count }),
  );
};

export default ButtonGroup;
