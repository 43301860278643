import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import { CurrenUserContextProvider } from 'context/current-user-context';
import { EmailContextProvider } from 'components/email/email-context';
import { OwnerContextProvider } from 'context/OwnerContextProvider';
import { NylasProvider } from 'helpers/nylasClient/nylas-context';
import { TextMessageContextProvider } from 'components/textMessage/text-message-context';
import { ReactQueryDevtools } from 'react-query/devtools';
import AppLoadingScreen from 'components/screen/AppLoadingScreen';
import 'regenerator-runtime/runtime';

const App = () => (
  // useEffect(() => {
  //   console.log('1', error);
  //   if (error) {
  //     throw error;
  //   }
  // }, [error]);

  // if (error) {
  //   console.log(error);
  //   // Allow the error boundary to render otherwise user will
  //   // automatically redirect to login because of protected routes
  //   return <div />;
  // }

  <Suspense fallback={<AppLoadingScreen />}>
    <CurrenUserContextProvider>
      <EmailContextProvider>
        <OwnerContextProvider>
          <NylasProvider>
            <TextMessageContextProvider>
              <ToastContainer
                containerId="info"
                position="bottom-left"
                autoClose={5000}
                closeOnClick={false}
                hideProgressBar
              />
              <Outlet />
            </TextMessageContextProvider>
          </NylasProvider>
        </OwnerContextProvider>
      </EmailContextProvider>
    </CurrenUserContextProvider>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </Suspense>
);
export default App;
