import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../../hooks/useAxios';

const useCreateTeam = () => {
  const queryClient = useQueryClient();

  const axios = useAxios();

  const createTeam = (formData: any) => axios.post('api/team/create', formData);

  return useMutation((formData: any) => createTeam(formData), {
    onSuccess: () => {
      queryClient.invalidateQueries(['getAuthUser']);
      queryClient.invalidateQueries(['getTeamsForUser']);
    },
    onError: (err: any) => err,
  });
};

export default useCreateTeam;
