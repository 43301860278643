import { ContactDetails } from 'backend/lead';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { LabelXSmall } from 'baseui/typography';
import useFindContact from 'components/lead/activity/useFindContact';
import { formatDistanceToNowStrict } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { ALERT_TYPES } from './constants';
import { Alert } from '../../backend/messages';
import useDismissAlert from './useDismissAlert';
import {
  StyledDate,
  StyledEventType,
  StyledInner,
  StyledListItem,
  StyledListItemContent,
  StyledListItemIcon,
  StyledEventHeader,
} from './styled-components';
import SharedLead from './SharedLead';
import AssignedLead from './AssignedLead';
import IncomingComm from './IncomingComm';
import NewLeadAvailable from './NewLeadAvailable';
import { CONTACT_TYPE } from '../lead/constants';
import { TextMessageContext } from '../textMessage/text-message-context';
import useNameFromCommunication from '../dashboard/dashboardAlerts/useNameFromCommunication';

const DashboarAlertItem = (props: Alert & { onClick?: () => any }) => {
  const navigate = useNavigate();

  const textMessageContext = useContext(TextMessageContext);

  const {
    dispatch,
    state: { isOpen },
  } = textMessageContext;

  // const [contact, setContact] = useState<Contact | undefined>();

  const { alertType, lead, id, communication, invalidated, actionBy, createdTs, onClick } = props;

  const { reverseEmailLookup, reversePhoneLookup } = useFindContact(lead);

  const communicationType = communication?.communicationType;

  const dismissAlert = useDismissAlert();

  const { lookupName } = useNameFromCommunication();

  const commName = lookupName(
    communication,
    lead.initialCommunicationDetails,
    lead.mergedInitialCommunicationDetails,
  );

  const handleDismissAlertClick = () => {
    // confirm(`${id}: Dismissed`);
    // return;
    dismissAlert.mutate(
      { ids: id },
      {
        onSuccess: () => {
          toast('Alert Dismissed', {
            theme: 'dark',
          });
        },
      },
    );
  };

  const lookupContact = () => {
    let match: ContactDetails | undefined;
    switch (communicationType) {
      case 'CALL':
      case 'TEXT_MESSAGE': {
        match = reversePhoneLookup(communication);
        break;
      }
      case 'EMAIL':
      case 'WEBFORM': {
        match = reverseEmailLookup(communication);
        break;
      }
      // no default
    }
    return match;
  };

  const contact = lookupContact();

  const getIcon = React.useCallback(() => {
    switch (alertType) {
      case 'LEAD_AVAILABLE_CLAIM':
      case 'LEAD_AVAILABLE_ASSIGNMENT':
        return <FontAwesomeIcon size="1x" icon={regular('flag')} />;
      case 'ASSIGNED':
        return <FontAwesomeIcon size="1x" icon={solid('share')} />;
      case 'INCOMING_COMMUNICATION':
        if (communicationType === 'CALL') {
          return (
            <span className="fa-layers fa-fw">
              <FontAwesomeIcon size="1x" icon={solid('phone')} transform={{ rotate: 0 }} />
              <FontAwesomeIcon
                size="1x"
                icon={solid('arrow-down')}
                transform="shrink-6 rotate-45 right-6 up-6"
              />
            </span>
          );
        }
        if (communicationType === 'TEXT_MESSAGE') {
          return <FontAwesomeIcon size="1x" icon={regular('comment-dots')} />;
        }
        if (communicationType === 'EMAIL') {
          return <FontAwesomeIcon size="1x" icon={regular('envelope')} />;
        }
        if (communicationType === 'WEBFORM') {
          return <></>;
        }
        return <></>;
      case 'LEAD_SHARED':
        return <></>;
      default:
        return <FontAwesomeIcon size="1x" icon={regular('bell')} />;
    }
  }, [alertType, communicationType]);

  const handleClick = () => {
    if (communicationType === 'TEXT_MESSAGE') {
      if (contact) {
        dispatch({
          leadRef: lead.id,
          isOpen: true,
          name: contact.name,
          contactType: contact.contactType,
          targetPhoneNumber: communication?.textedFromNumber,
          targetContactRef: contact.id,
        });
      } else {
        dispatch({
          leadRef: lead.id,
          isOpen: true,
          name: commName,
          contactType: CONTACT_TYPE.DIRECT_REPLY,
          targetPhoneNumber: communication?.textedFromNumber,
          targetContactRef: null,
        });
      }
    }
    if (communicationType !== 'TEXT_MESSAGE' && isOpen) {
      dispatch({
        leadRef: '',
        isOpen: false,
        contactType: '',
        targetContactRef: null,
      });
    }
    navigate(`/leads/${lead.id}`);
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const isLeadTaken =
    invalidated &&
    (alertType === 'LEAD_AVAILABLE_CLAIM' || alertType === 'LEAD_AVAILABLE_ASSIGNMENT');

  return (
    <StyledListItem $isInvalidated={invalidated}>
      <StyledInner>
        <StyledListItemIcon>{getIcon()}</StyledListItemIcon>
        <StyledListItemContent onClick={handleClick}>
          <StyledEventHeader>
            <StyledEventType $isLeadTaken={isLeadTaken}>{ALERT_TYPES[alertType]}</StyledEventType>
            <StyledDate>{formatDistanceToNowStrict(new Date(createdTs))} ago</StyledDate>
          </StyledEventHeader>
          {isLeadTaken && (
            <LabelXSmall marginBottom="scale200" color="negative300">
              No longer available
            </LabelXSmall>
          )}
          {(alertType === 'LEAD_AVAILABLE_CLAIM' || alertType === 'LEAD_AVAILABLE_ASSIGNMENT') && (
            <NewLeadAvailable
              {...communication}
              assignedPropertyRef={lead.assignedProperty?.propertyRef}
            />
          )}

          {alertType === 'ASSIGNED' && <AssignedLead {...lead} actionBy={actionBy} />}

          {alertType === 'LEAD_SHARED' && <SharedLead {...lead} />}

          {alertType === 'INCOMING_COMMUNICATION' && communication && (
            <IncomingComm {...props} contact={contact} />
          )}
        </StyledListItemContent>
        <div>
          <StatefulTooltip
            content="Dismiss"
            overrides={{
              Inner: {
                style: ({ $theme }) => ({
                  paddingTop: $theme.sizing.scale0,
                  paddingBottom: $theme.sizing.scale0,
                  paddingLeft: $theme.sizing.scale500,
                  paddingRight: $theme.sizing.scale500,
                  ...$theme.typography.ParagraphXSmall,
                  borderTopRightRadius: $theme.sizing.scale100,
                  borderTopLeftRadius: $theme.sizing.scale100,
                  borderBottomRightRadius: $theme.sizing.scale100,
                  borderBottomLeftRadius: $theme.sizing.scale100,
                }),
              },
            }}
          >
            <div>
              <Button
                type="button"
                shape="circle"
                size="mini"
                kind="tertiary"
                onClick={handleDismissAlertClick}
              >
                <FontAwesomeIcon icon={solid('times')} />
              </Button>
            </div>
          </StatefulTooltip>
        </div>
      </StyledInner>
    </StyledListItem>
  );
};

export default DashboarAlertItem;
