import React, { ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { Textarea } from 'baseui/textarea';
import { MAX_TEXT_AREA_LENGTH } from 'shared/constants';

const ControlledTextArea = ({ control, name, size, onChangeHandler, errPath, ...rest }: any) => {
  const {
    field: { ref, onChange, ...textareaProps },
    formState: { errors },
  } = useController({ name, control });

  return (
    <Textarea
      size={size}
      maxLength={MAX_TEXT_AREA_LENGTH}
      error={!!errPath || !!errors?.[name]}
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChangeHandler) onChangeHandler(e);
        onChange(e.target.value);
      }}
      {...textareaProps}
      {...rest}
    />
  );
};

export default ControlledTextArea;
