import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { Call, Device } from '@twilio/voice-sdk';
import useGetTeamsForUser from 'components/team/hooks/useGetTeamsForUser';
import useNavigatorMediaPermissions from 'components/notifications/useNavigatorMediaPermissions';
import { useCallProgressContext } from 'context/call/CallProgressProvider';
import useMobilePhoneDetection from 'hooks/useMobilePhoneDetection';
import useTwilioToken from './useTwilioToken';
import { DialOptions, TwilioContextT, TwilioProviderProps } from './types';

const TTL = import.meta.env.PROD ? 60000 * 60 : 60000 * 2;

const REFRESH_BUFFER = import.meta.env.PROD ? 60000 * 5 : 60000;

const TwilioContext = React.createContext<TwilioContextT | undefined>(undefined);

const TwilioProvider = ({ children }: TwilioProviderProps) => {
  const { isAuthenticated } = useAuth0();

  const { data: teams } = useGetTeamsForUser();

  const businessProfileId = teams?.[0]?.twilioBusinessProfileRef;

  const { isMobilePhone } = useMobilePhoneDetection();

  const { checkMicrophoneAccess } = useNavigatorMediaPermissions();

  const queryClient = useQueryClient();

  const { requestAccess } = useTwilioToken(businessProfileId!);

  const { setIsBusy } = useCallProgressContext();

  const intervalRef = React.useRef<any>();

  const [twilio, setTwilio] = useState<Device | undefined>();

  const [isMuted, setIsMuted] = useState<boolean>(false);

  const [currentCall, setCurrentCall] = useState<Call | undefined>();

  const [callStatus, setCallStatus] = useState<Call.State | undefined>();

  const bindCallEvents = React.useCallback(
    (call: Call) => {
      call.on('error', (e) => {
        console.log(e);
        setCallStatus(call.status());
        setCurrentCall(undefined);
        setIsBusy(false);
        if (e.code === 31401) {
          toast(
            'The browser or end-user denied permissions to user media. Please update your browser settings to allow SpaceIt use your microphone.',
            { autoClose: false },
          );
        }
      });

      call.on('accept', () => {
        setCallStatus(call.status());
      });

      // call.on('warning', (warningName, warningData) => {
      //   // TODO - Implement warning toast or modal. Message: ('We have
      //   // detected poor call quality conditions. You may experience
      //   // degraded call quality.');
      // });

      call.on('disconnect', () => {
        console.log('[TW CALL] disconect');
        setCallStatus(call.status());
        setCurrentCall(undefined);
        setIsBusy(false);
      });

      call.on('reject', () => {
        console.log('[TW CALL] reject');
        setCallStatus(call.status());
        setCurrentCall(undefined);
        setIsBusy(false);
      });

      call.on('cancel', () => {
        console.log('[TW CALL] cancel');
        setCallStatus(call.status());
        setCurrentCall(undefined);
        setIsBusy(false);
        /* Purges the query cache to get latest data to see if user had
        all transferred to a different line. */
        queryClient.cancelQueries(['callInProgress']);
        queryClient.resetQueries(['callInProgress']);
      });
    },
    [setIsBusy, queryClient],
  );

  const dial = async (options?: DialOptions) => {
    const call = await twilio?.connect({
      params: {
        ...options,
      },
    });

    if (call) {
      /**
       * Twilio call status: connecting > ringing > open > closed
       *  https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#callstatus
       */
      setCurrentCall(call);
      setIsBusy(true);
      setIsMuted(call.isMuted());
      setCallStatus(call.status());
      bindCallEvents(call);
    }
  };

  const getMedia = async (constraints: MediaStreamConstraints | undefined) => {
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia(constraints);
    } catch (err) {
      console.log(err);
    }
    return stream;
  };

  const hangup = () => {
    twilio?.disconnectAll();
  };

  const answer = () => {
    if (currentCall) {
      currentCall.accept();
    }
  };

  const rejectCall = () => {
    if (currentCall) {
      currentCall.reject();
    }
  };

  const mute = () => {
    if (currentCall) {
      const isCallMuted = currentCall.isMuted();
      currentCall.mute(!isCallMuted);
      setIsMuted(!isCallMuted);
    }
  };

  const refreshToken = React.useCallback(
    async (device: Device) => {
      const { data } = await requestAccess();
      // https://github.com/twilio/twilio-voice.js/issues/133
      // https://github.com/twilio/twilio-voice.js/issues/33
      device.updateToken(data);
    },
    [requestAccess],
  );

  const addDeviceListeners = React.useCallback(
    (device: Device) => {
      device.on('registering', () => {
        console.log('Registering');
      });

      device.on('registered', () => {
        console.log('Registered');
        intervalRef.current = setInterval(() => refreshToken(device), TTL - REFRESH_BUFFER);
      });

      device.on('unregistered', () => {
        console.log('Device is offline');
        clearInterval(intervalRef.current);
      });

      device.on('error', async (e) => {
        console.log(e.code, e.message, device.state);
        setIsBusy(false);
        if (e.code === 20104) {
          await refreshToken(device);
        }
      });

      // device.on('tokenWillExpire', () => {
      //   // updateToken().catch((e) => console.log(e));
      // });

      device.on('incoming', (call: Call) => {
        setCurrentCall(call);
        setIsBusy(true);
        setCallStatus(call.status());
        bindCallEvents(call);
      });
    },
    [bindCallEvents, refreshToken, setIsBusy],
  );

  const startupClient = React.useCallback(async () => {
    document.removeEventListener('click', startupClient);

    const { data } = await requestAccess();

    const device = new Device(data, {
      logLevel: import.meta.env.MODE !== 'production' ? 1 : 4,
      codecPreferences: [Call.Codec.Opus, Call.Codec.PCMU],
      tokenRefreshMs: REFRESH_BUFFER,
    });
    addDeviceListeners(device);

    if (!isMobilePhone) {
      console.log('Register device');
      await device.register();
      checkMicrophoneAccess();
    }

    setTwilio(device);
  }, [addDeviceListeners, checkMicrophoneAccess, isMobilePhone, requestAccess]);

  useEffect(() => {
    if (isAuthenticated && businessProfileId) {
      // Browser client should be started after a user gesture to avoid
      // AudioContext errors and warnings
      console.log('+', businessProfileId);
      document.addEventListener('click', startupClient);
    }
    return () => document.removeEventListener('click', startupClient);
  }, [isAuthenticated, startupClient, businessProfileId]);

  useEffect(() => clearInterval(intervalRef.current), []);

  return (
    <TwilioContext.Provider
      value={{
        answer,
        call: currentCall,
        callStatus,
        dial,
        rejectCall,
        getMedia,
        hangup,
        isMuted,
        device: twilio,
        mute,
      }}
    >
      {children}
    </TwilioContext.Provider>
  );
};

const useTwilio = () => {
  const context = React.useContext(TwilioContext);
  if (context === undefined) {
    throw new Error('useTwilio must be used within a TwilioProvider');
  }
  return context;
};

export { TwilioProvider, useTwilio };
