/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Spinner } from 'baseui/spinner';
import { Communication } from '../../backend/communication';
import { StyledEventBody } from './styled-components';
import useGetProperty from '../properties/hooks/useGetProperty';

const NewLeadAvailable = (props: { assignedPropertyRef: any } & Partial<Communication>) => {
  const { assignedPropertyRef, communicationType, message } = props;

  const propertyQuery = useGetProperty(assignedPropertyRef);

  const property = propertyQuery.data;

  const getCommunicationDetails = () => {
    switch (communicationType) {
      case 'CALL':
        return 'Inbound call received';
      case 'EMAIL':
        return 'Email received.';
      case 'TEXT_MESSAGE':
        return 'Text message received.';
      case 'WEBFORM':
        return 'Inbound inquiry from web form.';
      default:
        return '';
    }
  };

  if (propertyQuery.isLoading) {
    return <Spinner $size="small" />;
  }

  return (
    <>
      <StyledEventBody>{property?.name}</StyledEventBody>{' '}
      <StyledEventBody>{getCommunicationDetails()}</StyledEventBody>
    </>
  );
};

export default NewLeadAvailable;
