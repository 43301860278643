import { useQuery } from 'react-query';
import useAxios from './useAxios';
import { User } from '../backend/user';

function useUserById() {
  const axios = useAxios();
  return {
    async getUser({ queryKey }: any) {
      const [, id] = queryKey;
      if (typeof id === 'undefined') return Promise.reject();
      const { data } = await axios.get<User>(`api/users/${id}`);
      return data;
    },
  };
}

function useGetUserById(id: string | undefined, options?: {}) {
  const { getUser } = useUserById();
  return useQuery({
    queryFn: getUser,
    queryKey: ['getUser', id],
    enabled: Boolean(id),
    retry: false,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export default useGetUserById;
