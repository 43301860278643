import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import SettingsLayout from 'layouts/team/SettingsLayout';
import TeamLayout from 'layouts/team/TeamLayout';
import CampaignLayout from 'layouts/team/CampaignLayout';
import withSuspense from 'components/withSuspense';
import { NavigateSupportedType, ReportsIndexRoute } from './dynamic-index-routes';

const TeamMembers = withSuspense(lazy(() => import('components/team/teamMembers')));
const Units = withSuspense(lazy(() => import('components/team/units')));
const TeamProperties = withSuspense(lazy(() => import('components/team/properties')));
const CampaignsOutlet = withSuspense(lazy(() => import('components/team/campaign/components/CampaignsOutlet')));
const Campaign = withSuspense(lazy(() => import('components/team/campaign')));
const CampaignDetail = withSuspense(lazy(() => import('components/team/campaign/CampaignDetail')));
// const FeedCampaignTableRows = withSuspense(lazy(() => import('components/team/campaign/components/FeedCampaignTableRows')));
const FormCampaignTableRows = withSuspense(lazy(() => import('components/team/campaign/components/FormCampaignTableRows')));
const Activity = withSuspense(lazy(() => import('components/team/activity')));
const TeamSettingsIndex = withSuspense(lazy(() => import('routes/teams/teamId.settings._index')));
const TeamSettingsLogo = withSuspense(lazy(() => import('routes/teams/teamId.settings.team-logo')));
const TeamSettingLeads = withSuspense(lazy(() => import('routes/teams/teamId.settings.leads')));
const TeamSettingsDefaultGreeting = withSuspense(lazy(() => import('routes/teams/teamId.settings.default-greeting')));
const TeamSettingsNotifications = withSuspense(lazy(() => import('routes/teams/teamId.settings.notifications')));
const TeamSettingsCalendar = withSuspense(lazy(() => import('routes/teams/teamId.settings.calendar')));
const TeamSettingsEmailAuth = withSuspense(lazy(() => import('routes/teams/teamId.settings.email-auth')));
const PropertySettingsIndex = lazy(() => import('routes/properties/propertyId.settings._index'));
const PropertySettingsLeads = lazy(() => import('routes/properties/propertyId.settings.leads'));
const PropertySettingsPropertyDetails = lazy(() => import('routes/properties/propertyId.settings.property-details'));
const PropertySettingsPropertyContact = lazy(() => import('routes/properties/propertyId.settings.property-contact'));
const PropertySettingsMarketing = lazy(() => import('routes/properties/propertyId.settings.marketing'));
const PropertySettingsContactForm = lazy(() => import('routes/properties/propertyId.settings.contact-form'));
const OpenHouseIndex = withSuspense(lazy(() => import('routes/properties/propertyId.open_houses._index')));
const Funnel = withSuspense(lazy(() => import('components/reports/funnel')));
const FunnelReport = withSuspense(lazy(() => import('components/reports/funnel/FunnelReport')));
const TeamBuckets = withSuspense(lazy(() => import('components/reports/team/TeamBuckets')));
const TeamStats = withSuspense(lazy(() => import('components/reports/teamstats/TeamStats')));
const AgentStats = withSuspense(lazy(() => import('components/reports/agent')));
const AgentCallStats = withSuspense(lazy(() => import('components/reports/agentCallStats')));
const CampaignBuckets = withSuspense(lazy(() => import('components/reports/campaign/CampaignBuckets')));
const ChannelReport = withSuspense(lazy(() => import('components/reports/channel')));
const ListingReport = withSuspense(lazy(() => import('components/reports/listing/ListingReport')));
const TourReport = withSuspense(lazy(() => import('components/reports/tour/TourReport')));
const MarketingReport = withSuspense(lazy(() => import('components/reports/marketing')));
const OpenHouseCampaign = withSuspense(lazy(() => import('components/team/open-house/open-house-campaign')));
const Integrations = withSuspense(lazy(() => import('modules/team/admin/integrations')));
const ResponseTemplates = withSuspense(lazy(() => import('components/team/templated-response/template-response-list')));
const TourReminderEmail = withSuspense(lazy(() => import('components/team/tour-reminder-email')));

const campaignRouter: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="channels" replace />,
  },
  {
    path: 'channels',
    element: <Campaign />,
  },
  {
    path: 'feeds',
    // element: <FeedCampaignTableRows />,
    element: <Navigate to="../channels" replace />,
  },
  {
    path: 'forms',
    element: <FormCampaignTableRows />,
  },
];

const propertiesRouter: RouteObject[] = [
  {
    element: <TeamLayout />,
    children: [
      {
        index: true,
        element: <NavigateSupportedType />,
      },
      {
        path: 'members',
        element: <TeamMembers />,
      },
      {
        path: 'units',
        element: <Units />,
      },
      {
        path: 'open-houses',
        element: <OpenHouseIndex />,
      },
      {
        path: 'activity',
        element: <Activity />,
      },
      {
        path: 'campaigns',
        element: <CampaignsOutlet />,
        children: campaignRouter,
      },
    ],
  },
  {
    path: 'open-houses/:cid',
    element: <OpenHouseCampaign />,
  },
  {
    element: <CampaignLayout />,
    children: [
      {
        path: 'campaigns/:cid',
        element: <CampaignDetail />,
      },
    ],
  },
  {
    path: 'settings',
    element: <PropertySettingsIndex />,
    children: [
      {
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <PropertySettingsLeads />,
            handle: { title: 'Configuration' },
          },
          {
            path: 'property-details',
            element: <PropertySettingsPropertyDetails />,
            handle: { title: 'Property Details' },
          },
          {
            path: 'property-contact',
            element: <PropertySettingsPropertyContact />,
            handle: { title: 'Property Contact' },
          },
          {
            path: 'marketing',
            element: <PropertySettingsMarketing />,
            handle: { title: 'Marketing' },
          },
          {
            path: 'logo',
            element: <TeamSettingsLogo />,
            handle: { title: 'Team Logo' },
          },
          {
            path: 'greeting',
            element: <TeamSettingsDefaultGreeting />,
            handle: { title: 'Default Greeting' },
          },
          {
            path: 'notifications',
            element: <TeamSettingsNotifications />,
            handle: { title: 'Notifications' },
          },
          {
            path: 'calendar',
            element: <TeamSettingsCalendar />,
            handle: { title: 'Team Calendar' },
          },
          {
            path: 'email-auth',
            element: <TeamSettingsEmailAuth />,
            handle: { title: 'Email Authorization' },
          },
          {
            path: 'contact-form',
            element: <PropertySettingsContactForm />,
            handle: { title: 'Contact Form' },
          },
          {
            path: 'response-templates',
            element: <ResponseTemplates />,
            handle: { title: 'Response Templates' },
          },
          {
            path: 'tour-reminder',
            element: <TourReminderEmail />,
            handle: { title: 'Tour Reminder' },
          },
          {
            path: 'google-ads',
            element: <Integrations />,
            handle: { title: 'Google Ads Integration' },
          },
        ],
      },
    ],
  },
];

const teamRouter: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="properties" replace />,
  },
  {
    element: <TeamLayout />,
    children: [
      {
        path: 'properties',
        element: <TeamProperties />,
      },
      {
        path: 'members',
        element: <TeamMembers />,
      },
      {
        path: 'activity',
        element: <Activity />,
      },
      {
        path: 'campaigns',
        element: <CampaignsOutlet />,
        children: campaignRouter,
      },
    ],
  },
  {
    element: <CampaignLayout />,
    children: [
      {
        path: 'campaigns/:cid',
        element: <CampaignDetail />,
      },
    ],
  },
  {
    path: 'settings',
    element: <TeamSettingsIndex />,
    children: [
      {
        element: <SettingsLayout />,
        children: [
          {
            index: true,
            element: <TeamSettingLeads />,
            handle: { title: 'Configuration' },
          },
          {
            path: 'logo',
            element: <TeamSettingsLogo />,
            handle: { title: 'Team Logo' },
          },
          {
            path: 'greeting',
            element: <TeamSettingsDefaultGreeting />,
            handle: { title: 'Default Greeting' },
          },
          {
            path: 'notifications',
            element: <TeamSettingsNotifications />,
            handle: { title: 'Notifications' },
          },
          {
            path: 'calendar',
            element: <TeamSettingsCalendar />,
            handle: { title: 'Team Calendar' },
          },
          {
            path: 'email-auth',
            element: <TeamSettingsEmailAuth />,
            handle: { title: 'Email Authorization' },
          },
          {
            path: 'response-templates',
            element: <ResponseTemplates />,
            handle: { title: 'Response Templates' },
          },
          {
            path: 'tour-reminder',
            element: <TourReminderEmail />,
            handle: { title: 'Tour Reminder' },
          },
          {
            path: 'google-ads',
            element: <Integrations />,
            handle: { title: 'Google Ads Integration' },
          },
        ],
      },
    ],
  },
];

const reportsRouter = [
  {
    index: true,
    element: <ReportsIndexRoute />,
  },
  {
    path: 'pipeline',
    element: <Funnel />,
    children: [
      {
        index: true,
        element: <Navigate to="overview" replace />,
      },
      {
        path: 'overview',
        element: <FunnelReport />,
      },
      {
        path: 'team',
        element: <TeamBuckets />,
      },
      {
        path: '*',
        element: <Navigate to="overview" />,
      },
    ],
  },
  {
    path: 'lead-stats',
    element: <TeamStats />,
  },
  {
    path: 'agent-stats',
    element: <AgentStats />,
  },
  {
    path: 'call-stats',
    element: <AgentCallStats />,
  },
  {
    path: 'agent-call-stats',
    element: <Navigate to="/reports/call-stats" replace />,
  },
  {
    path: 'campaign-buckets',
    element: <CampaignBuckets />,
  },
  {
    path: 'campaign-channels',
    element: <ChannelReport />,
  },
  {
    path: 'listing',
    element: <ListingReport />,
  },
  {
    path: 'campaign',
    element: <CampaignBuckets />,
  },
  {
    path: 'tour',
    element: <TourReport />,
  },
  {
    path: 'marketing',
    element: <MarketingReport />,
  },
  {
    path: '*',
    element: <Navigate to="pipeline" />,
  },
];

export { propertiesRouter, reportsRouter, teamRouter };
