import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Communication } from '../../backend/communication';
import useAxios from '../../hooks/useAxios';

const useInProgressCallCheck = (
  userRef: string | undefined,
  options?: Omit<UseQueryOptions<Communication | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const userHasCallInProgress = async () => {
    if (typeof userRef === 'undefined') return Promise.reject();
    const { data } = await axios.get<Communication>(
      `api/communication/call-in-progress?userRef=${userRef}`,
    );
    return data;
  };

  return useQuery<Communication | undefined, AxiosError>(
    ['callInProgress', userRef],
    userHasCallInProgress,
    {
      enabled: !!userRef,
      retry: false,
      staleTime: 1000 * 10,
      ...options,
    },
  );
};
export default useInProgressCallCheck;
