import { styled } from 'baseui';

export const StyledErrorRoot = styled('div', ({ $theme }) => ({
  alignItems: 'center',
  backgroundColor: $theme.colors.mono100,
  color: $theme.colors.mono900,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  paddingTop: $theme.sizing.scale1600,
  ...$theme.typography.ParagraphSmall,
}));

export const StyledErrorInner = styled('div', ({ $theme }) => ({
  maxWidth: '640px',
  padding: $theme.sizing.scale600,
}));

export const StyledButtonContainer = styled('div', () => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-evenly',
  margin: '0 auto',
  maxWidth: '355px',
}));

export const StyledErrorTechnicalDetails = styled('div', ({ $theme }) => ({
  maxHeight: '120px',
  overflow: 'scroll',
  border: `1px solid ${$theme.colors.negative100}`,
  backgroundColor: $theme.colors.negative50,
  ...$theme.typography.MonoParagraphXSmall,
}));

export const StyledStackContainer = styled('div', ({ $theme }) => ({
  margin: $theme.sizing.scale600,
  wordBreak: 'break-word',
}));

export const StyledErrorLink = styled('span', ({ $theme }) => ({
  color: $theme.colors.primary400,
  textDecoration: 'underline',
  cursor: 'pointer',
  ':hover': {
    color: $theme.colors.primary300,
  },
}));
