import React, { useState, useEffect } from 'react';
import { Button } from 'baseui/button';
import { ParagraphXSmall } from 'baseui/typography';
import useFindContact from 'components/lead/activity/useFindContact';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  StyledContent,
  StyledMessage,
  StyledDescription,
  StyledCaption,
  StyledMessageContainer,
  StyledTitle,
  StyledAction,
} from './styled-components';
import useGetLead from '../lead/hooks/useGetLead';
import { Communication } from '../../backend/communication';
import useNameFromCommunication from '../dashboard/dashboardAlerts/useNameFromCommunication';

type IncomingWebFormPropsT = {
  details: Communication;
  unassignedFollowup: boolean;
  close: (id?: string) => void;
};

const IncomingWebFormAlert = ({ details, unassignedFollowup, close }: IncomingWebFormPropsT) => {
  const [name, setName] = useState<any>();

  const leadQuery = useGetLead(details.leadRef);

  const lead = leadQuery.data;

  const { reverseEmailLookup } = useFindContact(lead);

  const { lookupName } = useNameFromCommunication('Unknown Contact');

  const contact = reverseEmailLookup(details);

  const nameResult = lookupName(
    details,
    lead?.initialCommunicationDetails,
    lead?.mergedInitialCommunicationDetails,
  );

  const navigate = useNavigate();

  const location = useLocation();

  const openLead = () => {
    close();
    const to = `/leads/${details.leadRef}`;
    if (location.pathname !== to) {
      navigate(to);
    }
  };

  useEffect(() => {
    if (contact?.name) {
      setName(contact.name);
      return;
    }
    setName(nameResult);
  }, [contact?.name, nameResult]);

  return (
    <StyledMessageContainer>
      <StyledContent>
        <StyledTitle>New Message</StyledTitle>
        {unassignedFollowup && <StyledCaption>Lead still waiting for a response</StyledCaption>}
        {details.subject && (
          <ParagraphXSmall marginTop="scale200" marginBottom="scale200">
            {details.subject}
          </ParagraphXSmall>
        )}
        <StyledDescription>
          {name} {lead?.tenantName && <span>for {lead.tenantName}</span>}
        </StyledDescription>
        {/* {leadAssignedProperty && (
          <ParagraphXSmall marginTop="scale100" marginBottom="scale100">
            Property: {leadAssignedProperty.name}
          </ParagraphXSmall>
        )} */}
        <StyledMessage>{details?.message}</StyledMessage>
        <StyledAction>
          <Button type="button" shape="pill" size="mini" onClick={openLead}>
            View
          </Button>
        </StyledAction>
      </StyledContent>
    </StyledMessageContainer>
  );
};

export default IncomingWebFormAlert;
