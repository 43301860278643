import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { CampaignT } from '../../../backend/campaign';
import useAxios from '../../../hooks/useAxios';

const useGetCampaignsForTeam = (
  teamId: string | undefined,
  options?: Omit<UseQueryOptions<CampaignT[] | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getCampaignsForTeam = async () => {
    const { data } = await axios.get<CampaignT[]>(`api/team/${teamId}/campaigns`);
    return data;
  };

  return useQuery<CampaignT[] | undefined, AxiosError>(
    ['getCampaignsForTeam', teamId],
    () => getCampaignsForTeam(),
    {
      enabled: !!teamId,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useGetCampaignsForTeam;
