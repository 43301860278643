import React from 'react';
import { useStyletron } from 'baseui';
import { TableInstance } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { StyledTable, StyledTableHead, StyledTableHeadRow } from 'baseui/table-semantic';
import {
  WithStyleTableRoot,
  WithStyleTableHeadCell,
  WithStyleTableBody,
  WithStyleTableBodyRow,
  WithStyleTableBodyCell,
} from './styled-components';

type StandardTableOptionsT = {
  freeze?: 'header' | 'column' | 'both';
  disableRowHoverColor?: boolean;
};

const StandardTable = (props: TableInstance<any> & StandardTableOptionsT) => {
  const [css, theme] = useStyletron();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    disableRowHoverColor,
    freeze,
    visibleColumns,
  } = props;

  return (
    <>
      <WithStyleTableRoot data-id="table-root">
        <StyledTable {...getTableProps()}>
          <StyledTableHead>
            {headerGroups.map((headerGroup) => (
              <StyledTableHeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <WithStyleTableHeadCell
                    $freeze={freeze}
                    {...column.getHeaderProps({
                      ...column.getSortByToggleProps(),
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render('Header')}

                    {column.isSorted ? (
                      <span
                        className={css({
                          paddingLeft: '16px',
                          color: theme.colors.mono500,
                        })}
                      >
                        {column.isSortedDesc ? (
                          <FontAwesomeIcon size="sm" icon={solid('arrow-down')} />
                        ) : (
                          <FontAwesomeIcon size="sm" icon={solid('arrow-up')} />
                        )}
                      </span>
                    ) : null}
                  </WithStyleTableHeadCell>
                ))}
              </StyledTableHeadRow>
            ))}
          </StyledTableHead>
          <WithStyleTableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <WithStyleTableBodyRow
                  {...row.getRowProps()}
                  $disableRowHoverColor={disableRowHoverColor}
                >
                  {
                    // eslint-disable-next-line arrow-body-style
                    row.cells.map((cell) => {
                      return (
                        <WithStyleTableBodyCell $freeze={freeze} {...cell.getCellProps()}>
                          {cell.render('Cell', { index: i })}
                        </WithStyleTableBodyCell>
                      );
                    })
                  }
                </WithStyleTableBodyRow>
              );
            })}
            {!rows.length && (
              <tr>
                <td
                  colSpan={visibleColumns.length}
                  className={css({ textAlign: 'center', color: theme.colors.mono400 })}
                >
                  <div className={css({ margin: theme.sizing.scale600 })}>No Results</div>
                </td>
              </tr>
            )}
          </WithStyleTableBody>
        </StyledTable>
      </WithStyleTableRoot>
    </>
  );
};

StandardTable.defaultProps = {
  // globalFilter: null,
  // subRowFn: undefined,
  // initialState: {},
  // customFilters: {},
  freeze: undefined,
  disableRowHoverColor: false,
};

export default StandardTable;
