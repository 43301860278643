import {
  sub,
  add,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  setDay,
  startOfYear,
} from 'date-fns';
import { Row } from 'react-table';
import { CLIENT_DE_ALT_FIELDS } from '../constants';
import { DateRangeType } from '../shared/types';

export function trimPhoneNumber(phoneNumber: string | undefined) {
  // const pattern = /(?<!^)\+|[^\d+]+/g;
  const pattern = /^(\+)|\D/g;

  if (phoneNumber) {
    return phoneNumber.trim().replace(pattern, '$1');
  }

  return undefined;
}

export function formatCurrencyString(s: string | undefined) {
  if (s) {
    return parseFloat(s.replace(/\$|,/g, ''));
  }
  return null;
}

export function abbreviateBigNumber(num: any) {
  if (num > 999 && num < 1000000) {
    return `$${(num / 1000).toFixed(1)}K`;
  }
  if (num > 1000000) {
    return `$${(num / 1000000).toFixed(1)}M`;
  }
  return `$${num}`;
}

export const addDateRange = (type: DateRangeType) => {
  let start: Date | undefined;
  let end: Date | undefined;

  switch (type) {
    case 'ALL':
      start = undefined;
      end = undefined;
      break;
    case 'TODAY':
      start = startOfDay(new Date());
      end = endOfDay(start);
      break;
    case 'YESTERDAY':
      start = sub(startOfDay(new Date()), { days: 1 });
      end = endOfDay(start);
      break;
    case 'PAST_WEEK':
      end = startOfDay(new Date());
      start = sub(end, { weeks: 1 });
      break;
    case 'PAST_MONTH':
      end = startOfDay(new Date());
      start = sub(end, { months: 1 });
      break;
    case 'TOMORROW':
      start = startOfDay(add(new Date(), { days: 1 }));
      end = endOfDay(start);
      break;
    case 'THIS_WEEK':
      start = startOfWeek(startOfDay(new Date()));
      end = endOfWeek(endOfDay(new Date()));
      break;
    case 'THIS_MONTH':
      start = startOfMonth(startOfDay(new Date()));
      end = endOfMonth(endOfDay(new Date()));
      break;
    case 'LAST_MONTH': {
      const now = new Date();
      const previousMonth = new Date(now.getTime());
      previousMonth.setDate(0);
      end = endOfDay(previousMonth);
      start = startOfDay(startOfMonth(previousMonth));
      break;
    }
    case 'LAST_WEEK': {
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const mon = setDay(now, 1);
      const lastMonday = subWeeks(mon, 1);
      const sunday = add(lastMonday, { days: 6, hours: 23, minutes: 59, seconds: 59 });
      start = lastMonday;
      end = sunday;
      break;
    }
    case 'MTD':
      start = startOfMonth(startOfDay(new Date()));
      end = endOfDay(new Date());
      break;
    case 'YTD':
      start = startOfYear(startOfDay(new Date()));
      end = endOfDay(new Date());
      break;
    case 'CUSTOM':
      start = startOfDay(new Date());
      end = undefined;
      break;
    // no default
  }

  if (type && start && end) {
    return [start, end];
  }

  if (type === 'CUSTOM' && start) {
    return [];
  }

  return undefined;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const replaceInvalidEmailChars = (v: string) => {
  if (!v) return v;
  return v.trim().replace(/[@´&\\:;,“—–<>_[\]()\s]/g, '-');
};

export const concatName = (first: string | undefined, last: string | undefined) => {
  const n = [first, last].filter((v) => v);
  if (n.length) {
    return n.join(' ');
  }
  return undefined;
};

export const decodeParameter = (value: string | undefined) => {
  if (!value) return undefined;

  return decodeURIComponent(value).split('=')[1];
};

export const makeNumberRange = (len: number, min = 0) => {
  const arr = [];
  for (let i = min; i < len; i += 1) {
    arr.push(i);
  }
  return arr;
};

export const sortStrings = (a: string, b: string) =>
  a.localeCompare(b, 'en', { sensitivity: 'base' });

export const compareBasic = (a: any, b: any) => {
  if (a === b) return 0;

  return a > b ? 1 : -1;
};

export const sortDates = (d1: Date, d2: Date, desc: boolean = true) => {
  const prev = d1.getTime();
  const curr = d2.getTime();

  if (desc) {
    return curr - prev;
  }
  return prev - curr;
};

export const customDateSort = (a: Row<any>, b: Row<any>, columnId: string) => {
  const valueA: Date | undefined = a.original[columnId];
  const valueB: Date | undefined = b.original[columnId];
  const t1 = valueA?.getTime();
  const t2 = valueB?.getTime();

  if (!valueA) {
    return 1;
  }
  if (!valueB) {
    return -1;
  }
  if (t1 && t2) {
    if (t1 > t2) {
      return 1;
    }
    if (t1 < t2) {
      return -1;
    }
  }
  return 0;
};

export const sortExceptions = (a: string, b: string, exceptions: string[], first?: boolean) => {
  const indexA = exceptions.indexOf(a);
  const indexB = exceptions.indexOf(b);
  if (indexA === -1 && indexB === -1) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  }
  return first ? indexB - indexA : indexA - indexB;
};

export const parseFileName = (filename: string, ext?: boolean) =>
  ext ? filename.split('.').pop() : filename.split('.').shift();

export const getNumberOfBedrooms = (value: string | number | undefined, clientId?: string) => {
  if (clientId && CLIENT_DE_ALT_FIELDS.includes(clientId)) {
    if (value === 0 || value === '0') return 'Studio';
    return value;
  }
  if (value === 0 || value === '0') return 'Studio+';
  return value ? `${value}+` : value;
};
