import React from 'react';
import { Button } from 'baseui/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { ParagraphSmall } from 'baseui/typography';
import {
  StyledAction,
  StyledContent,
  // StyledIconContainer,
  StyledMessage,
  StyledMessageContainer,
  StyledTitle,
} from './styled-components';

import { Lead } from '../../backend/lead';
import useGetProperties from '../properties/hooks/useGetProperties';

type NewLeadAlertPropsT = {
  title: string;
  details: Lead;
  message?: string;
};

const NewLeadAlert = ({ title, details, message }: NewLeadAlertPropsT) => {
  const navigate = useNavigate();

  const location = useLocation();

  const propertiesQuery = useGetProperties();

  const properties = propertiesQuery.data;

  const assignedPropertyRef = details?.assignedProperty?.propertyRef;

  const leadAssignedProperty = properties?.filter(
    (property) => property.id === assignedPropertyRef,
  )[0];

  const handleClick = () => {
    const path = `/leads/${details.id}`;
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  return (
    <StyledMessageContainer>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        {message && <StyledMessage>{message}</StyledMessage>}
        {leadAssignedProperty && (
          <ParagraphSmall marginTop="scale100" marginBottom="scale100">
            {leadAssignedProperty.name}
          </ParagraphSmall>
        )}
        <StyledAction>
          <Button type="button" shape="pill" size="mini" onClick={handleClick}>
            View
          </Button>
        </StyledAction>
      </StyledContent>
    </StyledMessageContainer>
  );
};

export default NewLeadAlert;
