import { useMutation, useQueryClient } from 'react-query';
import useAxios from '../../../hooks/useAxios';

type MoveCommunicationT = {
  targetLeadRef: string;
};

const useMoveCommunication = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  const moveCommunication = (leadId: string, commId: string, targetLeadRef: string) =>
    axios.post<MoveCommunicationT>(
      `api/lead/${leadId}/communication/${commId}${
        targetLeadRef ? `?targetLeadRef=${targetLeadRef}` : ''
      }`,
    );

  return useMutation(
    ({ leadId, commId, targetLeadRef }: any) => moveCommunication(leadId, commId, targetLeadRef),
    {
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(['getLead', variables.leadId]);
        queryClient.invalidateQueries(['getLead', variables.targetLeadRef]);
      },
      onError: (err: any) => err,
    },
  );
};

export default useMoveCommunication;
