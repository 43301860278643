import { Communication } from 'backend/communication';
import { ContactDetails, Lead } from 'backend/lead';

const searchContacts = (
  contacts: ContactDetails[] | undefined,
  number: string | string[] | undefined,
) =>
  contacts?.filter((contact) =>
    Array.isArray(number)
      ? contact.phoneNumbers?.some((ph) => number?.includes(ph.number))
      : contact.phoneNumbers?.some((ph) => ph.number === number),
  )[0];

const useFindContact = (lead: Lead | undefined) => {
  const reversePhoneLookup = (comm: Communication | undefined): ContactDetails | undefined => {
    let number: string | string[] | undefined;

    const type = comm?.communicationType;

    const direction = comm?.communicationDirection;

    switch (type) {
      case 'CALL':
        number = direction === 'INCOMING' ? comm?.calledFromNumber : comm?.calledToNumber;
        break;
      case 'TEXT_MESSAGE':
        number = direction === 'INCOMING' ? comm?.textedFromNumber : comm?.textedToNumber;
        break;
      case 'WEBFORM':
        number = comm?.phoneNumbers;
        break;
      // no default
    }
    const matchedContact = searchContacts(lead?.contacts, number);

    if (matchedContact) {
      const selectNumber = Array.isArray(number)
        ? matchedContact?.phoneNumbers?.filter((phone) => phone.number === number?.[0])
        : matchedContact?.phoneNumbers?.filter((phone) => phone.number === number);

      return {
        ...matchedContact,
        phoneNumbers: selectNumber,
      };
    }

    return undefined;
  };

  const reverseEmailLookup = (comm: Communication | undefined) => {
    const direction = comm?.communicationDirection;
    const match = direction === 'INCOMING' ? comm?.fromAddress : comm?.toAddress;
    return lead?.contacts.filter((contact) => contact.email === match)[0];
  };

  return { reversePhoneLookup, reverseEmailLookup };
};

export default useFindContact;
