import { useEffect, useState } from 'react';
import { IconPaperclip, IconTextPlus } from '@tabler/icons-react';
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';
import StarterKit from '@tiptap/starter-kit';
import HardBreak from '@tiptap/extension-hard-break';
import TextStyle from '@tiptap/extension-text-style';
import { EditorContent, useEditor } from '@tiptap/react';
import { UseFormReturn, Controller } from 'react-hook-form';
import { ShareMedia } from 'components/textEditor/extensions/shareMedia';
import { ModalHeader, ModalBody } from 'baseui/modal';
import Marketing from '../media';
import { MarketingMaterial } from '../../backend/client';
import PickTemplate from '../team/templated-response/pick-template';
import { useModal } from '../modal/ModalProvider';

type TextMessageFooterPropsT = {
  teamRef: string | undefined;
  assignedPropertyRef?: string;
  onSubmit: (formData: any) => void;
};

function TextMessageFooter({
  onSubmit,
  teamRef,
  assignedPropertyRef,
  ...rest
}: TextMessageFooterPropsT & UseFormReturn<any>) {
  const [css, theme] = useStyletron();

  const [isEditorDirty, setIsEditorDirty] = useState(false);

  const { modalHandler, closeModal } = useModal();

  const { control, handleSubmit, setValue, getValues } = rest;

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          blockquote: false,
          bulletList: false,
          codeBlock: false,
          hardBreak: false,
          heading: false,
          horizontalRule: false,
          listItem: false,
          orderedList: false,
          bold: false,
          code: false,
          italic: false,
          strike: false,
          paragraph: {
            HTMLAttributes: {
              class: css({ margin: 0 }),
            },
          },
        }),
        TextStyle,
        HardBreak.extend({
          addKeyboardShortcuts() {
            return {
              // eslint-disable-next-line react/no-this-in-sfc
              Enter: () => this.editor.chain().setHardBreak().scrollIntoView().run(),
            };
          },
        }),
        ShareMedia.configure({
          HTMLAttributes: {
            class: css({
              backgroundColor: theme.colors.mono200,
              color: theme.colors.mono800,
              borderRadius: '20px',
              paddingTop: theme.sizing.scale0,
              paddingBottom: theme.sizing.scale0,
            }),
          },
          InnerHTMLAttributes: {
            class: css({
              paddingLeft: theme.sizing.scale100,
              paddingRight: theme.sizing.scale100,
            }),
          },
        }),
      ],
      autofocus: true,
      editorProps: {
        attributes: {
          class: css({
            outline: 'none',
            height: '72px',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderWidth: '2px',
            borderRadius: '4px',
            borderStyle: 'solid',
            borderColor: theme.colors.mono200,
            ...theme.typography.ParagraphSmall,
            lineHeight: '20px',
            paddingTop: theme.sizing.scale100,
            paddingBottom: theme.sizing.scale100,
            paddingLeft: theme.sizing.scale300,
            paddingRight: theme.sizing.scale300,
          }),
        },
      },
    },
    [],
  );

  const handleAttachMarketing = (
    selectedMedia: (MarketingMaterial & { propertyName: string })[],
  ) => {
    if (editor) {
      selectedMedia.forEach((r) => {
        editor
          .chain()
          .focus()
          .insertShareMedia({
            id: r.id,
            name: r.name,
            propertyName: r.propertyName,
            count: selectedMedia.length,
          })
          .run();
      });
    }
    closeModal();
  };

  const insetMarketing = () => {
    modalHandler(
      <>
        <ModalHeader>Attach Marketing Material</ModalHeader>
        <ModalBody>
          <Marketing
            teamRef={teamRef}
            propertyRef={assignedPropertyRef}
            onAttach={handleAttachMarketing}
            onClose={closeModal}
          />
        </ModalBody>
      </>,
    );
  };

  const insertTemplate = (template: string) => {
    if (editor) {
      editor.commands.insertContent(template);
    }
    closeModal();
  };

  const selectTemplate = () => {
    if (teamRef) {
      modalHandler(<PickTemplate teamRef={teamRef} onInsert={insertTemplate} />);
    }
  };

  const clearEditor = () => {
    editor?.commands.clearContent();
    setIsEditorDirty(false);
  };

  useEffect(() => {
    if (editor) {
      setIsEditorDirty(false);

      editor?.on('update', () => {
        setIsEditorDirty(!editor.isEmpty);
      });
    }

    return () => {
      setIsEditorDirty(false);
      editor?.off('update');
    };
  }, [editor]);

  useEffect(() => () => closeModal(), [closeModal]);

  return (
    <>
      <form
        onSubmit={handleSubmit(() => {
          setValue('message', editor?.getHTML());
          onSubmit(getValues());
          clearEditor();
        })}
      >
        <div
          className={css({
            padding: theme.sizing.scale400,
          })}
        >
          <Controller
            name="message"
            control={control}
            // rules={{ required: true }}
            render={() => <EditorContent editor={editor} />}
          />

          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: theme.sizing.scale0,
            })}
          >
            <div
              className={css({ alignItems: 'center', display: 'flex', gap: theme.sizing.scale300 })}
            >
              <Button
                type="button"
                size="compact"
                kind="tertiary"
                shape="circle"
                onClick={insetMarketing}
              >
                <IconPaperclip size={16} />
              </Button>
              <Button
                type="button"
                size="compact"
                kind="tertiary"
                shape="circle"
                onClick={selectTemplate}
              >
                <IconTextPlus size={16} />
              </Button>
            </div>
            <Button size="compact" type="submit" disabled={!isEditorDirty}>
              Send
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}

TextMessageFooter.defaultProps = {
  assignedPropertyRef: undefined,
};

export default TextMessageFooter;
