// import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClient } from '~/context/client-context';
import { Team } from '../../../backend/team';
import useGetTeam from '../hooks/useGetTeam';

type ProviderPropsT = {
  children: React.ReactNode;
};

const TeamContext = React.createContext<Team | undefined>(undefined);

TeamContext.displayName = 'TeamContext';

function TeamContextProvider({ children }: ProviderPropsT) {
  const navigate = useNavigate();

  const params = useParams<{ id: string }>();

  const query = useGetTeam(params.id);

  const client = useClient();

  useEffect(() => {
    if (query.data?.id) {
      localStorage.setItem('currentTeam', query.data?.id);
    }
  }, [query.data?.id]);

  if (query.isLoading) {
    return <div>Loading Team....</div>;
  }

  if (query.isError) {
    if (client.state.isOwner) {
      navigate('../properties', { replace: true });
    } else {
      navigate('../', { replace: true });
    }
    // throw new Error((query.error as AxiosError).message);
  }

  return <TeamContext.Provider value={query.data}>{children}</TeamContext.Provider>;
}

function useTeamContext() {
  const context = React.useContext(TeamContext);
  if (!context) {
    throw new Error('useTeamContext must be used within provider context.');
  }
  return context;
}

export { TeamContextProvider, useTeamContext };
