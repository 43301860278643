import { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import {
  StyledTextMessageContainer,
  StyledTextMessageInfo,
  StyledTextMessagePanel,
} from './styled-components';

import TextMessageHeader from './text-message-header';

import { TextMessageContext } from './text-message-context';

import TextMessage from './text-message';

type TextMessageFormT = {
  message: string;
};

function TextMessagePopout() {
  const {
    state: { isOpen, name = '', leadRef: leadId },
    dispatch,
  } = useContext(TextMessageContext);

  const queryClient = useQueryClient();

  const [unread, setUnread] = useState(false);

  const formMethods = useForm<TextMessageFormT>({
    defaultValues: {
      message: '',
    },
  });

  const [isMinimized, setIsMinimized] = useState(false);

  const { reset } = formMethods;

  const toggleMinimizedHandler = () => {
    setIsMinimized(!isMinimized);
  };

  const handleClose = () => {
    dispatch({
      isOpen: false,
      contactType: '',
      leadRef: '',
      targetContactRef: null,
      targetPhoneNumber: '',
    });
    queryClient.invalidateQueries(['getLead', leadId]);
    reset();
  };

  useEffect(() => {
    if (!isMinimized && unread) {
      setUnread(false);
    }
  }, [isMinimized, unread]);

  if (!isOpen) return null;

  return (
    <StyledTextMessageContainer data-struct="text-message-popout" $isMinimized={isMinimized}>
      <TextMessageHeader
        onClose={handleClose}
        isUnread={unread}
        isMinimized={isMinimized}
        handleMinimize={toggleMinimizedHandler}
      />
      <StyledTextMessagePanel $isMinimized={isMinimized}>
        <StyledTextMessageInfo>{name}</StyledTextMessageInfo>
        <TextMessage />
      </StyledTextMessagePanel>
    </StyledTextMessageContainer>
  );
}

export default TextMessagePopout;
