import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import useAxios from '../../hooks/useAxios';
import { Communication } from '../../backend/communication';

const useTextThread = (
  id: string,
  texterNumber: string,
  options?: Omit<UseQueryOptions<Communication[] | undefined, AxiosError>, 'queryKey' | 'queryFn'>,
) => {
  const axios = useAxios();

  const getTextThread = async () => {
    const { data } = await axios.get<Communication[]>(
      `api/lead/${id}/text-thread?phone=${encodeURIComponent(texterNumber)}`,
    );
    return data;
  };
  return useQuery<Communication[] | undefined, AxiosError>(
    ['getTextThread', id, texterNumber],
    () => getTextThread(),
    {
      enabled: !!id && !!texterNumber,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default useTextThread;
