import useGetNumbersForPurchase from '~/hooks/use-get-numbers-for-purchase';
import usePurchasePhoneNumber from '~/hooks/use-purchase-phone-number';
import React, { useEffect } from 'react';
import { Button } from 'baseui/button';
import { StatefulTooltip } from 'baseui/tooltip';
import { AutosizeInput, OnChangeParams } from 'baseui/select';
import { Spinner } from 'baseui/spinner';
import { useStyletron } from 'baseui';
import { useQueryClient } from 'react-query';
import { PatternFormat } from 'react-number-format';
import { useFormContext } from 'react-hook-form';
import { FormControl } from 'baseui/form-control';
import { ErrorMessage } from '@hookform/error-message';
import { LabelSmall } from 'baseui/typography';
import RequiredFieldMarker from '../../shared/RequiredFieldMarker';
import { ProvisionPhoneNumbers } from '../../../backend/team';
import ControlledSelect from './ControlledSelect';

type PropsT = {
  onPurchaseSuccess?: (response?: ProvisionPhoneNumbers) => any;
  onPurchaseError?: (response?: any) => any;
  twilioBusinessProfileRef?: string | null;
};

const CustomInput = React.forwardRef((props, ref) => {
  const mod: any & { maxLength: number } = {
    ...props,
    maxLength: 3,
  };
  return <AutosizeInput {...mod} inputRef={ref as any} />;
});

const PurchasePhoneNumberSelect = (props: PropsT) => {
  const [css, theme] = useStyletron();

  const {
    control,
    clearErrors,
    getValues,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useFormContext();

  const controlRef = React.useRef<any>();

  const queryClient = useQueryClient();

  const { mutate, isLoading: isWaitingPurchase } = usePurchasePhoneNumber();

  const { onPurchaseError, onPurchaseSuccess, twilioBusinessProfileRef } = props;

  const [areaCode$, phone$] = watch(['areaCode', 'phone']);

  const { data, isLoading } = useGetNumbersForPurchase(
    { areaCode: areaCode$, businessProfileId: twilioBusinessProfileRef },
    { staleTime: 0 },
  );

  const resetValues = () => {
    clearErrors(['areaCode', 'phone', 'phoneType']);
    setValue('phone', []);
    queryClient.removeQueries('getNumbersForPurchase');
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    clearErrors(['phone']);
    setValue('areaCode', target.value);
    if (target.value.length < 3) {
      resetValues();
    }
  };

  useEffect(
    () => () => {
      // clear on unmount
      queryClient.removeQueries('getNumbersForPurchase');
    },
    [queryClient],
  );

  const purchasePhoneHandler = () => {
    const number = getValues('phone')?.[0].id;

    if (number && typeof twilioBusinessProfileRef === 'string') {
      mutate(
        { number, busProfile: twilioBusinessProfileRef },
        {
          onSuccess: (res) => {
            if (typeof onPurchaseSuccess === 'function') {
              onPurchaseSuccess(res.data);
            }
          },
          onError: (e) => {
            // controlRef.current.setInputValue('');
            setValue('areaCode', '');
            resetValues();
            setError('phone', {
              message: 'The phone number is no longer available. Try a new search.',
            });
            if (typeof onPurchaseError === 'function') {
              onPurchaseError(e);
            }
          },
        },
      );
    }
  };

  return (
    <>
      <div data-profile={twilioBusinessProfileRef}>
        <LabelSmall marginTop={theme.sizing.scale300} marginBottom={theme.sizing.scale300}>
          <RequiredFieldMarker>Search for new phone number</RequiredFieldMarker>
        </LabelSmall>
      </div>
      <div
        data-si="WRAPPER"
        className={css({
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginBottom: theme.sizing.scale600,
        })}
      >
        <div className={css({ flexGrow: '1', flexShrink: 0, flexBasis: '225px' })}>
          <FormControl
            overrides={{
              ControlContainer: {
                style: () => ({
                  marginBottom: 0,
                }),
              },
            }}
          >
            <ControlledSelect
              control={control}
              name="phone"
              errPath={errors?.areaCode?.message}
              onInputChange={onInputChange}
              onChangeHandler={(v: OnChangeParams) => {
                if (v.type !== 'select') {
                  setValue('areaCode', '');
                  resetValues();
                }
              }}
              baseuiProps={{
                controlRef,
                isLoading,
                disabled: !twilioBusinessProfileRef,
                // onBlurResetsInput: false,
                size: 'compact',
                clearable: false,
                searchable: true,
                placeholder: data?.[0]?.number ? 'Select Phone Number' : 'Search by Area Code',
                options: data?.map((result) => ({
                  label: result.number,
                  id: result.number,
                })),
                noResultsMsg: isLoading ? (
                  'Searching...'
                ) : (
                  <>
                    <div>
                      {/\d{3}/.test(areaCode$) ? 'No available phone number.' : 'No Results'}
                    </div>
                    <div>Try a different area code</div>
                  </>
                ),
                overrides: {
                  Input: {
                    component: CustomInput,
                  },
                  Placeholder: {
                    style: ({ $theme }) => ({
                      color: data?.[0]?.number ? $theme.colors.mono900 : $theme.colors.mono400,
                    }),
                  },
                  ControlContainer: {
                    style: () => ({
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }),
                  },
                },
                getOptionLabel: ({ option }: any) => (
                  <div className={css({ display: 'flex', justifyContent: 'space-between' })}>
                    <PatternFormat
                      displayType="text"
                      value={option?.label}
                      format="+# (###) ###-####"
                    />
                    <Button kind="secondary" size="mini" type="button">
                      Select
                    </Button>
                  </div>
                ),
                getValueLabel: ({ option }: any) => (
                  <PatternFormat
                    displayType="text"
                    value={option?.label}
                    format="+# (###) ###-####"
                  />
                ),
              }}
            />
          </FormControl>
        </div>

        <StatefulTooltip
          showArrow
          placement="bottom"
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                borderTopLeftRadius: $theme.sizing.scale100,
                borderTopRightRadius: $theme.sizing.scale100,
                borderBottomLeftRadius: $theme.sizing.scale100,
                borderBottomRightRadius: $theme.sizing.scale100,
              }),
            },
            Inner: {
              style: ({ $theme }) => ({
                paddingLeft: $theme.sizing.scale400,
                paddingRight: $theme.sizing.scale400,
              }),
            },
          }}
          content="Click to add to your account"
        >
          <Button
            size="compact"
            type="button"
            onClick={purchasePhoneHandler}
            disabled={!phone$?.[0]?.id || isWaitingPurchase}
            overrides={{
              BaseButton: {
                style: { flex: '1 0 auto', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
              },
            }}
            startEnhancer={
              isWaitingPurchase ? (
                <Spinner $color={theme.colors.mono500} $borderWidth="3px" $size="11px" />
              ) : null
            }
          >
            {isWaitingPurchase ? 'Processing...' : 'Add Phone Number'}
          </Button>
        </StatefulTooltip>

        <div
          className={css({
            flex: '1 0 100%',
            color: theme.colors.negative400,
            ...theme.typography.font100,
            marginTop: theme.sizing.scale300,
            marginBottom: theme.sizing.scale300,
          })}
        >
          <ErrorMessage errors={errors} name="areaCode" />{' '}
          <ErrorMessage errors={errors} name="phone" />{' '}
          {/* <ErrorMessage errors={errors} name="phoneType" /> */}
        </div>
      </div>
    </>
  );
};

export default PurchasePhoneNumberSelect;
