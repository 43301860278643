import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useCurrentUserContext } from '~/context/current-user-context';
import { StyledLink } from '../shared/styles';
import { useClient } from '../../context/client-context';
import useGetTeamsForUser from '../team/hooks/useGetTeamsForUser';
import Banner from '../screen/Banner';

const EmailConnectionFail = () => {
  const client = useClient();

  const { data } = useGetTeamsForUser();

  const { teamPermissions } = useCurrentUserContext();

  const list = data
    ?.filter((team) => team.emailAuthStatus === 'FAILED')
    .map((record) => ({ id: record.id, name: record.name }));

  const team = list?.[0];

  const teamPermission = team ? teamPermissions[team.id] : undefined;

  const canManageConfiguration =
    teamPermission && (teamPermission.fullAccess || teamPermission.manageCampaigns);

  const link = `/${client.state?.displayValueAlt.toLowerCase()}/${team?.id}/settings`;

  return (
    <Banner
      appearance="warning100"
      isOpen={!!team}
      icon={<FontAwesomeIcon size="1x" icon={regular('flag')} />}
      title="Authorization Required."
    >
      Approval to use organization email account for {team?.name} needed.{' '}
      {canManageConfiguration && (
        <StyledLink to={link}>
          Update {client.state?.displayValue.toLowerCase()} settings.
        </StyledLink>
      )}
    </Banner>
  );
};

export default EmailConnectionFail;
