import React, { useEffect, useCallback } from 'react';
import { useCurrentUserId } from 'context/current-user-context';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useMatch } from 'react-router-dom';
import LeadTaken from './LeadTaken';
import LeadClaimed from './LeadClaimed';
import LeadChangedAlert from './LeadChangedAlert';
import LeadAssignedAlert from './LeadAssignedAlert';
import { useWebSocketObservable } from '../../context/web-socket-context';
import { LeadChangeDetail, MessageEvent } from '../../backend/messages';

const useLeadSubscription = () => {
  const queryClient = useQueryClient();

  const match = useMatch('/leads/:id');

  const currentUserId = useCurrentUserId();

  const messages$ = useWebSocketObservable();

  const closeToast = useCallback(() => {
    toast.dismiss();
  }, []);

  const openToast = useCallback(
    ({ details, eventType }: MessageEvent<LeadChangeDetail>) => {
      try {
        switch (eventType) {
          case 'LEAD_ASSIGNED': {
            // Lead assigned TO currently logged in user
            if (currentUserId === details.to) {
              toast(<LeadAssignedAlert details={details} close={closeToast} />, { delay: 100 });
            }

            // Lead currently in view and lead is assigned TO someone else
            if (match && match.params?.id === details.id && currentUserId !== details.to) {
              toast(<LeadTaken details={details} />);
            }
            break;
          }

          case 'LEAD_CLAIMED': {
            // Lead currently in view and is claimed BY someone else
            if (match && match.params?.id === details.id && currentUserId !== details.by) {
              toast(<LeadClaimed details={details} />);
            }
            break;
          }

          case 'LEAD_CHANGED': {
            // Lead currently in view has been updated by someone else.
            // Must be on team
            if (match && match.params?.id === details.id && currentUserId !== details.by) {
              toast(<LeadChangedAlert details={details} />);
            }
            break;
          }
          // no default
        }
        queryClient.invalidateQueries(['getLead', details.id]);
        queryClient.invalidateQueries(['getAlerts']);
        queryClient.invalidateQueries(['getUserSummaryReport']);
        queryClient.invalidateQueries(['getTeamSummary']);
      } catch (e) {
        console.log(e);
      }
    },
    [currentUserId, queryClient, match, closeToast],
  );

  useEffect(() => {
    const sub = messages$?.subscribe({
      next: (messageEvent: MessageEvent<LeadChangeDetail>) => {
        openToast({ ...messageEvent });
      },
      error: (e) => console.error(e),
      complete: () => console.info('[useLeadSubscription] complete'),
    });

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [messages$, openToast]);

  return { closeToast };
};

export default useLeadSubscription;
