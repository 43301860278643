import React from 'react';
import { FormControl } from 'baseui/form-control';
import { useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import ControlledSelect from './ControlledSelect';
import useAvailablePhoneNumbers from '../hooks/useAvailablePhoneNumbers';
import RequiredFieldMarker from '../../shared/RequiredFieldMarker';

const AvailablePhoneNumbers = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const availablePhoneNumbers = useAvailablePhoneNumbers();

  return (
    <FormControl
      label={<RequiredFieldMarker>Select a phone number</RequiredFieldMarker>}
      error={errors.phone?.message as string | undefined}
    >
      <ControlledSelect
        control={control}
        name="phone"
        baseuiProps={{
          size: 'compact',
          clearable: false,
          searchable: true,
          maxDropdownHeight: '220px',
          options: availablePhoneNumbers.data?.map((opt) => ({
            label: `${opt.number}`,
            id: opt.id,
          })),
          getOptionLabel: ({ option }: any) => (
            <PatternFormat displayType="text" value={option?.label} format="+# (###) ###-####" />
          ),
          getValueLabel: ({ option }: any) => (
            <PatternFormat displayType="text" value={option?.label} format="+# (###) ###-####" />
          ),
        }}
      />
    </FormControl>
  );
};

export default AvailablePhoneNumbers;
