import { FormControl } from 'baseui/form-control';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import ControlledSelect from '~/components/team/components/ControlledSelect';
import useBusinessProfiles from '~/hooks/use-business-profiles';

function TwilioBusinessProfileForm() {
  const { data: profiles } = useBusinessProfiles();

  const { control, setValue } = useFormContext();

  const items = profiles?.map((profile) => ({ id: profile.id, label: profile.name }));

  useEffect(() => {
    if (profiles) {
      setValue('twilioBusinessProfileRef', [{ id: profiles[0].id, label: profiles[0].name }], {
        shouldDirty: false,
      });
    }
  }, [profiles, setValue]);

  return (
    <FormControl label="Business Profile Label">
      <ControlledSelect
        baseuiProps={{
          backspaceRemoves: false,
          clearable: false,
          searchable: false,
          size: 'compact',
          options: items,
        }}
        control={control}
        name="twilioBusinessProfileRef"
      />
    </FormControl>
  );
}

export default TwilioBusinessProfileForm;
