import * as yup from 'yup';
import { isAfter } from 'date-fns';
import { BaseUiOption } from '../../../shared/types';
import 'yup-phone-lite';
import { ClosedReason, RejectedReason } from '../../../backend/lead';
import { EMAIL_REGEXP } from '../../../constants';

export const arraySchema = yup
  .array()
  .nullable()
  .of(yup.object({ label: yup.string(), id: yup.string() }))
  .required('Selection Required')
  .min(1, 'Selection Required');

export const leadTypeSchema = yup.object({
  leadType: yup.string().required('Selection Required'),
});

export const tenantNameSchema = yup.object({
  tenantName: yup.string().required('Required'),
});

export const zipcodeSchema = yup.object({
  zipcode: yup
    .string()
    .required('Required')
    .matches(/^[0-9]+$/, 'Only numeric zip code allowed.')
    .min(5, 'Must be exactly 5 digits.')
    .max(5, 'Must be exactly 5 digits.'),
});

export const tenantContactSchema: yup.AnyObjectSchema = yup.object().shape(
  {
    name: yup.string().required('Required'),
    primaryContact: yup
      .boolean()
      .test('hasDefaultContact', 'A primary tenant contact is required.', function t(value) {
        const otherTenantContacts = this.options.context?.otherTenantContacts;
        if (!otherTenantContacts?.length) {
          return true;
        }
        if (value) {
          return true;
        }
        const hasDefaultContact = otherTenantContacts.some(
          (contact: any) => contact.primaryContact,
        );
        return hasDefaultContact;
      }),
    phoneNumbers: yup
      .array()
      .of(
        yup.object({
          phoneType: yup.array().when('number', {
            is: (ph: string) => ph,
            then: arraySchema,
          }),
          number: yup
            .string()
            .test('test-phone', 'Phone must be a valid phone number.', (value) => {
              if (value) return yup.string().phone('US').isValidSync(value);
              return true;
            }),
          ext: yup.string(),
        }),
      )
      .test('unique', 'Duplicate phone number found', function m(list) {
        const otherContacts = this.options.context?.otherContacts;

        const combined = list ? [...list, ...otherContacts] : otherContacts;

        const filtered = combined
          .filter((v: any) => v.number)
          .map((v: any) => v.number?.replace(/\D+/g, '') + (v.ext || ''));

        if (filtered?.length === new Set(filtered).size) {
          return true;
        }
        throw this.createError({
          path: `${this.path}.unique`,
        });
      })
      .when(['email', '$leadType'], {
        is: (email: string) => !email || email.length === 0,
        then: yup.array().test('requireOne', 'requireOne', function test(arr) {
          if (!arr) return true;
          const isValid = arr?.some((v) => v.number);
          return isValid
            ? true
            : this.createError({
              message: 'One form of contact is required',
              path: 'phoneNumbers.0.number',
            });
        }),
      }),
    email: yup
      .string()
      .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email address' })
      .when(['phoneNumbers', '$leadType'], {
        is: (phoneNumbers: { number: string }[]) =>
          !phoneNumbers || !phoneNumbers.some((phone) => phone.number),
        then: yup.string().required('One form of contact is required'),
      }),
  },
  [['email', 'phoneNumbers']],
);

export const repContactSchema: yup.AnyObjectSchema = yup.object().shape(
  {
    name: yup.string().required('Required'),
    company: yup.string().required('Required'),
    phoneNumbers: yup
      .array()
      .of(
        yup.object({
          phoneType: yup.array().when('number', {
            is: (ph: string) => ph,
            then: arraySchema,
          }),
          number: yup
            .string()
            .test('test-phone', 'Phone must be a valid phone number.', (value) => {
              if (value) return yup.string().phone('US').isValidSync(value);
              return true;
            }),
          ext: yup.string(),
        }),
      )
      .test('unique', 'Duplicate phone number found', function m(list) {
        const otherContacts = this.options.context?.otherContacts;

        const combined = list ? [...list, ...otherContacts] : otherContacts;

        const filtered = combined
          .filter((v: any) => v.number)
          .map((v: any) => v.number?.replace(/\D+/g, '') + (v.ext || ''));

        if (filtered?.length === new Set(filtered).size) {
          return true;
        }
        throw this.createError({
          path: `${this.path}.unique`,
        });
      })
      .when(['email', '$leadType'], {
        is: (email: string) => !email || email.length === 0,
        then: yup.array().of(
          yup.object({
            phoneType: arraySchema,
            number: yup.string().phone('US').required('One form of contact is required'),
          }),
        ),
      }),
    email: yup
      .string()
      .matches(EMAIL_REGEXP, { excludeEmptyString: true, message: 'Invalid email address' })
      .when(['phoneNumbers', '$leadType'], {
        is: (phoneNumbers: { number: string }[]) =>
          !phoneNumbers || !phoneNumbers.some((phone) => phone.number),
        then: yup.string().required('One form of contact is required'),
      }),
  },
  [['email', 'phoneNumbers']],
);

export const leadListingSchema: yup.AnyObjectSchema = yup.object({
  propertyRef: arraySchema,
});

export const assignLeadSchema = yup.object({
  targetUserRef: arraySchema,
});

export const mergeLeadSchema = yup.object({
  targetLead: arraySchema,
});

export const invalidLeadSchema: yup.AnyObjectSchema = yup.object({
  reason: arraySchema,
  otherDesc: yup.string().when('reason', {
    is: (reason: BaseUiOption<RejectedReason>[]) => reason.length && reason[0].id === 'OTHER',
    then: yup.string().required('Required'),
  }),
});

export const changePropertyStatusSchema: yup.AnyObjectSchema = yup.object({
  propertyStatus: yup.string().required(),
  propertyNotInterestedReason: arraySchema,
  notInterestedDesc: yup.string().when('propertyNotInterestedReason', {
    is: (propertyNotInterestedReason: BaseUiOption<RejectedReason>[]) =>
      propertyNotInterestedReason.length && propertyNotInterestedReason[0].id === 'OTHER',
    then: yup.string().required('Required'),
  }),
});

export const commentsSchema = yup.object({
  text: yup.string().required('Required'),
});

export const reassignSchema = yup.object({
  targetUserRef: arraySchema,
});

export const moveCommunicationSchema = yup.object({
  targetLeadRef: arraySchema,
});

export const noLongerLookingSchema: yup.AnyObjectSchema = yup.object({
  closedReason: arraySchema,
  otherDesc: yup.string().when('closedReason', {
    is: (closedReason: BaseUiOption<ClosedReason>[]) =>
      closedReason.length && closedReason[0].id === 'OTHER',
    then: yup.string().required('Required'),
  }),
});

export const shareLeadSchema = yup.object({
  teamRef: arraySchema,
  propertyRef: yup
    .array()
    .nullable()
    .when('$isOwner', {
      is: (isOwner: boolean) => !isOwner,
      then: arraySchema,
    }),
  userRef: yup.array().of(yup.object({ label: yup.string(), id: yup.string() })),
  message: yup.string(),
});

export const leasedSchema = yup.object({
  leasedDate: yup.string().required('Required').nullable(),
  leasedExpiresDate: yup
    .string()
    .nullable()
    .test('date-range', 'Lease end date should be after start date', (value, context) => {
      if (value && context.parent.leasedDate) {
        const result = isAfter(new Date(value), new Date(context.parent.leasedDate));
        return result;
      }
      return true;
    }),
});
